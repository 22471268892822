import { BaseWidgetEntityWithWellId } from 'src/entities/widget/BaseWidgetEntityWithWellId';

import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';

function findFirstWellIdAmongWidgets(widgets: WidgetEntity[], currentWidget: WidgetEntity): number | null {
  for (const widget of widgets) {
    if (widget instanceof BaseWidgetEntityWithWellId) {
      if (widget === currentWidget) {
        continue;
      }

      if (widget.wellId !== null) {
        return widget.wellId;
      }
    }
  }

  return null;
}

export function setWellIdInGroup(widgets: WidgetEntity[], currentWidget: WidgetEntity): void {
  let currentWellId: null | number = null;

  const isWidgetWithWellId = currentWidget instanceof BaseWidgetEntityWithWellId;

  if (isWidgetWithWellId) {
    currentWellId = currentWidget.wellId;
  }

  const foundWellId = findFirstWellIdAmongWidgets(widgets, currentWidget);

  if (foundWellId === null && currentWellId === null) return;

  if (foundWellId !== null) {
    if (isWidgetWithWellId) {
      currentWidget.setWellId(foundWellId);
    }
  } else if (foundWellId === null && currentWellId !== null) {
    for (const widget of widgets) {
      if (widget instanceof BaseWidgetEntityWithWellId) {
        widget.setWellId(currentWellId);
      }
    }
  }
}
