import { action, makeObservable, observable } from 'mobx';

import { requireService } from 'src/packages/di';

import type { IFullscreenService } from './IFullscreenService';

export class FullscreenService implements IFullscreenService {
  @observable isFullscreenMode = false;
  private readonly notifications = requireService('notifications');

  constructor() {
    makeObservable(this);
  }

  @action.bound
  private handleChangeFSMode() {
    if (!document.fullscreenElement && !document.webkitFullscreenElement) {
      this.isFullscreenMode = false;
    } else {
      this.isFullscreenMode = true;
    }
  }

  @action.bound
  private handleFSError() {
    this.notifications.showErrorMessageT('errors:fullscreenNowAllowed');
    this.exitFullscreen();
  }

  @action.bound
  enterFullscreen(element: HTMLElement) {
    if (document.fullscreenEnabled || document.webkitFullscreenEnabled) {
      try {
        const request = (element.requestFullscreen || element.webkitRequestFullscreen).bind(element);
        request();
      } catch (e) {
        console.error(e);
        this.notifications.showErrorMessageT('errors:fullscreenNowAllowed');
      }
    } else {
      this.notifications.showErrorMessageT('errors:fullscreenNowAllowed');
    }
  }

  @action.bound
  exitFullscreen() {
    if (!document.webkitFullscreenElement && !document.fullscreenElement) {
      return;
    }

    try {
      const request = (document.exitFullscreen || document.webkitExitFullscreen).bind(document);
      request();
    } catch (e) {
      console.error(e);
    }
  }

  init = (): void => {
    document.addEventListener('fullscreenchange', this.handleChangeFSMode);
    document.addEventListener('fullscreenerror', this.handleFSError);
    document.addEventListener('webkitfullscreenchange', this.handleChangeFSMode);
    document.addEventListener('webkitfullscreenerror', this.handleFSError);
  };
}
