import { TabEntity } from 'src/entities/tab/TabEntity';

import type { TSerializedTab } from 'src/entities/workspace/types';

export const GROUPS = [
  {
    id: '1',
    color: '#EB5757',
  },
  {
    id: '2',
    color: '#F2C94C',
  },
  {
    id: '3',
    color: '#605CFF',
  },
  {
    id: '4',
    color: '#BD69FF',
  },
  {
    id: '5',
    color: '#219653',
  },
  {
    id: '6',
    color: '#213594',
  },
  {
    id: '7',
    color: '#7DA8FF',
  },
];

export function mapTabs(rawData: TSerializedTab[]): { tabs: TabEntity[]; activeKey?: string } {
  let activeKey = undefined;

  const tabs = rawData.map((rawTab) => {
    if (rawTab.isActive) {
      activeKey = rawTab.id;
    }

    return mapTab(rawTab);
  });

  return { tabs, activeKey };
}

export function mapTab(rawData: TSerializedTab): TabEntity {
  const groups = rawData.groups ?? GROUPS;

  const newTab = new TabEntity({
    name: rawData.name,
    id: rawData.id,
    templateId: rawData?.templateId,
    groups,
    layouts: rawData.layouts,
    rawWidgetsData: rawData.widgets,
  });

  return newTab;
}
