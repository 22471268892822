import { BackLight as BackIcon, CloseRoundLight as CloseIcon } from '@profgeosoft-ui/icons';
import { Button, Illustration, Modal } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './SaveWithoutChangesModal.module.scss';

type Props = {
  isOpened: boolean;
  onDeleteAccept(): void;
  onDeleteCancel(): void;
};

export const SaveWithoutChangesModal = observer(function SaveWithoutChangesModal({
  onDeleteAccept,
  onDeleteCancel,
  isOpened,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal open={isOpened} className={styles.modal} onClickOutside={null} closeIcon={false}>
      <div className={styles.header}>
        <div className={styles.img}>
          <Illustration.CloseNotSave />
        </div>
        <div>
          <p className={styles.title}>{t('modals:CloseWithoutChangesModal.title')}</p>
          <p className={styles.desc}>{t('modals:CloseWithoutChangesModal.desc')}</p>
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          variant="danger"
          onClick={onDeleteAccept}
          className={styles.button}
          icon={<CloseIcon className={styles.icon} />}
        >
          {t('common:Buttons:close')}
        </Button>
        <Button
          variant="primary"
          onClick={onDeleteCancel}
          className={styles.button}
          icon={<BackIcon className={styles.icon} />}
        >
          {t('common:Buttons:cancel')}
        </Button>
      </div>
    </Modal>
  );
});
