import { action, makeObservable, observable } from 'mobx';

import type { TabEntity } from 'src/entities/tab/TabEntity';

export class WorkspaceEntity {
  @observable tabsEntities: TabEntity[] = [];
  @observable activeKey?: string;
  @observable deletableTabName: string | null = null;

  constructor() {
    makeObservable(this);
  }

  @action.bound
  changeTab(index: number, tab: TabEntity): void {
    this.tabsEntities[index] = tab;
  }

  @action.bound
  replaceTab(tabId: string, tab: TabEntity): void {
    for (let i = 0; i < this.tabsEntities.length; i += 1) {
      if (this.tabsEntities[i].id === tabId) {
        this.tabsEntities[i] = tab;
      }
    }
  }

  @action.bound
  setTabsEntities(tabs: TabEntity[]): void {
    this.tabsEntities = tabs;
  }

  @action.bound
  addTabEntity(tab: TabEntity): void {
    this.tabsEntities.push(tab);
  }

  @action.bound
  setActiveKey(key?: string): void {
    this.activeKey = key;
  }

  @action.bound
  removeTab(id: string): void {
    const removable = this.tabsEntities.find((tab) => tab.id === id);
    if (removable != null) {
      removable.destroyWidgetEntities();
      this.tabsEntities.remove(removable);
    } else {
      throw new Error('Tab not found');
    }
  }
}
