import { action, makeObservable, observable } from 'mobx';

import { requireService } from 'src/packages/di';

import type { TabTemplatesListScreenEntity } from './TabTemplatesListScreen.entity';
import type { BaseSettingsScreen } from '../screen/BaseSettingsScreen';
import type { Stack } from 'src/packages/data/structures/Stack';
import type { TTabTemplateWithGSProps } from 'src/pages/dashboard/features/workspace/types';

export type TTemplateCollapse = {
  name: string;
  type: string;
  templates: TTabTemplateWithGSProps[];
};

export class TabTemplatesListScreenStore {
  readonly stack: Stack<BaseSettingsScreen>;
  readonly screenEntity: TabTemplatesListScreenEntity;

  @observable isLoading = false;
  @observable activeTemplateCollapses: string[] = ['public', 'private'];
  @observable selectedTemplateId?: number;

  constructor(
    stack: Stack<BaseSettingsScreen>,
    screen: TabTemplatesListScreenEntity,
    private readonly tabTemplatesService = requireService('tabTemplates')
  ) {
    this.stack = stack;
    this.screenEntity = screen;

    makeObservable(this);
  }

  get collapses(): TTemplateCollapse[] {
    const { publicTemplates, personal } = this.tabTemplatesService;

    let collapses: TTemplateCollapse[] = [];

    if (publicTemplates) {
      collapses.push({
        name: 'settings:Templates.public',
        type: 'public',
        templates: publicTemplates,
      });
    }

    if (personal) {
      collapses.push({
        name: 'settings:Templates.private',
        type: 'private',
        templates: personal,
      });
    }

    return collapses;
  }

  @action.bound
  onTemplateCollapse(value: string[]): void {
    this.activeTemplateCollapses = value;
  }

  @action.bound
  setTemplateId(id: number): void {
    this.selectedTemplateId = id;
  }

  @action.bound
  selectTabTemplate(template: TTabTemplateWithGSProps): void {
    if (template.id === this.selectedTemplateId) return;

    this.stack.setLength(1);
    this.screenEntity.createTabTemplateDetails(template);
    this.setTemplateId(template.id);
  }

  init = () => {
    const firstTemplate = this.collapses[0]?.templates?.[0];

    if (firstTemplate) {
      this.selectTabTemplate(firstTemplate);
    }
  };
}
