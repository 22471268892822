import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { ReactComponent as LogoGeo } from 'src/packages/assets/img/geo-logo.svg';
import { ReactComponent as LogoEPerform } from 'src/packages/assets/img/logo-e-perform.svg';
import { ReactComponent as LogoGaz } from 'src/packages/assets/img/logoGaz.svg';
import { ReactComponent as LogoINK } from 'src/packages/assets/img/logoInk.svg';

import { COMPANY } from './Logo.consts';

import styles from './Logo.module.scss';

type Props = {
  isOpened: boolean;
  handleClick: () => void;
};

export const Logo = observer(function Logo({ isOpened, handleClick }: Props) {
  return (
    <div onClick={handleClick} className={clsx(styles.logoWrapper, isOpened && styles.logoWrapper__opened)}>
      <div className={clsx(styles.logoContainer, isOpened && COMPANY === 'GAZ' && styles.logoContainer__gazOpened)}>
        <div
          className={clsx(
            styles.logoConcealer,
            COMPANY === 'GAZ' && styles.logoConcealer__gaz,
            COMPANY === 'GAZ' && isOpened && styles.logoConcealer__opened,
            COMPANY === 'INK' && styles.logoConcealer__ink,
            COMPANY === 'GEO' && styles.logoConcealer__geo,
            COMPANY === 'EPERFORM' && styles.logoConcealer__ePerform
          )}
        >
          {COMPANY === 'GAZ' && <LogoGaz className={styles.logoSvg} />}
          {COMPANY === 'INK' && <LogoINK className={styles.logoSvg} />}
          {COMPANY === 'GEO' && <LogoGeo className={styles.logoSvg} />}
          {COMPANY === 'EPERFORM' && <LogoEPerform className={styles.logoSvg} />}
        </div>
      </div>
    </div>
  );
});
