import {
  IMAGE_BROADCAST_AVAILABLE,
  OPERATIONAL_PARAMETERS_AVAILABLE,
  WELL_ANALYSE_AVAILABLE,
  WELL_DETAILS_AVAILABLE,
} from 'src/api/consts';

import type { TWidgetType, TWidgetTypeGroup } from './NewContentWidgetModal.store';

const wellDetailsWidgetGroup: TWidgetType = {
  type: 'well-details-widget',
  name: 'widgets:WellDetails.title',
  description: 'widgets:WellDetails.description',
  iconsMap: [['detail'], ['empty', 'empty']],
};

const wellAnalyseWidgetGroup: TWidgetType = {
  type: 'well-analyse-widget',
  name: 'widgets:WellAnalyse.title',
  description: 'widgets:WellAnalyse.description',
  iconsMap: [['analyze'], ['empty', 'empty']],
};

const imageBroadcastWidgetGroup: TWidgetType = {
  type: 'image-broadcast-widget',
  name: 'widgets:ImageBroadcast.title',
  description: 'widgets:ImageBroadcast.description',
  iconsMap: [['broadcast'], ['empty', 'empty']],
};

const widgetsGroups: TWidgetTypeGroup[] = [
  {
    type: 'dataFromSensors',
    name: 'modals:CreateContentWidget.sensorsData',
    description: 'modals:CreateContentWidget.rtAndHistorical',
    iconsMap: [['graph'], ['clock', 'vertArrow']],
    widgets: [
      {
        type: 'well-logs-widget',
        wellIndexType: 'LOG_TIME',
        name: 'modals:CreateContentWidget.generalTime',
        description: 'modals:CreateContentWidget.generalTimeSensosrs',
        iconsMap: [['clock'], ['graph']],
      },
      {
        type: 'well-logs-widget',
        wellIndexType: 'LOG_DEPTH',
        name: 'modals:CreateContentWidget.generalDepth',
        description: 'modals:CreateContentWidget.generalDepthSensors',
        iconsMap: [['depth'], ['graph']],
      },
    ],
  },
];

const filteredWidgetsGroups: (TWidgetType | TWidgetTypeGroup)[] = [...widgetsGroups];
const widgetsOperationalParamsGroups: TWidgetTypeGroup[] = [
  {
    type: 'operationalParams',
    name: 'widgets:OperationalParams.title',
    description: 'widgets:OperationalParams.description',
    iconsMap: [['operational'], ['clock', 'vertArrow']],
    widgets: [
      {
        type: 'well-operational-params-widget',
        wellIndexType: 'LOG_TIME',
        name: 'modals:CreateContentWidget.generalTime',
        description: 'modals:CreateContentWidget.operationalParamsTime',
        iconsMap: [['clock'], ['operational']],
      },
      {
        type: 'well-operational-params-widget',
        wellIndexType: 'LOG_DEPTH',
        name: 'modals:CreateContentWidget.generalDepth',
        description: 'modals:CreateContentWidget.operationalParamsDepth',
        iconsMap: [['depth'], ['operational']],
      },
    ],
  },
];

if (OPERATIONAL_PARAMETERS_AVAILABLE) {
  filteredWidgetsGroups.push(...widgetsOperationalParamsGroups);
}

if (WELL_DETAILS_AVAILABLE) {
  filteredWidgetsGroups.push(wellDetailsWidgetGroup);
}

if (WELL_ANALYSE_AVAILABLE) {
  filteredWidgetsGroups.push(wellAnalyseWidgetGroup);
}

if (IMAGE_BROADCAST_AVAILABLE) {
  filteredWidgetsGroups.push(imageBroadcastWidgetGroup);
}

export { filteredWidgetsGroups as widgetsGroups };
