import { assert } from 'src/packages/shared/utils/assert';
import { removeBracesFromString } from 'src/packages/shared/utils/removeBracesFromString';
import { TemplateValueBuilder } from 'src/packages/template-builder/TemplateBuilder';
import { WellItemEntity } from 'src/pages/dashboard/features/well-list-widget/WellCollapse';

import type { TWellListView } from 'src/api-types/wells.types';
import type { IDataSource } from 'src/packages/template-builder/TemplateBuilder';
import type { WellCollapseEntity } from 'src/pages/dashboard/features/well-list-widget/WellCollapse';

import { WellsCollapseEntity } from './WellsCollapse.entity';

type TWellsResponseType = Record<string, string | number | Record<string, string | number>[]>[];

export class CollapseEntityDataSource implements IDataSource {
  constructor(private readonly source: { [key: string]: string | number | Record<string, string | number>[] }) {}

  hasKey(key: string): boolean {
    return key in this.source;
  }

  getValue(key: string) {
    assert(key in this.source);

    const value = this.source[key];

    assert(typeof value !== 'undefined');

    return value;
  }
}

function isWellsCollapses(collapses: object, itemsKey: string): collapses is TWellsResponseType {
  if (!Array.isArray(collapses)) return false;

  for (const collapse of collapses) {
    if (!collapse || collapse === null || typeof collapse !== 'object') return false;
    if (!(itemsKey in collapse)) return false;

    const items = collapse[itemsKey];

    if (!Array.isArray(items)) return false;

    for (const item of items) {
      if (typeof item !== 'object') return false;
    }
  }

  return true;
}

export function mapWells(collapses: object, view: TWellListView, groupBy: number): WellCollapseEntity[] {
  const currentGroupViewData = view.grouping.groups.find((_, index) => index === groupBy)?.collapse;

  if (!currentGroupViewData) return [];

  const { itemsKey } = currentGroupViewData;

  if (isWellsCollapses(collapses, itemsKey)) {
    const collapseNameKey = currentGroupViewData.nameTemplate;

    const { nameTemplate: itemNameTemplate, idKey_Favorites: itemIdKey } = currentGroupViewData.item;

    const mappedCollapses: WellCollapseEntity[] = [];

    for (const collapse of collapses) {
      const source = new CollapseEntityDataSource(collapse);
      const templateValueBuilder = new TemplateValueBuilder().configure((settings) => settings.useDataSource(source));

      const collapseName = templateValueBuilder.build(collapseNameKey);
      const collapseAttrName = collapseNameKey.includes('.')
        ? removeBracesFromString(collapseNameKey).split('.')[1]
        : collapseNameKey;

      if (typeof collapseName !== 'string' || !(itemsKey in collapse)) {
        continue;
      }

      const items = collapse[itemsKey];

      if (!Array.isArray(items)) {
        continue;
      }

      const mappedCollapseItems: WellItemEntity[] = [];

      for (const item of items) {
        const source = new CollapseEntityDataSource(item);
        const templateValueBuilder = new TemplateValueBuilder().configure((settings) => settings.useDataSource(source));

        const itemName = templateValueBuilder.build(itemNameTemplate);
        const id = item[itemIdKey];
        const status = item[view.item.statusField];

        if (typeof itemName !== 'string' || typeof id !== 'number' || typeof status !== 'string') continue;

        mappedCollapseItems.push(new WellItemEntity({ name: itemName, id, status }));
      }

      mappedCollapses.push(
        new WellsCollapseEntity({
          attrName: collapseAttrName,
          name: collapseName,
          items: mappedCollapseItems,
          useCollapseTranslation: currentGroupViewData.useCollapseTranslation,
        })
      );
    }

    return mappedCollapses;
  }

  return [];
}
