import { observer } from 'mobx-react-lite';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import type { ReactZoomPanPinchContentRef } from 'react-zoom-pan-pinch';

const scaleUp = true;
const zoomFactor = 8;

type BroadcastImageProps = {
  src: string;
  alt: string;
};

export const BroadcastImage = observer(
  // eslint-disable-next-line mobx/missing-observer, mobx/no-anonymous-observer
  forwardRef(function BroadcastImage({ src, alt }: BroadcastImageProps, ref) {
    const [container, setContainer] = useState<HTMLDivElement | null>(null);
    const reactZoomPanPinchContent = useRef<ReactZoomPanPinchContentRef | null>(null);

    const [containerWidth, setContainerWidth] = useState<number>(0);
    const [containerHeight, setContainerHeight] = useState<number>(0);

    const [imageNaturalWidth, setImageNaturalWidth] = useState<number>(0);
    const [imageNaturalHeight, setImageNaturalHeight] = useState<number>(0);

    const imageScale = useMemo(() => {
      if (containerWidth === 0 || containerHeight === 0 || imageNaturalWidth === 0 || imageNaturalHeight === 0)
        return 0;
      const scale = Math.min(containerWidth / imageNaturalWidth, containerHeight / imageNaturalHeight);
      return scaleUp ? scale : Math.max(scale, 1);
    }, [containerWidth, containerHeight, imageNaturalWidth, imageNaturalHeight]);

    useEffect(() => {
      if (container) {
        const { width, height } = container.getBoundingClientRect();
        setContainerWidth(width);
        setContainerHeight(height);

        const resizeObserver = new ResizeObserver((entries) => {
          window.requestAnimationFrame((): void | undefined => {
            if (!Array.isArray(entries) || !entries.length) {
              return;
            }

            const { height, width } = container.getBoundingClientRect();
            setContainerWidth(width);
            setContainerHeight(height);
          });
        });

        resizeObserver.observe(container);

        return () => {
          resizeObserver.disconnect();
        };
      }
    }, [ref, container]);

    const handleImageOnLoad = (image: HTMLImageElement) => {
      setImageNaturalWidth(image.naturalWidth);
      setImageNaturalHeight(image.naturalHeight);
    };

    useEffect(() => {
      const image = new Image();
      image.onload = () => handleImageOnLoad(image);
      image.src = src;
    }, [src]);

    useImperativeHandle(ref, () => {
      return {
        zoomIn: reactZoomPanPinchContent.current?.zoomIn,
        zoomOut: reactZoomPanPinchContent.current?.zoomOut,
        setTransform: reactZoomPanPinchContent.current?.setTransform,
        resetTransform: reactZoomPanPinchContent.current?.resetTransform,
        centerView: reactZoomPanPinchContent.current?.centerView,
        zoomToElement: reactZoomPanPinchContent.current?.zoomToElement,
      };
    });

    return (
      <div style={{ height: '100%' }} ref={(el: HTMLDivElement | null) => setContainer(el)}>
        {imageScale > 0 && (
          <TransformWrapper
            key={`${containerWidth}x${containerHeight}`}
            initialScale={imageScale}
            minScale={imageScale}
            maxScale={imageScale * zoomFactor}
            centerOnInit
            ref={reactZoomPanPinchContent}
          >
            <TransformComponent
              wrapperStyle={{
                width: '100%',
                height: '100%',
              }}
            >
              <img alt={alt} src={src} />
            </TransformComponent>
          </TransformWrapper>
        )}
      </div>
    );
  })
);
