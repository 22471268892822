import { requireService } from 'src/packages/di';
import { parseWellListInternalState } from 'src/pages/dashboard/features/well-list-widget/WellListWidget.utils';

import type { TSerializedWidget } from 'src/entities/workspace/types';
import type { WellListWidgetEntity } from 'src/pages/dashboard/features/well-list-widget/WellListWidget.entity';
import type { WellListWidgetStore } from 'src/pages/dashboard/features/well-list-widget/WellListWidget.store';

import { baseWidgetApplyChanges } from './baseWidgetApplyChanges';

export function wellListApplyChanges(widget: WellListWidgetEntity, changes: TSerializedWidget, index: number) {
  const widgetStoreService = requireService('widgetStoreService');

  const store = widgetStoreService.getStore<WellListWidgetEntity, WellListWidgetStore>(widget);

  if (!store) return;

  baseWidgetApplyChanges(widget, changes, index);

  let shouldFetchWells = false;

  const internalState = parseWellListInternalState(changes.internalState);

  widget.setOuterState(internalState.filterState ?? null);

  if (internalState.filterBy && widget.filterBy !== internalState.filterBy) {
    shouldFetchWells = true;
    widget.changeFilterBy(internalState.filterBy);
  }

  if (internalState.groupBy && widget.groupBy !== internalState.groupBy) {
    shouldFetchWells = true;
    widget.changeGroupBy(internalState.groupBy);
  }

  if (internalState.collapsedKeys) {
    widget.setCollapsedKeys(internalState.collapsedKeys);
  }

  if (typeof internalState.searchValue !== 'undefined' && widget.searchValue !== internalState.searchValue) {
    widget.changeSearchValue(internalState.searchValue ?? '');
    shouldFetchWells = true;
  }

  widget.setWellId(internalState.selectedWellID ?? null);

  if (shouldFetchWells) {
    store.fetchWells();
  }
}
