import type { IFullscreenService } from './IFullscreenService';
import type { IServicesCollection } from 'src/packages/di';

import { FullscreenService } from './Fullscreen.service';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    fullscreenService: IFullscreenService;
  }
}

export function addFullscreeenService(di: IServicesCollection) {
  const service = new FullscreenService();
  service.init();

  di.addSingleton('fullscreenService', service);
}
