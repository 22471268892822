import type {
  TWellDetailsSerializedInternalState,
  WellDetailsWidgetEntity,
} from 'src/pages/dashboard/features/well-details-widget/WellDetailsWidget.entity';
import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

export function serializeWellDetailsTemplate(widget: WellDetailsWidgetEntity): TSerializedWidgetTemplate {
  const internalState: TWellDetailsSerializedInternalState = {
    wellId: widget.wellId,
    section: widget.section,
    isEditMode: widget.isEditMode,
  };

  return {
    uuid: widget.uuid,
    type: widget.type,
    groupId: widget.groupId,
    size: {
      w: widget.size.w,
      h: widget.size.h,
    },
    position: {
      x: widget.position.x,
      y: widget.position.y,
    },
    internalState,
  };
}
