import { action, makeObservable } from 'mobx';

import { BroadcastTunnel } from 'src/packages/broadcast-tunnel/BroadcastTunnel';
import { hasValue } from 'src/packages/shared/utils/common';

import type { MasterWindowsManager } from './MasterWindowsManager';
import type { THealthResponseMessage } from '../types';
import type { WorkspaceStore } from '../Workspace.store';
import type { THealthCheckMessage } from 'src/pages/tab/single-tab/SingleTab.types';

const INTERVAL_DELAY = 6000;

export class HealthCheckService {
  private readonly store: WorkspaceStore;
  private readonly windowsManager: MasterWindowsManager;
  private readonly healthCheckTunnel: BroadcastTunnel<THealthCheckMessage, THealthResponseMessage>;

  private intervalId: number | null = null;
  private timerId: number | null = null;

  constructor(store: WorkspaceStore, windowsManager: MasterWindowsManager) {
    this.store = store;
    this.windowsManager = windowsManager;

    this.healthCheckTunnel = new BroadcastTunnel((message): message is THealthResponseMessage => {
      if (message?.type !== 'health-response') return false;
      if (message?.to?.id !== this.store.id) return false;

      return true;
    }, this.onHealthCheckResponse);

    makeObservable(this);
  }

  @action.bound
  private onHealthCheckResponse = (message: THealthResponseMessage): void => {
    this.store.stopTabHealthChecking(message.from.id);
  };

  @action.bound
  private checkTabHealth = (id: string): void => {
    if (!this.store.id) return;

    this.store.addHealthCheckingTab(id);

    this.healthCheckTunnel.postMessage({
      type: 'health-check',
      from: {
        type: 'master',
        id: this.store.id,
      },
      to: {
        id,
      },
    });
  };

  @action.bound
  init = (): void => {
    this.intervalId = window.setInterval(() => {
      if (!this.windowsManager.isSessionRecoveringCompleted || this.store.externalTabs.length === 0) return;

      for (const tab of this.store.externalTabs) {
        if (tab.inited) {
          this.checkTabHealth(tab.id);
        }
      }

      this.timerId = window.setTimeout(() => {
        if (this.store.healthCheckingTabs.size === 0) return;

        for (const tabId of this.store.healthCheckingTabs) {
          this.windowsManager.removeClosedTab(tabId);
        }
      }, 5000);
    }, INTERVAL_DELAY);
  };

  dispose = (): void => {
    this.healthCheckTunnel.disconnect();

    if (hasValue(this.intervalId)) {
      clearInterval(this.intervalId);
    }

    if (hasValue(this.timerId)) {
      clearTimeout(this.timerId);
    }
  };
}
