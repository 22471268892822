import { observer } from 'mobx-react-lite';

import styles from './Versions.module.scss';

interface Props {
  frontend: string;
  backend: string;
  cache: string;
  orchestrator: string;
}

export const Versions = observer(function Versions({ frontend, backend, cache, orchestrator }: Props) {
  const versionsList = [
    { type: 'Frontend', version: frontend },
    { type: 'Backend', version: backend },
    { type: 'Cache', version: cache },
    { type: 'Orchestrator', version: orchestrator },
  ];
  return (
    <div className={styles.wrapper}>
      {versionsList.map((el) => (
        <div key={el.type} className={styles.textWrapper}>
          <span className={styles.versionType}>{el.type}:</span>
          <span>{el.version}</span>
        </div>
      ))}
    </div>
  );
});
