import { action, makeObservable, observable } from 'mobx';

import { SESSION_MANAGEMENT_SOCKET_URL } from 'src/api/consts';

import { IS_PORTABLE_DEVICE } from '../shared/consts/isPortableDevice';
import { SockClientService } from '../sock-client-service/SockClientService';

import { getBrokerLocationBySessionId } from './utils';

const MESSAGE_LOCATION = '/go-dashboard-service/secured/notification';

type TSessionCommonMessage =
  | 'TAB-TEMPLATES-CHANGED'
  | 'FAVORITES-CHANGED'
  | 'WORKSPACE-CHANGED'
  | 'WELL-LOGS-TEMPLATES-BY-TIME-CHANGED'
  | 'WELL-LOGS-TEMPLATES-BY-DEPTH-CHANGED';

type TSessionMessage =
  | TSessionCommonMessage
  | 'HELLO-FROM-DESKTOP'
  | 'HELLO-FROM-TABLET'
  | 'HELLO-FROM-DESKTOP-FORCE'
  | 'HELLO-FROM-TABLET-FORCE';

export type TDashboardReceivingMessage = TSessionCommonMessage | 'CLOSE-SESSION' | 'OK' | 'ERROR-CONCURRENT-SESSION';

type TSessionStatus = 'pending' | 'allowed' | 'error' | 'closed';

export interface ISessionService {
  sessionSockClient: SockClientService<TDashboardReceivingMessage>;
  get status(): TSessionStatus;
  init(): Promise<void>;
  sendMessage(message: TSessionMessage): void;
  subscribe(cb: (message: TDashboardReceivingMessage) => void): void;
  unsubscribe(cb: (message: TDashboardReceivingMessage) => void): void;
  setStatus(status: TSessionStatus): void;
  registerSession(): void;
  forceRegisterSession(): void;
  destroy(): void;
}

export class SessionService implements ISessionService {
  readonly sessionSockClient: SockClientService<TDashboardReceivingMessage>;

  private brokerLocation: string | null = null;

  @observable status: TSessionStatus = 'pending';

  constructor() {
    this.sessionSockClient = new SockClientService<TDashboardReceivingMessage>(SESSION_MANAGEMENT_SOCKET_URL);
    makeObservable(this);
  }

  @action.bound
  setStatus(status: TSessionStatus): void {
    this.status = status;
  }

  sendMessage(message: TSessionMessage): void {
    this.sessionSockClient.sendMessage(MESSAGE_LOCATION, message);
  }

  subscribe(cb: (message: TDashboardReceivingMessage) => void): void {
    if (!this.brokerLocation) return;

    this.sessionSockClient.subscribe(this.brokerLocation, cb);
  }

  unsubscribe(cb: (message: TDashboardReceivingMessage) => void): void {
    if (!this.brokerLocation) return;

    this.sessionSockClient.unsubscribe(this.brokerLocation, cb);
  }

  destroy = (): void => {
    this.sessionSockClient.destroy();
  };

  private setBrokerLocation = (sessionId: string) => {
    this.brokerLocation = getBrokerLocationBySessionId(sessionId);
  };

  registerSession(): void {
    const message = IS_PORTABLE_DEVICE ? 'HELLO-FROM-TABLET' : 'HELLO-FROM-DESKTOP';
    this.sendMessage(message);
  }

  forceRegisterSession(): void {
    const message = IS_PORTABLE_DEVICE ? 'HELLO-FROM-TABLET-FORCE' : 'HELLO-FROM-DESKTOP-FORCE';
    this.sendMessage(message);
  }

  async init(): Promise<void> {
    await this.sessionSockClient.init(true, this.setBrokerLocation);
  }
}
