import type { WidgetStateParams } from './types';

export class WidgetStatePublisher {
  params: Partial<WidgetStateParams> = {};

  constructor(initParams: Partial<WidgetStateParams>, private onChange?: (params: Partial<WidgetStateParams>) => void) {
    Object.assign(this.params, initParams);
    this.setParams = this.setParams.bind(this);
  }

  setParams(params: Partial<WidgetStateParams>) {
    Object.assign(this.params, params);
    if (this.onChange) {
      this.onChange(this.params);
    }
  }
}
