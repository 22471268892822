import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';
import type { TSerializedWidget } from 'src/entities/workspace/types';

type TMapper = (widget: TSerializedWidget, index: number) => WidgetEntity;

type TSerializer = (widget: WidgetEntity) => TSerializedWidget;

export interface IWidgetSerializerService {
  map(widget: TSerializedWidget, index: number): WidgetEntity;
  serialize(widget: WidgetEntity): TSerializedWidget;
  registerMapper<T extends WidgetEntity>(type: string, mapper: (widget: TSerializedWidget, index: number) => T): this;
  registerSerializer<T extends WidgetEntity>(type: string, serializer: (widget: T) => TSerializedWidget): this;
}

export class WidgetSerializerService {
  private collection: Map<string, TSerializer> & {
    get<T extends WidgetEntity>(type: string): (widget: T) => TSerializedWidget;
    set<T extends WidgetEntity>(type: string, serializer: (widget: T) => TSerializedWidget): void;
  } = new Map();
  private mappersCollection: Map<string, TMapper> = new Map();

  map(widget: TSerializedWidget, index: number): WidgetEntity {
    const mapper = this.mappersCollection.get(widget.type);

    if (!mapper) {
      console.error(widget.type);
      throw new Error('mapper does not exist');
    }

    return mapper(widget, index);
  }

  serialize(widget: WidgetEntity): TSerializedWidget {
    const serializer = this.collection.get(widget.type);

    if (!serializer) {
      console.error(widget.type);
      throw new Error('seralizer does not exist');
    }

    return serializer(widget);
  }

  registerMapper<T extends WidgetEntity>(type: string, mapper: (widget: TSerializedWidget, index: number) => T): this {
    if (this.mappersCollection.has(type)) {
      console.error(type);
      throw new Error('widget mapper already registered');
    }

    this.mappersCollection.set(type, mapper);

    return this;
  }

  registerSerializer<T extends WidgetEntity>(type: string, serializer: (widget: T) => TSerializedWidget): this {
    if (this.collection.has(type)) {
      console.error(type);
      throw new Error('widget serializer already registered');
    }

    this.collection.set<T>(type, serializer);

    return this;
  }
}
