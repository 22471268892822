import { WellListWidgetWrapper } from 'src/pages/dashboard/features/well-list-widget/WellListWidget';

import type { TRenderWidget, TWidgetRendererOptions } from './WidgetRendererService';
import type { WellListWidgetEntity } from 'src/pages/dashboard/features/well-list-widget/WellListWidget.entity';

export function renderWellListWidget(widget: WellListWidgetEntity, options: TWidgetRendererOptions): TRenderWidget {
  return ({ onCreateWellsFilterWidget, onCreateWellLogsWidget }) => (
    <WellListWidgetWrapper
      wellListEntity={widget}
      options={options}
      onCreateWellsFilterWidget={onCreateWellsFilterWidget}
      onCreateWellLogsWidget={onCreateWellLogsWidget}
    />
  );
}
