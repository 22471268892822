import { WellLogWidgetStompPublisher } from '@go-widgets/well-logs-widget';

import { API_WEBSOCKET_PATH, AUTH_TOKEN } from 'src/api/consts';

import { getWebsocketAddress } from '../Workspace.utils';

export interface IStompPublisherService {
  stompPublisher: WellLogWidgetStompPublisher;
}

export class StompPublisherService implements IStompPublisherService {
  stompPublisher = new WellLogWidgetStompPublisher();

  init(): void {
    this.stompPublisher.init(getWebsocketAddress(API_WEBSOCKET_PATH), AUTH_TOKEN);
  }
}
