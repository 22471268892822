import { assert } from 'src/packages/shared/utils/assert';

import type { IDataSource } from 'src/packages/template-builder/TemplateBuilder';

export type TFilterDataSource = { [key: string]: number | (string | number)[] | null };

export class WellsFilterDataSource implements IDataSource {
  constructor(private readonly source: TFilterDataSource) {}

  hasKey(key: string): boolean {
    return key in this.source;
  }

  getValue(key: string) {
    assert(key in this.source);

    const value = this.source[key];

    assert(typeof value !== 'undefined');

    return value;
  }
}
