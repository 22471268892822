import { action, makeObservable, observable } from 'mobx';

import type { SingleTabStore } from '../SingleTab.store';
import type { TSerializedTab } from 'src/entities/workspace/types';

import { FavoriteWellsSyncService } from './FavoriteWellsSyncService';
import { MasterRecoveringService } from './MasterRecoveringService';
import { MasterSearchService } from './MasterSearchService';
import { PingMasterService } from './PingMasterService';
import { SlaveHealthCheckService } from './SlaveHealthCheckService';
import { TabClosingService } from './TabClosingService';
import { TabOpeningService } from './TabOpeningService';
import { TabSyncingService } from './TabSyncingService';

export class SlaveWindowsManager {
  private readonly store: SingleTabStore;

  private readonly pingMasterService: PingMasterService;
  private readonly masterRecoveringService: MasterRecoveringService;
  private readonly tabClosingService: TabClosingService;
  private readonly tabOpeningService: TabOpeningService;
  private readonly tabSyncingService: TabSyncingService;
  private readonly healthCheckService: SlaveHealthCheckService;
  private readonly masterSearchService: MasterSearchService;
  private readonly favoriteWellsSyncService: FavoriteWellsSyncService;

  @observable master: {
    id: string | null;
    isConnected: boolean;
  } = {
    id: null,
    isConnected: false,
  };

  @observable isMasterPonged: boolean = false;

  constructor(store: SingleTabStore) {
    this.store = store;

    this.pingMasterService = new PingMasterService(store, this);
    this.masterRecoveringService = new MasterRecoveringService(store, this);
    this.tabClosingService = new TabClosingService(store, this);
    this.tabOpeningService = new TabOpeningService(store, this);
    this.tabSyncingService = new TabSyncingService(store, this);
    this.healthCheckService = new SlaveHealthCheckService(store, this);
    this.masterSearchService = new MasterSearchService(store, this);
    this.favoriteWellsSyncService = new FavoriteWellsSyncService(store);

    makeObservable(this);
  }

  @action.bound
  recoverMasterWindow = async (): Promise<void> => {
    await this.masterRecoveringService.recoverMasterWindow();
  };

  @action.bound
  setMasterPonged(value: boolean): void {
    this.isMasterPonged = value;
  }

  @action.bound
  setMasterStatus(value: boolean): void {
    this.master.isConnected = value;
  }

  @action.bound
  setMasterId(value: string): void {
    this.master.id = value;
  }

  private get tabId(): string {
    return this.store.tabId;
  }

  sendTabStatus(): void {
    this.tabOpeningService.sendTabStatus();
  }

  sendTabState(state: TSerializedTab): void {
    this.tabSyncingService.sendTabState(state);
  }

  @action.bound
  destroyTab(id: string): void {
    this.tabClosingService.destroyTab(id);
  }

  handleBeforeUnload = (): void => {
    this.destroyTab(this.tabId);

    if (this.store.state) {
      this.sendTabState(this.store.state);
    }
  };

  sendFavoriteWellsList(list: number[]): void {
    this.favoriteWellsSyncService.sendFavoriteWells(list);
  }

  init = (): void => {
    this.pingMasterService.init();
    this.masterSearchService.init();

    window.addEventListener('beforeunload', this.handleBeforeUnload, { capture: true });
  };

  dispose = (): void => {
    this.pingMasterService.dispose();
    this.tabClosingService.dispose();
    this.tabOpeningService.dispose();
    this.healthCheckService.dispose();
    this.masterSearchService.dispose();
    this.tabSyncingService.dispose();
    this.favoriteWellsSyncService.dispose();

    window.removeEventListener('beforeunload', this.handleBeforeUnload, { capture: true });
  };
}
