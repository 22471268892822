export function getNewAndDeletedFields<T>(curr: T[], prev: T[]): { newElements: T[]; deletedElements: T[] } {
  const deletedElements = [...prev];

  const newElements = curr.filter((field) => {
    const index = deletedElements.indexOf(field);

    if (index !== -1) {
      deletedElements.splice(index, 1);
    }

    return index === -1;
  });

  return { newElements, deletedElements };
}
