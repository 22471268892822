import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';

import { MenuItem } from '../menu-item/MenuItem';

import { support } from './Support.consts';

import styles from './Support.module.scss';

type Props = {
  isOpened: boolean;
  onCloseSidebar(): void;
};

export const Support = observer(function Support({ isOpened, onCloseSidebar }: Props) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className={clsx(styles.wrapper, isOpened && styles.wrapper__opened)}>
      {support.map((sup) => {
        const onClick = sup.key
          ? () => {
              onCloseSidebar();
              navigate(sup.key);
            }
          : void 0;

        return (
          <MenuItem
            key={sup.key}
            isOpened={isOpened}
            item={sup}
            isActive={location.pathname.replaceAll(/[*/]/g, '') === sup.key}
            isSecondary
            onClick={onClick}
          />
        );
      })}
    </div>
  );
});
