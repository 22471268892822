import * as Sentry from '@sentry/react';
import { flow, makeObservable } from 'mobx';

import { requireService } from 'src/packages/di';
import { fetchSettingsLabels, fetchValuesInterpretations } from 'src/packages/directories/api/directories.api';

import type { i18n as Ii18, TOptions } from 'i18next';

import { OperationAbortedError, ValidationTextApiError } from '../errors';

export interface Ii18Service {
  t(key: string, options?: TOptions): string;
  langauge: string;
  init: (primary: string, secondary?: string) => void;
}

export class I18NextStore implements Ii18Service {
  private readonly i18: Ii18;

  readonly langauge: string;

  constructor(i18: Ii18, private readonly notifications = requireService('notifications')) {
    this.langauge = i18.language;
    this.i18 = i18;
    makeObservable(this);
  }

  private addResourceBundle(lang: string, namespace: string, dictionary: object): void {
    this.i18.addResourceBundle(lang, namespace, dictionary);
  }

  private async loadLabelsAndInterpretations(lang: string): Promise<void> {
    await Promise.all([this.loadLables(lang), this.loadValuesInterpretations(lang)]);
  }

  t(key: string, options?: TOptions): string {
    const value = this.i18.t(key, options);

    if (value === key && typeof options?.defaultValue === 'undefined') {
      Sentry.captureException(new Error(`${this.langauge}: translation for "${key}" key not found`));
    }

    return value;
  }

  @flow.bound
  private async *loadValuesInterpretations(lang: string): Promise<void> {
    try {
      const interpretations = await fetchValuesInterpretations(lang);

      yield;

      this.addResourceBundle(lang, 'Interpretations', interpretations);
    } catch (error) {
      yield;
      if (error instanceof OperationAbortedError) return;

      if (error instanceof ValidationTextApiError && error.responseMessage) {
        this.notifications.showErrorMessage(error.responseMessage);
        return;
      }

      console.error(error);
      this.notifications.showErrorMessageT('errors:receivingInterpretations');
    }
  }

  @flow.bound
  private async *loadLables(lang: string): Promise<void> {
    try {
      const labels = await fetchSettingsLabels(lang);

      yield;

      this.addResourceBundle(lang, 'Labels', labels);
    } catch (error) {
      if (error instanceof OperationAbortedError) return;

      if (error instanceof ValidationTextApiError && error.responseMessage) {
        this.notifications.showErrorMessage(error.responseMessage);
        return;
      }

      console.error(error);
      this.notifications.showErrorMessageT('errors:receivingLabels');
    }
  }

  init = async (primary: string, secondary?: string) => {
    try {
      const languages = [primary, secondary].filter((lang): lang is string => !!lang);
      const promises = languages.map((lang) => this.loadLabelsAndInterpretations(lang));

      await Promise.all(promises);
    } catch (error) {
      console.error(error);
    }
  };
}
