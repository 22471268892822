import { action, computed, makeObservable, observable } from 'mobx';

import { hasValue } from 'src/packages/shared/utils/common';

import type {
  IRestrictions,
  TEnableIf,
  TFormula,
  TOnChangeComboboxInstructions,
  TOption,
  TVisuallyDisabled,
} from '../types';
import type { TDictObject, TRefQuery } from 'src/packages/directories/types';

import { Combobox } from '../abstract-control-entities';

export type TFilterMultiComboboxData = {
  label?: string;
  placeholder?: string;
  formElementRefId: string;
  fieldId: string;
  objectName?: string;
  attrName: string;
  useInMainProgressBar: boolean;
  required: boolean;
  enableIf?: TEnableIf[];
  calculatedValue?: TFormula;
  visuallyDisabled?: TVisuallyDisabled[];
  refObjectType?: string;
  refObjectAttr?: string;
  refQuery?: TRefQuery;
  initialValue?: string | number;
  initialRawOptions?: string[] | number[];
  options?: TOption[];
  hasHardcodedValues?: boolean;
  restrictions: IRestrictions;
  onChangeInstructions?: TOnChangeComboboxInstructions;
  refObjectFilterByFields?: {
    [key: string]: string;
  };
  directory: TDictObject[] | null;
};

export class FilterMultiCombobox extends Combobox<(string | number)[]> {
  readonly required: boolean;
  readonly hasHardcodedValues: boolean = false;
  override readonly directory: TDictObject[] | null = null;

  @observable hardcodedValues?: TOption[];
  @observable initialValue: (string | number)[] = [];
  @observable value: (string | number)[] = [];
  @observable rawOptions: (string | number)[];

  readonly attrName: string;

  constructor(data: TFilterMultiComboboxData) {
    super(data);

    this.attrName = data.attrName;
    this.directory = data.directory;
    this.useInMainProgressBar = data.useInMainProgressBar;
    this.required = data.required;
    this.rawOptions = data.initialRawOptions ?? [];
    this.hasHardcodedValues = !!data.hasHardcodedValues;
    this.hardcodedValues = data.options;

    makeObservable(this);
  }

  @computed
  get options(): TOption[] {
    if (this.hasHardcodedValues && this.hardcodedValues) {
      const options = this.hardcodedValues
        .filter((option) => this.rawOptions.includes(option.value))
        .map((option) => {
          const label = this.i18.t(`Labels:${option.label}.label`, {
            defaultValue: option.label,
          });

          return {
            value: option.value,
            label,
          };
        });

      return options;
    }

    return this.serializeDataToOptions();
  }

  protected override serializeDataToOptions(): TOption[] {
    if (!this.directory) return [];

    const directory = this.directory?.filter((dirItem) => this.rawOptions.includes(dirItem.id));

    if (!directory) {
      return [];
    }

    return Combobox.checkIsRegularDirectories(directory) ? this.serializeFilteredDirectoryToOptions(directory) : [];
  }

  serializeFilteredDirectoryToOptions(directory: TDictObject[]): TOption[] {
    if (!this.refObjectAttr) return [];

    const options = [];
    optionCycle: for (const dirValue of directory) {
      if (!dirValue.data[this.refObjectAttr] || !hasValue(dirValue.id)) {
        continue;
      }

      if (this.filterValues) {
        for (const key in this.filterValues) {
          if (dirValue.data[key] !== this.filterValues[key]) {
            continue optionCycle;
          }
        }
      }

      const dirValueAttr = dirValue.data[this.refObjectAttr];

      if (!dirValueAttr) continue;
      options.push({
        label: dirValueAttr.toString(),
        value: dirValue.id,
      });
    }

    return options;
  }

  @action.bound
  setRawOptions(options: number[]) {
    this.rawOptions = options;
  }

  @action.bound
  setValue(value: (string | number)[] | null, setValueAsInitial?: boolean) {
    if (!value) {
      this.value = [];
      return;
    }

    this.value = value;

    if (setValueAsInitial) {
      this.setInitialValue(value);
    }
  }

  @action.bound
  setInitialValue(value: (string | number)[]): void {
    this.initialValue = value;
  }

  @action.bound
  returnInitialValue(): void {
    this.value = this.initialValue;
  }

  @action.bound
  clearItem(): void {
    this.value = [];
    this.clearError();
  }

  @action.bound
  clearInitialValue(): void {
    this.initialValue = [];
  }

  checkIsReady(): boolean {
    return !this.errorText;
  }

  @action.bound
  clearError(): void {
    this.errorText = undefined;
  }

  @action.bound
  tryToSetRawValue(value: unknown, setValueAsInitial?: boolean): boolean {
    if (!value || value === 'null' || value === 'undefined') {
      this.setValue([], setValueAsInitial);
      return true;
    }

    if (Array.isArray(value)) {
      const parsedValue = [];

      for (const rawValue of value) {
        if (typeof rawValue === 'number' && !Number.isNaN(rawValue)) {
          parsedValue.push(rawValue);
          continue;
        }

        if (typeof rawValue === 'string') {
          parsedValue.push(rawValue);
          continue;
        }

        console.error('wrong value type', rawValue);
      }

      this.setValue(parsedValue, setValueAsInitial);

      return true;
    }

    return false;
  }

  @action.bound
  filterInvalidValues() {
    for (const item of [...this.value]) {
      const isExists = this.options.find((option) => option.value.toString() === item.toString());

      if (!isExists) {
        this.value.remove(item);
      }
    }
  }

  hasErrors(): boolean {
    return !!this.errorText;
  }

  @action.bound
  validate(): boolean {
    if (!this.value && this.restrictions.required) {
      this.errorText = 'form:Errors.required';
    }

    return this.hasErrors();
  }
}
