import { Field, Select } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';

import type { DevSettingsScreenEntity } from './DevSettingsScreen.entity';
import type { BaseSettingsScreen } from '../screen/BaseSettingsScreen';
import type { Stack } from 'src/packages/data/structures/Stack';

import { DevSettingsScreenStore } from './DevSettingsScreen.store';

import styles from './DevSettingsScreen.module.scss';

type Props = {
  stack: Stack<BaseSettingsScreen>;
  screen: DevSettingsScreenEntity;
};

export const DevSettingsScreen = observer(function ObjectsListScreen({ stack, screen }: Props) {
  const [store] = useState(() => new DevSettingsScreenStore(stack, screen));

  return (
    <article className={styles.secondLevelColumn}>
      <Field label="Primary lang">
        <Select style={{ width: 240 }} value={store.currentPrimaryLang} onChange={store.selectLanguage}>
          {store.languages.map((lang) => (
            <Select.Option key={lang[0]} value={lang[0]}>
              {lang[1]?.text}
            </Select.Option>
          ))}
        </Select>
      </Field>
    </article>
  );
});
