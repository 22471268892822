import { WELL_STATUS_SOCKET_URL } from 'src/api/consts';

import type { ISockClientService } from '../sock-client-service/SockClientService';
import type { IServicesCollection } from 'src/packages/di';

import { SockClientService } from '../sock-client-service/SockClientService';

export type TWellStatus = 'COMPLETED' | 'ACTIVE_GREEN' | 'ACTIVE_YELLOW' | 'ACTIVE_RED';

export type WellStatusMessage = {
  wellId: number;
  status: TWellStatus;
};

type BroadcastListMsg = {
  broadcastId: number;
};

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    wellStatusService: ISockClientService<WellStatusMessage & BroadcastListMsg>;
  }
}

export async function addWellStatusService(di: IServicesCollection): Promise<void> {
  const wellStatusService = new SockClientService<WellStatusMessage & BroadcastListMsg>(WELL_STATUS_SOCKET_URL);
  await wellStatusService.init();

  di.addSingleton('wellStatusService', wellStatusService);
}
