import { StopLight, PlayLight, ZoomOutCustom } from '@profgeosoft-ui/icons';
import { ActionsBar, Switch, Tooltip } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { ImageBroadcastingWidgetState } from '../ImageBroadcastingWidgetState';

import { BroadcastsPanel } from './BroadcastsPanel';
import { BroadcastsSelectPanel } from './BroadcastsSelectPanel';
import { WellboreSelectPanel } from './WellboreSelectPanel';

type BroadcastingActionsBarProps = {
  widgetState: ImageBroadcastingWidgetState;
  resetScale: VoidFunction;
};

export const BroadcastingActionsBar = observer(function BroadcastingActionsBar({
  widgetState,
  resetScale,
}: BroadcastingActionsBarProps) {
  const { t } = useTranslation('imageBroadcast');

  function selectTimeZone(value: boolean) {
    if (value) {
      widgetState.timeZonesStore.select('user');
    } else {
      widgetState.timeZonesStore.select('well');
    }
  }

  return (
    <ActionsBar>
      <ActionsBar.TopBar>
        <BroadcastsPanel widgetState={widgetState} />
        <WellboreSelectPanel widgetState={widgetState} />
        <BroadcastsSelectPanel widgetState={widgetState} />
        <Tooltip placement="left" title={widgetState.isPlaying ? t('pause') : t('play')}>
          <ActionsBar.Item onClick={() => widgetState.setPlayingState(!widgetState.isPlaying)}>
            {widgetState.isPlaying ? <StopLight /> : <PlayLight />}
          </ActionsBar.Item>
        </Tooltip>
        <Tooltip placement="left" title={t('resetZoom')}>
          <ActionsBar.Item onClick={() => resetScale()}>
            <ZoomOutCustom />
          </ActionsBar.Item>
        </Tooltip>
      </ActionsBar.TopBar>
      <ActionsBar.BottomBar>
        {widgetState.timeZonesStore.wellTimeZone != null && !Number.isNaN(widgetState.timeZonesStore.wellTimeZone) && (
          <Switch
            style={{ marginLeft: 4 }}
            orientation="vertical"
            size="m"
            checked={widgetState.timeZonesStore.selected === widgetState.timeZonesStore.wellTimeZone}
            leftNode={widgetState.timeZonesStore.userTimeZoneTitle}
            rightNode={widgetState.timeZonesStore.wellTimeZoneTitle}
            onChange={(value) => {
              selectTimeZone(value);
            }}
          />
        )}
      </ActionsBar.BottomBar>
    </ActionsBar>
  );
});
