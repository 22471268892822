import { OperationalParametersWidgetEntity } from 'src/pages/dashboard/features/operational-parameters-widget/OperationalParametersWidget.entity';
import { parseOperationalParametersInternalState } from 'src/pages/dashboard/features/operational-parameters-widget/parseOperationalParametersWidget';

import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

export function mapOperationalParametersTemplate(
  template: TSerializedWidgetTemplate,
  index: number
): OperationalParametersWidgetEntity {
  const { size, position } = template;

  const internalState = parseOperationalParametersInternalState(template.internalState ?? {});

  const operationalParametersWidget = new OperationalParametersWidgetEntity(
    {
      wellId: internalState.wellId,
      stateParams: internalState.stateParams ?? {},
      wellIndexType: internalState.wellIndexType,
      type: 'well-operational-params-widget',
      enableSound: internalState.enableSound,
    },
    {
      groupId: template.groupId || null,
      isGroupsDefaultOpened: false,
      uuid: template.uuid,
      isPinned: false,
      isFullscreen: false,
      zIndex: index + 1,
      size: {
        w: size.w,
        h: size.h,
      },
      position: {
        x: position.x,
        y: position.y,
      },
    }
  );

  return operationalParametersWidget;
}
