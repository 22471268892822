import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';

import { assert } from 'src/packages/shared/utils/assert';
import { hasValue } from 'src/packages/shared/utils/common';
import { createPromiseController } from 'src/packages/shared/utils/promise-controller';

import type { Layout } from '@profgeosoft/go-react-grid-layout';
import type { TabEntity } from 'src/entities/tab/TabEntity';
import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';
import type { TPromiseController } from 'src/packages/shared/utils/promise-controller';

import { getCurrentBreakpoint } from '../../utils/getCurrentBreakpoint';

import { ROWS_COUNT } from './consts';
import { WidgetsDisplayingPriorityManager } from './widgets-displaying-priority-manager';

export class WorkbenchStore {
  readonly widgetsDisplayingPriorityManager: WidgetsDisplayingPriorityManager;
  readonly tabEntity: TabEntity;

  @observable hoveredWidgetId: string | null = null;
  @observable draggingWidgetId: string | null = null;
  @observable deleteWidgetController: TPromiseController<boolean | null> | null = null;

  constructor(tab: TabEntity) {
    this.widgetsDisplayingPriorityManager = new WidgetsDisplayingPriorityManager(tab);
    this.tabEntity = tab;

    makeObservable(this);
  }

  @computed
  get minWorkbenchWidth(): number | null {
    const widths = this.tabEntity.widgets.reduce((acc: number[], widget) => {
      const widgetMinPxWidth = widget.sizeBoundaries.minInPixels.w;

      if (hasValue(widgetMinPxWidth)) {
        acc.push(widgetMinPxWidth);
      }

      return acc;
    }, []);

    if (!widths.length) {
      return null;
    }

    return Math.max(...widths);
  }

  @computed
  get rowHeight(): number | null {
    if (!this.tabEntity.workbenchViewportHeight) {
      return null;
    }

    return this.tabEntity.workbenchViewportHeight / ROWS_COUNT;
  }

  @action.bound
  setDraggingWidgetId(id: string | null): void {
    this.draggingWidgetId = id;
  }

  @action.bound
  onDeleteWidgetAccept(): void {
    this.deleteWidgetController?.resolve(true);
  }

  @action.bound
  onDeleteWidgetCancel(): void {
    this.deleteWidgetController?.resolve(null);
  }

  @action.bound
  setHoveredWidgetId(id: string | null): void {
    this.hoveredWidgetId = id;
  }

  @action.bound
  updateWidgetPosition(widgetLayout: Layout): void {
    const widgetEntity = this.tabEntity.widgetsCollection.get(widgetLayout.i);

    if (!widgetEntity) {
      return;
    }

    widgetEntity.setPosition(widgetLayout.x, widgetLayout.y);
  }

  @action.bound
  saveWidgetNewSize(widgetLayout: Layout): void {
    const widgetEntity = this.tabEntity.widgetsCollection.get(widgetLayout.i);

    assert(widgetEntity, 'no widget with given widgetId');

    widgetEntity.setSize(widgetLayout.w, widgetLayout.h);
  }

  @flow.bound
  async *deleteWidget(widget: WidgetEntity, requireConfirmation: boolean = true) {
    if (requireConfirmation) {
      try {
        this.deleteWidgetController = createPromiseController<boolean | null>();

        const shouldDeleteWidget = await this.deleteWidgetController;
        yield;

        if (!shouldDeleteWidget) return;
      } finally {
        this.deleteWidgetController = null;
      }
    }

    this.tabEntity.deleteWidget(widget);
  }

  effect = (): VoidFunction => {
    const widgetsDisplayingPriorityManagerDisposer = this.widgetsDisplayingPriorityManager.init();
    const currentBreakpointDisposer = reaction(
      () => this.tabEntity.width,
      () => {
        this.tabEntity.setCurrentBreakpoint(getCurrentBreakpoint(this.tabEntity.width));
      }
    );

    return () => {
      widgetsDisplayingPriorityManagerDisposer();
      currentBreakpointDisposer();
    };
  };
}
