import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';

import { requireService } from 'src/packages/di';
import { debounce } from 'src/packages/shared/utils/debounce';
import { createPromiseController } from 'src/packages/shared/utils/promise-controller';
import { mapTab } from 'src/serializers/tab/mapTab';
import { serializeTab } from 'src/serializers/tab/serializeTab';

import type { TWellsFilterView } from 'src/api-types/wells.types';
import type { TabEntity } from 'src/entities/tab/TabEntity';
import type { TSerializedTab } from 'src/entities/workspace/types';
import type { TPromiseController } from 'src/packages/shared/utils/promise-controller';
import type { TContentWidgetOptions } from 'src/pages/dashboard/features/workspace/Workspace.store';

import { SlaveWindowsManager } from './services/SlaveWindowsManager';

export class SingleTabStore {
  readonly tabId: string;

  @observable windowsManager: SlaveWindowsManager | null = null;
  @observable masterConnectionController: TPromiseController<boolean> | null = null;
  @observable newContentWidgetController: TPromiseController<TContentWidgetOptions | null> | null = null;
  @observable isLoading: boolean = false;
  @observable isUpdating: boolean = false;
  @observable isFavoritesUpdating: boolean = false;
  @observable hasError: boolean = false;
  @observable actualTab: TabEntity | null = null;

  constructor(
    tabId: string,
    private readonly notifications = requireService('notifications'),
    private readonly preloadService = requireService('preloadService'),
    readonly favoritesWells = requireService('favoritesWells'),
    readonly directories = requireService('directories'),
    readonly appBroadcastService = requireService('appBroadcastService'),
    readonly wellLogsTemplateService = requireService('wellLogsTemplateService')
  ) {
    this.tabId = tabId;

    makeObservable(this);
  }

  @action.bound
  setFavoritesUpdating(value: boolean): void {
    this.isFavoritesUpdating = value;
  }

  @action.bound
  setUpdating(value: boolean): void {
    this.isUpdating = value;
  }

  @action.bound
  mapTab(rawTab: TSerializedTab): void {
    this.actualTab = mapTab(rawTab);
  }

  @action.bound
  setMasterConnectionController(value: TPromiseController<boolean> | null): void {
    this.masterConnectionController = value;
  }

  @action.bound
  recoverMaster(): void {
    this.masterConnectionController?.resolve(true);
  }

  @action.bound
  ingoreMasterRecovering(): void {
    this.masterConnectionController?.resolve(false);
  }

  @computed
  get state(): TSerializedTab | null {
    if (this.actualTab) {
      return serializeTab(this.actualTab);
    }

    return null;
  }

  @flow.bound
  async *onCreateNewContentWidget() {
    if (!this.actualTab) {
      return;
    }

    try {
      this.newContentWidgetController = createPromiseController<TContentWidgetOptions>();

      const res = await this.newContentWidgetController;
      yield;

      if (!res) return;

      if (res.type === 'well-logs-widget' && res.wellIndexType) {
        this.actualTab.createWellLogsWidget(res.wellIndexType, null, null, { isGroupsDefaultOpened: true });
      } else if (res.type === 'well-operational-params-widget' && res.wellIndexType) {
        this.actualTab.createOperationalParametersWidget(res.wellIndexType, null, true, null, {
          isGroupsDefaultOpened: true,
        });
      } else if (res.type) {
        this.actualTab.createWidget(res.type);
      }
    } finally {
      yield;
      this.newContentWidgetController = null;
    }
  }

  @flow.bound
  private async *fetchWellsFilterView() {
    try {
      this.hasError = false;

      yield;

      let objects: string[] = [];

      const wellsFilterView = this.preloadService.getPreloadedData<TWellsFilterView>('filter-control');

      wellsFilterView.fields.forEach((field) => {
        if ('refObjectType' in field && field.refObjectType) {
          const refObjectType = field.refObjectType;

          if (refObjectType) {
            objects.push(refObjectType);
          }
        }
      });

      await this.directories.loadObjects(objects);

      yield;
    } catch (error) {
      yield;

      console.error(error);
      this.hasError = true;
      this.notifications.showErrorMessageT('errors:failedToLoadWellsFilterView');
    }
  }

  @flow.bound
  private async *fetchRequiredData() {
    try {
      this.favoritesWells.loadFavoritesIds();
      await this.fetchWellsFilterView();

      yield;

      this.windowsManager = new SlaveWindowsManager(this);

      this.windowsManager.init();
    } catch (error) {
      console.error(error);
    }
  }

  private sendState = (state: TSerializedTab): void => {
    this.windowsManager?.sendTabState(state);
  };

  @action.bound
  private debouncedSaveWorkspace = debounce(this.sendState, 400);

  effect = () => {
    this.fetchRequiredData();

    const disposer = reaction(
      () => ({
        state: this.state,
      }),
      ({ state }) => {
        if (state && !this.isUpdating) {
          this.debouncedSaveWorkspace(state);
        }
      }
    );

    const disposeFavorites = reaction(
      () => this.favoritesWells.wellIds.slice(),
      (favoriteWells) => {
        if (this.isFavoritesUpdating) return;

        this.windowsManager?.sendFavoriteWellsList(favoriteWells);
      }
    );

    return () => {
      disposer();
      disposeFavorites();
      this.windowsManager?.dispose();
      this.wellLogsTemplateService.destroy();
    };
  };
}
