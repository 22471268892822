import { Scrollbar } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useNowDate } from 'src/packages/shared/hooks/useNowDate';

import type { Item } from '../../../controls/abstract-control-entities';
import type { ReactElement } from 'react';

import { DateRange } from '../../../controls/components/date-range/DateRange';
import { DateRangeViewLabel } from '../../../controls/components/date-range-view-label/DateRangeViewLabel';
import { FilterMulticombobox } from '../../../controls/components/filter-multicombobox/FilterMulticombobox';
import { MulticomboboxViewLabel } from '../../../controls/components/multicombobox-view-label/MulticomboboxViewLabel';
import { DateRangeFieldEntity } from '../../../controls/entities/DateRangeField.entity';
import { FilterMultiCombobox as FilterMultiComboboxEntity } from '../../../controls/entities/FilterMultiCombobox.entity';

import styles from './FilterControlsList.module.scss';

type Props = {
  items: Item[];
  isEditMode: boolean;
  isLoading: boolean;
};

export const FilterControlsList = observer(function FilterControlsList({ items, isEditMode, isLoading }: Props) {
  const { t } = useTranslation();

  const nowDate = useNowDate({ roundToDay: true });

  const controls = items.map((item) => {
    let control: null | ReactElement = null;
    let label: string | null = null;

    if (item.label) {
      label = item.label;
    }

    if (item.fieldId) {
      label = t(`Labels:${item.fieldId}.label`);
    }

    if (item instanceof FilterMultiComboboxEntity) {
      control = isEditMode ? (
        <FilterMulticombobox
          className={clsx(styles.combobox, styles.field)}
          item={item}
          isLoading={isLoading}
          key={item.fieldId}
          label={label ?? item.fieldId}
          isDisabled={false}
        />
      ) : (
        <MulticomboboxViewLabel key={item.fieldId} item={item} label={label ?? item.fieldId} />
      );
    }

    if (item instanceof DateRangeFieldEntity) {
      control = isEditMode ? (
        <DateRange
          key={item.fieldId}
          item={item}
          isDisabled={false}
          defaultTime={nowDate}
          label={label ?? item.fieldId}
          className={styles.field}
        />
      ) : (
        <DateRangeViewLabel key={item.fieldId} label={label ?? item.fieldId} item={item} className={styles.field} />
      );
    }

    return control;
  });

  return (
    <div className={styles.filters}>
      <Scrollbar>{controls}</Scrollbar>
    </div>
  );
});
