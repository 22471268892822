import {
  VerticalCustom as DrillingIcon,
  UsersAltLight as RigCrewIcon,
  DrillingRigCustom as RigIcon,
  SearchLight as SearchIcon,
  StarFill as StarIcon,
  StarLight as StarOutlinedIcon,
  Status as StatusIcon,
  WellClusterLightCustom as WellClusterIcon,
} from '@profgeosoft-ui/icons';
import { Button, Input, Loader, RadioButtonGroup, Scrollbar } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useService, useServiceAccessor } from 'src/packages/di';
import { useRefs } from 'src/packages/shared/hooks/useRefsArray';

import type { WellItemEntity } from './WellCollapse';
import type { WellListWidgetEntity } from './WellListWidget.entity';
import type { IWellCollapseItemEntity } from '../well-collapse/components/well-collapse-item/WellCollapseItem.entity';
import type { TWidgetRendererOptions } from '../workspace/services/WidgetRendererService';
import type { WellIndexType, WidgetStateParams } from '@go-widgets/well-logs-widget';
import type { MouseEvent, Ref } from 'react';
import type { TWellListView } from 'src/api-types/wells.types';
import type { TCreateWidgetOptions } from 'src/entities/tab/TabEntity';

import { ContextMenuButton } from '../context-menu-button/ContextMenuButton';
import { WellCollapse } from '../well-collapse/WellCollapse';
import { WidgetHeader } from '../widget-header/WidgetHeader';
import { FilterButton } from '../workbench/filter-button/FilterButton';
import { PinWidgetButton } from '../workbench/pin-widget-button/PinWidgetButton';

import { WellListWidgetStore } from './WellListWidget.store';

import styles from './WellListWidget.module.scss';

type Props = {
  wellListEntity: WellListWidgetEntity;
  options: TWidgetRendererOptions;
  onCreateWellsFilterWidget(options?: TCreateWidgetOptions): void;
  onCreateWellLogsWidget(
    wellIndexType: WellIndexType,
    wellId: number | null,
    stateParams: Partial<WidgetStateParams> | null,
    options?: TCreateWidgetOptions
  ): void;
};

type WellListProps = {
  wellListEntity: WellListWidgetEntity;
  options: TWidgetRendererOptions;
  store: WellListWidgetStore;
  refAt: <U extends HTMLDivElement>(key: number) => Ref<U>;
  onCreateWellLogsWidget(
    wellIndexType: WellIndexType,
    wellId: number | null,
    stateParams: Partial<WidgetStateParams> | null,
    options?: TCreateWidgetOptions
  ): void;
};

const groupingIcons: Partial<Record<string, React.ReactElement>> = {
  'rig-icon': <RigIcon className={styles.groupTypeIcon} />,
  'rig-crew-icon': <RigCrewIcon className={styles.groupTypeIcon} />,
  'status-icon': <StatusIcon className={styles.groupTypeIcon} />,
  'location-icon': <WellClusterIcon className={styles.groupTypeIcon} />,
};

export const WellListWidgetWrapper = observer(function WellListWidgetWrapper({
  wellListEntity,
  options,
  onCreateWellsFilterWidget,
  onCreateWellLogsWidget,
}: Props) {
  const [store, setStore] = useState<WellListWidgetStore | null>(null);
  const { refAt, valueAt, clearRefs } = useRefs<HTMLDivElement, number>();

  const widgetStoreService = useService('widgetStoreService');

  useEffect(() => {
    const store = widgetStoreService.getStore<WellListWidgetEntity, WellListWidgetStore>(wellListEntity);

    if (store) {
      setStore(store);
    } else {
      const newStore = new WellListWidgetStore(
        wellListEntity,
        options.groupSelectStore,
        onCreateWellsFilterWidget,
        valueAt,
        clearRefs
      );

      widgetStoreService.setStore<WellListWidgetEntity, WellListWidgetStore>(wellListEntity, newStore);

      setStore(newStore);
    }
  }, [
    setStore,
    clearRefs,
    onCreateWellsFilterWidget,
    options.groupSelectStore,
    valueAt,
    wellListEntity,
    widgetStoreService,
  ]);

  if (!store) return null;

  return (
    <WellListWidget
      wellListEntity={wellListEntity}
      options={options}
      store={store}
      refAt={refAt}
      onCreateWellLogsWidget={onCreateWellLogsWidget}
    />
  );
});

export const WellListWidget = observer(function WellListWidget({
  wellListEntity,
  options,
  store,
  refAt,
  onCreateWellLogsWidget,
}: WellListProps) {
  const { theme } = useService('theme');
  const {
    changeFilterBy,
    changeGroupBy,
    isLoading,
    changeSearchValue,
    clearSearch,
    selectWell,
    toggleFavorite,
    favoritesWells,
    isFilterWidgetExists,
    createFilterWidget,
    setGroupForWellList,
    isFilterStateEmpty,
    onCollapseKeysChange,
    activeKey,
    fetchWells,
    isInitiated,
    effect,
  } = store;

  const {
    filterBy,
    groupBy,
    searchValue,
    selectedWellID,
    wells,
    view: { item: itemView },
  } = wellListEntity;

  useEffect(() => {
    if (!isInitiated) {
      fetchWells();
    }
  }, [isInitiated, fetchWells]);

  useEffect(effect, [effect]);

  const handlePinWidget = () => options.widgetsDisplayingPriorityManager.togglePinWidget(wellListEntity);

  const getDataService = useServiceAccessor('preloadService');
  const { t } = useTranslation();
  const dataService = getDataService();
  const wellListView = dataService.getPreloadedData<TWellListView>('well-list-control');

  const filteringItems = wellListView.filtering.filters.map((item, index) => ({
    label: t(`Labels:${item.name}.label`, { defaultValue: item.name }),
    value: index,
  }));

  const groupingItems = wellListView.grouping.groups.map((group, index) => {
    const { icon } = group;

    return {
      label: groupingIcons[icon] ?? group.name,
      value: index,
    };
  });

  const _onFavoriteToggle = (e: MouseEvent, item: IWellCollapseItemEntity) => {
    e.stopPropagation();
    toggleFavorite(item);
  };

  const renderButtons = (item: IWellCollapseItemEntity) => (
    <>
      <Button
        icon={
          favoritesWells.hasWell(item) ? (
            <StarIcon className={styles.activeIcon} />
          ) : (
            <StarOutlinedIcon className={styles.icon} />
          )
        }
        variant="flat"
        onClick={(e) => _onFavoriteToggle(e, item)}
        className={styles.iconButton}
      />
      <ContextMenuButton id={item.id} onCreateWellLogsWidget={onCreateWellLogsWidget} />
    </>
  );

  return (
    <>
      <WidgetHeader
        entity={wellListEntity}
        onWidgetChangeGroup={setGroupForWellList}
        groupSelectStore={options.groupSelectStore}
        title={wellListEntity.title}
        onWidgetDelete={() => options.onWidgetDelete(wellListEntity)}
        renderAdditionalButtons={() => (
          <>
            {!isFilterStateEmpty && !isFilterWidgetExists && <FilterButton onClick={createFilterWidget} />}
            {wellListEntity.isControlWidget && (
              <PinWidgetButton isPinned={wellListEntity.isPinned} onClick={handlePinWidget} />
            )}
          </>
        )}
      />
      <div className={styles.wrapper}>
        {isLoading && <Loader className={styles.loader} />}
        <div className={styles.contentWrapper}>
          <RadioButtonGroup
            variant="flat"
            items={filteringItems}
            value={filterBy}
            className={styles.switchContainer}
            itemClassName={styles.switchButton}
            onChange={changeFilterBy}
          />
          <Input
            allowClear
            fieldClassName={styles.search}
            prefix={<SearchIcon className={styles.searchIcon} />}
            value={searchValue}
            inputClassName={styles.searchInput}
            placeholder={t('wellList:search')}
            onChange={(e) => changeSearchValue(e.target.value)}
          />

          <Scrollbar>
            <WellCollapse<WellItemEntity>
              renderIcon={(item) => {
                const color =
                  item.status === 'COMPLETED' && theme === 'light' ? '#788CB9' : itemView.statusColor[item.status];
                return <DrillingIcon className={styles.drillingIcon} style={{ color: color ?? '#ffffff80' }} />;
              }}
              renderButtons={renderButtons}
              collapses={wells}
              onItemSelect={selectWell}
              refAt={refAt}
              activeId={selectedWellID ?? void 0}
              activeKey={activeKey}
              onChange={onCollapseKeysChange}
            />
          </Scrollbar>
        </div>

        <RadioButtonGroup
          variant="flat"
          items={groupingItems}
          value={groupBy}
          itemClassName={styles.groupTypeButton}
          className={styles.groupTypeContainer}
          onChange={changeGroupBy}
        />
      </div>
    </>
  );
});
