import { requireService } from 'src/packages/di';

import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';
import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

export function mapWidgetTemplates(widgets: TSerializedWidgetTemplate[]): WidgetEntity[] {
  const mappedWidgets: WidgetEntity[] = [];

  widgets.forEach((widget, index) => {
    const mappedWidget = mapWidgetTemplate(widget, index);

    mappedWidgets.push(mappedWidget);
  });

  return mappedWidgets;
}

export function mapWidgetTemplate(widget: TSerializedWidgetTemplate, index: number): WidgetEntity {
  const mapWidgetService = requireService('widgetTemplateSerializer');

  return mapWidgetService.map(widget, index);
}
