import { action, makeObservable, reaction } from 'mobx';

import type { TabEntity } from 'src/entities/tab/TabEntity';
import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';

export class WidgetsDisplayingPriorityManager {
  private readonly tab: TabEntity;

  constructor(tabEntity: TabEntity) {
    this.tab = tabEntity;

    makeObservable(this);
  }

  @action.bound
  private calculateAndSetZIndexes(widgets: WidgetEntity[], activeWidget?: WidgetEntity): void {
    const clonedWidgetsArray = [...widgets];

    clonedWidgetsArray.sort((a, b): number => {
      const isAPinned = a.isPinned;
      const isBPinned = b.isPinned;

      if (!isAPinned && isBPinned) {
        return -1;
      } else if (isAPinned && !isBPinned) {
        return 1;
      }

      if (activeWidget) {
        if (a === activeWidget) {
          return 1;
        } else if (b === activeWidget) {
          return -1;
        }
      }

      if (a.zIndex < b.zIndex) {
        return -1;
      } else if (b.zIndex < a.zIndex) {
        return 1;
      }

      return 0;
    });

    clonedWidgetsArray.forEach((widget, index) => {
      widget.setZIndex(index + 1);
    });
  }

  @action.bound
  togglePinWidget(widget: WidgetEntity): void {
    widget.setIsPinned(!widget.isPinned);
    this.calculateAndSetZIndexes(this.tab.widgets);
  }

  @action.bound
  placeWidgetOnTop(widget: WidgetEntity): void {
    this.calculateAndSetZIndexes(this.tab.widgets, widget);
  }

  init = (): VoidFunction => {
    const disposer = reaction(
      () => this.tab.widgets.slice(),
      (widgets) => {
        this.calculateAndSetZIndexes(widgets);
      }
    );

    return () => {
      disposer();
    };
  };
}
