import type { ISessionService } from './SessionService';
import type { IServicesCollection } from 'src/packages/di';

import { SessionService } from './SessionService';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    sessionService: ISessionService;
  }
}

export async function addSessionService(di: IServicesCollection): Promise<void> {
  const sessionService = new SessionService();

  di.addSingleton('sessionService', sessionService);
}
