import { parseWellAnalyseWidgetInternalState } from 'src/pages/dashboard/features/well-analyse-widget/parseWellAnalyseWidget';
import { WellAnalyseWidgetEntity } from 'src/pages/dashboard/features/well-analyse-widget/WellAnalyseWidget.entity';

import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

export function mapWellAnalyseTemplate(template: TSerializedWidgetTemplate, index: number): WellAnalyseWidgetEntity {
  const { size, position } = template;

  const internalState = parseWellAnalyseWidgetInternalState(template.internalState ?? {});

  const wellAnalyseWidget = new WellAnalyseWidgetEntity(
    {
      wellId: internalState.wellId,
      stateParams: internalState.stateParams ?? {},
      type: 'well-analyse-widget',
    },
    {
      groupId: template.groupId || null,
      isGroupsDefaultOpened: false,
      uuid: template.uuid,
      isPinned: false,
      isFullscreen: false,
      zIndex: index + 1,
      size: {
        w: size.w,
        h: size.h,
      },
      position: {
        x: position.x,
        y: position.y,
      },
    }
  );

  return wellAnalyseWidget;
}
