import type { TBreakpointsKeys } from '../features/workbench/types';

export const getCurrentBreakpoint = (width: number): TBreakpointsKeys => {
  if (width < 470) return 'xxxxs';
  if (width >= 470 && width < 630) return 'xxxs';
  if (width >= 630 && width < 758) return 'xxs';
  if (width >= 758 && width < 986) return 'xs';
  if (width >= 986 && width < 1270) return 's';
  if (width >= 1270 && width < 1430) return 'sm';
  if (width >= 1430 && width < 1670) return 'md';
  if (width >= 1670 && width < 1910) return 'lg';
  if (width >= 1910 && width < 2430) return 'xl';
  return 'xxl';
};
