// import * as Sentry from '@sentry/react';
import axios from 'axios';

import {
  BaseApiError,
  NetworkApiError,
  Server500ApiError,
  Unauthorized401ApiError,
  UnknownApiError,
  OperationAbortedError,
  NotFoundApiError,
  ValidationTextApiError,
  Validation400ApiErrorWithCause,
  Validation400ApiError,
  ForbiddenApiError,
} from 'src/app/errors';
import { isObjectWithKeys } from 'src/packages/shared/utils/isObjectWithKeys';

import type { AxiosError, AxiosResponse } from 'axios';

export function isAxiosError(e: unknown): e is AxiosError<unknown> {
  return axios.isAxiosError(e);
}

export function isCancelError(e: unknown) {
  return axios.isCancel(e);
}

export function throwApiError(e: unknown): never {
  if (e instanceof BaseApiError) {
    throw e;
  }

  if (isAxiosError(e)) {
    if (isCancelError(e)) {
      throw new OperationAbortedError();
    }

    const errorMsg: string | null = tryGetErrorMessageFromResponse(e.response);

    if (errorMsg) {
      throw new ValidationTextApiError(errorMsg, e);
    }

    if (!e.response) {
      throw new NetworkApiError(e);
    }
    if (e.response.status >= 500) {
      throw new Server500ApiError(e);
    }
    if (e.response.status === 401) {
      throw new Unauthorized401ApiError(e);
    }
    if (e.response.status === 403) {
      throw new ForbiddenApiError(e);
    }
    if (e.response.status === 400) {
      const cause: object | null = tryGetErrorCauseDataFromResponse(e.response);

      if (cause) {
        throw new Validation400ApiErrorWithCause<object>(e, cause);
      }

      throw new Validation400ApiError();
    }
    if (e.response.status === 404) {
      throw new NotFoundApiError(e);
    }
    throw new UnknownApiError(e);
  }
  throw e;
}

function tryGetErrorMessageFromResponse(response?: AxiosResponse): string | null {
  if (!response) {
    return null;
  }

  function checkMessageObj(data: unknown): data is { message: string | string[] } {
    return isObjectWithKeys(data) && 'message' in data;
  }

  const data = response.data;

  if (!checkMessageObj(data)) {
    return null;
  }

  const messageRaw = data['message'];
  if (Array.isArray(messageRaw)) {
    if (typeof messageRaw[0] === 'string') {
      return messageRaw[0];
    }
  }
  if (typeof messageRaw === 'string') {
    return messageRaw;
  }

  return null;
}

function tryGetErrorCauseDataFromResponse(response?: AxiosResponse): object | null {
  if (!response) {
    return null;
  }

  if (response instanceof Object) {
    return response;
  }

  return null;
}
