import { Stomp } from '@stomp/stompjs';
import SockJS from 'sockjs-client';

import { getAccessToken } from 'src/app/auth/auth';

import type { CompatClient, StompSubscription } from '@stomp/stompjs';

export interface ISockClientService<T> {
  init(withAuth: boolean): void;
  subscribe(key: string, callback: TStompCallback<T>): void;
  unsubscribe(key: string, callback: TStompCallback<T>): void;
  sendMessage(key: string, message: string): void;
  destroy(): void;
}

type TStompCallback<T> = (value: T) => void;

const SESSION_ID_REGEX = /^.+?\/\d+?\/(?<sessionId>([^/])*?)(\/websocket)?$/gi;

export class SockClientService<T> implements ISockClientService<T> {
  private readonly url: string;

  private socket: WebSocket | null = null;
  private client: CompatClient | null = null;
  private subscribers: Map<string, { callbackList: TStompCallback<T>[]; subscription?: StompSubscription }> = new Map();

  constructor(url: string) {
    this.url = url;
  }

  private getSessionId(): string | null {
    const url = this.client?.ws?._transport?.url;

    if (!url || typeof url !== 'string') return null;
    const sessionId = url.replace(SESSION_ID_REGEX, '$<sessionId>');

    return sessionId;
  }

  subscribe(key: string, callback: TStompCallback<T>): void {
    if (!this.subscribers.has(key)) {
      this.subscribers.set(key, { callbackList: [] });
    }

    const subscriberData = this.subscribers.get(key);
    if (this.client?.connected && subscriberData) {
      const { callbackList } = subscriberData;
      callbackList.push(callback);

      const subscription = this.client?.subscribe(key, (message) => {
        try {
          const data = JSON.parse(JSON.stringify(message.body));
          callbackList.forEach((cb) => cb(data));
        } catch (error) {
          console.error(error);
        }
      });

      subscriberData.subscription?.unsubscribe();
      subscriberData.subscription = subscription;
    }
  }

  async init(withAuth: boolean = false, onConnect?: (str: string) => void): Promise<void> {
    this.socket = new SockJS(this.url);

    this.client = Stomp.over(() => this.socket);
    this.client.debug = () => {};

    const accessToken = getAccessToken();

    if (withAuth && !accessToken) {
      return;
    }

    const headers = withAuth
      ? {
          Authorization: `Bearer ${accessToken}`,
        }
      : {};

    return new Promise((resolve) => {
      this.client?.connect(headers, () => {
        for (const [key, subscriberData] of this.subscribers) {
          const subscription = this.client?.subscribe(key, (message) => {
            try {
              const data = JSON.parse(JSON.stringify(message.body));
              subscriberData.callbackList.forEach((callback) => callback(data));
            } catch (error) {
              console.error(error);
            }
          });

          subscriberData.subscription?.unsubscribe();
          subscriberData.subscription = subscription;
        }
        const sessionId = this.getSessionId();

        onConnect?.(sessionId ?? '');
        resolve();
      });
    });
  }

  sendMessage(key: string, message: string): void {
    if (this.client?.connected) {
      this.client?.send(key, {}, message);
    }
  }

  unsubscribe(key: string, callback: TStompCallback<T>): void {
    const subscriberData = this.subscribers.get(key);

    if (!subscriberData) return;

    const { callbackList } = subscriberData;
    const index = callbackList.findIndex((cb) => cb === callback);

    if (index === -1) return;

    callbackList.splice(index, 1);

    if (this.client?.connected && callbackList.length === 0 && subscriberData.subscription) {
      subscriberData.subscription.unsubscribe();
      this.subscribers.delete(key);
    }
  }

  destroy = (): void => {
    this.socket?.close();
  };
}
