import { startOfDay } from 'date-fns';
import { useState } from 'react';

type TOptions = { roundToDay?: boolean };

export function useNowDate({ roundToDay }: TOptions): Date {
  const [date] = useState(roundToDay ? startOfDay(new Date()) : new Date());

  return date;
}
