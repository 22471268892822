import { PinFillLeft as PinIcon, PinLightLeft as PinOutlineIcon } from '@profgeosoft-ui/icons';
import { Button, Tooltip } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './PinWidgetButton.module.scss';

type Props = {
  isPinned: boolean;
  onClick: VoidFunction;
};

export const PinWidgetButton = observer(function PinWidgetButton({ isPinned, onClick }: Props) {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('common:Widget.pin')} delay={400}>
      <Button
        onClick={onClick}
        variant="flat"
        icon={isPinned ? <PinIcon /> : <PinOutlineIcon />}
        className={styles.button}
      />
    </Tooltip>
  );
});
