import type { IServicesCollection } from '../di';

import { ClassOfUnitsService } from './ClassOfUnitsService';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    classOfUnitsService: ClassOfUnitsService;
  }
}

export async function addClassesOfUnitsService(di: IServicesCollection): Promise<void> {
  const classOfUnitsService = new ClassOfUnitsService();

  await classOfUnitsService.init();

  di.addSingleton('classOfUnitsService', classOfUnitsService);
}
