import { action, makeObservable } from 'mobx';

import { BroadcastTunnel } from 'src/packages/broadcast-tunnel/BroadcastTunnel';

import type { SlaveWindowsManager } from './SlaveWindowsManager';
import type { SingleTabStore } from '../SingleTab.store';
import type { TDeleteTabMessage } from '../SingleTab.types';
import type { TTabDeletedMessage } from 'src/pages/dashboard/features/workspace/types';

export class TabClosingService {
  private readonly store: SingleTabStore;
  private readonly windowsManager: SlaveWindowsManager;
  private readonly tabClosingTunnel: BroadcastTunnel<TTabDeletedMessage, TDeleteTabMessage>;

  constructor(store: SingleTabStore, windowsManager: SlaveWindowsManager) {
    this.store = store;
    this.windowsManager = windowsManager;

    this.tabClosingTunnel = new BroadcastTunnel((message): message is TDeleteTabMessage => {
      if (message.type !== 'delete-tab' || this.store.tabId !== message?.to?.id) return false;

      return true;
    }, this.onCloseTab);

    makeObservable(this);
  }

  @action.bound
  destroyTab(id: string): void {
    if (!this.windowsManager.master.id) return;

    this.tabClosingTunnel.postMessage({
      type: 'tab-deleted',
      from: {
        type: 'slave',
        id: id,
      },
      to: {
        id: this.windowsManager.master.id,
      },
    });

    window.close();
  }

  private onCloseTab = (): void => {
    this.destroyTab(this.store.tabId);
  };

  dispose = (): void => {
    this.tabClosingTunnel.disconnect();
  };
}
