import { action, makeObservable } from 'mobx';

import type { Stack } from 'src/packages/data/structures/Stack';
import type { TTabTemplateWithGSProps } from 'src/pages/dashboard/features/workspace/types';

import { BaseSettingsScreen } from '../screen/BaseSettingsScreen';
import { TabTemplateDetailsEntity } from '../tab-template-details/TabTemplateDetails.entity';

export class TabTemplatesListScreenEntity extends BaseSettingsScreen {
  readonly type = 'tab-templates-list';

  constructor(stack: Stack<BaseSettingsScreen>) {
    super(stack);

    makeObservable(this);
  }

  @action.bound
  createTabTemplateDetails(template: TTabTemplateWithGSProps): void {
    const newChildren = new TabTemplateDetailsEntity(this.stack, template);

    this.stack.push(newChildren);
  }
}
