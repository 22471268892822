import { isAxiosError } from 'src/api/utils';

import type { AxiosError } from 'axios';

export abstract class BaseApiError extends Error {
  axiosError: AxiosError | null = null;

  constructor(error?: unknown) {
    super();
    if (isAxiosError(error)) {
      this.axiosError = error;
    }
  }
}

export class ValidationError extends BaseApiError {}

export class Server500ApiError extends BaseApiError {}

export class Unauthorized401ApiError extends BaseApiError {}

export class ForbiddenApiError extends BaseApiError {}

export class Validation400ApiError extends BaseApiError {}

export class NetworkApiError extends BaseApiError {}

export class UnknownApiError extends BaseApiError {}

export class NotFoundApiError extends BaseApiError {}

export class Validation400ApiErrorWithCause<T> extends Validation400ApiError {
  readonly reason: T;

  constructor(error: unknown, reason: T, message?: string) {
    super(error);
    if (message) {
      this.message = message;
    }
    this.reason = reason;
  }
}

export class ValidationTextApiError extends BaseApiError {
  readonly responseMessage: string;

  constructor(responseMsg: string, e?: unknown) {
    super(e);
    this.responseMessage = responseMsg;
  }
}

export class NotFoundError extends BaseApiError {}
