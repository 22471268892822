export const isStringArray = (value: unknown): value is string[] => {
  if (Array.isArray(value)) {
    for (const item of value) {
      if (typeof item !== 'string') {
        return false;
      }
    }

    return true;
  }

  return false;
};
