import type { TSerializedWidget } from 'src/entities/workspace/types';
import type { TFilterDataSource } from 'src/pages/dashboard/features/filter-widget/WellsFilterDataSource';
import type { WellListWidgetEntity } from 'src/pages/dashboard/features/well-list-widget/WellListWidget.entity';

import { serializeDataSource } from '../data-source/serializeDataSource';

export function serializeWellListWidget(widget: WellListWidgetEntity): TSerializedWidget {
  const listWidgetInternalState: {
    name: string;
    value: string | number | (number | string)[] | TFilterDataSource;
  }[] = [];

  if (widget.filterBy) {
    listWidgetInternalState.push({
      name: 'filterBy',
      value: widget.filterBy,
    });
  }

  if (widget.collapsedKeys) {
    listWidgetInternalState.push({
      name: 'collapsedKeys',
      value: Array.from(widget.collapsedKeys),
    });
  }

  if (widget.groupBy) {
    listWidgetInternalState.push({
      name: 'groupBy',
      value: widget.groupBy,
    });
  }

  if (widget.searchValue) {
    listWidgetInternalState.push({
      name: 'searchValue',
      value: widget.searchValue,
    });
  }

  if (widget.selectedWellID) {
    listWidgetInternalState.push({
      name: 'selectedWellID',
      value: widget.selectedWellID,
    });
  }

  if (widget.filterState) {
    listWidgetInternalState.push({
      name: 'filterState',
      value: serializeDataSource(widget.filterState),
    });
  }

  return {
    isPinned: widget.isPinned,
    uuid: widget.uuid,
    type: widget.type,
    groupId: widget.groupId,
    isFullscreen: widget.isFullscreen,
    size: {
      w: widget.size.w,
      h: widget.size.h,
    },
    position: {
      x: widget.position.x,
      y: widget.position.y,
    },
    internalState: {
      values: listWidgetInternalState,
    },
  };
}
