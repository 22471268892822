import { observer } from 'mobx-react-lite';

import { LanguageSwitcher } from 'src/components/sidebar/language-switcher/LanguageSwitcher';

import { ThemeSwitcher } from '../theme-switcher/ThemeSwitcher';

import styles from './Switchers.module.scss';

export const Switchers = observer(function Switchers() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.switcherWrapper}>
        <LanguageSwitcher />
      </div>
      <div className={styles.switcherWrapper}>
        <ThemeSwitcher />
      </div>
    </div>
  );
});
