import { BroadcastWriteTunnel } from 'src/packages/broadcast-tunnel/BroadcastWriteTunnel';

import type { TMasterClosedMessage } from '../types';
import type { WorkspaceStore } from '../Workspace.store';

export class MasterClosingService {
  private readonly store: WorkspaceStore;
  private readonly masterClosingTunnel: BroadcastWriteTunnel<TMasterClosedMessage>;

  constructor(store: WorkspaceStore) {
    this.store = store;

    this.masterClosingTunnel = new BroadcastWriteTunnel();
  }

  private handleBeforeUnload = (): void => {
    const id = this.store.id;

    for (const tab of this.store.externalTabs) {
      if (!tab.inited) continue;

      this.masterClosingTunnel.postMessage({
        type: 'master-closed',
        from: {
          type: 'master',
          id,
        },
        to: {
          id: tab.id,
        },
      });
    }
  };

  init = (): void => {
    window.addEventListener('beforeunload', this.handleBeforeUnload, { capture: true });
  };

  dispose = (): void => {
    window.removeEventListener('beforeunload', this.handleBeforeUnload, { capture: true });
  };
}
