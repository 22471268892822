import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ObjectListScreenEntity } from './ObjectsListScreen.entity';
import type { BaseSettingsScreen } from '../screen/BaseSettingsScreen';
import type { WellIndexType } from '@go-widgets/well-logs-widget';
import type { Stack } from 'src/packages/data/structures/Stack';

import { ScreenCollapse } from '../screen-collapse/ScreenCollapse';

import { ObjectsListScreenStore } from './ObjectsListScreen.store';

import styles from './ObjectsListScreen.module.scss';

export const dummyCollapses: TObjectCollapse[] = [
  {
    collapseName: 'settings:Widgets.title',
    id: 'widgets',
    items: [
      {
        id: 'LOG_DEPTH',
        title: 'settings:Widgets.byDepth',
      },
      {
        id: 'LOG_TIME',
        title: 'settings:Widgets.byTime',
      },
    ],
  },
];

type Props = {
  stack: Stack<BaseSettingsScreen>;
  screen: ObjectListScreenEntity;
};

type TObjectCollapse = {
  collapseName: string;
  id: string;
  items: {
    id: WellIndexType;
    title: string;
  }[];
};

export const ObjectsListScreen = observer(function ObjectsListScreen({ stack, screen }: Props) {
  const [store] = useState(() => new ObjectsListScreenStore(stack, screen, dummyCollapses[0].items[0].id));

  const { activeObjectCollapses, openObjectCollapse, selectObject } = store;

  const { t } = useTranslation();

  const renderCollapseContent = (collapse: TObjectCollapse) => {
    return collapse.items.map((item) => (
      <div
        key={item.id}
        onClick={() => selectObject(item.id)}
        className={clsx(
          styles.secondLevelColumnItem,
          screen.selectedObjectKey === item.id && styles.secondLevelColumnItem__active
        )}
      >
        {t(item.title)}
      </div>
    ));
  };

  return (
    <article className={styles.secondLevelColumn}>
      <ScreenCollapse<TObjectCollapse>
        getCollapseId={(collapse) => collapse.id}
        activeKeys={activeObjectCollapses}
        onOpenedCollapsesChange={openObjectCollapse}
        getCollapseName={(collapse) => t(collapse.collapseName)}
        renderCollapseContent={renderCollapseContent}
        collapses={dummyCollapses}
      />
    </article>
  );
});
