import {
  AddSquareLightBig as AddIcon,
  FilterAltLight as FilterIcon,
  FullArrowLight as FullIcon,
  SubttasksLight as SubstacksIcon,
  WidgetAddLight as WidgetAddIcon,
} from '@profgeosoft-ui/icons';
import { Button, Tooltip } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { TabsMenu } from 'src/pages/dashboard/features/tabs/components/tabs-menu/TabsMenu';

import type { TabEntity } from 'src/entities/tab/TabEntity';

import styles from './Toolbar.module.scss';

type Props = {
  tabsEntities: TabEntity[];
  onSetActiveKey(key?: string): void;
  onCreateTab(): void;
  onRemoveTab(id: string): void;
  onTabClose?(id: string): void;
  onOpenInNewWindow?(tabId: string): void;
  onFullscreen: VoidFunction;
  onChangeTabName(id: string, name: string): void;
  onSaveTabAsTemplateModalOpen(): void;
  onTabSavedAsTemplateChange(tab: TabEntity | null): void;
  onUpdateTemplate(tab: TabEntity): void;
  disabledTabs: string[];
  activeKey?: string;
  onTabFocus?(id: string): void;
  onCreateWellListWidget?(): void;
  onCreateWellsFilterWidget?(): void;
  onCreateContentWidget: VoidFunction;
};

export const Toolbar = observer(function Toolbar({
  activeKey,
  tabsEntities,
  disabledTabs,
  onSaveTabAsTemplateModalOpen,
  onTabSavedAsTemplateChange,
  onUpdateTemplate,
  onFullscreen,
  onTabFocus,
  onSetActiveKey,
  onCreateTab,
  onRemoveTab,
  onTabClose,
  onChangeTabName,
  onCreateWellListWidget,
  onCreateWellsFilterWidget,
  onCreateContentWidget,
  onOpenInNewWindow,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.toolbar}>
      <div className={styles.tabsButtons}>
        <Tooltip title={t('dashboard:Toolbar.addNewTab')} delay={400}>
          <Button
            variant="flat"
            icon={<AddIcon className={styles.icon} />}
            onClick={onCreateTab}
            className={styles.addTabButton}
          />
        </Tooltip>
        <TabsMenu
          disabledTabs={disabledTabs}
          onTabFocus={onTabFocus}
          onOpenInNewWindow={onOpenInNewWindow}
          activeKey={activeKey}
          tabs={tabsEntities}
          onTabRemove={onRemoveTab}
          onTabClose={onTabClose}
          onActiveKeyChange={onSetActiveKey}
          onChangeTabName={onChangeTabName}
          onSaveTabAsTemplateModalOpen={onSaveTabAsTemplateModalOpen}
          onUpdateTemplate={onUpdateTemplate}
          onTabSavedAsTemplateChange={onTabSavedAsTemplateChange}
        />
      </div>
      <div className={styles.widgetsToolbar}>
        <Tooltip title={t('dashboard:Toolbar.enableFullScreen')} delay={400}>
          <Button
            variant="flat"
            icon={<FullIcon className={styles.icon} />}
            title={t('dashboard:Toolbar.enableFullScreen')}
            onClick={onFullscreen}
            className={styles.toolbarIcon}
          />
        </Tooltip>

        <Tooltip title={t('dashboard:Toolbar.openWellsList')} delay={400}>
          <Button
            variant="flat"
            icon={<SubstacksIcon className={styles.icon} />}
            onClick={() => onCreateWellListWidget?.()}
            className={styles.toolbarIcon}
          />
        </Tooltip>
        <Tooltip title={t('dashboard:Toolbar.openFilters')} delay={400}>
          <Button
            variant="flat"
            icon={<FilterIcon className={styles.icon} />}
            onClick={() => onCreateWellsFilterWidget?.()}
            className={styles.toolbarIcon}
          />
        </Tooltip>
        <Tooltip title={t('dashboard:Toolbar.addNewWidget')} delay={400}>
          <Button
            variant="flat"
            icon={<WidgetAddIcon className={styles.icon} />}
            onClick={onCreateContentWidget}
            className={styles.toolbarIcon}
          />
        </Tooltip>
      </div>
    </div>
  );
});
