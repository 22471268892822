import { isObjectWithKeys } from 'src/packages/shared/utils/isObjectWithKeys';
import { DateRangeFieldEntity } from 'src/pages/dashboard/features/controls/entities/DateRangeField.entity';

import type { TDateIntervalFieldRaw, TSavedFormValue } from 'src/pages/dashboard/features/controls/types';

type TMapDateIntervalFieldItemOptions = {
  item: TDateIntervalFieldRaw;
  savedValue?: TSavedFormValue;
};

export const mapDateIntervalFieldItem = ({
  item,
  savedValue,
}: TMapDateIntervalFieldItemOptions): DateRangeFieldEntity => {
  const initialDates: { [key: string]: Date | number | null } = {};
  if (isObjectWithKeys<string | number | null>(savedValue)) {
    for (let key in savedValue) {
      const value = savedValue[key];
      if (key.includes('startDate') || key.includes('endDate')) {
        if (typeof value === 'number') {
          initialDates[key] = new Date(value * 1000);
          continue;
        }
        if (typeof value === 'string' && !isNaN(Number(value))) {
          initialDates[key] = new Date(Number(value) * 1000);
          continue;
        }
      }
      if (typeof value === 'number') {
        initialDates[key] = value;
      }
    }
  }

  const initialStart = initialDates[item.attrNames.startDate];
  const initialEnd = initialDates[item.attrNames.endDate];

  const data = {
    fieldId: item.fieldId,
    placeholderMin: 'common:DatePicker.defaultPlaceholder',
    placeholderMax: 'common:DatePicker.defaultPlaceholder',
    labels: {
      startDate: 'Labels:startDate.label',
      endDate: 'Labels:endDate.label',
    },
    unit: 'Labels:duration.unit',
    attrNames: item.attrNames,
    enableIf: item.enableIf,
    visuallyDisabled: item.visuallyDisabled,
    calculatedValue: item.calculatedValue,
    validate: item.validate,
    validationTags: item.validationTags,
    restrictions: { required: !!item.required },
    initialStart: initialStart instanceof Date ? initialStart : null,
    initialEnd: initialEnd instanceof Date ? initialEnd : null,
  };

  return new DateRangeFieldEntity(data);
};
