import type { IWellLogsTemplateService } from './WellLogsTemplateService';
import type { IServicesCollection } from 'src/packages/di';

import { WellLogsTemplateService } from './WellLogsTemplateService';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    wellLogsTemplateService: IWellLogsTemplateService;
  }
}
export async function addWellLogsTemplateService(di: IServicesCollection): Promise<void> {
  const wellLogsTemplateService = new WellLogsTemplateService();

  const promises = [
    wellLogsTemplateService.registerTemplateManager('LOG_DEPTH'),
    wellLogsTemplateService.registerTemplateManager('LOG_TIME'),
  ];

  await Promise.all(promises);

  di.addSingleton('wellLogsTemplateService', wellLogsTemplateService);
}
