import { action, computed, makeObservable, observable } from 'mobx';

import { BaseWidgetEntityWithWellId } from 'src/entities/widget/BaseWidgetEntityWithWellId';
import { requireService } from 'src/packages/di';
import { ComputedDataSource } from 'src/packages/template-builder/TemplateBuilder';

import type { WellCollapseEntity } from './WellCollapse';
import type { TFilterDataSource } from '../filter-widget/WellsFilterDataSource';
import type { WellCollapseItemEntity } from '../well-collapse/components/well-collapse-item/WellCollapseItem.entity';
import type { ObservableSet } from 'mobx';
import type { TWellListView } from 'src/api-types/wells.types';
import type { SizeBoundaries, TWidgetOptions } from 'src/entities/widget/WidgetEntity';
import type { IDataSource } from 'src/packages/template-builder/TemplateBuilder';

interface WellListWidgetOptions {
  type: string;
  filterState: TFilterDataSource | null;
  filterBy: number | null;
  groupBy: number | null;
  searchValue: string | null;
  selectedWellID: number | null;
  collapsedKeys: string[] | null;
}

export class WellListWidgetEntity extends BaseWidgetEntityWithWellId {
  readonly sizeBoundaries: SizeBoundaries = {
    minInPixels: {
      w: 280,
      h: 464,
    },
    maxInPixels: {
      w: 560,
    },
    min: {
      w: 37,
      h: 50,
    },
    max: {
      w: 93,
      h: 100,
    },
  };

  @observable wells: WellCollapseEntity[] = [];
  @observable view: TWellListView;
  @observable type: string;
  @observable filterState: TFilterDataSource | null;
  @observable filterBy: number;
  @observable groupBy: number;
  @observable searchValue: string;
  @observable selectedWellID: number | null;
  @observable collapsedKeys: ObservableSet<string>;

  constructor(
    { type, filterState, filterBy, groupBy, searchValue, selectedWellID, collapsedKeys }: WellListWidgetOptions,
    widgetOptions: TWidgetOptions,
    private readonly preloadService = requireService('preloadService')
  ) {
    super(widgetOptions);

    const view = this.preloadService.getPreloadedData<TWellListView>('well-list-control');

    this.collapsedKeys = observable.set(collapsedKeys ?? []);
    this.groupBy = groupBy ?? view.grouping.defaultGroupingIndex;
    this.filterBy = filterBy ?? view.filtering.defaultFilterIndex;
    this.filterState = filterState;
    this.searchValue = searchValue ?? '';
    this.selectedWellID = selectedWellID;
    this.type = type;
    this.view = view;

    makeObservable(this);
  }

  @computed
  get wellId(): number | null {
    return this.selectedWellID;
  }

  getNameT(): string {
    return 'wellList:title';
  }

  @action.bound
  updateWellStatus(id: number, status: string): void {
    for (const wellGroup of this.wells) {
      for (const well of wellGroup.items) {
        if (well.id === id) {
          well.setStatus(status);

          return;
        }
      }
    }
  }

  @action.bound
  setCollapsedKeys(keys: string[] | undefined): void {
    this.collapsedKeys = observable.set(keys ?? []);
  }

  @action.bound
  addCollapsedKey(key: string): void {
    this.collapsedKeys.add(key);
  }

  @action.bound
  removeCollapsedKey(key: string): void {
    this.collapsedKeys.delete(key);
  }

  @action.bound
  setFullscreen(value: boolean): void {
    this.isFullscreen = value;
  }

  @action.bound
  setWellId(wellId: number | null): void {
    this.selectedWellID = wellId;
  }

  @action.bound
  setOuterState(state: TFilterDataSource | null): void {
    this.filterState = state;
  }

  createDataSource(): IDataSource {
    return new ComputedDataSource(() => ({ search: this.searchValue, ...this.filterState }));
  }

  get isControlWidget(): boolean {
    return true;
  }

  get title(): string {
    const i18 = requireService('i18');

    return i18.t('wellList:title');
  }

  @action.bound
  changeFilterBy(filter: number): void {
    this.filterBy = filter;
  }

  @action.bound
  changeGroupBy(groupBy: number): void {
    this.groupBy = groupBy;
  }

  @action.bound
  changeSearchValue = (value: string) => {
    this.searchValue = value;
  };

  @action.bound
  setWells(wells: WellCollapseEntity[]): void {
    this.wells = wells;
  }

  @action.bound
  setSelectedWell(well: WellCollapseItemEntity | null): void {
    this.selectedWellID = well?.id ?? null;
  }
}
