import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';

export interface IWidgetStoreService {
  setStore<T extends WidgetEntity, S>(widget: T, store: S): void;
  getStore<T extends WidgetEntity, S>(widget: T): S | null;
}

export class WidgetStoreService implements IWidgetStoreService {
  private collection: Map<WidgetEntity, object> & {
    set<W extends WidgetEntity, S>(value: W, store: S): void;
    get<W extends WidgetEntity, S>(value: W): S;
  } = new Map();

  setStore<T extends WidgetEntity, S>(widget: T, store: S): void {
    if (this.collection.has(widget)) {
      const errorMessage = 'store is already created';

      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    this.collection.set(widget, store);
  }

  getStore<T extends WidgetEntity, S>(widget: T): S | null {
    const store = this.collection.get<T, S>(widget);

    return store ?? null;
  }
}
