export function getCorrectLocale(language: string): 'ru' | 'en' | 'es' {
  switch (language) {
    case 'ru':
    case 'en':
    case 'es':
      return language;
  }

  return 'ru';
}
