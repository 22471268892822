import type { IApplicationBroadcastService } from './ApplicationBroadcastService';
import type { IServicesCollection } from 'src/packages/di';

import { ApplicationBroadcastService } from './ApplicationBroadcastService';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    appBroadcastService: IApplicationBroadcastService;
  }
}

export function addApplicationBroadcastService(di: IServicesCollection): void {
  di.addSingleton('appBroadcastService', new ApplicationBroadcastService());
}
