import { action, makeObservable } from 'mobx';

import { BroadcastTunnel } from 'src/packages/broadcast-tunnel/BroadcastTunnel';
import { hasValue } from 'src/packages/shared/utils/common';

import type { MasterWindowsManager } from './MasterWindowsManager';
import type { TTabPongMessage } from '../types';
import type { WorkspaceStore } from '../Workspace.store';
import type { TPingTabMessage } from 'src/pages/tab/single-tab/SingleTab.types';

export class PingPongService {
  private readonly store: WorkspaceStore;
  private readonly windowsManager: MasterWindowsManager;
  private readonly pingTunnel: BroadcastTunnel<TPingTabMessage, TTabPongMessage>;
  private readonly masterPongTunnel: BroadcastTunnel<TTabPongMessage, TPingTabMessage>;

  private windowOpeningTimeout: number | null = null;

  constructor(store: WorkspaceStore, windowsManager: MasterWindowsManager) {
    this.store = store;
    this.windowsManager = windowsManager;

    this.pingTunnel = new BroadcastTunnel((message): message is TTabPongMessage => {
      if (message?.type !== 'pong' || message?.to?.id !== this.store.id) return false;

      return true;
    }, this.onTabPong);

    this.masterPongTunnel = new BroadcastTunnel((message): message is TPingTabMessage => {
      if (message?.type !== 'ping' || message?.to?.id !== this.store.id) return false;

      return true;
    }, this.onMasterPing);

    makeObservable(this);
  }

  @action.bound
  private onTabPong = (message: TTabPongMessage): void => {
    const { id: tabId } = message.from;
    this.store.responsedTabs.add(tabId);

    const windowRef = this.windowsManager.getWindowRef(tabId);

    if (windowRef) {
      if (windowRef?.location.href === 'about:blank') {
        windowRef.close();
        this.windowsManager.closeTab(tabId);

        this.windowOpeningTimeout = window.setTimeout(() => {
          this.windowsManager.openWindow(tabId);
        }, 200);
      } else {
        this.windowsManager.addWindowRef(tabId, windowRef);
      }
    }
  };

  private onMasterPing = (message: TPingTabMessage): void => {
    this.masterPongTunnel.postMessage({
      type: 'pong',
      from: {
        type: 'master',
        id: this.store.id,
      },
      to: {
        id: message.from.id,
      },
    });
  };

  private pingExternalTab(tabId: string): void {
    this.pingTunnel.postMessage({
      type: 'ping',
      from: {
        type: 'master',
        id: this.store.id,
      },
      to: {
        id: tabId,
      },
    });
  }

  pingExternalTabs(tabsIds: string[]): void {
    tabsIds.forEach((tabId) => {
      this.pingExternalTab(tabId);
    });
  }

  dispose = (): void => {
    this.pingTunnel.disconnect();
    this.masterPongTunnel.disconnect();

    if (hasValue(this.windowOpeningTimeout)) {
      clearTimeout(this.windowOpeningTimeout);
    }
  };
}
