import type { Template, TemplatesManager, UserSettingsManager, WellIndexType } from '@go-widgets/well-logs-widget';
import type { Stack } from 'src/packages/data/structures/Stack';

import { BaseSettingsScreen } from '../screen/BaseSettingsScreen';

export class WidgetTemplateDetailsEntity extends BaseSettingsScreen {
  readonly template: Template;
  readonly userSettingsManager: UserSettingsManager;
  readonly templatesManager: TemplatesManager;
  readonly type = 'widget-template-details';
  readonly indexType: WellIndexType;

  constructor(
    stack: Stack<BaseSettingsScreen>,
    template: Template,
    userSettingsManager: UserSettingsManager,
    templatesManager: TemplatesManager,
    indexType: WellIndexType
  ) {
    super(stack);

    this.template = template;
    this.userSettingsManager = userSettingsManager;
    this.indexType = indexType;
    this.templatesManager = templatesManager;
  }
}
