import React from 'react';
import { createRoot } from 'react-dom/client';

import '@profgeosoft/go-react-grid-layout/styles';
import '@profgeosoft-ui/react/dist/style.css';
import 'react-resizable/css/styles.css';
import 'src/packages/assets/styles/index.scss';
import 'src/declarations';
import 'src/configure';
import 'src/app/i18n';

import { App } from 'src/app/App';
import reportWebVitals from 'src/reportWebVitals';

import 'src/app/polyfills';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
