import { Loader } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';

import { MainLayout } from 'src/components/layouts/main-layout/MainLayout';
import { Workspace } from 'src/pages/dashboard/features/workspace/Workspace';

import type { FC } from 'react';

export const DashboardPage: FC = observer(function DashboardPage() {
  const isLoading = false;

  const renderContent = () => {
    if (isLoading) {
      return <Loader size="l" />;
    }

    return <Workspace />;
  };

  return <MainLayout title="Dashboard">{renderContent()}</MainLayout>;
});
