import { action, makeObservable, observable } from 'mobx';

export type SizeBoundaries = {
  minInPixels: Partial<WidgetSize>;
  maxInPixels: Partial<WidgetSize> | null;
  min: WidgetSize;
  max: WidgetSize;
  useColsCoeffsFor?: {
    minW: boolean;
    maxW: boolean;
    w: boolean;
  };
};

export type WidgetSize = {
  w: number;
  h: number;
};

export type WidgetPosition = {
  x: number;
  y: number;
};

export interface TWidgetOptions {
  isFullscreen: boolean;
  isPinned: boolean;
  zIndex: number;
  uuid: string;
  position: WidgetPosition;
  size: WidgetSize;
  groupId: string | null;
  isGroupsDefaultOpened: boolean;
}

export abstract class WidgetEntity {
  readonly isGroupsDefaultOpened: boolean;

  @observable uuid: string;
  @observable size: WidgetSize;
  @observable position: WidgetPosition;
  @observable isPinned: boolean;
  @observable zIndex: number;
  @observable groupId: string | null;
  @observable isFullscreen: boolean;

  abstract readonly sizeBoundaries: SizeBoundaries;

  abstract get isControlWidget(): boolean;
  abstract get type(): string;
  abstract get title(): string;
  abstract setFullscreen(value: boolean): void;
  abstract getNameT(): string;
  destroy?(): void;

  constructor({
    isPinned,
    zIndex,
    uuid,
    size,
    position,
    groupId,
    isGroupsDefaultOpened,
    isFullscreen,
  }: TWidgetOptions) {
    this.isPinned = isPinned;
    this.zIndex = zIndex;
    this.uuid = uuid;
    this.size = size;
    this.position = position;
    this.groupId = groupId;
    this.isFullscreen = isFullscreen;
    this.isGroupsDefaultOpened = isGroupsDefaultOpened;

    makeObservable(this);
  }

  @action.bound
  setGroup(group: string | null): void {
    this.groupId = group;
  }

  @action.bound
  setPosition(x: number, y: number): void {
    this.position.x = x;
    this.position.y = y;
  }

  @action.bound
  setSize(w: number, h: number): void {
    this.size.w = w;
    this.size.h = h;
  }

  @action.bound
  setIsPinned(is: boolean): void {
    this.isPinned = is;
  }

  @action.bound
  setZIndex(zIndex: number): void {
    this.zIndex = zIndex;
  }
}
