import { createAtom, makeAutoObservable, runInAction } from 'mobx';

import type { IAtom } from 'mobx';

export class Sound {
  protected audio: HTMLAudioElement;

  playingAtom: IAtom;

  constructor(private url: string) {
    this.audio = new Audio();
    this.audio.preload = 'auto';
    this.audio.src = url;

    this.playingAtom = createAtom('SoundStore.playing');

    this.audio.addEventListener('play', () => this.playingAtom.reportChanged());
    this.audio.addEventListener('pause', () => this.playingAtom.reportChanged());
  }

  stop(): void {
    this.audio.pause();
    this.audio.currentTime = 0;
  }

  play(): Promise<void> {
    return this.audio.play();
  }

  pause(): void {
    this.audio.pause();
  }

  get playing() {
    this.playingAtom.reportObserved();
    return !this.audio.paused;
  }
}

export class SoundAlarmService {
  sound: Sound;

  subscribersCounter: number = 0;

  isNotified: boolean = false;

  soundTimer: ReturnType<typeof setInterval> | null = null;

  constructor(private onNoActionsOnPage: () => void, url: string) {
    makeAutoObservable(this);

    this.sound = new Sound(url);
  }

  private clearSoundInterval() {
    if (this.soundTimer) {
      clearInterval(this.soundTimer);
    }
  }

  private setSoundTimer(timer: ReturnType<typeof setInterval> | null) {
    runInAction(() => {
      this.soundTimer = timer;
    });
  }

  addNewAlert() {
    if (this.subscribersCounter === 0) {
      const timer = setInterval(() => {
        this.sound.play().catch(() => {
          if (!this.isNotified) {
            this.onNoActionsOnPage();

            this.isNotified = true;
          }
        });
      }, 1000);

      this.setSoundTimer(timer);
    }
    runInAction(() => {
      this.subscribersCounter += 1;
    });
  }

  removeAlert() {
    runInAction(() => {
      this.subscribersCounter -= 1;
    });

    if (this.subscribersCounter <= 0) {
      runInAction(() => {
        this.subscribersCounter = 0;
      });
      this.sound.stop();
      this.clearSoundInterval();
      this.setSoundTimer(null);
    }
  }
}
