import type { TWellLogsSerializedInternalState } from './WellLogsWidget.entity';
import type { WellIndexType } from '@go-widgets/well-logs-widget';

export function parseWellLogsWidgetInternalState(state: object): TWellLogsSerializedInternalState {
  let wellIndexType: WellIndexType = 'LOG_DEPTH';
  let isEditMode: boolean = false;
  let wellId: number | null = null;
  let stateParams: object = {};

  if ('wellIndexType' in state && (state.wellIndexType === 'LOG_DEPTH' || state.wellIndexType === 'LOG_TIME')) {
    wellIndexType = state.wellIndexType;
  }

  if ('isEditMode' in state && typeof state.isEditMode === 'boolean') {
    isEditMode = state.isEditMode;
  }

  if ('wellId' in state && typeof state.wellId === 'number') {
    wellId = state.wellId;
  }

  if ('stateParams' in state && typeof state.stateParams === 'object' && !!state.stateParams) {
    stateParams = state.stateParams;
  }

  return {
    wellIndexType,
    isEditMode,
    wellId,
    stateParams,
  };
}
