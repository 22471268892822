import * as Sentry from '@sentry/react';
import axios from 'axios';

import { AUTH_API_URL } from 'src/api/consts';
import { getAccessToken } from 'src/app/auth/auth';
import { UserService } from 'src/app/auth/user-service';

const agent = axios.create({
  baseURL: AUTH_API_URL,
  headers: {
    'content-type': 'application/json',
  },
});

agent.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      await UserService.login();
    }

    Sentry.captureException(error);

    return Promise.reject(error);
  }
);

agent.interceptors.request.use((config) => {
  const accessToken = getAccessToken();

  if (accessToken) {
    config.headers.set('Authorization', `Bearer ${accessToken}`);
  }

  return config;
});

export { agent };
