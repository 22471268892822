import type { ILocalStorageService } from './LocalStorageService';
import type { IServicesCollection } from 'src/packages/di';

import { LocalStorageService } from './LocalStorageService';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    localStorage: ILocalStorageService;
  }
}

export const addLocalStorageService = (di: IServicesCollection) => {
  di.addSingleton('localStorage', new LocalStorageService());
};
