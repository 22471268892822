import { action, makeObservable } from 'mobx';

import { BroadcastTunnel } from 'src/packages/broadcast-tunnel/BroadcastTunnel';
import { requireService } from 'src/packages/di';
import { isNumberArray } from 'src/packages/shared/utils/is-number-array';

import type { TFavoriteWellsChanged } from '../types';
import type { WorkspaceStore } from '../Workspace.store';

export class FavoriteWellsSyncService {
  private readonly store: WorkspaceStore;
  private readonly favoriteWellsTunnel: BroadcastTunnel<TFavoriteWellsChanged, TFavoriteWellsChanged>;

  constructor(
    store: WorkspaceStore,
    private readonly favoriteWellsService = requireService('favoritesWells'),
    private readonly sessionService = requireService('sessionService')
  ) {
    this.store = store;

    this.favoriteWellsTunnel = new BroadcastTunnel((message): message is TFavoriteWellsChanged => {
      if (message?.type !== 'favorite-wells-changed' || !isNumberArray(message?.data)) return false;

      return true;
    }, this.onFavoriteWellsChanged);

    makeObservable(this);
  }

  @action.bound
  private onFavoriteWellsChanged = (message: TFavoriteWellsChanged): void => {
    this.store.setFavoritesUpdating(true);
    this.favoriteWellsService.setList(message.data);
    this.sessionService.sendMessage('FAVORITES-CHANGED');
    this.store.setFavoritesUpdating(false);
  };

  sendFavoriteWells(list: number[]): void {
    this.favoriteWellsTunnel.postMessage({
      type: 'favorite-wells-changed',
      from: {
        type: 'master',
        id: this.store.id,
      },
      data: list,
    });
  }

  dispose = (): void => {
    this.favoriteWellsTunnel.disconnect();
  };
}
