import { ImageBroadcastWidgetEntity } from 'src/pages/dashboard/features/image-broadcast-widget/ImageBroadcastWidget.entity';
import { parseWellImageBroadcastWidgetInternalState } from 'src/pages/dashboard/features/image-broadcast-widget/parseImageBroadcastWidget';

import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

export function mapImageBroadcastTemplate(
  template: TSerializedWidgetTemplate,
  index: number
): ImageBroadcastWidgetEntity {
  const { size, position } = template;

  const internalState = parseWellImageBroadcastWidgetInternalState(template.internalState ?? {});

  const wellAnalyseWidget = new ImageBroadcastWidgetEntity(
    {
      wellId: internalState.wellId,
      stateParams: internalState.stateParams ?? {},
      type: 'image-broadcast-widget',
    },
    {
      groupId: template.groupId || null,
      isGroupsDefaultOpened: false,
      uuid: template.uuid,
      isPinned: false,
      isFullscreen: false,
      zIndex: index + 1,
      size: {
        w: size.w,
        h: size.h,
      },
      position: {
        x: position.x,
        y: position.y,
      },
    }
  );

  return wellAnalyseWidget;
}
