import { DoneRoundLight as CheckIcon, CloseRoundLight as CloseIcon } from '@profgeosoft-ui/icons';
import { Button, Illustration, Modal } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './SessionsConflictModal.module.scss';

type Props = {
  isOpened: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const SessionsConflictModal = observer(function SessionsConflictModal({ onCancel, onConfirm, isOpened }: Props) {
  const { t } = useTranslation();

  return (
    <Modal open={isOpened} className={styles.modal} onClickOutside={null} closeIcon={false}>
      <div className={styles.header}>
        <div className={styles.img}>
          <Illustration.Tabs />
        </div>
        <div>
          <p className={styles.title}>{t('modals:SessionRecovering.title')}</p>
          <p className={styles.desc}>{t('modals:SessionRecovering.desc')}</p>
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button variant="danger" onClick={onCancel} className={styles.button} icon={<CloseIcon />}>
          {t('common:Buttons.cancel')}
        </Button>
        <Button variant="primary" onClick={onConfirm} className={styles.button} icon={<CheckIcon />}>
          {t('common:Buttons.restore')}
        </Button>
      </div>
    </Modal>
  );
});
