import type { TOperationalParametersSerializedInternalState } from './OperationalParametersWidget.entity';
import type { WellIndexType } from '@go-widgets/well-logs-widget';

export function parseOperationalParametersInternalState(state: object): TOperationalParametersSerializedInternalState {
  let wellId: number | null = null;
  let stateParams: object = {};
  let wellIndexType: WellIndexType = 'LOG_DEPTH';
  let enableSound: boolean = true;

  if ('wellIndexType' in state && (state.wellIndexType === 'LOG_DEPTH' || state.wellIndexType === 'LOG_TIME')) {
    wellIndexType = state.wellIndexType;
  }

  if ('wellId' in state && typeof state.wellId === 'number') {
    wellId = state.wellId;
  }

  if ('stateParams' in state && typeof state.stateParams === 'object' && !!state.stateParams) {
    stateParams = state.stateParams;
  }

  if ('enableSound' in state && typeof state.enableSound === 'boolean') {
    enableSound = state.enableSound;
  }

  return {
    wellIndexType,
    wellId,
    stateParams,
    enableSound,
  };
}
