export const calculateContainerHeight = (rows: HTMLCollection, paddingTop: number = 0, rowGap: number = 0) => {
  const MAX_VISIBLE_TAB_TEMPLATE_ROWS = 3;

  let heightSumm = 0;
  let viewRowGapSumm = 0;
  let visibleRowsCounter = 0;

  for (const row of rows) {
    if (visibleRowsCounter >= MAX_VISIBLE_TAB_TEMPLATE_ROWS) break;

    const { height } = row.getBoundingClientRect();

    heightSumm += height;
    visibleRowsCounter += 1;
    viewRowGapSumm += rowGap;
  }

  return heightSumm + paddingTop + viewRowGapSumm;
};
