import { v4 as uuidv4 } from 'uuid';

import type { Stack } from 'src/packages/data/structures/Stack';

export abstract class BaseSettingsScreen {
  readonly stack: Stack<BaseSettingsScreen>;
  readonly $$instanceId = uuidv4();

  constructor(stack: Stack<BaseSettingsScreen>) {
    this.stack = stack;
  }

  abstract get type(): string;
}
