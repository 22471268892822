import { InfoLight } from '@profgeosoft-ui/icons';
import { ActionsBar, LoaderIcon, Tooltip } from '@profgeosoft-ui/react';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import enLocale from 'date-fns/locale/en-US';
import esLocale from 'date-fns/locale/es';
import ruLocale from 'date-fns/locale/ru';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useService } from 'src/packages/di';

import type { ImageBroadcastingWidgetState } from '../ImageBroadcastingWidgetState';
import type { Locale as DateFnsLocale } from 'date-fns';

import styles from './Broadcast.module.scss';

type Props = {
  widgetState: ImageBroadcastingWidgetState;
};

export const BroadcastsPanel = observer(function BroadcastsPanel({ widgetState }: Props) {
  const { t } = useTranslation('imageBroadcast');

  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const language = useService('language');

  useEffect(() => {
    if (open) {
      setTooltipOpen(false);
    }
  }, [open]);

  return (
    <Tooltip
      open={tooltipOpen && !open}
      onOpenChange={(e) => setTooltipOpen(e)}
      placement="left"
      title={t('imageInfo')}
    >
      <ActionsBar.Item active={open} onClick={() => setOpen(true)}>
        <Tooltip
          open={open}
          title={
            widgetState.selectedBroadcast && widgetState.selectedBroadcast.info ? (
              <div className={styles.TranslationPanelText}>
                {widgetState.selectedBroadcast.inProgress ? (
                  <LoaderIcon />
                ) : (
                  <>
                    <div style={{ opacity: 0.6 }}>{t('updatedTime')}:</div>
                    <div>{format(parseISO(widgetState.selectedBroadcast.info.updateTime), 'dd.MM.yyyy HH:mm')}</div>
                    <div>
                      (
                      {formatDistanceToNow(parseISO(widgetState.selectedBroadcast.info.updateTime), {
                        locale: getDateFnsLocale(language.language),
                      })}
                      )
                    </div>
                  </>
                )}
              </div>
            ) : (
              <span>{t('broadcastsEmpty')}</span>
            )
          }
          trigger={['click']}
          onOpenChange={(e) => setOpen(e)}
          placement="left"
        >
          <InfoLight />
        </Tooltip>
      </ActionsBar.Item>
    </Tooltip>
  );
});

function getDateFnsLocale(lang: string): DateFnsLocale {
  switch (lang) {
    case 'ru':
      return ruLocale;
    case 'es':
      return esLocale;
    case 'en':
    default:
      return enLocale;
  }
}
