import type { TBreakpointsKeys } from '../types';
import type { Layouts } from '@profgeosoft/go-react-grid-layout';

import { ROWS_COUNT, breakpoints, cols } from '../consts';

export function getPositionX(widgetWidth: number, breakpoint: TBreakpointsKeys | undefined): number {
  if (!breakpoint) return 0;

  const width = cols[breakpoint];

  return Math.floor(width / 2) - Math.floor(widgetWidth / 2);
}

export function getPositionY(widgetHeight: number, offsetTop: number | undefined = 0, height?: number): number {
  if (!height) return offsetTop;

  const pixelsInRow = height / ROWS_COUNT;
  const offsetTopInRows = offsetTop / pixelsInRow;
  const workbenchOffset = Math.floor(ROWS_COUNT / 2);
  const widgetOffset = widgetHeight / 2;

  if (widgetOffset >= workbenchOffset) return offsetTopInRows;

  return workbenchOffset - widgetOffset + offsetTopInRows;
}

export function getDefaultLayouts(): Layouts {
  const layouts: Layouts = {};

  for (const breakpointName in breakpoints) {
    layouts[breakpointName] = [];
  }

  return layouts;
}
