import { Field } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';

import type { FilterMultiCombobox } from '../../entities/FilterMultiCombobox.entity';

import styles from './MulticomboboxViewLabel.module.scss';

type Props = {
  item: FilterMultiCombobox;
  label: string;
};

export const MulticomboboxViewLabel = observer(function MulticomboboxViewLabel({ item, label }: Props) {
  const labels = item.value.map((value) => item.options.find((option) => option.value === value));

  if (labels.length === 0) return null;

  return (
    <Field label={label}>
      <div className={styles.labels}>
        {labels.map((label) =>
          label ? (
            <p key={label?.value} className={styles.label}>
              {label.label}
            </p>
          ) : null
        )}
      </div>
    </Field>
  );
});
