import { Select, Field } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useService } from 'src/packages/di';
import { hasValue } from 'src/packages/shared/utils/common';

import type { DefaultOptionType } from '@profgeosoft-ui/react';
import type { ReactElement, CSSProperties } from 'react';

import { DropdownPreloader } from '../dropdown-preloader/DropdownPreloader';

import styles from './FormMultiselect.module.scss';

export type ValidationOptions = Partial<{
  maxCount: number;
}>;

type Props = {
  open?: boolean;
  allowClear?: boolean;
  className?: string;
  placeholder?: string;
  searchValue?: string;
  value?: (string | number)[] | null;
  options?: DefaultOptionType[];
  label?: string;
  loading?: boolean;
  showSearch?: boolean;
  filterOption?: boolean;
  errorText?: string | [string, { [locKey: string]: string | number }];
  isDisabled?: boolean;
  dropdownLoading?: boolean;
  customDropdown?: ReactElement;
  optionFilterProp?: string;
  dropdownStyle?: CSSProperties;
  onBlur?: () => void;
  onClear?: () => void;
  onFocus?: () => void;
  onSearch?: (value: string) => void;
  onChange?: (value: (string | number)[]) => void;
  onClick?: () => void;
  onDropdownVisibleChange?: (open: boolean) => void;
  dropdownRender?: (origin: ReactElement) => ReactElement;
  validationOptions?: ValidationOptions;
};

export const FormMultiSelect = observer(function FormMultiselect({
  open,
  allowClear = true,
  className,
  placeholder,
  searchValue,
  value,
  label,
  options,
  errorText: errorTextRaw,
  loading,
  showSearch,
  isDisabled,
  filterOption,
  dropdownLoading,
  customDropdown,
  dropdownStyle,
  optionFilterProp,
  dropdownRender: dropdownRenderProp,
  onBlur,
  onSearch,
  onFocus,
  onClear,
  onChange,
  onClick,
  onDropdownVisibleChange,
  validationOptions,
}: Props) {
  const [searchTerm, setSearchTerm] = useState(searchValue ?? '');
  const { t } = useTranslation();

  const notifications = useService('notifications');

  function handleSearch(searchTerm: string): void {
    setSearchTerm(searchTerm);
    onSearch?.(searchTerm);
  }

  function localFilterOption(searchValue: string, option?: DefaultOptionType): boolean {
    const optionInLowerCase = option?.label?.toString().toLowerCase();
    const searchValueInLowerCase = searchValue.toLowerCase();

    return !!optionInLowerCase?.includes(searchValueInLowerCase);
  }

  function dropdownRender(origin: ReactElement): ReactElement {
    if (dropdownLoading) return <DropdownPreloader />;
    if (!!dropdownRenderProp) return dropdownRenderProp(origin);
    if (customDropdown) return customDropdown;
    return origin;
  }

  function handleChange(changedValue: (string | number)[]): void {
    if (!validationOptions) {
      onChange?.(changedValue);
      return;
    }

    if (hasValue(validationOptions.maxCount) && changedValue.length > validationOptions.maxCount) {
      const message = label ? t('errors:limitExceeded') : t('errors:limitExceededWithLabel', { label });

      notifications.showWarningMessage(message);
    } else {
      onChange?.(changedValue);
    }
  }

  const handleBlur = () => {
    onBlur?.();
    setSearchTerm('');
  };

  const errorText =
    errorTextRaw && (typeof errorTextRaw === 'string' ? t(errorTextRaw) : t(errorTextRaw[0], errorTextRaw[1]));

  return (
    <Field label={label} className={className} hint={errorText} status={errorText ? 'error' : undefined}>
      <Select
        open={open}
        mode="multiple"
        value={!!value ? value : []}
        allowClear={allowClear}
        searchValue={searchValue || searchTerm}
        placeholder={loading ? t('common:pleaseWait') : placeholder || t('common:chooseValue')}
        className={styles.select}
        loading={loading}
        status={!!errorText ? 'error' : undefined}
        showSearch={showSearch}
        disabled={isDisabled || loading}
        filterOption={filterOption || localFilterOption}
        dropdownStyle={dropdownStyle}
        optionFilterProp={optionFilterProp}
        dropdownRender={dropdownRender}
        options={options}
        onClear={onClear}
        onFocus={onFocus}
        popupClassName={styles.dropdownWrapper}
        onBlur={handleBlur}
        onSearch={handleSearch}
        onChange={handleChange}
        onClick={onClick}
        onDropdownVisibleChange={onDropdownVisibleChange}
      />
    </Field>
  );
});
