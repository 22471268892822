import { downloadTemplate } from '@go-widgets/well-logs-widget/dist/stores/TemplatesManager';
import { action, flow, makeObservable, observable } from 'mobx';

import { debounce } from 'src/packages/shared/utils/debounce';
import { createPromiseController, type TPromiseController } from 'src/packages/shared/utils/promise-controller';

import type { WidgetTemplateDetailsEntity } from './WidgetTemplateDetails.entity';
import type { Template, TemplatesManager } from '@go-widgets/well-logs-widget';

export class WidgetTemplateDetailsStore {
  readonly screenEntity: WidgetTemplateDetailsEntity;

  @observable deleteTemplateController: TPromiseController<boolean | null> | null = null;

  constructor(screen: WidgetTemplateDetailsEntity) {
    this.screenEntity = screen;

    makeObservable(this);
  }

  get templates(): TemplatesManager {
    return this.screenEntity.templatesManager;
  }

  get id(): number | null {
    return this.screenEntity.template.id;
  }

  get template(): Template {
    return this.screenEntity.template;
  }

  get logs(): string[] {
    return Array.from(new Set(this.template.parameters));
  }

  @action.bound
  onDeleteTemplateAccept(): void {
    this.deleteTemplateController?.resolve(true);
  }

  @action.bound
  onDeleteTemplateCancel(): void {
    this.deleteTemplateController?.resolve(null);
  }

  onTemplateNameChange = debounce((name: string) => {
    if (this.template.name === name || name.length === 0 || this.id === null) {
      return;
    }

    this.templates.updateTemplateName(this.id, name);
  }, 500);

  onTemplateDescriptionChange = debounce((desc: string) => {
    if (this.template.description === desc || this.id === null) {
      return;
    }

    this.templates.updateTemplateDescription(this.id, desc.trim());
  }, 500);

  @action.bound
  onOpenByDefaultChange(checked: boolean): void {
    if (checked) {
      this.templates.setAsDefault(this.id);
    }
  }

  @action.bound
  async onDuplicateToPersonal(): Promise<void> {
    if (this.id === null) {
      return;
    }

    await this.templates.duplicateTemplateToPersonal(this.id);
  }

  @action.bound
  async onDuplicateToPublic(): Promise<void> {
    if (this.id === null) {
      return;
    }

    await this.templates.duplicateTemplateToPublic(this.id);
  }

  @flow.bound
  async *onTemplateDelete(): Promise<void> {
    if (this.id === null) {
      return;
    }

    try {
      this.deleteTemplateController = createPromiseController<boolean | null>();

      const shouldDeleteTemplate = await this.deleteTemplateController;

      yield;

      if (!shouldDeleteTemplate) return;

      await this.templates.deleteTemplate(this.id);
    } finally {
      this.deleteTemplateController = null;
    }
  }

  @action.bound
  onTemplateExport(): void {
    downloadTemplate(this.template);
  }
}
