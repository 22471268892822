import { action, makeObservable } from 'mobx';

import { BroadcastTunnel } from 'src/packages/broadcast-tunnel/BroadcastTunnel';
import { requireService } from 'src/packages/di';
import { isNumberArray } from 'src/packages/shared/utils/is-number-array';

import type { SingleTabStore } from '../SingleTab.store';
import type { TFavoriteWellsChanged } from 'src/pages/dashboard/features/workspace/types';

export class FavoriteWellsSyncService {
  private readonly store: SingleTabStore;
  private readonly favoriteWellsTunnel: BroadcastTunnel<TFavoriteWellsChanged, TFavoriteWellsChanged>;

  constructor(store: SingleTabStore, private readonly favoriteWellsService = requireService('favoritesWells')) {
    this.store = store;

    this.favoriteWellsTunnel = new BroadcastTunnel((message): message is TFavoriteWellsChanged => {
      if (message?.type !== 'favorite-wells-changed' || !isNumberArray(message?.data)) return false;

      return true;
    }, this.onFavoriteWellsChanged);

    makeObservable(this);
  }

  @action.bound
  private onFavoriteWellsChanged = (message: TFavoriteWellsChanged): void => {
    this.store.setFavoritesUpdating(true);
    this.favoriteWellsService.setList(message.data);
    this.store.setFavoritesUpdating(false);
  };

  sendFavoriteWells(list: number[]): void {
    this.favoriteWellsTunnel.postMessage({
      type: 'favorite-wells-changed',
      from: {
        type: 'slave',
        id: this.store.tabId,
      },
      data: list,
    });
  }

  dispose = (): void => {
    this.favoriteWellsTunnel.disconnect();
  };
}
