import { BackLight as ArrowLeftRound } from '@profgeosoft-ui/icons';
import { Button, Modal } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { TWidgetTypeGroup } from './NewContentWidgetModal.store';
import type { WellIndexType } from '@go-widgets/well-logs-widget';
import type { TabEntity } from 'src/entities/tab/TabEntity';

import { NewContentWidgetModalStore } from './NewContentWidgetModal.store';
import { SingleWidgetPanel } from './widget-and-group-panel/SingleWidgetPanel';
import { WidgetAndGroupPanel } from './widget-and-group-panel/WidgetAndGroupPanel';

import styles from './NewContentWidgetModal.module.scss';

type Props = {
  isOpened: boolean;
  actualTab: TabEntity;
  onCreateWidget: ((type: string, wellIndexType?: WellIndexType) => void) | null;
  onCloseModal: VoidFunction;
};

export const NewContentWidgetModal = observer(function NewContentWidgetModal({
  isOpened,
  actualTab,
  onCreateWidget,
  onCloseModal,
}: Props) {
  const { t } = useTranslation();
  const [store] = useState(() => new NewContentWidgetModalStore(actualTab));

  const handleBack = () => {
    store.setChosenGroupType(null);
  };

  const handleCloseModal = () => {
    onCloseModal();
    store.setChosenGroupType(null);
  };

  return (
    <Modal
      open={isOpened}
      className={styles.modal}
      onClose={handleCloseModal}
      headerClassName={styles.titleWrapper}
      title={
        <>
          {!!store.chosenGroupType && (
            <Button icon={<ArrowLeftRound />} variant="flat" onClick={handleBack} className={styles.backButton}>
              {t('common:Buttons.back')}
            </Button>
          )}
          <h4 className={styles.titleText}>{t('modals:CreateContentWidget.title')}</h4>
        </>
      }
    >
      <>
        <div className={styles.subheader}>
          {!store.chosenGroupType && (
            <p className={styles.subheaderText}>{t('modals:CreateContentWidget.groupSubtitle')}</p>
          )}
          {!!store.chosenGroupType && (
            <p className={styles.subheaderText}>{t('modals:CreateContentWidget.widgetsSubtitle')}</p>
          )}
        </div>
        <div className={styles.contentWrapper}>
          {store.chosenGroupType?.widgets?.map((widget) => {
            const handleWidgetClick = () => {
              onCreateWidget?.(widget.type, widget.wellIndexType);
              store.setChosenGroupType(null);
            };

            return (
              <WidgetAndGroupPanel
                key={`${widget.type}-${widget.wellIndexType}`}
                name={widget.name}
                description={widget.description}
                iconsMap={widget.iconsMap}
                onClick={handleWidgetClick}
              />
            );
          })}
          {!store.chosenGroupType &&
            store.widgetsGroups.map((group) => {
              if (isGroup(group)) {
                const handleGroupClick = () => store.setChosenGroupType(group);
                const handleWidgetClick = () => {
                  onCreateWidget?.(group.type);
                  store.setChosenGroupType(null);
                };
                return (
                  <WidgetAndGroupPanel
                    key={group.type}
                    name={group.name}
                    description={group.description}
                    iconsMap={group.iconsMap}
                    onClick={group.widgets.length > 1 ? handleGroupClick : handleWidgetClick}
                  />
                );
              }

              const handleWidgetClick = () => {
                onCreateWidget?.(group.type);
                store.setChosenGroupType(null);
              };

              if (group.iconsMap && group.iconsMap[0][0]) {
                return (
                  <SingleWidgetPanel
                    key={group.type}
                    icon={group.iconsMap[0][0]}
                    onClick={handleWidgetClick}
                    name={group.name}
                    description={group.description}
                  />
                );
              }

              return (
                <WidgetAndGroupPanel
                  key={group.type}
                  name={group.name}
                  description={group.description}
                  onClick={handleWidgetClick}
                />
              );
            })}
        </div>
      </>
    </Modal>
  );
});

function isGroup(group: object): group is TWidgetTypeGroup {
  return group.hasOwnProperty('widgets');
}
