import { action, makeObservable, observable } from 'mobx';

import { BaseWidgetEntityWithWellId } from 'src/entities/widget/BaseWidgetEntityWithWellId';
import { requireService } from 'src/packages/di';

import type { WellIndexType, WellLogsWidgetState, WidgetStateParams } from '@go-widgets/well-logs-widget';
import type { SizeBoundaries, TWidgetOptions } from 'src/entities/widget/WidgetEntity';

interface WellLogsWidgetOptions {
  type: string;
  stateParams: Partial<WidgetStateParams>;
  wellIndexType: WellIndexType;
  isEditMode: boolean;
  wellId: number | null;
}

export type TWellLogsSerializedInternalState = {
  stateParams: Partial<WidgetStateParams>;
  wellIndexType: WellIndexType;
  isEditMode: boolean;
  wellId: number | null;
};

export class WellLogsWidgetEntity extends BaseWidgetEntityWithWellId {
  readonly sizeBoundaries: SizeBoundaries = {
    minInPixels: {
      w: 350,
      h: 612,
    },
    maxInPixels: null,
    min: {
      w: 47,
      h: 66,
    },
    max: {
      w: 256,
      h: 100,
    },
    useColsCoeffsFor: { minW: false, maxW: true, w: false },
  };

  @observable state: WellLogsWidgetState | null = null;
  @observable type: string;
  @observable wellIndexType: WellIndexType;
  @observable isEditMode: boolean;
  @observable wellId: number | null;
  @observable stateParams: Partial<WidgetStateParams>;
  @observable inActiveTab: boolean = false;

  constructor(
    { type, wellIndexType, stateParams, isEditMode, wellId }: WellLogsWidgetOptions,
    widgetOptions: TWidgetOptions,
    private readonly i18 = requireService('i18')
  ) {
    super(widgetOptions);

    this.type = type;
    this.wellIndexType = wellIndexType;
    this.isEditMode = isEditMode;
    this.wellId = wellId;
    this.stateParams = stateParams;

    makeObservable(this);
  }

  getNameT(): string {
    if (this.wellIndexType === 'LOG_DEPTH') {
      return 'settings:Widgets.byDepth';
    }

    return 'settings:Widgets.byTime';
  }

  @action.bound
  setWellId(wellId: number | null): void {
    this.wellId = wellId;
  }

  @action.bound
  setStateParams(stateParams: Partial<WidgetStateParams>): void {
    this.stateParams = stateParams;
  }

  get isControlWidget(): boolean {
    return false;
  }

  get title(): string {
    return this.i18.t('wellLogs:title');
  }

  @action.bound
  setState(state: WellLogsWidgetState | null): void {
    this.state = state;
  }

  @action.bound
  setEditMode(value: boolean): void {
    this.isEditMode = value;

    if (value && !this.isFullscreen) {
      this.setFullscreen(true);
    }
  }

  @action.bound
  setFullscreen(value: boolean): void {
    this.isFullscreen = value;
    this.state?.setAppParams({
      fullScreen: value,
    });
  }

  @action.bound
  setInActiveTab(value: boolean): void {
    this.inActiveTab = value;
    this.state?.setAppParams({
      active: this.inActiveTab,
    });
  }

  override destroy() {
    this.state?.destroy();
  }
}
