export const MASTER_ID_KEY = 'master-id';

export function getWebsocketAddress(uri?: string): string {
  const loc = window.location;

  let newUri = '';

  if (loc.protocol === 'https:') {
    newUri = 'wss:';
  } else {
    newUri = 'ws:';
  }

  newUri += `//${loc.host}`;

  if (uri) {
    newUri += uri;
  }

  return newUri;
}
