import type { ITabTemplatesService } from './ITabTemplatesService';
import type { IServicesCollection } from 'src/packages/di';

import { TabTemplatesService } from './TabTemplates.service';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    tabTemplates: ITabTemplatesService;
  }
}

export async function addTabTemplatesService(di: IServicesCollection) {
  const tabTemplatesService = new TabTemplatesService();
  await tabTemplatesService.init();

  di.addSingleton('tabTemplates', tabTemplatesService);
}
