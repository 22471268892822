import { goStorageAgent } from 'src/api/goStorageAgent';
import { throwApiError } from 'src/api/utils';
import { requireService } from 'src/packages/di';

import type { TTabTemplateData, TTabTemplateWithGSProps } from 'src/pages/dashboard/features/workspace/types';

export class TabTemplatesApi {
  private readonly auth = requireService('authService');

  async loadPersonalTemplates(): Promise<TTabTemplateWithGSProps[]> {
    try {
      const { data } = await goStorageAgent.get<TTabTemplateWithGSProps[]>('object/list/GOdashboard_Tab_Template', {
        params: {
          ownerUserId: this.auth.userInfo.sub,
          type: 'personal',
        },
      });

      return data;
    } catch (error) {
      throwApiError(error);
    }
  }

  async updateTemplate(id: number, template: Partial<TTabTemplateData>): Promise<void> {
    try {
      await goStorageAgent.patch(`object/GOdashboard_Tab_Template/${id}`, template);
    } catch (error) {
      throwApiError(error);
    }
  }

  async loadPublicTemplates(): Promise<TTabTemplateWithGSProps[]> {
    try {
      const { data } = await goStorageAgent.get<TTabTemplateWithGSProps[]>('object/list/GOdashboard_Tab_Template', {
        params: { type: 'public' },
      });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }

  async loadSystemTemplates(): Promise<TTabTemplateWithGSProps[]> {
    try {
      const { data } = await goStorageAgent.get<TTabTemplateWithGSProps[]>('object/list/GOdashboard_Tab_Template', {
        params: { type: 'system' },
      });

      return data;
    } catch (e) {
      throwApiError(e);
    }
  }

  async createTemplate(template: TTabTemplateData): Promise<TTabTemplateWithGSProps> {
    try {
      const { data } = await goStorageAgent.post('object/new/GOdashboard_Tab_Template', template);

      return data;
    } catch (error) {
      throwApiError(error);
    }
  }

  async deleteTemplate(id: number): Promise<void> {
    try {
      await goStorageAgent.delete(`object/GOdashboard_Tab_Template/${id}`);
    } catch (error) {
      throwApiError(error);
    }
  }

  async loadTemplates(): Promise<{
    systemTemplates: TTabTemplateWithGSProps[];
    public: TTabTemplateWithGSProps[];
    personal: TTabTemplateWithGSProps[];
  }> {
    try {
      const [systemTemplates, publicTemplates, personalTemplates] = await Promise.all([
        this.loadSystemTemplates(),
        this.loadPublicTemplates(),
        this.loadPersonalTemplates(),
      ]);

      return {
        systemTemplates: systemTemplates,
        public: publicTemplates,
        personal: personalTemplates,
      };
    } catch (error) {
      throwApiError(error);
    }
  }
}
