import { FilterWidgetEntity } from 'src/pages/dashboard/features/filter-widget/FilterWidget.entity';
import { parseWellsFilterInternalState } from 'src/pages/dashboard/features/filter-widget/FilterWidget.utils';

import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

export function mapWellsFilterTemplate(template: TSerializedWidgetTemplate, index: number): FilterWidgetEntity {
  const internalState = parseWellsFilterInternalState(template?.internalState ?? {});
  const { size, position } = template;

  const newFilterWidget = new FilterWidgetEntity(
    {
      isEditMode: internalState.isEditMode,
      state: internalState.state,
      initialState: internalState.initialState,
      type: 'filter-control',
    },
    {
      uuid: template.uuid,
      isPinned: false,
      zIndex: index + 1,
      isFullscreen: false,
      position: {
        x: position.x,
        y: position.y,
      },
      size: {
        w: size.w,
        h: size.h,
      },
      groupId: template.groupId ?? null,
      isGroupsDefaultOpened: false,
    }
  );

  return newFilterWidget;
}
