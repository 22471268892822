export interface ILocalStorageService {
  get<TValue>(key: string): null | TValue;
  set<T>(key: string, value: T): void;
  delete(key: string): void;
}

export class LocalStorageService implements ILocalStorageService {
  get<TValue>(key: string): TValue | null {
    const value = localStorage.getItem(key);

    if (!value) return null;

    return JSON.parse(value);
  }

  set<T>(key: string, value: T): void {
    const jsonValue = JSON.stringify(value);
    localStorage.setItem(key, jsonValue);
  }

  delete(key: string): void {
    localStorage.removeItem(key);
  }
}
