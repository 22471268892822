import { Refresh2Light, SensorsLightCustom } from '@profgeosoft-ui/icons';
import { ActionsBar, Button, RadioGroup, Tooltip } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ImageBroadcastingWidgetState } from '../ImageBroadcastingWidgetState';

type Props = {
  widgetState: ImageBroadcastingWidgetState;
};

const BroadcastsSelect = observer(function BroadcastsSelect({ widgetState }: Props) {
  const { t } = useTranslation('imageBroadcast');

  function onSelect(id: number) {
    const broadcast = widgetState.imageBroadcasts.list.find((obj) => obj.id === String(id));
    if (broadcast) {
      widgetState.setBroadcast(broadcast);
    }
  }

  const selected = widgetState.selectedBroadcast?.id ?? null;

  return (
    <>
      <RadioGroup
        onChange={(value) => onSelect(Number(value))}
        value={selected}
        items={widgetState.imageBroadcasts.list.map((bore) => ({
          label: bore.text,
          value: Number(bore.id),
        }))}
      />
      <Button
        variant="flat"
        icon={<Refresh2Light />}
        onClick={() => {
          widgetState.refetchBroadcasts();
        }}
        style={{ marginTop: 8, paddingLeft: 0 }}
      >
        {t('updateList')}
      </Button>
    </>
  );
});

export const BroadcastsSelectPanel = observer(function BroadcastsSelectPanel({ widgetState }: Props) {
  const { t } = useTranslation('imageBroadcast');
  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setTooltipOpen(false);
    }
  }, [open]);

  return (
    <Tooltip
      open={tooltipOpen && !open}
      onOpenChange={(e) => setTooltipOpen(e)}
      placement="left"
      title={t('broadcastsSelection')}
    >
      <ActionsBar.Item active={open} onClick={() => setOpen(true)}>
        <Tooltip
          open={open}
          title={
            widgetState.imageBroadcasts.list.length > 0 ? (
              <BroadcastsSelect widgetState={widgetState} />
            ) : (
              <span>{t('broadcastsEmpty')}</span>
            )
          }
          trigger={['click']}
          onOpenChange={(e) => setOpen(e)}
          placement="left"
        >
          <SensorsLightCustom />
        </Tooltip>
      </ActionsBar.Item>
    </Tooltip>
  );
});
