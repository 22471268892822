import type { ClassOfUnit, IClassOfUnitsService } from '@go-widgets/well-logs-widget';

import { wellDirectoryServiceAgent } from '../../api/well-directory-service-agent';
import { requireService } from '../di';

export class ClassOfUnitsService implements IClassOfUnitsService {
  readonly api = wellDirectoryServiceAgent;
  units: ClassOfUnit[] = [];
  constructor(private readonly notifications = requireService('notifications')) {}

  async init() {
    try {
      const response = await this.api.get<ClassOfUnit[]>('/class-of-unit');
      this.units = response.data;
    } catch (error) {
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadUnits');
    }
  }

  findClassById = (id: number) => {
    const unitClass = this.units.find((unit) => unit.id === id);
    return unitClass ?? false;
  };
}
