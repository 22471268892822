import { BroadcastTunnel } from 'src/packages/broadcast-tunnel/BroadcastTunnel';
import { delay } from 'src/packages/shared/utils/delay';
import { createPromiseController } from 'src/packages/shared/utils/promise-controller';

import type { TExtraMasterDetectedMessage, TExtraMasterSearchMessage } from '../types';
import type { WorkspaceStore } from '../Workspace.store';
import type { TPromiseController } from 'src/packages/shared/utils/promise-controller';

export class ExtraMasterSearchService {
  private readonly store: WorkspaceStore;
  private readonly extraMasterSearchTunnel: BroadcastTunnel<TExtraMasterDetectedMessage, TExtraMasterSearchMessage>;
  private readonly extraMasterDetectingTunnel: BroadcastTunnel<TExtraMasterSearchMessage, TExtraMasterDetectedMessage>;

  private extraMasterSearchController: TPromiseController<boolean> | null = null;

  constructor(store: WorkspaceStore) {
    this.store = store;

    this.extraMasterSearchTunnel = new BroadcastTunnel((message): message is TExtraMasterSearchMessage => {
      if (message?.type !== 'find-extra-master' || message?.from?.type !== 'master') return false;

      return true;
    }, this.onSearchMaster);

    this.extraMasterDetectingTunnel = new BroadcastTunnel((message): message is TExtraMasterDetectedMessage => {
      if (message?.type !== 'extra-master-detected' || message?.from?.type !== 'master') return false;

      return true;
    }, this.onExtraMasterDetected);
  }

  private onSearchMaster = (): void => {
    this.extraMasterSearchTunnel.postMessage({
      type: 'extra-master-detected',
      from: {
        type: 'master',
        id: this.store.id,
      },
      to: {
        type: 'master',
      },
    });
  };

  private onExtraMasterDetected = (): void => {
    this.extraMasterSearchController?.resolve(true);
  };

  private abortExtraMasterSearch = (): void => {
    this.extraMasterSearchController?.resolve(false);
  };

  async tryToFindExtraMaster(): Promise<boolean> {
    this.extraMasterDetectingTunnel.postMessage({
      type: 'find-extra-master',
      from: {
        type: 'master',
        id: this.store.id,
      },
      to: {
        type: 'master',
      },
    });

    let isExtraMasterDetected = false;

    try {
      this.extraMasterSearchController = createPromiseController();

      const controller = new AbortController();

      controller.signal.addEventListener('abort', this.abortExtraMasterSearch);

      isExtraMasterDetected = await Promise.race([
        this.extraMasterSearchController,
        delay(300, false, controller.signal),
      ]);

      controller.abort();
      controller.signal.removeEventListener('abort', this.abortExtraMasterSearch);
      this.extraMasterSearchController = null;
    } catch (err) {
      console.error(err);
    } finally {
      return isExtraMasterDetected;
    }
  }

  dispose = (): void => {
    this.extraMasterSearchTunnel.disconnect();
    this.extraMasterDetectingTunnel.disconnect();
  };
}
