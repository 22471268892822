import { Loader, LoaderIcon } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import type { ImageBroadcastingWidgetState } from '../ImageBroadcastingWidgetState';
import type { ReactZoomPanPinchHandlers } from 'react-zoom-pan-pinch';

import { BroadcastImage } from './BroadcastImage';
import { BroadcastingActionsBar } from './BroadcastingActionsBar';

import styles from './Broadcast.module.scss';

type ImageBroadcastingWidgetProps = {
  widgetState: ImageBroadcastingWidgetState;
};

export const ImageBroadcastingWidget = observer(function ImageBroadcastingContent({
  widgetState,
}: ImageBroadcastingWidgetProps) {
  const { t } = useTranslation('imageBroadcast');
  const reactZoomPanPinchContent = useRef<ReactZoomPanPinchHandlers>(null);

  const loading =
    widgetState.status !== 'ready' ||
    widgetState.wellbores.inProgress ||
    widgetState.imageBroadcasts.inProgress ||
    (widgetState.selectedBroadcast && !widgetState.selectedBroadcast.image && widgetState.selectedBroadcast.inProgress);

  return (
    <div className={styles.WidgetWrapper}>
      {loading && (
        <div className={styles.LoaderWrapper}>
          <LoaderIcon />
        </div>
      )}
      {!loading &&
        (widgetState.imageBroadcasts.list.length === 0 || !widgetState.currentImageUrl ? (
          <div className={styles.NoBroadcast}>{t('negativeCase.broadcastsEmpty')}</div>
        ) : (
          <>
            <div
              style={{
                padding: '8px 16px',
                height: '100%',
              }}
            >
              <BroadcastImage
                src={widgetState.currentImageUrl.url}
                alt={widgetState.currentImageUrl.alt}
                ref={reactZoomPanPinchContent}
              />
            </div>
            {widgetState.selectedBroadcast && widgetState.selectedBroadcast.inProgress && (
              <Loader size="l" style={{ backgroundColor: 'transparent' }} />
            )}
          </>
        ))}
      <div className={styles.ActionsBarWrapper}>
        <BroadcastingActionsBar
          widgetState={widgetState}
          resetScale={() => {
            reactZoomPanPinchContent?.current?.resetTransform();
          }}
        />
      </div>
    </div>
  );
});
