import { isStringArray } from 'src/packages/shared/utils/is-string-array';

import type { TFilterDataSource } from '../filter-widget/WellsFilterDataSource';

export type TWellListInternalState = {
  filterState?: TFilterDataSource;
  filterBy: number;
  groupBy: number;
  searchValue: string;
  selectedWellID: number | null;
  collapsedKeys: string[];
};

export function parseWellListInternalState(internalState: object): Partial<TWellListInternalState> {
  const state = 'values' in internalState ? internalState.values : void 0;

  const transformedState: Partial<TWellListInternalState> = {};

  if (state && Array.isArray(state)) {
    for (const prop of state) {
      const value = prop.value;
      const name = prop.name;

      if (typeof name === 'string' && (typeof value === 'string' || typeof value === 'number')) {
        if (name === 'searchValue' && typeof value === 'string') {
          transformedState[name] = value;
        }

        if ((name === 'groupBy' || name === 'filterBy' || name === 'selectedWellID') && typeof value === 'number') {
          transformedState[name] = value;
        }
      } else {
        if (typeof name === 'string' && typeof value === 'object') {
          if (name === 'filterState') {
            transformedState[name] = value;
          }

          if (name === 'collapsedKeys' && isStringArray(value)) {
            transformedState[name] = value;
          }
        }
      }
    }
  }

  return transformedState;
}
