import { WellListWidgetEntity } from 'src/pages/dashboard/features/well-list-widget/WellListWidget.entity';
import { parseWellListInternalState } from 'src/pages/dashboard/features/well-list-widget/WellListWidget.utils';

import type { TSerializedWidget } from 'src/entities/workspace/types';

export function mapWellListWidget(
  widget: TSerializedWidget,
  index: number,
  isTemplate: boolean = false
): WellListWidgetEntity {
  const internalState = parseWellListInternalState(widget.internalState);

  const searchValue = internalState.searchValue ?? null;
  const collapsedKeys = internalState.collapsedKeys ?? null;

  const newWidget = new WellListWidgetEntity(
    {
      groupBy: internalState.groupBy ?? null,
      filterBy: internalState.filterBy ?? null,
      filterState: internalState.filterState ?? null,
      searchValue: isTemplate ? null : searchValue,
      selectedWellID: internalState.selectedWellID ?? null,
      collapsedKeys: isTemplate ? null : collapsedKeys,
      type: 'well-list-control',
    },
    {
      uuid: widget.uuid,
      isPinned: isTemplate ? false : !!widget.isPinned,
      zIndex: index + 1,
      isFullscreen: widget.isFullscreen ?? false,
      position: {
        x: widget?.position?.x ?? 0,
        y: widget?.position?.y ?? 0,
      },
      size: {
        w: widget.size?.w ?? 28,
        h: widget.size?.h ?? 240,
      },
      groupId: widget.groupId,
      isGroupsDefaultOpened: false,
    }
  );

  return newWidget;
}
