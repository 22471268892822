import { Field } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';

import type { DateRangeFieldEntity } from '../../entities/DateRangeField.entity';

import { DATE_FORMAT } from '../form-date-picker/consts';

import styles from './DateRangeViewLabel.module.scss';

type Props = {
  item: DateRangeFieldEntity;
  label: string;
  className?: string;
};

export const DateRangeViewLabel = observer(function DateRangeViewLabel({ item, label, className }: Props) {
  if (!item.startDate) return null;

  return (
    <div className={clsx(className, styles.wrapper)}>
      <Field label={label}>
        <div className={styles.dates}>
          <p className={styles.date}>{item.startDate ? format(item.startDate, DATE_FORMAT) : '-'}</p>
          <p className={styles.dash}>-</p>
          <p className={styles.date}>{item.endDate ? format(item.endDate, DATE_FORMAT) : '-'}</p>
        </div>
      </Field>
    </div>
  );
});
