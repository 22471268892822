import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';
import type { TSerializedWidget } from 'src/entities/workspace/types';

export function baseWidgetApplyChanges(widget: WidgetEntity, changes: TSerializedWidget, index: number): void {
  widget.setIsPinned(!!changes.isPinned);
  widget.setZIndex(index + 1);
  widget.setFullscreen(!!changes.isFullscreen);
  widget.setPosition(changes.position.x, changes.position.y);
  widget.setSize(changes.size.w, changes.size.h);
  widget.setGroup(changes.groupId);
}
