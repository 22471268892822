import { Button, Modal, RadioButtonGroup, Scrollbar } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TemplateImg } from 'src/packages/assets/img/template.svg';
import { requireService } from 'src/packages/di';

import type { TGoStorageTabTemplateType, TTabTemplateWithGSProps } from '../workspace/types';

import { calculateContainerHeight } from './CreateTabModal.utils';

import styles from './CreateTabModal.module.scss';

type Props = {
  isOpened: boolean;
  onCancel(): void;
  onConfirm(template: TTabTemplateWithGSProps): void;
  onCreateEmptyTab(): void;
};

export const CreateTabModal = observer(function CreateTabModal({
  onCancel,
  onConfirm,
  onCreateEmptyTab,
  isOpened,
}: Props) {
  const { t } = useTranslation();
  const tabTemplatesService = requireService('tabTemplates');
  const [selectedGroup, setSelectedGroup] = useState<TGoStorageTabTemplateType>('system');
  const [templatesContainerHeight, setTemplatesContainerHeight] = useState(0);
  const [transformedTabTemplates, setTransformedTabTemplates] = useState<TTabTemplateWithGSProps[][]>([]);

  const handleClose = () => {
    setSelectedGroup('system');
    onCancel();
  };

  const header = (
    <>
      <p className={styles.title}>{t('modals:CreateTabModal.title')}</p>
      <div className={styles.headerButtons}>
        <Button variant="flat" onClick={onCreateEmptyTab} className={styles.createEmptyButton}>
          {t('modals:CreateTabModal.createEmpty')}
        </Button>
      </div>
    </>
  );

  const tabTemplates: Record<TGoStorageTabTemplateType, TTabTemplateWithGSProps[]> = {
    personal: tabTemplatesService.personal,
    public: tabTemplatesService.publicTemplates,
    system: tabTemplatesService.system,
  };

  const rawTabTemplates = tabTemplates[selectedGroup];
  const templatesContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!templatesContainerRef?.current) return;
    const rows = templatesContainerRef?.current.children;

    const containerPaddingTop = 32;

    if (!rows) return;
    const containerHeight = calculateContainerHeight(rows, containerPaddingTop, 16);

    setTemplatesContainerHeight(containerHeight);
  }, [templatesContainerRef, isOpened, transformedTabTemplates]);

  useEffect(() => {
    setTransformedTabTemplates([]);

    for (let i = 0; i < rawTabTemplates.length; i += 4) {
      const chunk = rawTabTemplates.slice(i, i + 4);

      setTransformedTabTemplates((prevState) => [...prevState, chunk]);
    }
  }, [selectedGroup, rawTabTemplates]);

  return (
    <Modal
      open={isOpened}
      className={styles.modal}
      onClickOutside={null}
      onClose={handleClose}
      title={header}
      headerClassName={styles.header}
      wrapperClassName={styles.wrapper}
    >
      <RadioButtonGroup<TGoStorageTabTemplateType>
        variant="flat"
        items={[
          { value: 'system', label: t('modals:CreateTabModal.Tabs.system') },
          { value: 'public', label: t('modals:CreateTabModal.Tabs.public') },
          { value: 'personal', label: t('modals:CreateTabModal.Tabs.private') },
        ]}
        value={selectedGroup}
        className={styles.switchContainer}
        itemClassName={styles.switchButton}
        onChange={setSelectedGroup}
      />

      <Scrollbar wrapperClassName={styles.wrapperScroll}>
        <div className={styles.templates} style={{ height: templatesContainerHeight }} ref={templatesContainerRef}>
          {selectedGroup &&
            transformedTabTemplates.map((tabTemplatesRow, index) => (
              <div key={index} className={styles.row}>
                {tabTemplatesRow?.map((item) => {
                  const template = item.data;
                  return (
                    <article key={item.id} className={styles.template} role="button" onClick={() => onConfirm(item)}>
                      <TemplateImg className={styles.templateImg} />
                      <div className={styles.templateTitle}>{template.template.name}</div>
                      <div className={styles.templateDescription}>{template.template.description}</div>
                    </article>
                  );
                })}
              </div>
            ))}
        </div>
      </Scrollbar>
    </Modal>
  );
});
