import type { Auth, IAuthService } from './AuthStore';
import type { IServicesCollection } from 'src/packages/di';

import { AuthStore } from './AuthStore';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    authService: IAuthService;
  }
}

export function addAuthService(di: IServicesCollection, userInfo?: Auth.UserInfo): void {
  if (!userInfo) {
    return;
  }

  di.addSingleton('authService', new AuthStore(userInfo));
}
