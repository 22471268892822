import { BackLight } from '@profgeosoft-ui/icons';
import { ConfigProvider, Loader } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';

import { Router } from 'src/app/router/Router';
import { useServiceAccessor } from 'src/packages/di';
import 'src/packages/assets/styles/index.scss';
import { ConnectionClosedModal } from 'src/pages/dashboard/features/connection-closed-modal/ConnectionClosedModal';

import type { FC } from 'react';

import { getIsTokenExpired } from './auth/auth';
import { Auth } from './auth/AuthStore';
import { UserService } from './auth/user-service';
import { RootStore, RootStoreProvider } from './root-store';
import { initAppServices } from './root-store/initAppServices';
import { getLocale } from './router/utils';

import styles from './App.module.scss';

async function auth(): Promise<Auth.UserInfo | undefined> {
  if (getIsTokenExpired()) {
    await UserService.initUserServiceWithCheck();
  } else {
    await UserService.initUserService();
  }

  try {
    const userInfo = await UserService.getUserData();

    if (Auth.isUserInfo(userInfo)) {
      return userInfo;
    } else {
      console.error('Invalid user object');
    }
  } catch (error) {
    console.error(error);
  }
}

export const App: FC = observer(function App() {
  const [store, setStore] = useState<RootStore | null>(null);
  const [error, setError] = useState<'Forbidden' | 'Unknown' | null>(null);

  const getPreloadService = useServiceAccessor('preloadService');
  const getTheme = useServiceAccessor('theme');
  const getLanguage = useServiceAccessor('language');
  const getAuthService = useServiceAccessor('authService');

  useEffect(() => {
    async function init(): Promise<void> {
      const userInfo = await auth();

      if (!userInfo) {
        return;
      }

      const message = await initAppServices({ userInfo });
      if (message === 'Forbidden') {
        setError('Forbidden');
        return;
      }
      if (message === 'Unknown') {
        setError('Unknown');
        return;
      }

      const preloadService = getPreloadService();

      await preloadService.preload();

      setStore(new RootStore());
    }

    init();
  }, [getPreloadService]);

  if (error) {
    return (
      <ConfigProvider
        config={{
          theme: getTheme().theme,
          style: 'bordered',
          localization: getLocale(getLanguage().language),
          size: 's',
        }}
        externalElement={document.body}
      >
        <ConnectionClosedModal
          isOpened={true}
          titleT="modals:AccessDenied.title"
          descT="modals:AccessDenied.desc"
          externalAction={() => getAuthService().logout()}
          externalActionT="modals:AccessDenied.bactToLogin"
          externalActionIcon={<BackLight />}
        />
      </ConfigProvider>
    );
  }

  if (!store) {
    return (
      <div className={styles.loaderContainer}>
        <Loader size="l" />
      </div>
    );
  }

  return (
    <RootStoreProvider store={store}>
      <Router />
    </RootStoreProvider>
  );
});
