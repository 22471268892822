import type {
  OperationalParametersWidgetEntity,
  TOperationalParametersSerializedInternalState,
} from 'src/pages/dashboard/features/operational-parameters-widget/OperationalParametersWidget.entity';
import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

export function serializeOperationalParametersTemplate(
  widget: OperationalParametersWidgetEntity
): TSerializedWidgetTemplate {
  const internalState: TOperationalParametersSerializedInternalState = {
    wellId: widget.wellId,
    wellIndexType: widget.wellIndexType,
    stateParams: {
      display: widget.stateParams.display,
      wellboreId: widget.stateParams.wellboreId,
      logsFilter: widget.stateParams.logsFilter,
      visibilitySettings: widget.stateParams.visibilitySettings,
    },
    enableSound: widget.enableSound,
  };

  return {
    uuid: widget.uuid,
    type: widget.type,
    groupId: widget.groupId,
    size: {
      w: widget.size.w,
      h: widget.size.h,
    },
    position: {
      x: widget.position.x,
      y: widget.position.y,
    },
    internalState,
  };
}
