import type { TRenderWidget, TWidgetRendererOptions } from './WidgetRendererService';
import type { WellLogsWidgetEntity } from '../../well-logs-widget/WellLogsWidget.entity';

import { WellLogsWidgetWrapper } from '../../well-logs-widget/WellLogsWidget';

export function renderWellLogsWidget(widget: WellLogsWidgetEntity, options: TWidgetRendererOptions): TRenderWidget {
  return ({ onCreateWellLogsWidget }) => (
    <WellLogsWidgetWrapper entity={widget} options={options} onCreateWellLogsWidget={onCreateWellLogsWidget} />
  );
}
