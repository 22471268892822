import { parseWellLogsWidgetInternalState } from 'src/pages/dashboard/features/well-logs-widget/parseWellLogsWidget';
import { WellLogsWidgetEntity } from 'src/pages/dashboard/features/well-logs-widget/WellLogsWidget.entity';

import type { TSerializedWidget } from 'src/entities/workspace/types';

export function mapWellLogsWidget(widget: TSerializedWidget, index: number): WellLogsWidgetEntity {
  const internalState = parseWellLogsWidgetInternalState(widget.internalState);

  const newFilterWidget = new WellLogsWidgetEntity(
    {
      wellIndexType: internalState.wellIndexType,
      isEditMode: internalState.isEditMode,
      wellId: internalState.wellId,
      stateParams: internalState.stateParams,
      type: 'well-logs-widget',
    },
    {
      uuid: widget.uuid,
      isPinned: !!widget.isPinned,
      zIndex: index + 1,
      isFullscreen: widget.isFullscreen ?? false,
      position: {
        x: widget?.position?.x ?? 0,
        y: widget?.position?.y ?? 0,
      },
      size: {
        w: widget.size?.w ?? 59,
        h: widget.size?.h ?? 316,
      },
      groupId: widget.groupId,
      isGroupsDefaultOpened: false,
    }
  );

  return newFilterWidget;
}
