import * as Sentry from '@sentry/react';
import axios from 'axios';

import { getAccessToken } from 'src/app/auth/auth';
import { UserService } from 'src/app/auth/user-service';
import { getLocale } from 'src/packages/language/utils';

import { API_GOSTORAGE_URL } from './consts';

const goStorageAgent = axios.create({
  baseURL: API_GOSTORAGE_URL,
  headers: {
    'content-type': 'application/json',
  },
});

goStorageAgent.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      const accessToken = await UserService.updateToken();

      if (error?.config && accessToken) {
        return axios.request({
          ...error.config,
          headers: {
            ...error.config.headers,
            Authorization: `Bearer ${accessToken}`,
          },
        });
      }
    }

    Sentry.captureException(error);

    return Promise.reject(error);
  }
);

goStorageAgent.interceptors.request.use((config) => {
  const accessToken = getAccessToken();

  config.headers['accept-language'] ??= getLocale();

  if (accessToken) {
    config.headers.set('Authorization', `Bearer ${accessToken}`);
  }

  return config;
});

export { goStorageAgent };
