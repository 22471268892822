import { action, makeObservable } from 'mobx';

import { BroadcastTunnel } from 'src/packages/broadcast-tunnel/BroadcastTunnel';

import type { SlaveWindowsManager } from './SlaveWindowsManager';
import type { SingleTabStore } from '../SingleTab.store';
import type { TTabDataMessage } from '../SingleTab.types';
import type { TTabCreatedMessage } from 'src/pages/dashboard/features/workspace/types';

export class TabOpeningService {
  private readonly store: SingleTabStore;
  private readonly windowsManager: SlaveWindowsManager;

  private readonly tabOpeningTunnel: BroadcastTunnel<TTabCreatedMessage, TTabDataMessage>;

  constructor(store: SingleTabStore, windowsManager: SlaveWindowsManager) {
    this.store = store;
    this.windowsManager = windowsManager;

    this.tabOpeningTunnel = new BroadcastTunnel((message): message is TTabDataMessage => {
      if (message.type !== 'tab-data' || this.tabId !== message?.to?.id) return false;

      return true;
    }, this.onReceivingTabData);

    makeObservable(this);
  }

  private get tabId(): string {
    return this.store.tabId;
  }

  @action.bound
  private onReceivingTabData = (message: TTabDataMessage): void => {
    if (this.store.actualTab && this.store.state) {
      this.windowsManager.sendTabState(this.store.state);
    } else {
      this.store.mapTab(message.data);
    }
  };

  sendTabStatus = (): void => {
    this.tabOpeningTunnel.postMessage({
      type: 'tab-created',
      from: {
        type: 'slave',
        id: this.tabId,
      },
      to: {
        // TODO: add master id
        type: 'master',
      },
    });
  };

  dispose = (): void => {
    this.tabOpeningTunnel.disconnect();
  };
}
