import { RadioButtonGroup } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';

import { useService } from 'src/packages/di';

export const LanguageSwitcher = observer(function LanguageSwitcher() {
  const languageService = useService('language');
  const languages = languageService.languages;

  if (languageService.isLanguageSwitcherHidden) {
    return null;
  }

  const items = languages.map((lang) => ({
    label: lang.title,
    value: lang.lang,
  }));

  return <RadioButtonGroup items={items} value={languageService.language} size="m" onChange={languageService.select} />;
});
