import { action, computed, makeObservable, observable } from 'mobx';

import { Item } from '../abstract-control-entities';

type TDateValue = {
  startDate: Date | null;
  endDate: Date | null;
};

type DateIntervalFieldData = {
  fieldId: string;
  unit: string;
  initialStart: Date | null;
  initialEnd: Date | null;
  labels: Record<string, string>;
  placeholderMax: string;
  placeholderMin: string;
  attrNames: {
    endDate: string;
    startDate: string;
  };
};

export class DateRangeFieldEntity extends Item<TDateValue> {
  readonly placeholderMin: string;
  readonly placeholderMax: string;
  readonly labels: Record<string, string>;
  readonly unit: string;
  readonly attrNames: {
    endDate: string;
    startDate: string;
  };

  @observable initialValue: TDateValue = {
    startDate: null,
    endDate: null,
  };
  @observable startDateBoundary: Date | null = null;
  @observable endDateBoundary: Date | null = null;
  @observable startDate: Date | null;
  @observable endDate: Date | null;
  @observable disabledStartDate: Date | null = null;
  @observable disabledEndDate: Date | null = null;

  constructor(data: DateIntervalFieldData) {
    super(data);

    this.unit = data.unit;
    this.labels = data.labels;
    this.placeholderMax = data.placeholderMax;
    this.placeholderMin = data.placeholderMin;
    this.startDate = data.initialStart;
    this.endDate = data.initialEnd;
    this.initialValue = {
      startDate: data.initialStart,
      endDate: data.initialEnd,
    };
    this.attrNames = {
      startDate: data.attrNames.startDate,
      endDate: data.attrNames.endDate,
    };

    makeObservable(this);
  }

  @computed
  get value(): TDateValue {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }

  @action.bound
  clearItem(): void {
    this.startDate = null;
    this.endDate = null;
  }

  @action.bound
  clearInitialValue(): void {
    this.initialValue.startDate = null;
    this.initialValue.endDate = null;
  }

  @action.bound
  returnInitialValue(): void {
    this.startDate = this.initialValue.startDate;
    this.endDate = this.initialValue.endDate;
  }

  @action.bound
  setInitialValue(value: TDateValue): void {
    this.initialValue = {
      startDate: value.startDate,
      endDate: value.endDate,
    };
  }

  @action.bound
  setValue(value: TDateValue, setValueAsInitial?: boolean): void {
    this.startDate?.valueOf() !== value.startDate?.valueOf() && this.setStartDate(value.startDate);
    this.endDate?.valueOf() !== value.endDate?.valueOf() && this.setEndDate(value.endDate);

    if (setValueAsInitial) {
      this.setInitialValue(value);
    }
  }

  @action.bound
  setStartDateBoundary(value: Date | null): void {
    this.startDateBoundary = value;
  }

  @action.bound
  setEndDateBoundary(value: Date | null): void {
    this.endDateBoundary = value;
  }

  @action.bound
  tryToSetRawValue(value: unknown, setValueAsInitial?: boolean): boolean {
    if (typeof value !== 'object' || value === null) {
      return false;
    }

    const objectValue: { startDate?: unknown; endDate?: unknown } = value;
    const { startDate, endDate } = objectValue;

    if (
      (typeof startDate === 'number' ||
        (typeof startDate === 'string' && !isNaN(Number(startDate))) ||
        startDate === null) &&
      (typeof endDate === 'number' || (typeof endDate === 'string' && !isNaN(Number(endDate))) || endDate === null)
    ) {
      const newValue = {
        startDate: startDate === null ? null : new Date(Number(startDate) * 1000),
        endDate: endDate === null ? null : new Date(Number(endDate) * 1000),
      };

      this.setValue(newValue, setValueAsInitial);

      return true;
    }
    return false;
  }

  @action.bound
  setStartDate(value: Date | null): void {
    if (value === this.startDate || value?.getTime() === this.startDate?.getTime()) {
      return;
    }

    this.startDate = value;

    if (this.endDate && value) {
      if (value > this.endDate) {
        this.endDate = null;
        return;
      }
    }
  }

  @action.bound
  setEndDate(value: Date | null): void {
    if (value === this.endDate || value?.getTime() === this.endDate?.getTime()) {
      return;
    }

    this.endDate = value;
  }

  getDisabledEndDates = (currentDate: Date): boolean => {
    const currentTime = currentDate.getTime();

    return (
      (!!this.startDate && currentTime < this.startDate.getTime()) ||
      (!!this.disabledEndDate && this.disabledEndDate.getTime() > currentTime) ||
      (!!this.startDateBoundary && currentTime < this.startDateBoundary.getTime()) ||
      (!!this.endDateBoundary && currentTime > this.endDateBoundary.getTime())
    );
  };

  getDisabledStartDates = (currentDate: Date): boolean => {
    const currentTime = currentDate.getTime();

    return (
      (!!this.disabledStartDate && currentTime < this.disabledStartDate.getTime()) ||
      (!!this.startDateBoundary && currentTime < this.startDateBoundary.getTime()) ||
      (!!this.endDateBoundary && currentTime > this.endDateBoundary.getTime())
    );
  };
}
