import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useService } from 'src/packages/di';

import type { TMenuItem } from 'src/components/sidebar/navigation/Navigation';

import styles from './MenuItem.module.scss';

type Props = {
  isActive?: boolean;
  isOpened: boolean;
  item: TMenuItem;
  isSecondary?: boolean;
  onClick?: () => void;
};

export const MenuItem = observer(function MenuItem({ isActive, isOpened, item, isSecondary, onClick }: Props) {
  const { t } = useTranslation('sidebar');
  const theme = useService('theme');

  return (
    <button
      className={clsx(
        styles.wrapper,
        isActive && styles.wrapper__active,
        isSecondary && isActive && styles.wrapper__secondaryActive
      )}
      disabled={!onClick}
      onClick={onClick}
    >
      <div className={clsx(styles.iconWrapper, isActive && styles.iconWrapper__active)}>
        {!!item?.notifications?.count && <div className={styles.notificationsIconBall} />}
        {item.renderIcon(
          clsx(
            styles.icon,
            isActive && styles.icon__active,
            isSecondary && isActive && styles.icon__secondaryActive,
            theme.theme === 'dark' && styles.icon__dark
          )
        )}
      </div>
      <div className={clsx(styles.textWrapper, isOpened && styles.textWrapper__opened)}>
        <h3
          className={clsx(
            styles.text,
            isActive && styles.text__active,
            isSecondary && isActive && styles.text__secondaryActive,
            isOpened && styles.text__opened
          )}
        >
          {t(item.name)}
        </h3>
        {!!item?.notifications?.count && (
          <p className={clsx(styles.subTextFont, styles.notificationsCount)}>{item.notifications.count}</p>
        )}
      </div>
    </button>
  );
});
