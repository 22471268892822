import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Arrow } from 'src/packages/assets/img/arrow.svg';

import styles from './tab-creation-hint.module.scss';

export const TabCreationHint = observer(function TabCreationHint() {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <Arrow />
      <p className={styles.text}>{t('dashboard:Hints.tabHint')}</p>
    </div>
  );
});
