import { action, makeObservable, observable } from 'mobx';

import type { ObjectListScreenEntity } from './ObjectsListScreen.entity';
import type { BaseSettingsScreen } from '../screen/BaseSettingsScreen';
import type { WellIndexType } from '@go-widgets/well-logs-widget';
import type { Stack } from 'src/packages/data/structures/Stack';

import { dummyCollapses } from './ObjectsListScreen';

export class ObjectsListScreenStore {
  readonly stack: Stack<BaseSettingsScreen>;
  readonly screenEntity: ObjectListScreenEntity;

  @observable activeObjectCollapses: string[] = dummyCollapses.map((collapse) => collapse.id);

  constructor(stack: Stack<BaseSettingsScreen>, screen: ObjectListScreenEntity, defaultObjectId?: WellIndexType) {
    this.stack = stack;
    this.screenEntity = screen;

    if (defaultObjectId) {
      this.selectObject(defaultObjectId);
    }

    makeObservable(this);
  }

  @action.bound
  selectObject(id: WellIndexType): void {
    if (id === this.screenEntity.selectedObjectKey) return;

    this.stack.setLength(1);
    this.screenEntity.setObjectKey(id);
    this.screenEntity.createWidgetTemplatesList(id);
  }

  @action.bound
  openObjectCollapse(key: string[]): void {
    this.activeObjectCollapses = key;
  }
}
