import { BroadcastTunnel } from 'src/packages/broadcast-tunnel/BroadcastTunnel';

import type { SlaveWindowsManager } from './SlaveWindowsManager';
import type { SingleTabStore } from '../SingleTab.store';
import type { TSerializedTab } from 'src/entities/workspace/types';
import type { TTabSyncMessage } from 'src/pages/dashboard/features/workspace/types';

export class TabSyncingService {
  private readonly store: SingleTabStore;
  private readonly windowsManager: SlaveWindowsManager;
  private readonly tabSyncingTunnel: BroadcastTunnel<TTabSyncMessage, TTabSyncMessage>;

  constructor(store: SingleTabStore, windowsManager: SlaveWindowsManager) {
    this.store = store;
    this.windowsManager = windowsManager;

    this.tabSyncingTunnel = new BroadcastTunnel((message): message is TTabSyncMessage => {
      if (
        message?.type !== 'tab-changed' ||
        message?.from?.type !== 'master' ||
        message?.from?.id !== this.windowsManager.master.id ||
        message?.to?.id !== this.store.tabId
      ) {
        return false;
      }

      return true;
    }, this.onTabChanged);
  }

  private get tabId(): string {
    return this.store.tabId;
  }

  private onTabChanged = (message: TTabSyncMessage): void => {
    this.store.setUpdating(true);
    this.store.mapTab(message.data);
    this.store.setUpdating(false);
  };

  sendTabState(state: TSerializedTab): void {
    if (this.store.actualTab && this.windowsManager.master.id) {
      this.tabSyncingTunnel.postMessage({
        type: 'tab-changed',
        data: state,
        from: {
          type: 'slave',
          id: this.tabId,
        },
        to: {
          id: this.windowsManager.master.id,
        },
      });
    }
  }

  dispose = (): void => {
    this.tabSyncingTunnel.disconnect();
  };
}
