import type { TBreakpoints } from './types';

export const ROWS_COUNT = 100;
export const X_CONTAINER_PADDING = 4;
export const Y_CONTAINER_PADDING = 4;
export const X_WIDGETS_MARGIN = 2;
export const Y_WIDGETS_MARGIN = 0;
export const COLS = 256;

// для ширины одна колонка = 10px
export const breakpoints: TBreakpoints = {
  xxl: 2430,
  xl: 1910,
  lg: 1670,
  md: 1430,
  sm: 1270,
  s: 986,
  xs: 758,
  xxs: 630,
  xxxs: 470,
  xxxxs: 320,
};

export const cols: TBreakpoints = {
  xxl: COLS,
  xl: COLS,
  lg: COLS,
  md: COLS,
  sm: COLS,
  s: COLS,
  xs: COLS,
  xxs: COLS,
  xxxs: COLS,
  xxxxs: COLS,
};
