import { CloseRoundLight as CloseIcon, DetailVertCustom as DotsIcon, OutLight as OutIcon } from '@profgeosoft-ui/icons';
import { Tooltip } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOutsideClick } from 'src/packages/shared/hooks/use-outside-click';

import type { ChangeEvent, KeyboardEvent, MouseEvent } from 'react';

import { MAX_TAB_NAME_LENGTH } from '../../TabsList.consts';

import styles from './MenuItem.module.scss';

type Props = {
  isActive: boolean;
  id: string;
  name: string;
  isExternalTab: boolean;
  showUpdateTemplateButton: boolean;
  onSaveTabAsTemplate(): void;
  onUpdateTemplate(): void;
  onTabRemove(id: string): void;
  onTabClose?(id: string): void;
  onTabFocus(): void;
  onActiveKeyChange(key: string): void;
  onChangeTabName(id: string, name: string): void;
  onCloseMenu(): void;
  onOpenInNewWindow?(): void;
};

export const MenuItem = observer(function MenuItem({
  isActive,
  name,
  id,
  isExternalTab,
  showUpdateTemplateButton,
  onSaveTabAsTemplate,
  onUpdateTemplate,
  onTabFocus,
  onCloseMenu,
  onTabClose,
  onTabRemove,
  onActiveKeyChange,
  onChangeTabName,
  onOpenInNewWindow,
}: Props) {
  const { t } = useTranslation();
  const [isFocused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState(name);
  const [inputWidth, setInputWidth] = useState(0);
  const [isOpen, setOpen] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const inputRef = useRef<HTMLInputElement>(null);
  const span = useRef<HTMLSpanElement>(null);

  const menuActions = [
    {
      name: 'common:Buttons.rename',
      key: 'rename',
      disabled: isExternalTab,
      action: () => {
        setOpen(false);
        setFocused(true);
        inputRef.current?.focus();
        activateRenameInput();
      },
    },
    {
      name: 'common:Buttons.saveAsTemplate',
      key: 'template',
      action: () => {
        onSaveTabAsTemplate();
        onCloseMenu();
      },
    },
    {
      name: 'common:Buttons.save',
      key: 'update',
      isHidden: !showUpdateTemplateButton,
      action: () => {
        onUpdateTemplate();
        onCloseMenu();
      },
    },
    {
      name: 'common:Buttons.export',
      key: 'export',
      action: () => {},
    },
    {
      name: isExternalTab ? 'common:Buttons.goToWindow' : 'common:Buttons.openInNewWindow',
      isHidden: !onOpenInNewWindow,
      key: 'open',
      action: isExternalTab ? onTabFocus : onOpenInNewWindow,
    },
  ];

  const renderTooltipComponent = () => (
    <div ref={containerRef} className={styles.menu}>
      {menuActions
        .filter((item) => !item.isHidden)
        .map((item) => (
          <div key={item.key} onClick={item.action} className={clsx(styles.menuItem, styles.subItem)}>
            <p>{t(item.name)}</p>
          </div>
        ))}
    </div>
  );

  const onRemoveTabButtonClick = (e: MouseEvent, id: string) => {
    e.stopPropagation();

    onCloseMenu();
    onTabRemove(id);
  };

  const onCloseTabButtonClick = (e: MouseEvent, id: string) => {
    e.stopPropagation();

    onCloseMenu();
    onTabClose?.(id);
  };

  const onInputBlur = async () => {
    if (inputValue.length === 0 || name === inputValue) {
      setFocused(false);
      setInputValue(name);

      return;
    }

    onChangeTabName(id, inputValue);
    setFocused(false);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.length > MAX_TAB_NAME_LENGTH) {
      return;
    }

    setInputValue(value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Space') {
      e.stopPropagation();
    }

    if (e.key === 'Enter') {
      onInputBlur();
    }
  };

  useOutsideClick(containerRef, () => setOpen(false), [buttonRef]);
  useOutsideClick(inputRef, onInputBlur, [inputRef, containerRef]);

  const activateRenameInput = () => {
    if (span.current && !isExternalTab) {
      setInputWidth(span.current.offsetWidth);
      setFocused(true);
    }
  };

  return (
    <Tooltip
      onOpenChange={setOpen}
      title={renderTooltipComponent()}
      placement="left-start"
      trigger={['click']}
      open={isOpen}
      withArrow={false}
      gap={3}
      className={styles.popover}
    >
      <div
        role="button"
        onClick={() => !isExternalTab && onActiveKeyChange(id)}
        className={clsx(
          styles.menuItem,
          isActive && styles.menuItem__selected,
          isExternalTab && styles.menuItem__disabled
        )}
        onDoubleClick={activateRenameInput}
      >
        {isFocused && !isExternalTab ? (
          <input
            ref={inputRef}
            value={inputValue}
            type="text"
            onChange={onInputChange}
            onKeyDown={handleKeyDown}
            onBlur={onInputBlur}
            onPointerDown={(e) => e.stopPropagation()}
            className={styles.input}
            style={{ width: inputWidth || 'auto' }}
            autoFocus
          />
        ) : (
          <span ref={span} className={clsx(styles.tabName, isExternalTab && styles.tabName__disabled)}>
            {name}
          </span>
        )}

        <div className={styles.toolsWrapper} onClick={(e) => e.stopPropagation()}>
          <div className={styles.toolsWrapperMain}>
            <button
              onClick={(e) => (isExternalTab ? onCloseTabButtonClick(e, id) : onRemoveTabButtonClick(e, id))}
              className={styles.tabToolIconButton}
            >
              <CloseIcon />
            </button>

            <button
              ref={buttonRef}
              className={clsx(styles.tabToolIconButton)}
              type="button"
              onClick={() => setOpen(!isOpen)}
            >
              <DotsIcon />
            </button>
          </div>

          {isExternalTab && (
            <button onClick={onTabFocus} className={styles.tabToolIconButton}>
              <OutIcon />
            </button>
          )}
        </div>
      </div>
    </Tooltip>
  );
});
