type TMessageType = 'message' | 'error';

export interface IApplicationBroadcastService {
  addEventListener<T>(type: TMessageType, onMessage: (message: T) => void): void;
  removeEventListener<T>(onMessage: (message: T) => void): void;
  postMessage<T>(message: T): void;
}

export class ApplicationBroadcastService implements IApplicationBroadcastService {
  private readonly channel: BroadcastChannel;
  private readonly callbacks: Set<Function> = new Set();

  constructor() {
    this.channel = new BroadcastChannel('dashboard-service');
    this.channel.addEventListener('message', this.onMessage);
  }

  private onMessage = (event: MessageEvent): void => {
    for (const callback of this.callbacks) {
      callback(event.data);
    }
  };

  addEventListener<T>(type: TMessageType, onMessage: (message: T) => void): void {
    if (this.callbacks.has(onMessage)) return;

    switch (type) {
      case 'message':
        this.callbacks.add(onMessage);
        break;
      default:
        break;
    }
  }

  removeEventListener<T>(onMessage: (message: T) => void): void {
    this.callbacks.delete(onMessage);
  }

  postMessage<T>(message: T): void {
    this.channel.postMessage(message);
  }
}
