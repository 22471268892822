import { action, makeObservable, observable } from 'mobx';

import type { WellIndexType } from '@go-widgets/well-logs-widget';
import type { Stack } from 'src/packages/data/structures/Stack';

import { BaseSettingsScreen } from '../screen/BaseSettingsScreen';
import { WidgetTemplatesListScreenEntity } from '../widget-templates-list-screen/WidgetTemplatesListScreen.entity';

export class ObjectListScreenEntity extends BaseSettingsScreen {
  readonly type = 'object-list';

  @observable selectedObjectKey?: string;

  constructor(stack: Stack<BaseSettingsScreen>) {
    super(stack);

    makeObservable(this);
  }

  @action.bound
  setObjectKey(key: string): void {
    this.selectedObjectKey = key;
  }

  @action.bound
  createWidgetTemplatesList(id: WellIndexType): void {
    const newChildren = new WidgetTemplatesListScreenEntity(this.stack, id);

    this.stack.push(newChildren);
  }
}
