import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { TIconTuple } from '../NewContentWidgetModal.store';

import { PanelIcon } from './PanelIcon';

import styles from './WidgetAndGroupPanel.module.scss';

type Props = {
  name: string;
  description: string;
  iconsMap?: [TIconTuple, TIconTuple];
  onClick: VoidFunction;
};

export const WidgetAndGroupPanel = observer(function WidgetAndGroupPanel({
  name,
  description,
  iconsMap,
  onClick,
}: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper} onClick={onClick}>
      {!!iconsMap && (
        <div className={styles.iconsWrapper}>
          <div className={styles.iconsColumn}>
            {iconsMap[0].map((icon) => (
              <PanelIcon key={icon} iconName={icon} />
            ))}
          </div>
          <div className={styles.iconsColumn}>
            {iconsMap[1].map((icon) => (
              <PanelIcon key={icon} iconName={icon} />
            ))}
          </div>
        </div>
      )}
      {!iconsMap && (
        <div className={styles.iconsStubOuter}>
          <div className={styles.iconsStubInner} />
        </div>
      )}
      <p className={styles.name}>{t(name)}</p>
      <p className={styles.description}>{t(description)}</p>
    </div>
  );
});
