import { v4 as uuidv4 } from 'uuid';

import { getDefaultLayouts } from 'src/pages/dashboard/features/workbench/utils/utils';
import { type TSelectTemplate, type TTabTemplateWithGSProps } from 'src/pages/dashboard/features/workspace/types';
import { GROUPS } from 'src/serializers/tab/mapTab';

import type { TSerializedTab } from './types';

export function isEmptyTabTemplate(template: TTabTemplateWithGSProps | TSelectTemplate): template is TSelectTemplate {
  return 'type' in template && 'title' in template && template.type === 'empty';
}

export function getNewTab(template: TTabTemplateWithGSProps | TSelectTemplate): TSerializedTab {
  if (isEmptyTabTemplate(template)) {
    return {
      id: uuidv4(),
      isActive: false,
      name: template.title,
      groups: GROUPS,
      layouts: getDefaultLayouts(),
      widgets: [],
    };
  }

  return {
    id: uuidv4(),
    isActive: false,
    templateId: template.id,
    name: template.data.template.name,
    groups: GROUPS,
    layouts: template.data.template.layouts,
    widgets: [],
  };
}
