import { requireService } from 'src/packages/di';

import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';
import type { TSerializedWidget } from 'src/entities/workspace/types';

export function serializeWidgets(widgets: WidgetEntity[]): TSerializedWidget[] {
  return widgets
    .slice()
    .sort((a, b) => a.zIndex - b.zIndex)
    .map((widget) => {
      return serializeWidget(widget);
    });
}

function serializeWidget(widget: WidgetEntity): TSerializedWidget {
  const serializeWdigetService = requireService('widgetSerializer');

  return serializeWdigetService.serialize(widget);
}
