import { parseOperationalParametersInternalState } from 'src/pages/dashboard/features/operational-parameters-widget/parseOperationalParametersWidget';

import type { TSerializedWidget } from 'src/entities/workspace/types';
import type { OperationalParametersWidgetEntity } from 'src/pages/dashboard/features/operational-parameters-widget/OperationalParametersWidget.entity';

import { baseWidgetApplyChanges } from './baseWidgetApplyChanges';

export function operationalParametersApplyChanges(
  widget: OperationalParametersWidgetEntity,
  changes: TSerializedWidget,
  index: number
) {
  baseWidgetApplyChanges(widget, changes, index);

  const internalState = parseOperationalParametersInternalState(changes.internalState);

  widget.setWellId(internalState.wellId);
  widget.setStateParams(internalState.stateParams);
}
