import { parseWellDetailsWidgetInternalState } from 'src/pages/dashboard/features/well-details-widget/parseWellDetailsWidget';
import { WellDetailsWidgetEntity } from 'src/pages/dashboard/features/well-details-widget/WellDetailsWidget.entity';

import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

export function mapWellDetailsTemplate(template: TSerializedWidgetTemplate, index: number): WellDetailsWidgetEntity {
  const { size, position } = template;

  const internalState = parseWellDetailsWidgetInternalState(template.internalState ?? {});

  const wellLogsWidget = new WellDetailsWidgetEntity(
    {
      groupId: template.groupId ?? null,
      isGroupsDefaultOpened: false,
      uuid: template.uuid,
      isPinned: false,
      isFullscreen: false,
      zIndex: index + 1,
      size: {
        w: size.w,
        h: size.h,
      },
      position: {
        x: position.x,
        y: position.y,
      },
    },
    {
      wellId: internalState.wellId,
      section: internalState.section,
      isEditMode: internalState.isEditMode,
    }
  );

  return wellLogsWidget;
}
