import type { TFilterDataSource } from 'src/pages/dashboard/features/filter-widget/WellsFilterDataSource';

export function serializeDataSource(data: TFilterDataSource): TFilterDataSource {
  const _data: TFilterDataSource = {};

  for (const [key, value] of Object.entries(data)) {
    if (typeof value === 'number' || typeof value === 'string') {
      _data[key] = value;
    }

    if (Array.isArray(value)) {
      _data[key] = value.map((value) => value);
    }

    if (value === null) {
      _data[key] = null;
    }
  }

  return _data;
}
