import type { IFavoritesService } from './IFavoritesService';
import type { IServicesCollection } from 'src/packages/di';

import { FavoritesWellsService } from './Favorites.service';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    favoritesWells: IFavoritesService;
  }
}

export function addFavoritesWellsService(di: IServicesCollection) {
  di.addSingleton('favoritesWells', new FavoritesWellsService());
}
