import type { TSerializedWidget } from 'src/entities/workspace/types';
import type {
  OperationalParametersWidgetEntity,
  TOperationalParametersSerializedInternalState,
} from 'src/pages/dashboard/features/operational-parameters-widget/OperationalParametersWidget.entity';

export function serializeOperationalParametersWidget(widget: OperationalParametersWidgetEntity): TSerializedWidget {
  const internalState: TOperationalParametersSerializedInternalState = {
    wellId: widget.wellId,
    wellIndexType: widget.wellIndexType,
    stateParams: {
      parameters: widget.stateParams.parameters,
      display: widget.stateParams.display,
      wellboreId: widget.stateParams.wellboreId,
      logsFilter: widget.stateParams.logsFilter,
      visibilitySettings: widget.stateParams.visibilitySettings,
    },
    enableSound: widget.enableSound,
  };

  return {
    wellId: widget.wellId,
    uuid: widget.uuid,
    type: widget.type,
    groupId: widget.groupId,
    size: {
      w: widget.size.w,
      h: widget.size.h,
    },
    position: {
      x: widget.position.x,
      y: widget.position.y,
    },
    isFullscreen: widget.isFullscreen,
    internalState,
  };
}
