import { BroadcastTunnel } from 'src/packages/broadcast-tunnel/BroadcastTunnel';

import type { SlaveWindowsManager } from './SlaveWindowsManager';
import type { SingleTabStore } from '../SingleTab.store';
import type { THealthCheckMessage } from '../SingleTab.types';
import type { THealthResponseMessage } from 'src/pages/dashboard/features/workspace/types';

export class SlaveHealthCheckService {
  private readonly store: SingleTabStore;
  private readonly healthCheckTunnel: BroadcastTunnel<THealthResponseMessage, THealthCheckMessage>;
  private readonly windowsManager: SlaveWindowsManager;

  constructor(store: SingleTabStore, windowsManager: SlaveWindowsManager) {
    this.store = store;
    this.windowsManager = windowsManager;

    this.healthCheckTunnel = new BroadcastTunnel((message): message is THealthCheckMessage => {
      if (
        message.type !== 'health-check' ||
        message?.to?.id !== this.store.tabId ||
        message?.from?.id !== this.windowsManager.master.id
      ) {
        return false;
      }

      return true;
    }, this.onHealthCheckRequest);
  }

  private onHealthCheckRequest = (): void => {
    if (!this.windowsManager.master.id) return;

    this.healthCheckTunnel.postMessage({
      type: 'health-response',
      from: {
        type: 'slave',
        id: this.store.tabId,
      },
      to: {
        id: this.windowsManager.master.id,
      },
    });
  };

  dispose = (): void => {
    this.healthCheckTunnel.disconnect();
  };
}
