import { requireService } from 'src/packages/di';
import { parseWellsFilterInternalState } from 'src/pages/dashboard/features/filter-widget/FilterWidget.utils';

import type { TSerializedWidget } from 'src/entities/workspace/types';
import type { FilterWidgetEntity } from 'src/pages/dashboard/features/filter-widget/FilterWidget.entity';
import type { FilterWidgetStore } from 'src/pages/dashboard/features/filter-widget/FilterWidget.store';

import { baseWidgetApplyChanges } from './baseWidgetApplyChanges';

export function wellsFilterApplyChanges(widget: FilterWidgetEntity, changes: TSerializedWidget, index: number): void {
  const storeService = requireService('widgetStoreService');
  const store = storeService.getStore<FilterWidgetEntity, FilterWidgetStore>(widget);

  if (!store) return;

  baseWidgetApplyChanges(widget, changes, index);

  const internalState = parseWellsFilterInternalState(changes.internalState);

  widget.resetAll();
  widget.setState(internalState.state);
  widget.setInitialState(internalState.initialState);
  widget.mapInitialState();
  widget.setEditMode(internalState.isEditMode);
}
