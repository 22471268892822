import type { Layout, Layouts } from '@profgeosoft/go-react-grid-layout';
import type { TSerializedWorkspaceState } from 'src/api-types/workspace.types';
import type { TabEntity } from 'src/entities/tab/TabEntity';
import type { TSerializedTab } from 'src/entities/workspace/types';
import type { WorkspaceEntity } from 'src/entities/workspace/WorkspaceEntity';

import { serializeGroup } from '../groups/serializeGroup';
import { serializeWidgets } from '../widgets/serializeWidgets';

export function serializeLayouts(layouts: Layouts): Layouts {
  const serializedLayouts: Layouts = {};

  for (const [breakpointKey, layoutsItem] of Object.entries(layouts)) {
    serializedLayouts[breakpointKey] = layoutsItem.map((layout: Layout) => {
      // исправить после обновления библиотеки
      const _layout: Layout = {
        i: layout.i,
        x: layout.x,
        y: layout.y,
        w: layout.w,
        h: layout.h,
      };

      if ('minW' in layout) {
        _layout.minW = layout.minW;
      }

      if ('maxW' in layout) {
        _layout.maxW = layout.maxW;
      }

      if ('minH' in layout) {
        _layout.minH = layout.minH;
      }

      if ('maxH' in layout) {
        _layout.maxH = layout.maxH;
      }

      if ('minPxW' in layout) {
        _layout.minPxW = layout.minPxW;
      }

      if ('minPxH' in layout) {
        _layout.minPxH = layout.minPxH;
      }

      if ('maxPxW' in layout) {
        _layout.maxPxW = layout.maxPxW;
      }

      if ('maxPxH' in layout) {
        _layout.maxPxH = layout.maxPxH;
      }

      if ('moved' in layout) {
        _layout.moved = layout.moved;
      }

      if ('static' in layout) {
        _layout.static = layout.static;
      }

      if ('isDraggable' in layout) {
        _layout.isDraggable = layout.isDraggable;
      }

      if ('isResizable' in layout) {
        _layout.isResizable = layout.isResizable;
      }

      if ('isBounded' in layout) {
        _layout.isBounded = layout.isBounded;
      }

      if ('resizeHandles' in layout) {
        _layout.resizeHandles = layout.resizeHandles?.map((handle) => handle);
      }

      if ('isQuickResizeAllowed' in layout) {
        _layout.isQuickResizeAllowed = layout.isQuickResizeAllowed;
      }

      return _layout;
    });
  }

  return serializedLayouts;
}

export function serializeTab(tab: TabEntity, activeKey?: string): TSerializedTab {
  return {
    name: tab.name,
    id: tab.id,
    isActive: tab.id === activeKey,
    templateId: tab.templateId ?? null,
    groups: tab.groupManager.groups.map((group) => serializeGroup(group)),
    layouts: serializeLayouts(tab.layouts),
    widgets: serializeWidgets(tab.widgets),
  };
}

export function serializeTabs(tabsEntities: TabEntity[], activeKey?: string): TSerializedTab[] {
  return tabsEntities.map((tab) => serializeTab(tab, activeKey));
}

export function serializeWorkspaceState(workspaceState: WorkspaceEntity): TSerializedWorkspaceState {
  return {
    tabs: serializeTabs(workspaceState.tabsEntities),
  };
}
