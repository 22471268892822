import { action, makeObservable, observable } from 'mobx';

import { assert } from 'src/packages/shared/utils/assert';

export class Stack<T> {
  @observable.shallow stackArray: T[] = [];

  constructor() {
    makeObservable(this);
  }

  get length(): number {
    return this.stackArray.length;
  }

  @action.bound
  pop(): T {
    const item = this.stackArray.pop();

    assert(typeof item !== 'undefined');

    return item;
  }

  @action.bound
  push(item: T): void {
    this.stackArray.push(item);
  }

  peek(): T {
    return this.stackArray[this.length - 1];
  }

  isEmpty(): boolean {
    return this.length === 0;
  }

  clearStack(): void {
    this.stackArray.clear();
  }

  setLength(clearTo: number): T[] {
    return this.stackArray.splice(clearTo);
  }
}
