import { useContext } from 'react';

import type { RootStore } from './RootStore';

import { RootStoreContext } from './RootStoreContext';

export function useStore(): RootStore {
  const store = useContext(RootStoreContext);

  if (!store) {
    throw new Error('Store was not provided. Did you forget wrap in <StoreProvider>?');
  }

  return store;
}
