import { Loader } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';

import { MainLayout } from 'src/components/layouts/main-layout/MainLayout';

import type { FC } from 'react';

import { SingleTab } from './single-tab/SingleTab';

export const TabPage: FC = observer(function TabPage() {
  const isLoading = false;

  const renderContent = () => {
    if (isLoading) {
      return <Loader size="l" />;
    }

    return <SingleTab />;
  };

  return (
    <MainLayout title="Tab" showSidebar={false}>
      {renderContent()}
    </MainLayout>
  );
});
