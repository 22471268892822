import { parseWellLogsWidgetInternalState } from 'src/pages/dashboard/features/well-logs-widget/parseWellLogsWidget';

import type { TSerializedWidget } from 'src/entities/workspace/types';
import type { WellLogsWidgetEntity } from 'src/pages/dashboard/features/well-logs-widget/WellLogsWidget.entity';

import { baseWidgetApplyChanges } from './baseWidgetApplyChanges';

export function wellLogsApplyChanges(widget: WellLogsWidgetEntity, changes: TSerializedWidget, index: number) {
  baseWidgetApplyChanges(widget, changes, index);

  const internalState = parseWellLogsWidgetInternalState(changes.internalState);

  // TODO: FIX

  widget.setEditMode(internalState.isEditMode);
  widget.setWellId(internalState.wellId);
  widget.setStateParams(internalState.stateParams);
}
