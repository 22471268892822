import { action, computed, flow, makeObservable, observable } from 'mobx';

import { requireService } from 'src/packages/di';
import { debounce } from 'src/packages/shared/utils/debounce';
import { createPromiseController } from 'src/packages/shared/utils/promise-controller';
import { mapWidgetTemplates } from 'src/serializers/widget-templates/mapWidgetTemplates';

import type { TabTemplateDetailsEntity } from './TabTemplateDetails.entity';
import type { TPromiseController } from 'src/packages/shared/utils/promise-controller';
import type { TTabTemplateData } from 'src/pages/dashboard/features/workspace/types';

export class TabTemplateDetailsStore {
  readonly screenEntity: TabTemplateDetailsEntity;

  @observable deleteTemplateController: TPromiseController<boolean | null> | null = null;

  constructor(screen: TabTemplateDetailsEntity, private readonly tabTemplatesService = requireService('tabTemplates')) {
    this.screenEntity = screen;

    makeObservable(this);
  }

  @computed
  get widgetList(): string[] {
    const widgetsTemplates = this.screenEntity.template.data.template.widgets;

    const widgets = mapWidgetTemplates(widgetsTemplates);

    let widgetsNameTList: string[] = [];

    for (const widget of widgets) {
      widgetsNameTList.push(widget.getNameT());
    }

    return Array.from(new Set(widgetsNameTList));
  }

  get id(): number {
    return this.screenEntity.template.id;
  }

  get templateData(): TTabTemplateData {
    return this.screenEntity.template.data;
  }

  @computed
  get isPersonal(): boolean {
    return this.templateData.type === 'personal';
  }

  @computed
  get isPublic(): boolean {
    return this.templateData.type === 'public';
  }

  @action.bound
  onDeleteTemplateAccept(): void {
    this.deleteTemplateController?.resolve(true);
  }

  @action.bound
  onDeleteTemplateCancel(): void {
    this.deleteTemplateController?.resolve(null);
  }

  onTemplateNameChange = debounce(async (name: string) => {
    this.changeTemplateName(name);
  }, 500);

  @flow.bound
  private async *changeTemplateName(name: string) {
    if (this.templateData.template.name === name || name.length === 0) return;

    try {
      const newTemplateData: Partial<TTabTemplateData> = {
        template: {
          ...this.templateData.template,
          name,
        },
      };

      await this.tabTemplatesService.updateTemplate(this.id, newTemplateData);
      yield;
    } catch (error) {
      yield;
      console.error(error);
    }
  }

  onTemplateDescChange = debounce(async (description: string) => {
    this.changeTemplateDesc(description);
  }, 500);

  @flow.bound
  private async *changeTemplateDesc(description: string) {
    if (this.templateData.template.description === description) return;

    try {
      const newTemplateData: Partial<TTabTemplateData> = {
        template: {
          ...this.templateData.template,
          description,
        },
      };

      await this.tabTemplatesService.updateTemplate(this.id, newTemplateData);
      yield;
    } catch (error) {
      yield;
      console.error(error);
    }
  }

  @action.bound
  onDuplicateToPersonal(): void {
    this.tabTemplatesService.cloneToPersonal(this.templateData);
  }

  @action.bound
  onDuplicateToPublic(): void {
    this.tabTemplatesService.cloneToPublic(this.templateData);
  }

  @flow.bound
  private async *syncCurrentTemplates() {
    try {
      if (this.isPersonal) {
        await this.tabTemplatesService.syncPersonalTemplates();
      } else {
        await this.tabTemplatesService.syncPublicTemplates();
      }

      yield;
    } catch (error) {
      yield;
      console.error(error);
    }
  }

  @flow.bound
  async *onTemplateDelete() {
    try {
      this.deleteTemplateController = createPromiseController<boolean | null>();

      const shouldDeleteTemplate = await this.deleteTemplateController;
      yield;

      if (!shouldDeleteTemplate) return;

      await this.tabTemplatesService.deleteTemplate(this.id);

      yield;

      await this.syncCurrentTemplates();

      yield;

      this.screenEntity.stack.setLength(1);
    } finally {
      this.deleteTemplateController = null;
    }
  }
}
