import { observer } from 'mobx-react-lite';
import React from 'react';

import type { RootStore } from './RootStore';
import type { PropsWithChildren } from 'react';

import { RootStoreContext } from './RootStoreContext';

interface Props extends PropsWithChildren<{}> {
  store: RootStore;
}

export const RootStoreProvider = observer(function RootStoreProvider({ children, store }: Props) {
  return <RootStoreContext.Provider value={store}>{children}</RootStoreContext.Provider>;
});
