import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { TIcon } from '../NewContentWidgetModal.store';

import { PanelIcon } from './PanelIcon';

import styles from './WidgetAndGroupPanel.module.scss';

type Props = {
  name: string;
  description: string;
  icon: TIcon;
  onClick: VoidFunction;
};

export const SingleWidgetPanel = observer(function SingleWidgetPanel({ name, description, icon, onClick }: Props) {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper} onClick={onClick}>
      <div className={styles.singleIcon}>
        <PanelIcon iconName={icon} />
      </div>
      <p className={styles.name}>{t(name)}</p>
      <p className={styles.description}>{t(description)}</p>
    </div>
  );
});
