import { BackLight as BackIcon, CloseRoundLight as CloseIcon } from '@profgeosoft-ui/icons';
import { Button, Illustration, Modal } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './RemoveObjectModal.module.scss';

type Props = {
  isOpened: boolean;
  title: string;
  desc: string;
  removeButtonText?: string;
  onCancel: () => void;
  onConfirm: () => void;
};

export const RemoveObjectModal = observer(function RemoveObjectModal({
  onCancel,
  onConfirm,
  isOpened,
  removeButtonText,
  title,
  desc,
}: Props) {
  const { t } = useTranslation();

  return (
    <Modal open={isOpened} className={styles.modal} onClickOutside={null} closeIcon={false}>
      <div className={styles.header}>
        <div className={styles.img}>
          <Illustration.Attention />
        </div>
        <div>
          <p className={styles.title}>{title}</p>
          <p className={styles.desc}>{desc}</p>
        </div>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button variant="danger" onClick={onConfirm} className={styles.button} icon={<CloseIcon />}>
          {removeButtonText ?? t('common:Buttons.close')}
        </Button>
        <Button variant="primary" onClick={onCancel} className={styles.button} icon={<BackIcon />}>
          {t('common:Buttons.cancel')}
        </Button>
      </div>
    </Modal>
  );
});
