import { requireService } from 'src/packages/di';

import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';
import type { TSerializedWidget } from 'src/entities/workspace/types';

export function mapWidgets(widgets: TSerializedWidget[]): WidgetEntity[] {
  const mappedWidgets: WidgetEntity[] = [];

  widgets.forEach((widget, index) => {
    try {
      const mappedWidget = mapWidget(widget, index);

      mappedWidgets.push(mappedWidget);
    } catch (e) {
      console.error(`Widget of type "${widget.type}" is no available.`);
    }
  });

  return mappedWidgets;
}

export function mapWidget(widget: TSerializedWidget, index: number): WidgetEntity {
  const mapWidgetService = requireService('widgetSerializer');

  return mapWidgetService.map(widget, index);
}
