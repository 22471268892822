import { requireService } from 'src/packages/di';
import { RegularComboBox } from 'src/pages/dashboard/features/controls/entities/RegularComboBox.entity';

import type { TRegularComboboxRaw, TSavedFormValue } from 'src/pages/dashboard/features/controls/types';

type TComboboxesSerializerOptions = {
  item: TRegularComboboxRaw;
  savedValue?: TSavedFormValue;
};

const isValidInitialValue = (value: unknown): value is number => {
  return typeof value === 'number' || !isNaN(Number(value));
};

export const mapComboBoxItem = ({ item, savedValue }: TComboboxesSerializerOptions): RegularComboBox => {
  const directories = requireService('directories');
  const i18 = requireService('i18');

  const data = {
    formElementRefId: item.attrName,
    fieldId: item.fieldId,
    useInMainProgressBar: !!item?.useInMainProgressBar,
    required: !!item?.required,
    refObjectAttr: item?.refObjectAttr,
    refObjectType: item?.refObjectType,
    validate: item.validate,
    validationTags: item.validationTags,
    calculatedValue: item.calculatedValue,
    onChangeInstructions: item.onChange,
    enableIf: item.enableIf,
    visuallyDisabled: item.visuallyDisabled,
    refObjectFilterByFields: item.refObjectFilterByFields,
    initialValue: isValidInitialValue(savedValue) ? Number(savedValue) : undefined,
    restrictions: { required: !!item.required },
    directory: item.refQuery ? directories.getJoinedObject(item.refQuery) : directories.getObject(item.refObjectType),
    label: i18.t(`Labels:${item.fieldId}.label`, { defaultValue: '' }),
    placeholder: i18.t(`Labels:${item.fieldId}.placeholder`, { defaultValue: '' }),
    nullValue: i18.t(`Labels:${item.fieldId}.nullValue`, { defaultValue: '' }),
    refQuery: item.refQuery,
    attrConcat: item.attrConcat,
    delimiter: item.delimiter,
    attrConcatRefs: item.attrConcatRefs,
  };

  return new RegularComboBox(data);
};
