import { action, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import type { CollapseItemOptions } from '../well-collapse/components/well-collapse-item/WellCollapseItem.entity';
import type { ICollapseEntity } from '../well-collapse/WellCollapse.entity';

import { WellCollapseItemEntity } from '../well-collapse/components/well-collapse-item/WellCollapseItem.entity';

type WellCollapseOptions = {
  name: string;
  items: WellItemEntity[];
};

export class WellCollapseEntity implements ICollapseEntity<WellItemEntity> {
  readonly $$instanceUuid = uuidv4();
  readonly name: string;
  readonly items: WellItemEntity[];

  constructor({ name, items }: WellCollapseOptions) {
    this.name = name;
    this.items = items;
  }

  get id(): string {
    return this.name;
  }
}

export type WellItemOptions = CollapseItemOptions & {
  status: string;
};

export class WellItemEntity extends WellCollapseItemEntity {
  @observable status: string;

  constructor({ name, id, status }: WellItemOptions) {
    super({ name, id });

    this.status = status;

    makeObservable(this);
  }

  @action.bound
  setStatus(status: string): void {
    this.status = status;
  }
}
