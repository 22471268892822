import { action, flow, makeObservable, observable } from 'mobx';

import { storage } from 'src/packages/shared/consts/storage';
import { hasValue } from 'src/packages/shared/utils/common';
import { delay } from 'src/packages/shared/utils/delay';
import { getBaseURL } from 'src/packages/shared/utils/getBaseUrl';
import { isStringArray } from 'src/packages/shared/utils/is-string-array';
import { createPromiseController } from 'src/packages/shared/utils/promise-controller';

import type { WorkspaceStore } from '../Workspace.store';
import type { TSerializedTab } from 'src/entities/workspace/types';

import { ExternalTabClosingService } from './ExternalTabClosingService';
import { ExternalTabOpeningService } from './ExternalTabOpeningService';
import { FavoriteWellsSyncService } from './FavoriteWellsSyncService';
import { HealthCheckService } from './HealthCheckService';
import { MasterClosingService } from './MasterClosingService';
import { MasterDetectingService } from './MasterDetectingService';
import { PingPongService } from './PingPongService';
import { TabSyncingService } from './TabSyncingService';

const POPUP_ATTRS = 'popup=yes,width=4000,height=4000';
const EXTERNAL_TABS_KEY = 'external-tabs';

export class MasterWindowsManager {
  private readonly store: WorkspaceStore;
  private readonly pingPongService: PingPongService;
  private readonly externalTabClosingService: ExternalTabClosingService;
  private readonly externalTabOpeningService: ExternalTabOpeningService;
  private readonly masterDetectingService: MasterDetectingService;
  private readonly tabSyncingService: TabSyncingService;
  private readonly masterClosingService: MasterClosingService;
  private readonly healthCheckService: HealthCheckService;
  private readonly favoriteWellsSyncService: FavoriteWellsSyncService;
  // TODO: добавить для огранечения количества основных вкладок приложения
  // private readonly masterSearchTunnel: BroadcastTunnel<TMasterSearchMessage, TMasterDetectingMessage>;

  readonly windows: Partial<Record<string, Window>> = {};

  @observable isSessionRecoveringCompleted: boolean = false;
  @observable isTabsSyncModalOpen: boolean = false;
  @observable windowOpeningTimeout: number | null = null;

  constructor(store: WorkspaceStore) {
    this.store = store;
    this.healthCheckService = new HealthCheckService(store, this);
    this.pingPongService = new PingPongService(store, this);
    this.externalTabClosingService = new ExternalTabClosingService(store, this);
    this.externalTabOpeningService = new ExternalTabOpeningService(store);
    this.masterDetectingService = new MasterDetectingService(store);
    this.tabSyncingService = new TabSyncingService(store);
    this.masterClosingService = new MasterClosingService(store);
    this.favoriteWellsSyncService = new FavoriteWellsSyncService(store);

    makeObservable(this);
  }

  get id(): string {
    return this.store.id;
  }

  @action.bound
  openWindow(id: string): null | Window {
    const url = `${getBaseURL()}/tab/${id}`;

    const windowRef = window.open(url, id, POPUP_ATTRS);

    if (windowRef) {
      this.addWindowRef(id, windowRef);
    }

    this.store.addExternalTab(id);
    this.store.adjustActualTab();

    return windowRef;
  }

  @action.bound
  removeClosedTab(id: string): void {
    this.externalTabClosingService.removeClosedTab(id);
  }

  closeTab = (id: string): void => {
    this.externalTabClosingService.closeTab(id);
  };

  focusTab = (id: string): void => {
    this.windows[id]?.focus();
  };

  @action.bound
  addWindowRef(id: string, ref: Window): void {
    this.windows[id] = ref;
  }

  @action.bound
  getWindowRef(id: string): null | Window {
    return window.open('', id, POPUP_ATTRS);
  }

  @action.bound
  removeWindowRef(id: string): void {
    delete this.windows[id];
  }

  @flow.bound
  private async *recoverSession(tabs: string[]) {
    try {
      this.store.setExternalTabsRecoveringController(createPromiseController<boolean>());

      const shouldRecover = await this.store.externalTabsRecoveringController;

      yield;

      if (!shouldRecover) return;

      let tabsOpenedIndex: number = 0;
      let tabsOpeningFailed = false;

      for (const tab of tabs) {
        const targetTab = this.store.getTabById(tab);

        if (!targetTab) continue;

        const windowResponse = this.openWindow(targetTab.id);

        if (windowResponse === null) {
          tabsOpeningFailed = true;
          break;
        } else {
          tabsOpenedIndex += 1;
        }
      }

      if (!tabsOpeningFailed) return;

      await this.retrySessionRecovering(tabs.slice(tabsOpenedIndex));
      yield;
    } finally {
      this.store.setExternalTabsRecoveringController(null);
    }
  }

  getActualExternalTabs(): string[] {
    const externalTabs = storage.GET<string[]>(EXTERNAL_TABS_KEY);

    if (!isStringArray(externalTabs)) return [];

    const actualTabs = externalTabs.filter((id) => this.store.tabsIds.includes(id));

    if (actualTabs.length !== externalTabs.length) {
      storage.SET<string[]>(EXTERNAL_TABS_KEY, actualTabs);
    }

    return actualTabs;
  }

  sendFavoriteWellsList(list: number[]): void {
    this.favoriteWellsSyncService.sendFavoriteWells(list);
  }

  sendChangedTab(tab: TSerializedTab): void {
    this.tabSyncingService.sendChangedTab(tab);
  }

  @flow.bound
  async *openExternalTabs() {
    try {
      const actualExternalTabs = this.getActualExternalTabs();

      if (actualExternalTabs.length === 0) return;

      this.isTabsSyncModalOpen = true;
      this.pingPongService.pingExternalTabs(actualExternalTabs);

      await delay(2000);
      yield;

      this.store.responsedTabs.forEach((tabId) => {
        this.store.initExternalTab(tabId);
      });

      this.store.adjustActualTab();

      const notResponsedTabs: string[] = [];

      for (const tab of actualExternalTabs) {
        if (!this.store.responsedTabs.has(tab)) {
          notResponsedTabs.push(tab);
        }
      }

      this.isTabsSyncModalOpen = false;

      if (this.store.responsedTabs.size === actualExternalTabs.length) return;

      await this.recoverSession(notResponsedTabs);
      yield;
    } finally {
      this.isSessionRecoveringCompleted = true;
    }
  }

  @flow.bound
  private async *retrySessionRecovering(tabs: string[]): Promise<void> {
    try {
      this.store.setExternalTabsRetryRecoveringController(createPromiseController<boolean>());

      const shouldRetryRecovering = await this.store.externalTabsRetryRecoveringController;

      yield;

      if (!shouldRetryRecovering) return;

      for (const tab of tabs) {
        const targetTab = this.store.getTabById(tab);

        if (!targetTab) continue;

        this.openWindow(targetTab.id);
      }
    } finally {
      this.store.setExternalTabsRetryRecoveringController(null);
    }
  }

  init = (): void => {
    this.healthCheckService.init();
    this.masterClosingService.init();
  };

  dispose = (): void => {
    this.healthCheckService.dispose();
    this.pingPongService.dispose();
    this.externalTabClosingService.dispose();
    this.externalTabOpeningService.dispose();
    this.masterDetectingService.dispose();
    this.tabSyncingService.dispose();
    this.masterClosingService.dispose();
    this.favoriteWellsSyncService.dispose();

    if (hasValue(this.windowOpeningTimeout)) {
      clearTimeout(this.windowOpeningTimeout);
    }
  };
}
