import { requireService } from 'src/packages/di';
import { OperationalParametersWidgetEntity } from 'src/pages/dashboard/features/operational-parameters-widget/OperationalParametersWidget.entity';
import { OperationalParametersWidgetStore } from 'src/pages/dashboard/features/operational-parameters-widget/OperationalParametersWidget.store';
import { parseOperationalParametersInternalState } from 'src/pages/dashboard/features/operational-parameters-widget/parseOperationalParametersWidget';

import type { TSerializedWidget } from 'src/entities/workspace/types';

export function mapOperationalParametersWidget(
  widget: TSerializedWidget,
  index: number
): OperationalParametersWidgetEntity {
  const internalState = parseOperationalParametersInternalState(widget.internalState);
  const widgetEntity = new OperationalParametersWidgetEntity(
    {
      wellIndexType: internalState.wellIndexType,
      wellId: internalState.wellId,
      stateParams: internalState.stateParams,
      type: 'well-operational-params-widget',
      enableSound: internalState.enableSound,
    },
    {
      uuid: widget.uuid,
      isPinned: !!widget.isPinned,
      zIndex: index + 1,
      isFullscreen: widget.isFullscreen ?? false,
      position: {
        x: widget?.position?.x ?? 0,
        y: widget?.position?.y ?? 0,
      },
      size: {
        w: widget.size?.w ?? 59,
        h: widget.size?.h ?? 316,
      },
      groupId: widget.groupId,
      isGroupsDefaultOpened: false,
    }
  );

  if (internalState.wellId) {
    const storeService = requireService('widgetStoreService');

    const widgetStore = storeService.getStore<OperationalParametersWidgetEntity, OperationalParametersWidgetStore>(
      widgetEntity
    );

    if (widgetStore == null) {
      const newStore = new OperationalParametersWidgetStore(widgetEntity);
      newStore.initWidgetByWellId(internalState.wellId);

      storeService.setStore<OperationalParametersWidgetEntity, OperationalParametersWidgetStore>(
        widgetEntity,
        newStore
      );
    }
  }

  return widgetEntity;
}
