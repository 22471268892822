import type { ObjectListScreenEntity } from '../../objects-list-screen/ObjectsListScreen.entity';
import type { TRenderScreen, TSettingsScreenOptions } from '../SettingsScreenRendererService';

import { ObjectsListScreen } from '../../objects-list-screen/ObjectsListScreen';

export function renderObjectsListScreen(
  screen: ObjectListScreenEntity,
  options: TSettingsScreenOptions
): TRenderScreen {
  return () => <ObjectsListScreen stack={options.stack} screen={screen} />;
}
