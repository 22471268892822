import { requireService } from 'src/packages/di';

import type { WorkspaceStore } from '../Workspace.store';

import { FavoritesRefreshingService } from './FavoritesRefreshingService';
import { SessionRegistrationService } from './SessionRegistrationService';
import { TabTemplatesRefreshingService } from './TabTemplatesRefreshingService';
import { WorkspaceRefreshingService } from './WorkspaceRefreshingService';

export class SessionManager {
  readonly sessionRegistrationService: SessionRegistrationService;

  private readonly favoritesRefreshingService: FavoritesRefreshingService;
  private readonly workspaceRefreshingService: WorkspaceRefreshingService;
  private readonly tabTemplatesRefreshingService: TabTemplatesRefreshingService;

  constructor(workspace: WorkspaceStore, private readonly sessionService = requireService('sessionService')) {
    this.favoritesRefreshingService = new FavoritesRefreshingService(workspace);
    this.workspaceRefreshingService = new WorkspaceRefreshingService(workspace);
    this.sessionRegistrationService = new SessionRegistrationService(workspace);
    this.tabTemplatesRefreshingService = new TabTemplatesRefreshingService(workspace);
  }

  sendFavoritesChanged(): void {
    this.favoritesRefreshingService.sendFavoritesChanged();
  }

  sendWorkspaceChanged(): void {
    this.workspaceRefreshingService.sendWorkspaceChanged();
  }

  sendTabTemplatesChanged(): void {
    this.tabTemplatesRefreshingService.sendTabTemplatesChanged();
  }

  init = async (): Promise<void> => {
    this.favoritesRefreshingService.init();
    this.workspaceRefreshingService.init();
    this.sessionRegistrationService.init();
    this.tabTemplatesRefreshingService.init();
  };

  dispose = (): void => {
    this.favoritesRefreshingService.dispose();
    this.workspaceRefreshingService.dispose();
    this.sessionRegistrationService.dispose();
    this.tabTemplatesRefreshingService.dispose();

    this.sessionService.destroy();
  };
}
