import { goStorageAgent } from 'src/api/goStorageAgent';
import { throwApiError } from 'src/api/utils';
import { requireServiceAccessor } from 'src/packages/di';

type TFavoritesData = {
  favorites?: unknown;
  ownerUserId?: unknown;
};

type TFavoritesResponse = {
  id: number;
  status: string;
  objectType: string;
  data: {
    favorites?: unknown;
    ownerUserId?: unknown;
  };
  createdAt: number;
}[];

type TCreateFavoritesResponse = {
  id: number;
  objectType: string;
};

export class FavoritesApi {
  constructor(readonly auth = requireServiceAccessor('authService')) {}

  async getFavorites(): Promise<TFavoritesResponse> {
    try {
      const response = await goStorageAgent.get<TFavoritesResponse>(
        'object/list/GOdashboard_WellDirectoryPreferences',
        {
          params: {
            ownerUserId: this.auth().userInfo.sub,
          },
        }
      );

      return response.data;
    } catch (error) {
      throwApiError(error);
    }
  }

  async createFavotites(): Promise<TCreateFavoritesResponse> {
    try {
      const response = await goStorageAgent.post<TCreateFavoritesResponse>(
        'object/new/GOdashboard_WellDirectoryPreferences',
        {
          ownerUserId: this.auth().userInfo.sub,
          favorites: [],
        }
      );

      return response.data;
    } catch (error) {
      throwApiError(error);
    }
  }

  async updateFavorites(favoritesObjectId: number, favorites: number[]): Promise<TFavoritesData> {
    try {
      const response = await goStorageAgent.patch<TFavoritesData>(
        `object/GOdashboard_WellDirectoryPreferences/${favoritesObjectId}`,
        {
          ownerUserId: this.auth().userInfo.sub,
          favorites,
        }
      );

      return response.data;
    } catch (error) {
      throwApiError(error);
    }
  }
}
