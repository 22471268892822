import { Validation400ApiErrorWithCause } from 'src/app/errors';

import type { TRefQuery, TRefRest } from './types';
import type { AxiosError } from 'axios';

import { REF_QUERY_REG_EXP } from './consts';

export function isErrorWithLongMessage(e: unknown): e is AxiosError & { reason: { error: { longMessage: string } } } {
  if (!(e instanceof Validation400ApiErrorWithCause)) {
    return false;
  }

  const reason = e.reason;

  return (
    reason instanceof Object &&
    'error' in reason &&
    reason.error instanceof Object &&
    'longMessage' in reason.error &&
    typeof reason.error.longMessage === 'string'
  );
}

export const isDynamicJoin = (refQuery: TRefQuery | TRefRest): boolean => {
  const stringifiedRefQuery = JSON.stringify(refQuery);

  const isDynamic = !!stringifiedRefQuery.match(REF_QUERY_REG_EXP);

  return isDynamic;
};

export const isStringNumberOrBoolean = (value: unknown): value is string | number | boolean => {
  const types = ['string', 'number', 'boolean'];

  return types.includes(typeof value);
};
