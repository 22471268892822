import type { TRenderWidget, TWidgetRendererOptions } from './WidgetRendererService';
import type { WellAnalyseWidgetEntity } from '../../well-analyse-widget/WellAnalyseWidget.entity';

import { WellAnalyseWidgetWrapper } from '../../well-analyse-widget/WellAnalyseWidget';

export function renderWellAnalyseWidget(
  widget: WellAnalyseWidgetEntity,
  options: TWidgetRendererOptions
): TRenderWidget {
  return ({ onCreateWellAnalyseWidget }) => (
    <WellAnalyseWidgetWrapper entity={widget} options={options} onCreateWellAnalyseWidget={onCreateWellAnalyseWidget} />
  );
}
