import { throwApiError } from 'src/api/utils';
import { wellDirectoryServiceAgent } from 'src/api/well-directory-service-agent';

export type TFilterOptions = { [key: string]: number | number[] };

export class FilterWidgetApi {
  async fetchOptions(data: unknown): Promise<TFilterOptions> {
    try {
      const response = await wellDirectoryServiceAgent.post<TFilterOptions>('wells/filter/options', data);

      return response.data;
    } catch (error) {
      throwApiError(error);
    }
  }
}
