export function delay(ms: number): Promise<void>;
export function delay<T>(ms: number, resolveWith: T, cancellationToken?: AbortSignal, rejectWith?: Error): Promise<T>;

export function delay<T>(ms: number, resolveWith?: T, cancellationToken?: AbortSignal, rejectWith?: Error): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    function handleAbort() {
      reject(rejectWith);
    }

    cancellationToken?.addEventListener('abort', handleAbort);

    setTimeout(() => {
      cancellationToken?.removeEventListener('abort', handleAbort);
      resolve(resolveWith!);
    }, ms);
  });
}
