import { requireServiceAccessor } from 'src/packages/di';

import type { IServicesCollection } from 'src/packages/di';

import alert from '../assets/sound/alert.mp3';

import { SoundAlarmService } from './SoundAlarmService';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    soundAlert: SoundAlarmService;
  }
}

export function addSoundAlarmService(di: IServicesCollection) {
  const notifyService = di.requireService('notifications');
  const getI18 = requireServiceAccessor('i18');
  const soundAlarm = new SoundAlarmService(
    () =>
      notifyService.showWarningMessage(
        getI18().t('operationalParams:activateAlarmText'),
        getI18().t('operationalParams:activateAlarmTitle')
      ),
    alert
  );

  di.addSingleton('soundAlert', soundAlarm);
}
