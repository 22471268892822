import { toUnixTime } from 'src/packages/shared/utils/toUnixTime';
import { DateRangeFieldEntity } from 'src/pages/dashboard/features/controls/entities/DateRangeField.entity';
import { FilterMultiCombobox } from 'src/pages/dashboard/features/controls/entities/FilterMultiCombobox.entity';

import type { FilterWidgetEntity } from 'src/pages/dashboard/features/filter-widget/FilterWidget.entity';
import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

export function serializeWellsFilterTemplate(widget: FilterWidgetEntity): TSerializedWidgetTemplate {
  const listWidgetInternalState: {
    name: string;
    value: string | number | (number | string)[];
  }[] = [];

  for (const item of widget.items) {
    if (item instanceof FilterMultiCombobox) {
      listWidgetInternalState.push({
        name: item.attrName,
        value: item.value,
      });
    }

    if (item instanceof DateRangeFieldEntity) {
      if (item.startDate) {
        listWidgetInternalState.push({
          name: item.attrNames.startDate,
          value: toUnixTime(item.startDate),
        });
      }

      if (item.endDate) {
        listWidgetInternalState.push({
          name: item.attrNames.endDate,
          value: toUnixTime(item.endDate),
        });
      }
    }
  }

  return {
    uuid: widget.uuid,
    type: widget.type,
    groupId: widget.groupId,
    size: {
      w: widget.size.w,
      h: widget.size.h,
    },
    position: {
      x: widget.position.x,
      y: widget.position.y,
    },
    internalState: {
      isEditMode: true,
      state: widget.state,
      initialState: listWidgetInternalState.map((stateItem) => ({
        name: stateItem.name,
        value: Array.isArray(stateItem.value) ? stateItem.value.map((val) => val) : stateItem.value,
      })),
    },
  };
}
