import { action, computed, makeObservable } from 'mobx';

import { requireService } from 'src/packages/di';
import { languageSwitcherItems } from 'src/packages/language/consts';

import type { DevSettingsScreenEntity } from './DevSettingsScreen.entity';
import type { BaseSettingsScreen } from '../screen/BaseSettingsScreen';
import type { Stack } from 'src/packages/data/structures/Stack';
import type { LANGUAGES } from 'src/packages/language/consts';

export class DevSettingsScreenStore {
  readonly stack: Stack<BaseSettingsScreen>;
  readonly screenEntity: DevSettingsScreenEntity;

  constructor(
    stack: Stack<BaseSettingsScreen>,
    screen: DevSettingsScreenEntity,
    private readonly language = requireService('language')
  ) {
    this.stack = stack;
    this.screenEntity = screen;
    makeObservable(this);
  }

  @action.bound
  selectLanguage(lang: LANGUAGES): void {
    this.language.changePrimaryLanguage(lang);
  }

  get languages() {
    return Object.entries(languageSwitcherItems);
  }

  @computed
  get currentPrimaryLang() {
    return this.language.primary;
  }
}
