import { action, makeObservable } from 'mobx';

import { BroadcastReadTunnel } from 'src/packages/broadcast-tunnel/BroadcastReadTunnel';
import { getBaseURL } from 'src/packages/shared/utils/getBaseUrl';
import { createPromiseController } from 'src/packages/shared/utils/promise-controller';

import type { SlaveWindowsManager } from './SlaveWindowsManager';
import type { SingleTabStore } from '../SingleTab.store';
import type { TMasterClosedMessage } from 'src/pages/dashboard/features/workspace/types';

export class MasterRecoveringService {
  private readonly store: SingleTabStore;
  private readonly windowsManager: SlaveWindowsManager;
  private readonly masterClosingTunnel: BroadcastReadTunnel<TMasterClosedMessage>;

  constructor(store: SingleTabStore, windowsManager: SlaveWindowsManager) {
    this.store = store;
    this.windowsManager = windowsManager;

    this.masterClosingTunnel = new BroadcastReadTunnel((message): message is TMasterClosedMessage => {
      if (
        message?.type !== 'master-closed' ||
        message?.from?.type !== 'master' ||
        message?.from?.id !== this.windowsManager.master.id ||
        message?.to?.id !== this.store.tabId
      ) {
        return false;
      }

      return true;
    }, this.recoverMasterWindow);

    makeObservable(this);
  }

  private openMaster = (): null | Window => {
    const url = `${getBaseURL()}/`;

    return window.open(url, '_blank');
  };

  @action.bound
  recoverMasterWindow = async (): Promise<void> => {
    if (this.store.masterConnectionController) return;

    this.windowsManager.setMasterPonged(false);
    this.windowsManager.setMasterStatus(false);

    const controller = createPromiseController<boolean>();

    this.store.masterConnectionController = controller;

    const res = await this.store.masterConnectionController;

    if (res) {
      this.openMaster();
    } else {
      this.store.setMasterConnectionController(null);
    }
  };

  dispose = (): void => {
    this.masterClosingTunnel.disconnect();
  };
}
