import type { WidgetStateParams as ImageBroadcastWidgetParams } from '../../broadcast/types';
import type { GroupSelectStore } from '../../group-select/GroupSelect.store';
import type { WidgetsDisplayingPriorityManager } from '../../workbench/widgets-displaying-priority-manager';
import type { ParametersStateParams, WellIndexType, WidgetStateParams } from '@go-widgets/well-logs-widget';
import type { WidgetStateParams as AnalyseWidgetParams } from '@go-widgets/well-logs-widget/dist/AnalysisWidget/types';
import type { ReactNode } from 'react';
import type { TCreateWidgetOptions } from 'src/entities/tab/TabEntity';
import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';

type TRenderer = (widget: WidgetEntity, options: TWidgetRendererOptions) => TRenderWidget;

export type TWidgetRendererOptions = {
  groupSelectStore: GroupSelectStore;
  widgetsDisplayingPriorityManager: WidgetsDisplayingPriorityManager;
  onWidgetDelete(widget: WidgetEntity, requireConfirmation?: boolean): void;
};

type TRenderWidgetProps = {
  onCreateWellsFilterWidget(options?: TCreateWidgetOptions): void;
  onCreateWellLogsWidget(
    wellIndexType: WellIndexType,
    wellId: number | null,
    stateParams: Partial<WidgetStateParams> | null,
    options?: TCreateWidgetOptions
  ): void;
  onCreateWellAnalyseWidget(
    wellId: number | null,
    stateParams: Partial<AnalyseWidgetParams> | null,
    options?: TCreateWidgetOptions
  ): void;
  onCreateImageBroadcastWidget(wellId: number | null, stateParams: Partial<ImageBroadcastWidgetParams> | null): void;
  onCreateOperationalParametersWidget(
    wellIndexType: WellIndexType,
    wellId: number | null,
    enableSound: boolean,
    stateParams: Partial<ParametersStateParams> | null,
    options?: TCreateWidgetOptions
  ): void;
};

export type TRenderWidget = (props: TRenderWidgetProps) => ReactNode;

export class WidgetRendererService {
  private collection: Map<string, TRenderer> & {
    get<T extends WidgetEntity>(type: string): (widget: T, options: TWidgetRendererOptions) => TRenderWidget;
    set<T extends WidgetEntity>(
      type: string,
      renderer: (widget: T, options: TWidgetRendererOptions) => TRenderWidget
    ): void;
  } = new Map();

  renderWidget(widget: WidgetEntity, options: TWidgetRendererOptions): TRenderWidget {
    const renderer = this.collection.get(widget.type);

    if (!renderer) {
      console.error(`${widget.type} renderer not found`);
      throw new Error('renderer not found');
    }

    return renderer(widget, options);
  }

  registerRenderer<T extends WidgetEntity>(
    type: string,
    renderer: (widget: T, options: TWidgetRendererOptions) => TRenderWidget
  ): void {
    if (this.collection.has(type)) {
      console.error(`${type} renderer already registered`);
      throw new Error('renderer already registered');
    }

    this.collection.set(type, renderer);
  }
}
