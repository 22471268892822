import { parseWellLogsWidgetInternalState } from 'src/pages/dashboard/features/well-logs-widget/parseWellLogsWidget';
import { WellLogsWidgetEntity } from 'src/pages/dashboard/features/well-logs-widget/WellLogsWidget.entity';

import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

export function mapWellLogsTemplate(template: TSerializedWidgetTemplate, index: number): WellLogsWidgetEntity {
  const { size, position } = template;

  const internalState = parseWellLogsWidgetInternalState(template.internalState ?? {});

  const wellLogsWidget = new WellLogsWidgetEntity(
    {
      wellId: internalState.wellId,
      wellIndexType: internalState.wellIndexType,
      isEditMode: false,
      stateParams: internalState.stateParams ?? {},
      type: 'well-logs-widget',
    },
    {
      groupId: template.groupId || null,
      isGroupsDefaultOpened: false,
      uuid: template.uuid,
      isPinned: false,
      isFullscreen: false,
      zIndex: index + 1,
      size: {
        w: size.w,
        h: size.h,
      },
      position: {
        x: position.x,
        y: position.y,
      },
    }
  );

  return wellLogsWidget;
}
