import { requireService } from 'src/packages/di';

import type { WorkspaceStore } from '../Workspace.store';
import type { TDashboardReceivingMessage } from 'src/packages/session-service/SessionService';

export class FavoritesRefreshingService {
  private readonly workspace: WorkspaceStore;

  constructor(
    workspace: WorkspaceStore,
    private readonly sessionService = requireService('sessionService'),
    private readonly favoritesWells = requireService('favoritesWells')
  ) {
    this.workspace = workspace;
  }

  sendFavoritesChanged(): void {
    this.sessionService.sendMessage('FAVORITES-CHANGED');
  }

  private onFavoriteChange = async (message: TDashboardReceivingMessage): Promise<void> => {
    if (message !== 'FAVORITES-CHANGED') return;

    try {
      this.workspace.setFavoritesUpdating(true);

      await this.favoritesWells.loadFavoritesIds();
    } catch (error) {
      console.error(error);
    } finally {
      this.workspace.setFavoritesUpdating(false);
    }
  };

  init = (): void => {
    this.sessionService.subscribe(this.onFavoriteChange);
  };

  dispose = (): void => {
    this.sessionService.unsubscribe(this.onFavoriteChange);
  };
}
