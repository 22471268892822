import { isValidAppSection, type AppSection } from '@go-widgets/well-details-widget';

import type { TWellDetailsSerializedInternalState } from './WellDetailsWidget.entity';

export function parseWellDetailsWidgetInternalState(state: object): TWellDetailsSerializedInternalState {
  let wellId: number | null = null;
  let section: AppSection = 'info';
  let isEditMode = false;

  if ('section' in state && typeof state.section === 'string' && isValidAppSection(state.section)) {
    section = state.section;
  }

  if ('wellId' in state && typeof state.wellId === 'number') {
    wellId = state.wellId;
  }

  if ('isEditMode' in state && typeof state.isEditMode === 'boolean') {
    isEditMode = state.isEditMode;
  }

  return {
    wellId,
    section,
    isEditMode,
  };
}
