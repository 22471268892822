import { action, makeObservable, observable } from 'mobx';

import { THEMES } from 'src/app/theme/consts';
import { storage } from 'src/packages/shared/consts/storage';

const checkTheme = (value: string | null): value is THEMES => {
  if (!value) return false;
  if (['light', 'dark'].includes(value)) return true;
  return false;
};

export interface IThemeService {
  theme: THEMES;
  toggle(): void;
  select(theme: THEMES): void;
}

export class ThemeStore {
  @observable theme: THEMES;

  constructor() {
    const storedTheme = storage.GET<THEMES>('theme');
    const initTheme = checkTheme(storedTheme) ? storedTheme : THEMES.dark;
    this.theme = initTheme;

    makeObservable(this);
  }

  private _setTheme(theme: THEMES) {
    this.theme = theme;
    storage.SET('theme', theme);
  }

  @action.bound
  toggle() {
    this._setTheme(this.theme === THEMES.dark ? THEMES.light : THEMES.dark);
  }

  @action.bound
  select(theme: THEMES) {
    this._setTheme(theme);
  }
}
