import { action, makeObservable, observable } from 'mobx';

export class VersionsStore {
  @observable isOpened: boolean = false;
  @observable frontendV: string = process.env.REACT_APP_COMMIT_TAG
    ? process.env.REACT_APP_COMMIT_TAG
    : `commit/${process.env.REACT_APP_COMMIT_SHORT_SHA}`;
  @observable backendV: string = '';
  @observable cacheV: string = '';
  @observable orchestratorV: string = '';

  constructor() {
    makeObservable(this);
  }

  @action.bound
  setIsOpened(value: boolean): void {
    this.isOpened = value;
  }
}
