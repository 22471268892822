import { CopyAltLight as CopyIcon, TrashLight as TrashIcon } from '@profgeosoft-ui/icons';
import { Button, Input, Textarea } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  MAX_TAB_TEMPLATE_DESCRIPTION_LENGTH,
  MAX_TAB_TEMPLATE_NAME_LENGTH,
} from 'src/packages/shared/consts/tab-template';
import { RemoveObjectModal } from 'src/pages/dashboard/features/remove-object-modal/RemoveObjectModal';

import type { TabTemplateDetailsEntity } from './TabTemplateDetails.entity';

import { TabTemplateDetailsStore } from './TabTemplateDetails.store';

import styles from './TabTemplateDetails.module.scss';

type Props = {
  screen: TabTemplateDetailsEntity;
};

export const TabTemplateDetails = observer(function TabTemplateDetails({ screen }: Props) {
  const [name, setName] = useState(screen.template.data.template.name);
  const [desc, setDesc] = useState(screen.template.data.template.description ?? '');

  const [store] = useState(() => new TabTemplateDetailsStore(screen));

  const { t } = useTranslation();

  const {
    onTemplateDelete,
    onTemplateNameChange,
    onTemplateDescChange,
    onDuplicateToPersonal,
    onDuplicateToPublic,
    onDeleteTemplateAccept,
    onDeleteTemplateCancel,
    deleteTemplateController,
    templateData,
    widgetList,
    isPublic,
  } = store;

  useEffect(() => {
    setName(templateData.template.name);
  }, [templateData.template.name]);

  useEffect(() => {
    setDesc(templateData.template.description ?? '');
  }, [templateData.template.description]);

  const sortedWidgetList = widgetList
    .map((widget) => t(widget))
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  return (
    <article className={styles.column}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <p className={styles.headerTitle}>{t('settings:templateDetails')}</p>
          <div className={styles.headerButton}>
            <Button
              variant="flat"
              icon={<TrashIcon className={styles.icon} />}
              onClick={onTemplateDelete}
              className={styles.iconButton}
            />
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.templateDetails}>
            <Input
              value={name}
              maxLength={MAX_TAB_TEMPLATE_NAME_LENGTH}
              onChange={(e) => setName(e.target.value)}
              onBlur={(e) => onTemplateNameChange(e.target.value.trim())}
              fieldClassName={clsx(styles.field, styles.fieldName)}
              inputClassName={styles.input}
              allowClear={false}
            />
            <Textarea
              rows={2}
              value={desc}
              maxLength={MAX_TAB_TEMPLATE_DESCRIPTION_LENGTH}
              fieldClassName={clsx(styles.field, styles.fieldDesc)}
              className={styles.descFieldWrapper}
              onChange={setDesc}
              onBlur={(e) => onTemplateDescChange(e.target.value.trim())}
            />
            <Button
              variant="flat"
              icon={<CopyIcon />}
              onClick={isPublic ? onDuplicateToPersonal : onDuplicateToPublic}
              className={styles.duplicateButton}
            >
              {isPublic ? t('settings:duplicateToPersonal') : t('settings:duplicateToPublic')}
            </Button>
          </div>

          <div className={styles.widgets}>
            <p className={styles.widgetsHeader}>{t('settings:Widgets.title')}:</p>
            <ul className={styles.widgetsList}>
              {sortedWidgetList.map((widget, ind) => (
                <li key={ind} className={styles.widget}>
                  <div className={styles.widgetNumber}>{ind + 1}</div>
                  {widget}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <RemoveObjectModal
        title={t('modals:RemoveWidgetTemplateModal.title')}
        desc={t('modals:RemoveWidgetTemplateModal.desc')}
        removeButtonText={t('common:Buttons.delete')}
        isOpened={!!deleteTemplateController}
        onConfirm={onDeleteTemplateAccept}
        onCancel={onDeleteTemplateCancel}
      />
    </article>
  );
});
