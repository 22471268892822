import type { BaseSettingsScreen } from './BaseSettingsScreen';
import type { ReactNode } from 'react';
import type { Stack } from 'src/packages/data/structures/Stack';

type TRenderer = (screen: BaseSettingsScreen, options: TSettingsScreenOptions) => TRenderScreen;

export type TSettingsScreenOptions = {
  stack: Stack<BaseSettingsScreen>;
};

type TRenderWidgetProps = {
  widgetType?: string;
  widgetSubtype?: string;
  templateType?: 'widget' | 'tab';
};

export type TRenderScreen = (props: TRenderWidgetProps) => ReactNode;

export class SettingsScreenRendererService {
  private collection: Map<string, TRenderer> & {
    get<T extends BaseSettingsScreen>(type: string): (screen: T, options: TSettingsScreenOptions) => TRenderScreen;
    set<T extends BaseSettingsScreen>(
      type: string,
      renderer: (screen: T, options: TSettingsScreenOptions) => TRenderScreen
    ): void;
  } = new Map();

  renderScreen(screen: BaseSettingsScreen, options: TSettingsScreenOptions): TRenderScreen {
    const renderer = this.collection.get(screen.type);

    if (!renderer) {
      console.error(`${screen} renderer not found`);
      throw new Error('renderer not found');
    }

    return renderer(screen, options);
  }

  registerRenderer<T extends BaseSettingsScreen>(
    type: string,
    renderer: (screen: T, options: TSettingsScreenOptions) => TRenderScreen
  ): this {
    if (this.collection.has(type)) {
      console.error(`${type} renderer already registered`);
      throw new Error('renderer already registered');
    }

    this.collection.set(type, renderer);

    return this;
  }
}
