import type { TRenderWidget, TWidgetRendererOptions } from './WidgetRendererService';
import type { OperationalParametersWidgetEntity } from '../../operational-parameters-widget/OperationalParametersWidget.entity';

import { OperationalParametersWidgetWrapper } from '../../operational-parameters-widget/OperationalParametersWidget';

export function renderOperationalParametersWidget(
  widget: OperationalParametersWidgetEntity,
  options: TWidgetRendererOptions
): TRenderWidget {
  return () => <OperationalParametersWidgetWrapper entity={widget} options={options} />;
}
