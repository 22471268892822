import { requireService } from 'src/packages/di';

import type { WorkspaceStore } from '../Workspace.store';
import type { TDashboardReceivingMessage } from 'src/packages/session-service/SessionService';

export class TabTemplatesRefreshingService {
  private readonly workspace: WorkspaceStore;

  constructor(
    workspace: WorkspaceStore,
    private readonly sessionService = requireService('sessionService'),
    private readonly tabTemplatesService = requireService('tabTemplates')
  ) {
    this.workspace = workspace;
  }

  sendTabTemplatesChanged(): void {
    this.sessionService.sendMessage('TAB-TEMPLATES-CHANGED');
  }

  private onTabTemplatesChange = async (message: TDashboardReceivingMessage): Promise<void> => {
    if (message !== 'TAB-TEMPLATES-CHANGED') return;

    try {
      await this.tabTemplatesService.loadAllTemplates();
    } catch (error) {
      console.error(error);
    }
  };

  init = (): void => {
    this.sessionService.subscribe(this.onTabTemplatesChange);
  };

  dispose = (): void => {
    this.sessionService.unsubscribe(this.onTabTemplatesChange);
  };
}
