import { DoneRoundLight as CheckIcon, CloseRoundLight as CloseIcon } from '@profgeosoft-ui/icons';
import { Button, Modal, Textarea, Input } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  MAX_TAB_TEMPLATE_DESCRIPTION_LENGTH,
  MAX_TAB_TEMPLATE_NAME_LENGTH,
} from 'src/packages/shared/consts/tab-template';

import { SaveWithoutChangesModal } from '../save-without-changes-modal/SaveWithoutChangesModal';

import styles from './SaveTabAsTemplateModal.module.scss';

type Props = {
  isOpened: boolean;
  tabName: string;
  onClose(): void;
  onSave(name: string, description: string): void;
};

export const SaveTabAsTemplateModal = observer(function SaveTabAsTemplateModal({
  isOpened,
  tabName,
  onClose,
  onSave,
}: Props) {
  const [isApproveCancelModalOpened, setApproveCancelModalOpened] = useState(false);

  const [name, setName] = useState(tabName);
  const [description, setDescription] = useState('');

  const [nameError, setNameError] = useState(false);

  const { t } = useTranslation();

  const handleCloseApproveModal = () => {
    setApproveCancelModalOpened(false);
  };

  const handleOpenApproveModal = () => {
    setApproveCancelModalOpened(true);
  };

  const handleCancel = () => {
    setName('');
    setDescription('');
    setApproveCancelModalOpened(false);
    onClose();
  };

  const handleSave = () => {
    if (!name.length) {
      setNameError(true);
      return;
    }

    onSave(name, description);
    onClose();
    setName('');
    setDescription('');
  };

  const handleChangeName = (name: string) => {
    setNameError(false);
    setName(name);
  };

  return (
    <>
      <Modal
        open={isOpened}
        onClickOutside={() => {}}
        onClose={onClose}
        className={styles.wrapper}
        closeIcon={false}
        headerClassName={styles.header}
        title={<h2 className={styles.header}>{t('common:Tab.createNewTabTemplate')}</h2>}
      >
        <div className={styles.inputsWrapper}>
          <div>
            <p className={styles.inputsText}>{t('common:Tab.inputTemplateName')}</p>
            <Input
              maxLength={MAX_TAB_TEMPLATE_NAME_LENGTH}
              fieldClassName={clsx(styles.nameInput, nameError && styles.inputError)}
              value={name}
              onChange={(e) => handleChangeName(e.currentTarget.value)}
            />
          </div>
          <div>
            <p className={styles.inputsText}>{t('common:Tab.inputTemplateDescription')}</p>
            <Textarea
              className={styles.inputWrapper}
              fieldClassName={styles.descriptionInput}
              maxLength={MAX_TAB_TEMPLATE_DESCRIPTION_LENGTH}
              value={description}
              onChange={(value) => setDescription(value)}
            />
            <p className={styles.inputsText}>{t('common:Tab.maxSymbolCount', { count: 85 })}</p>
          </div>
        </div>
        <div className={styles.buttonsWrapper}>
          <Button variant="danger" className={styles.button} onClick={handleOpenApproveModal} icon={<CloseIcon />}>
            {t('common:Buttons.cancel')}
          </Button>
          <Button
            variant="success"
            disabled={nameError}
            className={styles.button}
            onClick={handleSave}
            icon={<CheckIcon />}
          >
            {t('common:Buttons.save')}
          </Button>
        </div>
      </Modal>
      <SaveWithoutChangesModal
        isOpened={isApproveCancelModalOpened}
        onDeleteCancel={handleCloseApproveModal}
        onDeleteAccept={handleCancel}
      />
    </>
  );
});
