import { parseWellImageBroadcastWidgetInternalState } from 'src/pages/dashboard/features/image-broadcast-widget/parseImageBroadcastWidget';

import type { TSerializedWidget } from 'src/entities/workspace/types';
import type { ImageBroadcastWidgetEntity } from 'src/pages/dashboard/features/image-broadcast-widget/ImageBroadcastWidget.entity';

import { baseWidgetApplyChanges } from './baseWidgetApplyChanges';

export function imageBroadcastApplyChanges(
  widget: ImageBroadcastWidgetEntity,
  changes: TSerializedWidget,
  index: number
) {
  baseWidgetApplyChanges(widget, changes, index);

  const internalState = parseWellImageBroadcastWidgetInternalState(changes.internalState);

  widget.setWellId(internalState.wellId);
  widget.setStateParams(internalState.stateParams);
}
