import type { TRenderWidget, TWidgetRendererOptions } from './WidgetRendererService';
import type { ImageBroadcastWidgetEntity } from '../../image-broadcast-widget/ImageBroadcastWidget.entity';

import { ImageBroadcastWidgetWrapper } from '../../image-broadcast-widget/ImageBroadcastWidget';

export function renderImageBroadcastWidget(
  widget: ImageBroadcastWidgetEntity,
  options: TWidgetRendererOptions
): TRenderWidget {
  return ({ onCreateImageBroadcastWidget }) => (
    <ImageBroadcastWidgetWrapper
      entity={widget}
      options={options}
      onCreateImageBroadcastWidget={onCreateImageBroadcastWidget}
    />
  );
}
