import { makeObservable, observable, action, computed } from 'mobx';

import type { IRestrictions, TOnChangeComboboxInstructions, TOption } from '../types';
import type { TDictObject, TJoinResponse } from 'src/packages/directories/types';

import { Combobox } from '../abstract-control-entities';

type TRegularComboBoxData = {
  placeholder?: string;
  formElementRefId: string;
  fieldId: string;
  objectName?: string;
  required: boolean;
  refObjectType?: string;
  refObjectAttr?: string;
  initialValue?: string | number;
  restrictions: IRestrictions;
  onChangeInstructions?: TOnChangeComboboxInstructions;
  refObjectFilterByFields?: {
    [key: string]: string;
  };
  directory: TDictObject[] | TJoinResponse[] | null;
};

export class RegularComboBox extends Combobox<string | number | null> {
  readonly required: boolean;

  @observable initialValue: string | number | null = null;
  @observable value: string | number | null;

  constructor(data: TRegularComboBoxData) {
    super(data);
    this.required = data.required;
    this.value = data.initialValue || null;

    makeObservable(this);
  }

  @computed
  get options(): TOption[] {
    return this.serializeDataToOptions();
  }

  @action.bound
  setValue(value: number | string | null, setValueAsInitial?: boolean): void {
    this.invalidValue = null;
    this.value = value;

    if (setValueAsInitial) {
      this.setInitialValue(value);
    }
  }

  @action.bound
  setInitialValue(value: string | number | null): void {
    this.initialValue = value;
  }

  @action.bound
  returnInitialValue(): void {
    this.value = this.initialValue;
  }

  @action.bound
  tryToSetRawValue(value: unknown, setValueAsInitial?: boolean): boolean {
    if (typeof value === 'string' && !isNaN(Number(value))) {
      this.setValue(Number(value), setValueAsInitial);
      return true;
    }
    if (value === 'null' || value === null || value === undefined) {
      this.setValue(null, setValueAsInitial);
      return true;
    }
    if (typeof value === 'number' && !Number.isNaN(value)) {
      this.setValue(value, setValueAsInitial);
      return true;
    }
    if (value !== null && value !== undefined && !Number.isNaN(value)) {
      this.invalidValue = value.toString();
    }
    return false;
  }

  @action.bound
  clearItem(): void {
    this.value = null;
    this.clearError();
  }

  @action.bound
  clearInitialValue(): void {
    this.initialValue = null;
  }

  checkIsReady(): boolean {
    if (!!this.errorText) return false;
    return !!this.value;
  }

  @action.bound
  clearError(): void {
    this.errorText = undefined;
  }

  hasErrors(): boolean {
    return !!this.errorText;
  }

  @action.bound
  validate(): boolean {
    if (!this.value && this.restrictions.required) {
      this.errorText = 'form:Errors.required';
    }

    return this.hasErrors();
  }
}
