import { HorizontalPilotCustom } from '@profgeosoft-ui/icons';
import { ActionsBar, RadioGroup, Tooltip } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { ImageBroadcastingWidgetState } from '../ImageBroadcastingWidgetState';

type Props = {
  widgetState: ImageBroadcastingWidgetState;
};

export const WellboreSelect = observer(function WellboreSelect({ widgetState }: Props) {
  const { t } = useTranslation('imageBroadcast');

  if (widgetState.wellbores.list.length === 0) {
    return <>{t('imageBroadcast:noBorehole')}</>;
  }
  function onSelect(id: number) {
    const bore = widgetState.wellbores.list.find((obj) => obj.id === id);
    if (bore) {
      widgetState.setWellbore(bore);
    }
  }
  const selected = widgetState.selectedWellbore?.id ?? null;

  return (
    <RadioGroup
      onChange={(value) => onSelect(Number(value))}
      value={selected}
      items={widgetState.wellbores.list.map((bore) => ({
        label: bore.name,
        value: bore.id,
      }))}
    />
  );
});

export const WellboreSelectPanel = observer(function WellboreSelectPanel({ widgetState }: Props) {
  const { t } = useTranslation('imageBroadcast');
  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setTooltipOpen(false);
    }
  }, [open]);

  return (
    <Tooltip
      open={tooltipOpen && !open}
      onOpenChange={(e) => setTooltipOpen(e)}
      placement="left"
      title={t('wellboreSelection')}
    >
      <ActionsBar.Item active={open} onClick={() => setOpen(true)}>
        <Tooltip
          open={open}
          title={<WellboreSelect widgetState={widgetState} />}
          trigger={['click']}
          onOpenChange={(e) => setOpen(e)}
          placement="left"
        >
          <HorizontalPilotCustom />
        </Tooltip>
      </ActionsBar.Item>
    </Tooltip>
  );
});
