import { useCallback, useState } from 'react';

import type { Ref } from 'react';

export function useRefs<T, K = string | number>() {
  const [refs] = useState(() => new Map<K, T | null>());

  const refAt = useCallback(
    <U extends T>(key: K): Ref<U> => {
      if (!refs.has(key)) {
        refs.set(key, null);
      }

      return (value: U) => {
        refs.set(key, value);
      };
    },
    [refs]
  );

  const valueAt = useCallback(
    (key: K): T | null => {
      return refs.get(key) || null;
    },
    [refs]
  );

  const clearRefs = useCallback(() => {
    refs.clear();
  }, [refs]);

  return { refAt, valueAt, clearRefs };
}
