import type { Stack } from 'src/packages/data/structures/Stack';
import type { TTabTemplateWithGSProps } from 'src/pages/dashboard/features/workspace/types';

import { BaseSettingsScreen } from '../screen/BaseSettingsScreen';

export class TabTemplateDetailsEntity extends BaseSettingsScreen {
  readonly template: TTabTemplateWithGSProps;
  readonly type = 'tab-template-details';

  constructor(stack: Stack<BaseSettingsScreen>, template: TTabTemplateWithGSProps) {
    super(stack);

    this.template = template;
  }
}
