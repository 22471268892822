import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';
import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

type TMapper = (widget: TSerializedWidgetTemplate, index: number) => WidgetEntity;

type TSerializer = (widget: WidgetEntity) => TSerializedWidgetTemplate;

export interface IWidgetTemplateSerializerService {
  map(widget: TSerializedWidgetTemplate, index: number): WidgetEntity;
  registerMapper<T extends WidgetEntity>(
    type: string,
    mapper: (widget: TSerializedWidgetTemplate, index: number) => T
  ): this;
  serialize(widget: WidgetEntity): TSerializedWidgetTemplate;
  registerSerializer<T extends WidgetEntity>(type: string, serializer: (widget: T) => TSerializedWidgetTemplate): this;
}

export class WidgetTemplateSerializerService {
  private mappersCollection: Map<string, TMapper> = new Map();
  private serializersCollection: Map<string, TSerializer> & {
    get<T extends WidgetEntity>(type: string): (widget: T) => TSerializedWidgetTemplate;
    set<T extends WidgetEntity>(type: string, serializer: (widget: T) => TSerializedWidgetTemplate): void;
  } = new Map();

  serialize(widget: WidgetEntity): TSerializedWidgetTemplate {
    const serializer = this.serializersCollection.get(widget.type);

    if (!serializer) {
      console.error(widget.type);
      throw new Error('widget template seralizer does not exist');
    }

    return serializer(widget);
  }

  map(widget: TSerializedWidgetTemplate, index: number): WidgetEntity {
    const mapper = this.mappersCollection.get(widget.type);

    if (!mapper) {
      console.error(widget.type);
      throw new Error('widget template mapper does not exist');
    }

    return mapper(widget, index);
  }

  registerSerializer<T extends WidgetEntity>(type: string, serializer: (widget: T) => TSerializedWidgetTemplate): this {
    if (this.serializersCollection.has(type)) {
      console.error(type);
      throw new Error('widget template serializer already registered');
    }

    this.serializersCollection.set<T>(type, serializer);

    return this;
  }

  registerMapper<T extends WidgetEntity>(
    type: string,
    mapper: (widget: TSerializedWidgetTemplate, index: number) => T
  ): this {
    if (this.mappersCollection.has(type)) {
      console.error(type);
      throw new Error('widget template mapper already registered');
    }

    this.mappersCollection.set(type, mapper);

    return this;
  }
}
