import type { TSerializedWidget } from 'src/entities/workspace/types';
import type {
  TWellLogsSerializedInternalState,
  WellLogsWidgetEntity,
} from 'src/pages/dashboard/features/well-logs-widget/WellLogsWidget.entity';

export function serializeWellLogsWidget(widget: WellLogsWidgetEntity): TSerializedWidget {
  const internalState: TWellLogsSerializedInternalState = {
    wellIndexType: widget.wellIndexType,
    isEditMode: false,
    wellId: widget.wellId,
    stateParams: {
      templateId: widget.stateParams.templateId,
      wellboreId: widget.stateParams.wellboreId,
      autoScroll: widget.stateParams.autoScroll,
      trackInfoVisible: widget.stateParams.trackInfoVisible,
      infoPanels: widget.stateParams.infoPanels,
      timeZone: widget.stateParams.timeZone,
      zoom: widget.stateParams.zoom,
      indexScroll: widget.stateParams.indexScroll,
    },
  };

  return {
    wellId: widget.wellId,
    uuid: widget.uuid,
    type: widget.type,
    groupId: widget.groupId,
    size: {
      w: widget.size.w,
      h: widget.size.h,
    },
    position: {
      x: widget.position.x,
      y: widget.position.y,
    },
    isFullscreen: widget.isFullscreen,
    internalState,
  };
}
