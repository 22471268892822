import { requireService } from 'src/packages/di';
import { FilterMultiCombobox } from 'src/pages/dashboard/features/controls/entities/FilterMultiCombobox.entity';

import type { TFilterMultiComboboxData } from 'src/pages/dashboard/features/controls/entities/FilterMultiCombobox.entity';
import type { TComboboxRaw } from 'src/pages/dashboard/features/controls/types';

type TMulticomboboxSerializerOptions = {
  item: TComboboxRaw;
};

export const mapFilterMulticombobox = ({ item }: TMulticomboboxSerializerOptions): FilterMultiCombobox => {
  const directories = requireService('directories');
  const i18 = requireService('i18');

  const data: TFilterMultiComboboxData = {
    formElementRefId: item.attrName,
    fieldId: item.fieldId,
    useInMainProgressBar: !!item.useInMainProgressBar,
    required: !!item.required,
    enableIf: item.enableIf,
    visuallyDisabled: item.visuallyDisabled,
    calculatedValue: item.calculatedValue,
    refObjectAttr: item.refObjectAttr,
    refObjectType: item.refObjectType,
    onChangeInstructions: item.onChange,
    attrName: item.attrName,
    refObjectFilterByFields: item.refObjectFilterByFields,
    restrictions: { required: !!item.required },
    directory: directories.getObject(item.refObjectType),
    refQuery: 'refQuery' in item ? item.refQuery : undefined,
    label: i18.t(`Labels:${item.fieldId}.label`, { defaultValue: '' }),
    placeholder: i18.t(`Labels:${item.fieldId}.placeholder`, { defaultValue: '' }),
  };

  if (item.control === 'MultiComboBox') {
    data.hasHardcodedValues = item.hasValues;
    data.options = item.values;
  }

  return new FilterMultiCombobox(data);
};
