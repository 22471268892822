import type { TTemplateValue } from '../request-builder/RequestBuilder';

import { assert } from '../shared/utils/assert';

const TEMPLATE_REGEXP = /([$%@])?{([0-9A-Za-z.-_]+)}/g;

export interface IDataSource {
  hasKey(key: string): boolean;
  getValue(key: string): unknown;
}

export function isTemplateValue(template: unknown): template is TTemplateValue {
  if (
    typeof template === 'string' ||
    (!!template &&
      typeof template === 'object' &&
      'value' in template &&
      typeof template.value === 'string' &&
      'type' in template &&
      typeof template.type === 'string')
  ) {
    return true;
  }

  return false;
}

export class ObjectDataSource implements IDataSource {
  constructor(private readonly source: { [key: string]: unknown }) {}

  hasKey(key: string): boolean {
    return key in this.source;
  }

  getValue(key: string) {
    assert(key in this.source);

    const value = this.source[key];

    assert(typeof value !== 'undefined');

    return value;
  }
}

export class ComputedDataSource implements IDataSource {
  constructor(readonly getter: () => { [key: string]: unknown }) {
    this.getter = getter;
  }

  hasKey(key: string): boolean {
    return key in this.getter();
  }

  getValue(key: string) {
    const value = this.getter()[key];

    assert(typeof value !== 'undefined');

    return value;
  }
}

export class RequestBuilderSettings {
  dataSources = new Set<TDataSourceDeclaration>();

  useDataSource(dataSource: IDataSource, { order = 1, prefix = '' }: TDataSourceParams = {}): this {
    this.dataSources.add({ dataSource, params: { order, prefix } });
    return this;
  }
}

type TDataSourceDeclaration = { dataSource: IDataSource; params: TDataSourceParams };
type TDataSourceParams = { prefix?: string; order?: number };

export class TemplateValueBuilder {
  private readonly settings = new RequestBuilderSettings();

  configure(callback: (settings: RequestBuilderSettings) => void): this {
    callback(this.settings);
    return this;
  }

  static isTemplateValue(value: TTemplateValue): boolean {
    let _value = typeof value === 'string' ? value : value.value;

    return [..._value.matchAll(TEMPLATE_REGEXP)].length > 0;
  }

  build(template: TTemplateValue): {} | null {
    let _template = typeof template === 'string' ? template : template.value;
    const isArray = typeof template !== 'string' && template.type === 'array';

    const matches = [..._template.matchAll(TEMPLATE_REGEXP)];

    let result: {} | null = null;

    if (isArray || matches.length === 1) {
      const [, sign, name] = matches[0];

      result = this.getValueFromDataSource(name, sign);
    } else if (matches.length > 1) {
      const res: unknown[] = [];

      for (const [, sign, name] of matches) {
        res.push(this.getValueFromDataSource(name, sign));
      }

      result = res.join(' ');
    }

    return result;
  }

  private getValueFromDataSource(key: string, prefix?: string): {} | null {
    for (const source of this.settings.dataSources) {
      if (source.dataSource.hasKey(key)) {
        if ((source.params.prefix && prefix && source.params.prefix === prefix) || !source.params.prefix) {
          const value = source.dataSource.getValue(key);

          assert(typeof value !== 'undefined', 'Value not found in source');

          return value;
        }
      }
    }

    return null;
  }
}
