import { requireService } from 'src/packages/di';

import { UserService } from './user-service';

export namespace Auth {
  export type UserInfo = {
    name?: string;
    preferred_username: string;
    email: string;
    family_name?: string;
    given_name?: string;
    sub: string;
  };

  export const isUserInfo = (object: unknown): object is UserInfo =>
    typeof object === 'object' && !!object && 'sub' in object && 'preferred_username' in object;
}

export interface IAuthService {
  userInfo: Auth.UserInfo;
  logout(): void;
}

export class AuthStore {
  readonly userInfo: Auth.UserInfo;

  constructor(userInfo: Auth.UserInfo, private readonly notifications = requireService('notifications')) {
    this.notifications = notifications;
    this.userInfo = userInfo;
  }

  async logout(): Promise<void> {
    try {
      await UserService.logout();
    } catch (error) {
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToSignOut');
    } finally {
      window.location.reload();
    }
  }
}
