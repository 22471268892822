import type { ILanguageService } from './ILanguageService';
import type { IServicesCollection } from 'src/packages/di';

import { LanguageStore } from './LanguageStore';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    language: ILanguageService;
  }
}

export const addLanguageService = (di: IServicesCollection) => {
  const service = new LanguageStore();
  service.init();

  di.addSingleton('language', service);
};
