import { action, makeObservable } from 'mobx';

import type { Template, TemplatesManager, UserSettingsManager, WellIndexType } from '@go-widgets/well-logs-widget';
import type { Stack } from 'src/packages/data/structures/Stack';

import { BaseSettingsScreen } from '../screen/BaseSettingsScreen';
import { WidgetTemplateDetailsEntity } from '../widget-template-details/WidgetTemplateDetails.entity';

export class WidgetTemplatesListScreenEntity extends BaseSettingsScreen {
  readonly widgetSubType: WellIndexType;
  readonly type = 'widget-templates-list';

  constructor(stack: Stack<BaseSettingsScreen>, widgetSubType: WellIndexType) {
    super(stack);

    this.widgetSubType = widgetSubType;

    makeObservable(this);
  }

  @action.bound
  createWidgetTemplateDetails(
    template: Template,
    userSettingsManager: UserSettingsManager,
    templatesManager: TemplatesManager,
    indexType: WellIndexType
  ): void {
    const newChildren = new WidgetTemplateDetailsEntity(
      this.stack,
      template,
      userSettingsManager,
      templatesManager,
      indexType
    );

    this.stack.push(newChildren);
  }
}
