import type { INotificationsService } from './NotificationsStore';
import type { IServicesCollection } from 'src/packages/di';

import { NotificationsStore } from './NotificationsStore';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    notifications: INotificationsService;
  }
}

export function addNotificationsStore(di: IServicesCollection) {
  di.addSingleton('notifications', new NotificationsStore());
}
