import { ImageBroadcastWidgetEntity } from 'src/pages/dashboard/features/image-broadcast-widget/ImageBroadcastWidget.entity';
import { parseWellImageBroadcastWidgetInternalState } from 'src/pages/dashboard/features/image-broadcast-widget/parseImageBroadcastWidget';

import type { TSerializedWidget } from 'src/entities/workspace/types';

export function mapImageBroadcastWidget(widget: TSerializedWidget, index: number): ImageBroadcastWidgetEntity {
  const internalState = parseWellImageBroadcastWidgetInternalState(widget.internalState);

  const newWidget = new ImageBroadcastWidgetEntity(
    {
      wellId: internalState.wellId,
      stateParams: internalState.stateParams,
      type: 'image-broadcast-widget',
    },
    {
      uuid: widget.uuid,
      isPinned: !!widget.isPinned,
      zIndex: index + 1,
      isFullscreen: widget.isFullscreen ?? false,
      position: {
        x: widget?.position?.x ?? 0,
        y: widget?.position?.y ?? 0,
      },
      size: {
        w: widget.size?.w ?? 59,
        h: widget.size?.h ?? 316,
      },
      groupId: widget.groupId,
      isGroupsDefaultOpened: false,
    }
  );

  return newWidget;
}
