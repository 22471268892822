import type { IWidgetStoreService } from './WidgetStoreService';
import type { IServicesCollection } from 'src/packages/di';

import { WidgetStoreService } from './WidgetStoreService';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    widgetStoreService: IWidgetStoreService;
  }
}

export function addWidgetStoreService(di: IServicesCollection) {
  di.addSingleton('widgetStoreService', new WidgetStoreService());
}
