import { action, makeObservable, observable } from 'mobx';

import { BaseWidgetEntityWithWellId } from 'src/entities/widget/BaseWidgetEntityWithWellId';
import { requireService } from 'src/packages/di';

import type { AppSection, WellDetailsWidgetState } from '@go-widgets/well-details-widget';
import type { SizeBoundaries, TWidgetOptions } from 'src/entities/widget/WidgetEntity';

interface WellDetailsOptions {
  wellId: number | null;
  section: AppSection;
  isEditMode: boolean;
}

export type TWellDetailsSerializedInternalState = {
  section: AppSection;
  wellId: number | null;
  isEditMode: boolean;
};

export class WellDetailsWidgetEntity extends BaseWidgetEntityWithWellId {
  readonly sizeBoundaries: SizeBoundaries = {
    minInPixels: {
      w: 350,
      h: 612,
    },
    maxInPixels: null,
    min: {
      w: 47,
      h: 66,
    },
    max: {
      w: 256,
      h: 100,
    },
    useColsCoeffsFor: { minW: false, maxW: true, w: false },
  };

  @observable state: WellDetailsWidgetState | null = null;
  @observable wellId: number | null;
  @observable section: AppSection;
  @observable isEditMode: boolean;

  constructor(
    widgetOptions: TWidgetOptions,
    { wellId, section, isEditMode }: WellDetailsOptions,
    private readonly i18 = requireService('i18')
  ) {
    super(widgetOptions);

    this.wellId = wellId;
    this.section = section;
    this.isEditMode = isEditMode;

    makeObservable(this);
  }

  get type(): string {
    return 'well-details-widget';
  }

  get isControlWidget(): boolean {
    return false;
  }

  get title(): string {
    return this.i18.t(this.getNameT());
  }

  getNameT(): string {
    return 'widgets:WellDetails.title';
  }

  @action.bound
  setWellId(wellId: number | null): void {
    this.wellId = wellId;
  }

  @action.bound
  setState(state: WellDetailsWidgetState | null): void {
    this.state = state;
  }

  @action.bound
  setSection(section: AppSection): void {
    this.section = section;
  }

  @action.bound
  setEditMode(value: boolean): void {
    this.isEditMode = value;

    if (value && !this.isFullscreen) {
      this.setFullscreen(true);
    }
  }

  @action.bound
  setFullscreen(value: boolean): void {
    this.isFullscreen = value;

    if (!this.isFullscreen) {
      this.isEditMode = false;
    }

    this.state?.updateParams({
      fullscreen: value,
      editMode: this.isEditMode,
    });
  }
}
