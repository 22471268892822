import { toUnixTime } from 'src/packages/shared/utils/toUnixTime';

import type { TWellsFilterInternalState } from './FilterWidget.entity';
import type { TFilterDataSource } from './WellsFilterDataSource';
import type { Item } from '../controls/abstract-control-entities';

import { DateRangeFieldEntity } from '../controls/entities/DateRangeField.entity';
import { FilterMultiCombobox } from '../controls/entities/FilterMultiCombobox.entity';

export type TFilterState = {
  name: string;
  value: string | number | (number | string)[];
}[];

export function parseWellsFilterInternalState(internalState: object): TWellsFilterInternalState {
  const isEditMode =
    'isEditMode' in internalState && typeof internalState.isEditMode === 'boolean' ? internalState.isEditMode : false;
  const state = 'state' in internalState ? parseFilterState(internalState.state) : null;
  const initialState = 'initialState' in internalState ? parseFilterState(internalState.initialState) : null;

  return {
    isEditMode,
    state,
    initialState,
  };
}

export function parseFilterState(state: unknown): TFilterState | null {
  if (!Array.isArray(state)) return null;

  const parsedState: {
    name: string;
    value: string | number | (number | string)[];
  }[] = [];

  for (const stateItem of state) {
    if (!!stateItem && typeof stateItem === 'object' && 'name' in stateItem && typeof stateItem.name === 'string') {
      if ('value' in stateItem) {
        const { value } = stateItem;

        if (Array.isArray(value)) {
          let parsedValue: (number | string)[] = [];

          for (const valueItem of value) {
            if (typeof valueItem === 'string' || typeof valueItem === 'number') {
              parsedValue.push(valueItem);
            }
          }

          parsedState.push({
            name: stateItem.name,
            value: parsedValue,
          });
        }

        if (typeof value === 'string' || typeof value === 'number') {
          parsedState.push({
            name: stateItem.name,
            value,
          });
        }
      }
    }
  }

  return parsedState;
}

export function serializeItemsToInitialData(items: Item[]): TFilterDataSource {
  const data: TFilterDataSource = {};

  for (const item of items) {
    if (item instanceof FilterMultiCombobox && item.initialValue) {
      if (item.initialValue.length > 0) {
        data[item.attrName] = item.initialValue;
      }
    }

    if (item instanceof DateRangeFieldEntity) {
      if (item.initialValue.startDate) {
        data[item.attrNames.startDate] = toUnixTime(item.initialValue.startDate);
      }

      if (item.initialValue.endDate) {
        data[item.attrNames.endDate] = toUnixTime(item.initialValue.endDate);
      }
    }
  }

  return data;
}

export function serializeItemsToData(items: Item[]): TFilterDataSource {
  const data: TFilterDataSource = {};

  for (const item of items) {
    if (item instanceof FilterMultiCombobox && item.value) {
      if (item.value.length > 0) {
        data[item.attrName] = item.value;
      }
    }

    if (item instanceof DateRangeFieldEntity) {
      if (item.startDate) {
        data[item.attrNames.startDate] = toUnixTime(item.startDate);
      }

      if (item.endDate) {
        data[item.attrNames.endDate] = toUnixTime(item.endDate);
      }
    }
  }

  return data;
}

export function serializeFilterDataSource(data: TFilterDataSource): TFilterState {
  const serializedOuterState: {
    name: string;
    value: string | number | (number | string)[];
  }[] = [];

  for (const [name, value] of Object.entries(data)) {
    if (value !== null) {
      serializedOuterState.push({
        name,
        value,
      });
    }
  }

  return serializedOuterState;
}
