import { action, makeObservable } from 'mobx';

import { BroadcastTunnel } from 'src/packages/broadcast-tunnel/BroadcastTunnel';
import { hasValue } from 'src/packages/shared/utils/common';

import type { SlaveWindowsManager } from './SlaveWindowsManager';
import type { SingleTabStore } from '../SingleTab.store';
import type { TPingTabMessage } from '../SingleTab.types';
import type { TTabPongMessage } from 'src/pages/dashboard/features/workspace/types';

export class PingMasterService {
  private readonly store: SingleTabStore;
  private readonly windowsManager: SlaveWindowsManager;
  private readonly pingMasterTunnel: BroadcastTunnel<TPingTabMessage, TTabPongMessage>;
  private readonly pingTunnel: BroadcastTunnel<TTabPongMessage, TPingTabMessage>;

  private intervalId: number | null = null;
  private timerId: number | null = null;

  constructor(store: SingleTabStore, windowsManager: SlaveWindowsManager) {
    this.store = store;
    this.windowsManager = windowsManager;

    this.pingMasterTunnel = new BroadcastTunnel((message): message is TTabPongMessage => {
      if (
        message?.type !== 'pong' ||
        message?.from?.type !== 'master' ||
        message?.from?.id !== this.windowsManager.master.id ||
        message?.to?.id !== this.store.tabId
      ) {
        return false;
      }

      return true;
    }, this.onMasterPong);

    this.pingTunnel = new BroadcastTunnel((message): message is TPingTabMessage => {
      if (message.type !== 'ping' || message?.to?.id !== this.store.tabId) return false;
      if (this.windowsManager.master.id && message?.from?.id !== this.windowsManager.master.id) return false;

      return true;
    }, this.onTabPing);

    makeObservable(this);
  }

  @action.bound
  private onMasterPong = (): void => {
    this.windowsManager.setMasterPonged(true);
  };

  private pingMaster(): void {
    if (this.store.tabId && this.windowsManager.master.id) {
      this.pingMasterTunnel.postMessage({
        type: 'ping',
        from: {
          type: 'slave',
          id: this.store.tabId,
        },
        to: {
          id: this.windowsManager.master.id,
        },
      });
    }
  }

  @action.bound
  private onTabPing = (message: TPingTabMessage) => {
    if (!this.windowsManager.master.id) {
      this.windowsManager.setMasterStatus(true);
      this.windowsManager.setMasterId(message.from.id);
      this.windowsManager.sendTabStatus();
    }

    if (this.windowsManager.master.id) {
      this.pingTunnel.postMessage({
        type: 'pong',
        from: {
          type: 'slave',
          id: this.store.tabId,
        },
        to: {
          id: this.windowsManager.master.id,
        },
      });
    }
  };

  @action.bound
  init = (): void => {
    this.intervalId = window.setInterval(() => {
      if (!this.windowsManager.master.id || !this.windowsManager.master.isConnected) return;

      this.pingMaster();

      this.timerId = window.setTimeout(() => {
        if (!this.windowsManager.isMasterPonged) {
          this.windowsManager.recoverMasterWindow();
        } else {
          this.store.setMasterConnectionController(null);
        }

        this.windowsManager.setMasterPonged(false);
      }, 1000);
    }, 5000);
  };

  dispose = (): void => {
    this.pingMasterTunnel.disconnect();
    this.pingTunnel.disconnect();

    if (hasValue(this.intervalId)) {
      clearInterval(this.intervalId);
    }
    if (hasValue(this.timerId)) {
      clearTimeout(this.timerId);
    }
  };
}
