import { arrayMove } from '@dnd-kit/sortable';
import { action, computed, flow, makeObservable, observable, reaction } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import { getNewTab, isEmptyTabTemplate } from 'src/entities/workspace/utils';
import { WorkspaceEntity } from 'src/entities/workspace/WorkspaceEntity';
import { requireService } from 'src/packages/di';
import { IS_PORTABLE_DEVICE } from 'src/packages/shared/consts/isPortableDevice';
import { hasValue } from 'src/packages/shared/utils/common';
import { debounce } from 'src/packages/shared/utils/debounce';
import { getNewAndDeletedFields } from 'src/packages/shared/utils/getNewAndDeletedElements';
import { createPromiseController } from 'src/packages/shared/utils/promise-controller';
import { WorkspaceApi } from 'src/pages/dashboard/features/workspace/api/TabsApi';
import { mapTab, mapTabs } from 'src/serializers/tab/mapTab';
import { serializeLayouts, serializeWorkspaceState } from 'src/serializers/tab/serializeTab';
import { mapWidgetTemplates } from 'src/serializers/widget-templates/mapWidgetTemplates';
import { serializeWidgetsTemplates } from 'src/serializers/widget-templates/serializeWidgetTemplates';

import type { TSelectTemplate, TTabTemplateData, TTabTemplateWithGSProps } from './types';
import type { DragEndEvent } from '@dnd-kit/core';
import type { WellIndexType } from '@go-widgets/well-logs-widget';
import type { ObservableSet } from 'mobx';
import type { TWellsFilterView } from 'src/api-types/wells.types';
import type { TSerializedWorkspaceState } from 'src/api-types/workspace.types';
import type { TabEntity } from 'src/entities/tab/TabEntity';
import type { WidgetPosition, WidgetSize } from 'src/entities/widget/WidgetEntity';
import type { TPromiseController } from 'src/packages/shared/utils/promise-controller';

import { ExtraMasterSearchService } from './services/ExtraMasterSearchService';
import { MasterWindowsManager } from './services/MasterWindowsManager';
import { SessionManager } from './session-manager/SessionManager';
import { MAX_TABS_COUNT } from './Workspace.consts';
import { MASTER_ID_KEY } from './Workspace.utils';

export type TContentWidgetOptions = {
  type: string | null;
  wellIndexType?: WellIndexType;
};

export type TSerializedWidgetTemplate = {
  type: string;
  uuid: string;
  groupId: string | null;
  internalState?: object;
  size: WidgetSize;
  position: WidgetPosition;
};

type TExternalTab = {
  id: string;
  inited: boolean;
};

export class WorkspaceStore {
  private workbenchId: number | null = null;

  private readonly api: WorkspaceApi;

  readonly extraMasterSearchService: ExtraMasterSearchService;
  readonly masterWindowsManager: MasterWindowsManager | null = null;
  readonly sessionManager: SessionManager;

  @observable id: string;
  @observable isExtraMasterDetected: boolean = false;
  @observable healthCheckingTabs: ObservableSet<string> = observable.set<string>();
  @observable externalTabs: TExternalTab[] = [];
  @observable responsedTabs: ObservableSet<string> = observable.set<string>();
  @observable newContentWidgetController: TPromiseController<TContentWidgetOptions | null> | null = null;
  @observable externalTabsRecoveringController: TPromiseController<boolean> | null = null;
  @observable externalTabsRetryRecoveringController: TPromiseController<boolean> | null = null;
  @observable workspace: WorkspaceEntity;
  @observable isLoading: boolean = false;
  @observable isUpdating: boolean = false;
  @observable isFavoritesUpdating: boolean = false;
  @observable isRemoveTabModalOpen: boolean = false;
  @observable isCreateTabModalOpen: boolean = false;
  @observable tabRemovingControls?: TPromiseController<boolean>;
  @observable selectTemplateControls?: TPromiseController<TTabTemplateWithGSProps | TSelectTemplate | null>;
  @observable deletableTabName: string | null = null;
  @observable hasError: boolean = false;
  @observable isSyncDisabled = false;
  @observable isSaveTabAsTemplateModalOpened = false;
  @observable tabSavedAsTemplate: TabEntity | null = null;
  @observable isInited: boolean = false;

  constructor(
    private readonly notifications = requireService('notifications'),
    readonly favoritesWells = requireService('favoritesWells'),
    readonly directories = requireService('directories'),
    readonly appBroadcastService = requireService('appBroadcastService'),
    readonly localStorageService = requireService('localStorage'),
    private readonly preloadService = requireService('preloadService'),
    private readonly tabTemplatesService = requireService('tabTemplates'),
    private readonly i18 = requireService('i18'),
    private readonly wellLogsTemplateService = requireService('wellLogsTemplateService')
  ) {
    this.api = new WorkspaceApi();
    this.workspace = new WorkspaceEntity();
    this.notifications = notifications;
    this.masterWindowsManager = IS_PORTABLE_DEVICE ? null : new MasterWindowsManager(this);
    this.sessionManager = new SessionManager(this);
    this.extraMasterSearchService = new ExtraMasterSearchService(this);

    const localMasterId = this.localStorageService.get(MASTER_ID_KEY);

    if (localMasterId && typeof localMasterId === 'string') {
      this.id = localMasterId;
    } else {
      this.id = uuidv4();
    }

    makeObservable(this);
  }

  @computed
  private get workspaceState(): TSerializedWorkspaceState {
    return serializeWorkspaceState(this.workspace);
  }

  @computed
  get actualTab(): TabEntity | null {
    return this.tabsEntities.find((tab) => tab.id === this.workspace.activeKey) || null;
  }

  get tabsEntities(): TabEntity[] {
    return this.workspace.tabsEntities;
  }

  @computed
  get initedExternalTabs(): TExternalTab[] {
    return this.externalTabs.filter((tab) => tab.inited);
  }

  @computed
  get externalTabsIds(): string[] {
    return this.externalTabs.map((tab) => tab.id);
  }

  @action.bound
  onSaveTabAsTemplateModalClose(): void {
    this.isSaveTabAsTemplateModalOpened = false;
  }

  @action.bound
  onSaveTabAsTemplateModalOpen(): void {
    this.isSaveTabAsTemplateModalOpened = true;
  }

  @action.bound
  onRemoveTabCancel(): void {
    this.tabRemovingControls?.resolve(false);
  }

  @action.bound
  onRemoveTabAccept(): void {
    this.tabRemovingControls?.resolve(true);
  }

  @action.bound
  onCreateNewTabAccept(template: TTabTemplateWithGSProps): void {
    this.selectTemplateControls?.resolve(template);
  }

  @action.bound
  onCreateNewEmptyTab(): void {
    const template: TSelectTemplate = {
      type: 'empty',
      title: this.i18.t('dashboard:Tabs.emptyTab'),
    };

    this.selectTemplateControls?.resolve(template);
  }

  @action.bound
  onCreateNewTabCancel(): void {
    this.selectTemplateControls?.resolve(null);
  }

  @action.bound
  addHealthCheckingTab(tabId: string): void {
    this.healthCheckingTabs.add(tabId);
  }

  @action.bound
  stopTabHealthChecking(tabId: string): void {
    this.healthCheckingTabs.delete(tabId);
  }

  @action.bound
  onDragEnd({ active, over }: DragEndEvent): void {
    if (this.workspace.tabsEntities && active.id !== over?.id) {
      const activeIndex = this.workspace.tabsEntities.findIndex((i) => i.id === active.id);
      const overIndex = this.workspace.tabsEntities.findIndex((i) => i.id === over?.id);
      const tabs = arrayMove(this.workspace.tabsEntities, activeIndex, overIndex);

      this.workspace.setTabsEntities(tabs);
      this.changeTabsOrder(this.workspace.tabsEntities);
    }
  }

  @action.bound
  setTabSavedAsTemplate(tab: TabEntity | null): void {
    this.tabSavedAsTemplate = tab;
  }

  @action.bound
  setFavoritesUpdating(value: boolean): void {
    this.isFavoritesUpdating = value;
  }

  @flow.bound
  async *onSaveTabAsTemplate(name: string, description: string) {
    if (!this.tabSavedAsTemplate) {
      this.onSaveTabAsTemplateModalClose();
      return;
    }

    const tabTemplateObject: TTabTemplateData = {
      type: 'personal',
      ownerUserId: this.api.auth().userInfo.sub,
      template: {
        name,
        description,
        layouts: serializeLayouts(this.tabSavedAsTemplate.layouts),
        widgets: serializeWidgetsTemplates(this.tabSavedAsTemplate.widgets),
      },
    };

    try {
      await this.tabTemplatesService.saveAsPersonal(tabTemplateObject);
      yield;

      this.notifications.showSuccessMessageT('common:Tab.tabTemplateSuccessfullySaved');
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToSaveTabTemplate');
    } finally {
      this.sessionManager.sendTabTemplatesChanged();
      this.onSaveTabAsTemplateModalClose();
    }
  }

  @flow.bound
  async *onUpdateTemplate(tab: TabEntity) {
    if (tab.templateId === null) {
      return;
    }

    const templateData = this.tabTemplatesService.getTemplateDataById(tab.templateId);

    if (!templateData) return;

    const tabTemplateObject: TTabTemplateData = {
      type: 'personal',
      ownerUserId: this.api.auth().userInfo.sub,
      template: {
        name: templateData.template.name,
        description: templateData.template.description,
        layouts: serializeLayouts(tab.layouts),
        widgets: serializeWidgetsTemplates(tab.widgets),
      },
    };

    try {
      await this.tabTemplatesService.updatePersonalTemplate(tab.templateId, tabTemplateObject);

      yield;

      this.notifications.showSuccessMessageT('common:Tab.tabTemplateSuccessfullySaved');
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToSaveTabTemplate');
    } finally {
      this.sessionManager.sendTabTemplatesChanged();
    }
  }

  @flow.bound
  async *onCreateNewContentWidget() {
    if (!this.actualTab) {
      return;
    }

    try {
      this.newContentWidgetController = createPromiseController<TContentWidgetOptions>();

      const res = await this.newContentWidgetController;
      yield;

      if (!res) return;

      if (res.type === 'well-logs-widget' && res.wellIndexType) {
        this.actualTab.createWellLogsWidget(res.wellIndexType, null, null, { isGroupsDefaultOpened: true });
      } else if (res.type === 'well-operational-params-widget' && res.wellIndexType) {
        this.actualTab.createOperationalParametersWidget(res.wellIndexType, null, true, null, {
          isGroupsDefaultOpened: true,
        });
      } else if (res.type) {
        this.actualTab.createWidget(res.type);
      }
    } finally {
      yield;
      this.newContentWidgetController = null;
    }
  }

  @flow.bound
  async *changeActiveKey(newKey?: string) {
    this.hasError = false;

    try {
      this.workspace.setActiveKey(newKey);

      const serializedState = serializeWorkspaceState(this.workspace);
      await this.saveWorkspace(serializedState);

      yield;
    } catch (error) {
      yield;

      console.error(error);
      this.hasError = true;
      this.notifications.showErrorMessageT('errors:failedToChangeActiveTab');
    }
  }

  @flow.bound
  async *changeTabName(id: string, name: string) {
    if (this.isUpdating) return;

    try {
      this.hasError = false;
      this.isUpdating = true;

      const tab = this.workspace.tabsEntities.find((tab) => tab.id === id);

      if (!tab) return;

      tab.setName(name);

      const serializedState = serializeWorkspaceState(this.workspace);
      await this.saveWorkspace(serializedState);

      yield;

      tab.changeFocus(false);
    } catch (error) {
      yield;

      console.error(error);
      this.hasError = true;
      this.notifications.showErrorMessageT('errors:failedToChangeTabName');
    } finally {
      this.isUpdating = false;
    }
  }

  @flow.bound
  async *changeTabsOrder(prevTabs: TabEntity[]) {
    if (this.isUpdating) return;

    try {
      this.isUpdating = true;
      this.hasError = false;

      const serializedState = serializeWorkspaceState(this.workspace);
      await this.saveWorkspace(serializedState);

      yield;
    } catch (error) {
      yield;

      this.workspace.setTabsEntities(prevTabs);

      console.error(error);
      this.hasError = true;
      this.notifications.showErrorMessageT('errors:failedToChangeTabsOrder');
    } finally {
      this.isUpdating = false;
    }
  }

  @flow.bound
  async *removeTab(id: string) {
    if (this.isUpdating) return;

    const tabName = this.workspace.tabsEntities.find((tab) => tab.id === id)?.name;

    if (!tabName) return;

    this.deletableTabName = tabName;

    try {
      this.isRemoveTabModalOpen = true;

      this.tabRemovingControls = createPromiseController<boolean>();
      const shouldRemoveTab = await this.tabRemovingControls;

      yield;

      if (!shouldRemoveTab) return;
    } finally {
      this.isRemoveTabModalOpen = false;
      this.tabRemovingControls = void 0;
    }

    try {
      this.isUpdating = true;
      this.hasError = false;

      this.workspace.removeTab(id);

      let _activeKey = this.workspace.activeKey;

      if (this.workspace.activeKey === id) {
        const hasTabs = this.workspace.tabsEntities.length > 0;

        _activeKey = hasTabs ? this.workspace.tabsEntities[0].id : void 0;

        if (hasTabs) {
          this.workspace.tabsEntities[0].setShouldScrollToViewport(true);
        }
      }

      this.workspace.setActiveKey(_activeKey);

      const serializedWorkspaceState = serializeWorkspaceState(this.workspace);
      await this.saveWorkspace(serializedWorkspaceState);

      yield;
    } catch (error) {
      yield;

      console.error(error);
      this.hasError = true;
      this.notifications.showErrorMessageT('errors:failedToRemoveTab');
    } finally {
      this.isUpdating = false;
    }
  }

  @action.bound
  adjustActualTab(): void {
    if (
      this.externalTabs.length === 0 ||
      !this.actualTab?.id ||
      !this.externalTabs.find((tab) => tab.id === this.actualTab?.id)
    ) {
      return;
    }

    for (const tab of this.tabsEntities) {
      const isTabExternal = !!this.externalTabs.find((externalTab) => externalTab.id === tab.id);

      if (!isTabExternal) {
        this.workspace.setActiveKey(tab.id);

        return;
      }
    }

    this.workspace.setActiveKey(void 0);
  }

  @action.bound
  addExternalTab(id: string, inited?: boolean): void {
    this.externalTabs.push({
      id,
      inited: !!inited,
    });
  }

  @action.bound
  removeExternalTab(id: string): void {
    const tab = this.externalTabs.find((tab) => tab.id === id);

    if (tab) {
      this.externalTabs.remove(tab);
    }
  }

  @action.bound
  initExternalTab(tabId: string): void {
    const tab = this.externalTabs.find((tab) => tab.id === tabId);

    if (tab) {
      tab.inited = true;
    } else {
      this.externalTabs.push({
        id: tabId,
        inited: true,
      });
    }
  }

  @flow.bound
  async *updateWorkspace() {
    if (this.isSyncDisabled) return;

    this.isSyncDisabled = true;

    try {
      this.hasError = false;

      const tabsResponse = await this.api.fetchTabs();

      yield;

      if (!tabsResponse.length) {
        await this.api.createWorkspace();
        yield;
        this.workspace.setTabsEntities([]);
        return;
      }

      const {
        data: { tabs: rawTabs },
      } = tabsResponse[0];

      const rawTabsIds = rawTabs.map((tab) => tab.id);
      const prevTabsIds = this.workspace.tabsEntities.map((tab) => tab.id);

      const { newElements: newTabsIds, deletedElements: deletedTabsIds } = getNewAndDeletedFields(
        rawTabsIds,
        prevTabsIds
      );

      if (this.masterWindowsManager) {
        deletedTabsIds.forEach((tabId) => this.masterWindowsManager?.closeTab(tabId));
      }

      let newWorkspaceTabs: TabEntity[] = [];

      for (const rawTab of rawTabs) {
        if (newTabsIds.includes(rawTab.id)) {
          newWorkspaceTabs.push(mapTab(rawTab));
        } else {
          const targetTab = this.workspace.tabsEntities.find((_tab) => _tab.id === rawTab.id);

          if (!targetTab) continue;

          targetTab.applyChanges(rawTab);
          this.masterWindowsManager?.sendChangedTab(rawTab);

          newWorkspaceTabs.push(targetTab);
        }
      }

      this.workspace.setTabsEntities(newWorkspaceTabs);
    } catch (error) {
      yield;

      console.error(error);
      this.hasError = true;
      this.notifications.showErrorMessageT('errors:failedToLoadTabs');
    }
  }

  @flow.bound
  private async *fetchTabs() {
    if (this.isLoading) return;

    try {
      this.isLoading = true;
      this.hasError = false;

      this.favoritesWells.loadFavoritesIds();

      await this.fetchWellsFilterView();

      const tabsResponse = await this.api.fetchTabs();

      yield;

      if (!tabsResponse.length) {
        await this.api.createWorkspace();
        yield;
        this.workspace.setTabsEntities([]);
      } else {
        const {
          data: { tabs: rawTabs },
          id,
        } = tabsResponse[0];
        this.workbenchId = id;

        const { tabs } = mapTabs(rawTabs);

        this.workspace.setTabsEntities(tabs);
        this.workspace.setActiveKey(tabs?.[0]?.id);
      }
    } catch (error) {
      yield;

      console.error(error);
      this.hasError = true;
      this.notifications.showErrorMessageT('errors:failedToLoadTabs');
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  private async *fetchWellsFilterView() {
    try {
      this.hasError = false;

      yield;

      let objects: string[] = [];

      const wellsFilterView = this.preloadService.getPreloadedData<TWellsFilterView>('filter-control');

      wellsFilterView.fields.forEach((field) => {
        if ('refObjectType' in field && field.refObjectType) {
          const refObjectType = field.refObjectType;

          if (refObjectType) {
            objects.push(refObjectType);
          }
        }
      });

      await this.directories.loadObjects(objects);

      yield;
    } catch (error) {
      yield;

      console.error(error);
      this.hasError = true;
      this.notifications.showErrorMessageT('errors:failedToLoadWellsFilterView');
    }
  }

  @flow.bound
  async *createTab() {
    if (this.isUpdating) return;

    if (this.tabsEntities.length >= MAX_TABS_COUNT) {
      this.notifications.showErrorMessageT('errors:maxNumberOfTabs');
      return;
    }

    let template: TTabTemplateWithGSProps | TSelectTemplate | null = null;

    try {
      this.isCreateTabModalOpen = true;

      this.selectTemplateControls = createPromiseController<TTabTemplateWithGSProps | TSelectTemplate | null>();
      template = await this.selectTemplateControls;
      yield;

      if (!template) return;
    } finally {
      this.isCreateTabModalOpen = false;
      this.selectTemplateControls = void 0;
    }

    if (!template) return;

    try {
      this.hasError = false;
      this.isUpdating = true;

      const tab = getNewTab(template);
      yield;

      const _tab = mapTab(tab);
      _tab.setShouldScrollToViewport(true);

      if ('data' in template) {
        const widgets = mapWidgetTemplates(template.data.template.widgets);

        widgets.forEach((widget) => {
          _tab.addWidget(widget);
        });
      }

      this.workspace.setActiveKey(tab.id);
      this.workspace.addTabEntity(_tab);

      const serializedState = serializeWorkspaceState(this.workspace);
      await this.saveWorkspace(serializedState);
      yield;

      if (isEmptyTabTemplate(template)) {
        _tab.changeFocus(true);
      }
    } catch (error) {
      yield;

      console.error(error);
      this.hasError = true;
      this.notifications.showErrorMessageT('errors:failedToCreateTab');
    } finally {
      this.isUpdating = false;
    }
  }

  @flow.bound
  private async *saveWorkspace(serializedState: TSerializedWorkspaceState) {
    if (!hasValue(this.workbenchId)) {
      return;
    }

    if (this.isSyncDisabled) {
      this.isSyncDisabled = false;
      return;
    }

    await this.api.saveWorkspaceState(serializedState, this.workbenchId);
    yield;

    if (this.isInited) {
      this.sessionManager.sendWorkspaceChanged();
    } else {
      this.isInited = true;
    }

    yield;
  }

  @action.bound
  private debouncedSaveWorkspace = debounce((state: TSerializedWorkspaceState) => {
    this.saveWorkspace(state);
  }, 400);

  closeInitedTabs = (): void => {
    this.initedExternalTabs.forEach((tab) => this.masterWindowsManager?.closeTab(tab.id));
  };

  @flow.bound
  private async *fetchRequiredData() {
    try {
      await this.fetchTabs();

      if (this.masterWindowsManager) {
        await this.masterWindowsManager?.openExternalTabs();
      }

      yield;
    } catch (error) {
      console.error(error);
    }
  }

  getTabById(id: string): TabEntity | undefined {
    return this.tabsEntities.find((tabEntity) => tabEntity.id === id);
  }

  @action.bound
  onRecoverExternalTabs(): void {
    this.externalTabsRecoveringController?.resolve(true);
  }

  @action.bound
  onExternalTabsRecoveringCancel(): void {
    this.externalTabsRecoveringController?.resolve(false);
  }

  @action.bound
  setExternalTabsRecoveringController(value: TPromiseController<boolean> | null): void {
    this.externalTabsRecoveringController = value;
  }

  @action.bound
  setExternalTabsRetryRecoveringController(value: TPromiseController<boolean> | null): void {
    this.externalTabsRetryRecoveringController = value;
  }

  @action.bound
  onRetryExternalTabsRecovering(): void {
    this.externalTabsRetryRecoveringController?.resolve(true);
  }

  @action.bound
  onRetryExternalTabsRecoveringCancel(): void {
    this.externalTabsRetryRecoveringController?.resolve(false);
  }

  get tabsIds(): string[] {
    const ids: string[] = [];

    for (const tab of this.tabsEntities) {
      ids.push(tab.id);
    }

    return ids;
  }

  private dispose = (): void => {
    this.extraMasterSearchService.dispose();
    this.sessionManager.dispose();
    this.masterWindowsManager?.dispose();
  };

  destroy = (): void => {
    this.dispose();
    this.wellLogsTemplateService.destroy();
  };

  @action.bound
  setExtraMasterDetected(value: boolean): void {
    this.isExtraMasterDetected = value;
  }

  private init = async (): Promise<void> => {
    try {
      const shouldClose = await this.extraMasterSearchService.tryToFindExtraMaster();

      if (shouldClose) {
        this.setExtraMasterDetected(true);
        this.destroy();

        return;
      }

      await this.wellLogsTemplateService.init();

      this.sessionManager.init();

      this.fetchRequiredData();

      this.masterWindowsManager?.init();

      window.addEventListener('beforeunload', this.destroy, { capture: true });
    } catch (error) {
      console.error(error);
    }
  };

  @action.bound
  effect = () => {
    this.init();

    const disposer = reaction(
      () => ({
        state: this.workspaceState,
      }),
      ({ state }) => {
        this.debouncedSaveWorkspace(state);
      }
    );

    const disposeFavorites = reaction(
      () => this.favoritesWells.wellIds.slice(),
      (favoriteWells) => {
        if (this.isFavoritesUpdating) return;

        this.sessionManager.sendFavoritesChanged();
        this.masterWindowsManager?.sendFavoriteWellsList(favoriteWells);
      }
    );

    return () => {
      disposer();
      disposeFavorites();
      this.dispose();
      window.removeEventListener('beforeunload', this.destroy, { capture: true });
    };
  };
}
