import { action, makeObservable } from 'mobx';

import { BroadcastTunnel } from 'src/packages/broadcast-tunnel/BroadcastTunnel';
import { mapTab } from 'src/serializers/tab/mapTab';

import type { TTabSyncMessage } from '../types';
import type { WorkspaceStore } from '../Workspace.store';
import type { TSerializedTab } from 'src/entities/workspace/types';

export class TabSyncingService {
  private readonly store: WorkspaceStore;
  private readonly tabSyncingTunnel: BroadcastTunnel<TTabSyncMessage, TTabSyncMessage>;

  constructor(store: WorkspaceStore) {
    this.store = store;

    this.tabSyncingTunnel = new BroadcastTunnel((message): message is TTabSyncMessage => {
      if (message?.type !== 'tab-changed' || message?.to?.id !== this.store.id || message?.from?.type !== 'slave')
        return false;

      return true;
    }, this.onTabChanged);

    makeObservable(this);
  }

  @action.bound
  private onTabChanged = (message: TTabSyncMessage): void => {
    this.store.workspace.replaceTab(message.from.id, mapTab(message.data));
  };

  sendChangedTab(data: TSerializedTab): void {
    this.tabSyncingTunnel.postMessage({
      type: 'tab-changed',
      from: {
        type: 'master',
        id: this.store.id,
      },
      to: {
        id: data.id,
      },
      data,
    });
  }

  dispose = (): void => {
    this.tabSyncingTunnel.disconnect();
  };
}
