function getEnvVariable(key: string): string | undefined {
  return window._env_[key] || process.env[key];
}

function getRequiredEnvVariable(key: string): string {
  const value = window._env_[key] || process.env[key];

  if (typeof value === 'undefined') {
    console.error(key);
    throw new Error('env variable is not set');
  }

  return value;
}

export const WELL_STATUS_SOCKET_URL = getRequiredEnvVariable('REACT_APP_WELL_STATUS_SOCKET_URL');
export const SESSION_MANAGEMENT_SOCKET_URL = getRequiredEnvVariable('REACT_APP_SESSION_MANAGEMENT_SOCKET_URL');
export const WELL_DIRECTORY_SERVICE_API_URL = getRequiredEnvVariable('REACT_APP_WELL_DIRECTORY_SERVICE_API_URL');
export const DASHBOARD_SERVICE_API_URL = getRequiredEnvVariable('REACT_APP_DASHBOARD_SERVICE_API_URL');
export const API_GOSTORAGE_URL = getRequiredEnvVariable('REACT_APP_GOSTORAGE_API_URL');
export const API_GOSTORAGE_WELL_LOGS_URL = getRequiredEnvVariable('REACT_APP_GOSTORAGE_WELL_LOGS_API_URL');
export const API_LEGACY_CACHE_URL = getRequiredEnvVariable('REACT_APP_CACHE_URL');
export const API_WEBSOCKET_PATH = getRequiredEnvVariable('REACT_APP_WEBSOCKET_URL') ?? '';
export const AUTH_API_URL = getRequiredEnvVariable('REACT_APP_AUTH_API_URL') ?? '';
export const AUTH_TOKEN = getRequiredEnvVariable('REACT_APP_AUTH_TOKEN') ?? '';
export const WITH_WELLBORE_SORTING = getEnvVariable('REACT_APP_WITH_WELLBORE_SORTING') === 'false' ? false : true;
export const WELL_DETAILS_AVAILABLE = getEnvVariable('REACT_APP_WELL_DETAILS_AVAILABLE') === 'true' || false;
export const WELL_ANALYSE_AVAILABLE = getEnvVariable('REACT_APP_WELL_ANALYSE_AVAILABLE') === 'true' || false;
export const IMAGE_BROADCAST_AVAILABLE = getEnvVariable('REACT_APP_IMAGE_BROADCAST_AVAILABLE') === 'true' || false;
export const OPERATIONAL_PARAMETERS_AVAILABLE =
  getEnvVariable('REACT_APP_OPERATIONAL_PARAMETERS_AVAILABLE') === 'true' || false;
export const DEV_SETTINGS_PAGE_AVAILABLE = getEnvVariable('REACT_APP_DEV_SETTINGS_PAGE_AVAILABLE') === 'true' || false;
export const SPLIT_RANGE_TIME = getEnvVariable('REACT_APP_SPLIT_RANGE_TIME') || null;
export const SPLIT_RANGE_DEPTH = getEnvVariable('REACT_APP_SPLIT_RANGE_DEPTH') || null;
