import { CloseRoundLight as CloseIcon } from '@profgeosoft-ui/icons';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import type { MouseEvent, ReactNode } from 'react';

import styles from './TabName.module.scss';

interface TabNameProps {
  children: ReactNode;
  onRemove(): void;
}

export const TabName = observer(function TabName({ children, onRemove }: TabNameProps) {
  const removeTab = (e: MouseEvent) => {
    e.stopPropagation();
    onRemove();
  };

  return (
    <div className={styles.tab}>
      <span className={styles.tabName}>{children}</span>
      <button onClick={removeTab} type="button" className={clsx(styles.closeButton)}>
        <CloseIcon />
      </button>
    </div>
  );
});
