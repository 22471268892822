import { Loader } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useViewStore } from 'src/packages/shared/hooks/useViewStore';

import type { TabTemplatesListScreenEntity } from './TabTemplatesListScreen.entity';
import type { TTemplateCollapse } from './TabTemplatesListScreen.store';
import type { BaseSettingsScreen } from '../screen/BaseSettingsScreen';
import type { Stack } from 'src/packages/data/structures/Stack';
import type { TTabTemplateWithGSProps } from 'src/pages/dashboard/features/workspace/types';

import { ScreenCollapse } from '../screen-collapse/ScreenCollapse';
import { TemplatesList } from '../templates-list/TemplatesList';

import { TabTemplatesListScreenStore } from './TabTemplatesListScreen.store';

import styles from './TabTemplatesListScreen.module.scss';

type Props = {
  stack: Stack<BaseSettingsScreen>;
  screen: TabTemplatesListScreenEntity;
};

export const TabTemplatesListScreen = observer(function TabTemplatesListScreen({ stack, screen }: Props) {
  const store = useViewStore(() => new TabTemplatesListScreenStore(stack, screen));

  const { t } = useTranslation();

  const { isLoading, activeTemplateCollapses, onTemplateCollapse, selectTabTemplate, selectedTemplateId, collapses } =
    store;

  const renderCollapseContent = (collapse: TTemplateCollapse) => (
    <TemplatesList<TTabTemplateWithGSProps>
      templates={collapse.templates}
      adaptTemplateToItem={(template: TTabTemplateWithGSProps) => ({
        name: template.data.template.name,
        description: template.data.template.description,
        id: template.id,
      })}
      activeKey={selectedTemplateId}
      onClick={selectTabTemplate}
    />
  );

  return (
    <article
      className={styles.column}
      style={{
        '--go-loader-overlay-bg': 'transparent',
      }}
    >
      {isLoading ? (
        <Loader className={styles.loader} />
      ) : (
        <ScreenCollapse<TTemplateCollapse>
          activeKeys={activeTemplateCollapses}
          onOpenedCollapsesChange={onTemplateCollapse}
          getCollapseName={(collapse) => t(collapse.name)}
          getCollapseId={(collapse) => collapse.type}
          renderCollapseContent={renderCollapseContent}
          renderCollapseButtons={() => null}
          collapses={collapses}
        />
      )}
    </article>
  );
});
