import { BookOpenLight as BookIcon } from '@profgeosoft-ui/icons';
import { Scrollbar, Tooltip } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import type { TabEntity } from 'src/entities/tab/TabEntity';

import { MenuItem } from '../menu-item/MenuItem';

import styles from './TabsMenu.module.scss';

type Props = {
  activeKey?: string;
  tabs: TabEntity[];
  disabledTabs: string[];
  onSaveTabAsTemplateModalOpen(): void;
  onTabSavedAsTemplateChange(tab: TabEntity | null): void;
  onUpdateTemplate(tab: TabEntity): void;
  onTabRemove(id: string): void;
  onTabFocus?(id: string): void;
  onTabClose?(id: string): void;
  onActiveKeyChange(key: string): void;
  onChangeTabName(id: string, name: string): void;
  onOpenInNewWindow?(tabId: string): void;
};

export const TabsMenu = observer(function TabsMenu({
  activeKey,
  tabs,
  disabledTabs,
  onSaveTabAsTemplateModalOpen,
  onTabSavedAsTemplateChange,
  onUpdateTemplate,
  onTabFocus,
  onTabRemove,
  onTabClose,
  onActiveKeyChange,
  onChangeTabName,
  onOpenInNewWindow,
}: Props) {
  const { t } = useTranslation();

  const [isInnerTooltipOpen, setInnerTooltipOpen] = useState(false);
  const [isExternalTooltipOpen, setExternalTooltipOpen] = useState(false);

  const handleExternalTooltipOpenChange = (value: boolean) => {
    if (isInnerTooltipOpen) {
      setExternalTooltipOpen(false);
      return;
    }

    setExternalTooltipOpen(value);
  };

  const handleInnerTooltipOpenChange = (value: boolean) => {
    handleExternalTooltipOpenChange(false);

    setInnerTooltipOpen(value);
  };

  const renderTooltipComponent = () => (
    <Scrollbar wrapperClassName={styles.wrapperScroll}>
      <div className={styles.menu}>
        {tabs.map((tab) => (
          <MenuItem
            onTabFocus={() => onTabFocus?.(tab.id)}
            onTabClose={onTabClose}
            isExternalTab={!!disabledTabs.find((disabledTab) => disabledTab === tab.id)}
            onOpenInNewWindow={onOpenInNewWindow ? () => onOpenInNewWindow(tab.id) : void 0}
            showUpdateTemplateButton={!!tab.templateId}
            key={tab.id}
            id={tab.id}
            name={tab.name}
            onCloseMenu={() => setInnerTooltipOpen(false)}
            onActiveKeyChange={onActiveKeyChange}
            onTabRemove={onTabRemove}
            onChangeTabName={onChangeTabName}
            onUpdateTemplate={() => onUpdateTemplate(tab)}
            onSaveTabAsTemplate={() => {
              onTabSavedAsTemplateChange(tab);
              onSaveTabAsTemplateModalOpen();
            }}
            isActive={tab.id === activeKey}
          />
        ))}
      </div>
    </Scrollbar>
  );

  return (
    <Tooltip
      title={t('dashboard:Toolbar.openTabsMenu')}
      onOpenChange={handleExternalTooltipOpenChange}
      open={isExternalTooltipOpen}
      delay={400}
    >
      <div>
        <Tooltip
          onOpenChange={handleInnerTooltipOpenChange}
          title={renderTooltipComponent()}
          placement="bottom-end"
          open={isInnerTooltipOpen}
          withArrow={false}
          trigger={['click']}
          gap={8}
          className={styles.popover}
        >
          <button
            className={clsx(styles.menuButton, isInnerTooltipOpen && styles.menuButton__active)}
            type="button"
            onClick={() => setInnerTooltipOpen(true)}
          >
            <BookIcon className={styles.icon} />
          </button>
        </Tooltip>
      </div>
    </Tooltip>
  );
});
