import type { TGroup } from 'src/entities/tab/TabEntity';

export type TSerializedGroup = {
  id: string;
  color: string;
};

export function serializeGroup(group: TGroup): TSerializedGroup {
  return {
    id: group.id,
    color: group.color,
  };
}
