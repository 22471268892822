import { action, makeObservable, observable } from 'mobx';

import { Stack } from 'src/packages/data/structures/Stack';
import { requireService } from 'src/packages/di';

import type { TSettingType } from './SettingsGrid';
import type { BaseSettingsScreen } from 'src/pages/settings/features/screen/BaseSettingsScreen';

import { DevSettingsScreenEntity } from '../dev-settings/DevSettingsScreen.entity';
import { ObjectListScreenEntity } from '../objects-list-screen/ObjectsListScreen.entity';
import { TabTemplatesListScreenEntity } from '../tab-templates-list-screen/TabTemplatesListScreen.entity';

export class SettingsStore {
  readonly stack: Stack<BaseSettingsScreen>;

  @observable mainColumn?: string;

  constructor(
    defaultId?: string,
    private readonly wellLogsTemplateService = requireService('wellLogsTemplateService')
  ) {
    this.stack = new Stack<BaseSettingsScreen>();

    if (defaultId) {
      this.createObjectList(defaultId);
    }

    makeObservable(this);
  }

  getNavClickHandler = (key: TSettingType): ((id: string) => void) => {
    this.stack.setLength(0);

    switch (key) {
      case 'widgetsManager':
        return this.createObjectList;
      case 'tabsManager':
        return this.createTabTemplateList;
      case 'devSettings':
        return this.createDevPage;
      default:
        return this.setMainColumn;
    }
  };

  @action.bound
  setMainColumn = (mainColumn: string): void => {
    this.mainColumn = mainColumn;
  };

  @action.bound
  createObjectList = (id: string) => {
    if (this.mainColumn === id) return;

    this.stack.clearStack();
    this.setMainColumn(id);
    this.stack.push(new ObjectListScreenEntity(this.stack));
  };

  @action.bound
  createTabTemplateList = (id: string) => {
    if (this.mainColumn === id) return;

    this.stack.clearStack();
    this.setMainColumn(id);
    this.stack.push(new TabTemplatesListScreenEntity(this.stack));
  };

  @action.bound
  createDevPage = (id: string) => {
    if (this.mainColumn === id) return;

    this.stack.clearStack();
    this.setMainColumn(id);
    this.stack.push(new DevSettingsScreenEntity(this.stack));
  };

  effect = () => {
    return () => this.wellLogsTemplateService.destroy();
  };
}
