import type { TSerializedWidget } from 'src/entities/workspace/types';
import type {
  TWellDetailsSerializedInternalState,
  WellDetailsWidgetEntity,
} from 'src/pages/dashboard/features/well-details-widget/WellDetailsWidget.entity';

export function serializeWellDetailsWidget(widget: WellDetailsWidgetEntity): TSerializedWidget {
  const internalState: TWellDetailsSerializedInternalState = {
    wellId: widget.wellId,
    section: widget.section,
    isEditMode: widget.isEditMode,
  };

  return {
    wellId: widget.wellId,
    uuid: widget.uuid,
    type: widget.type,
    groupId: widget.groupId,
    size: {
      w: widget.size.w,
      h: widget.size.h,
    },
    position: {
      x: widget.position.x,
      y: widget.position.y,
    },
    isFullscreen: widget.isFullscreen,
    internalState,
  };
}
