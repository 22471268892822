import { Button, Illustration, Modal } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { useService } from 'src/packages/di';

import type { ReactNode } from 'react';

import styles from './ConnectionClosedModal.module.scss';

type Props = {
  isOpened: boolean;
  titleT: string;
  descT: string;
  externalAction?: () => void;
  externalActionT?: string;
  externalActionIcon?: ReactNode;
};

export const ConnectionClosedModal = observer(function ConnectionClosedModal({
  titleT,
  descT,
  isOpened,
  externalAction,
  externalActionT,
  externalActionIcon,
}: Props) {
  const { t } = useTranslation();
  const authService = useService('authService');

  return (
    <Modal open={isOpened} className={styles.modal} onClickOutside={null} closeIcon={false}>
      <div className={styles.header}>
        <div className={styles.img}>
          <Illustration.AllowPopup />
        </div>
        <div>
          <p className={styles.title}>{t(titleT)}</p>
          <p className={styles.desc}>{t(descT)}</p>
        </div>
      </div>
      {externalAction && (
        <Button onClick={authService.logout} icon={externalActionIcon} className={styles.button} size="m">
          {externalActionT && t(externalActionT)}
        </Button>
      )}
    </Modal>
  );
});
