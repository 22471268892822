import { Field } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { DateRangeFieldEntity } from '../../entities/DateRangeField.entity';

import { FormDatePicker } from '../form-date-picker/FormDatePicker';

import styles from './DateRange.module.scss';

type Props = {
  item: DateRangeFieldEntity;
  label: string;
  isDisabled: boolean;
  defaultTime: Date;
  className?: string;
};

export const DateRange = observer(function DateRange({ item, isDisabled, label, defaultTime, className }: Props) {
  const { t } = useTranslation();
  return (
    <Field label={label} className={className}>
      <div className={styles.intervalWrapper}>
        <FormDatePicker
          allowClear
          value={item.startDate}
          label={t(item.labels['startDate'], { defaultValue: '' })}
          className={styles.input}
          placeholder={t(item.placeholderMin)}
          ghostLabel
          disabled={isDisabled}
          disabledDate={item.getDisabledStartDates}
          onChange={item.setStartDate}
          showCalendarIcon={false}
        />
        <div className={styles.dash}>—</div>
        <FormDatePicker
          allowClear
          value={item.endDate}
          label={t(item.labels['endDate'], { defaultValue: '' })}
          className={styles.input}
          ghostLabel
          placeholder={t(item.placeholderMax)}
          disabled={isDisabled}
          disabledDate={item.getDisabledEndDates}
          onChange={item.setEndDate}
          showCalendarIcon={false}
        />
      </div>
    </Field>
  );
});
