import { flow, makeObservable, observable } from 'mobx';

import { requireServiceAccessor } from 'src/packages/di';

import type { IDirectoriesService } from './IDirectoriesService';
import type { TDictObject, TJoinResponse, TRefQuery, TValuesInterpretations } from './types';

import { hasValue } from '../shared/utils/common';

import { fetchDirectory, fetchJoinedDirectory } from './api/directories.api';
import { REF_QUERY_REG_EXP } from './consts';
import { isDynamicJoin, isStringNumberOrBoolean, isErrorWithLongMessage } from './utils';

export class DirectoriesService implements IDirectoriesService {
  private currentObjectsRequests: Record<string, Promise<TDictObject[]>>;

  @observable private valuesInterpretations?: TValuesInterpretations = {};
  @observable private objects: Record<string, TDictObject[]> = {};

  @observable private joinedObjects: Record<string, TJoinResponse[]> = {};
  @observable isLoading: boolean = false;

  constructor(private readonly notifications = requireServiceAccessor('notifications')) {
    this.notifications = notifications;
    this.currentObjectsRequests = {};

    makeObservable(this);
  }

  private async _fetchDirectoryObject(objectName: string): Promise<TDictObject[]> {
    const request = fetchDirectory(objectName);
    this.currentObjectsRequests[objectName] = request;
    return request;
  }

  @flow.bound
  private async *fetchDirectoryObject(objName: string) {
    try {
      const dictionaryObject = !!this.currentObjectsRequests[objName]
        ? await this.currentObjectsRequests[objName]
        : await this._fetchDirectoryObject(objName);
      yield;

      this.objects[objName] = dictionaryObject;
    } catch (error) {
      yield;
      if (isErrorWithLongMessage(error)) {
        this.notifications().showErrorMessage(error.reason.error.longMessage);
        return;
      }

      this.notifications().showErrorMessageT('directories:Errors.recievingType');
      return;
    }
  }

  private filterInvalidJoinWhere(refQuery: TRefQuery): TRefQuery {
    const joinWhere = refQuery.where
      ? {
          where: refQuery.where.filter((where) => {
            const whereValue = where['value'];
            return hasValue(whereValue) && whereValue !== 'undefined' && whereValue !== 'null';
          }),
        }
      : {};

    return {
      ...refQuery,
      ...joinWhere,
      join: refQuery.join.map((join) => {
        const where = join.where
          ? {
              where: join.where.filter((where) => {
                const whereValue = where['value'];

                return hasValue(whereValue) && whereValue !== 'undefined' && whereValue !== 'null';
              }),
            }
          : {};

        return {
          ...join,
          ...where,
        };
      }),
    };
  }

  getObject(objName?: string): TDictObject[] | null {
    if (objName && !!this.objects[objName]) {
      return this.objects[objName];
    }

    return null;
  }

  getJoinedObject(refQuery?: TRefQuery): TJoinResponse[] | null {
    if (!refQuery) return null;
    const serializedRefQuery = JSON.stringify(refQuery);
    return this.joinedObjects[serializedRefQuery] || null;
  }

  getValueInterpretation(fieldId: string, value: unknown, undefinedAsNull: boolean = false): string | number | null {
    const interObject = this.valuesInterpretations?.[fieldId];

    if (!interObject) {
      return null;
    }

    const _value = value === undefined && undefinedAsNull ? null : value;

    if (_value === null || _value === 'null') {
      return interObject?.['null'] || null;
    }

    if (typeof _value === 'string') {
      return interObject?.[_value] || null;
    }

    if (typeof _value === 'number' || typeof _value === 'boolean') {
      return interObject?.[_value.toString()] || null;
    }

    return null;
  }

  async loadObjects(objectsNames: string[]): Promise<void> {
    const promises: Promise<void>[] = objectsNames.map((obj) => {
      if (!this.objects[obj]) {
        return this.fetchDirectoryObject(obj);
      }

      return Promise.resolve();
    });

    await Promise.all(promises);
  }

  async loadJoinedObjects(refQueries: TRefQuery[]): Promise<void> {
    const promises: Promise<TJoinResponse[] | void>[] = refQueries.map((refQuery) => {
      if (!this.joinedObjects[JSON.stringify(refQuery)]) {
        return this.fetchJoinedDirectory(refQuery);
      }
      return Promise.resolve();
    });

    await Promise.all(promises);
  }

  fetchDynamicJoinObject = async (refQuery: TRefQuery, values: Record<string, unknown>): Promise<TJoinResponse[]> => {
    try {
      const stringifiedRefQuery = JSON.stringify(refQuery);

      const parseKey = (_: string, sign: string, key: string): string => {
        const valueKey = `${sign}${key}`;
        const value = values[valueKey];

        if (!hasValue(value) || (Array.isArray(value) && !value.length)) {
          return 'null';
        }

        if (isStringNumberOrBoolean(value)) {
          return value.toString();
        }

        if (Array.isArray(value)) {
          return value.join(',');
        }

        return JSON.stringify(value);
      };

      const stringifiedRefQueryWithSetValues = stringifiedRefQuery.replace(REF_QUERY_REG_EXP, parseKey);
      const refQueryWithFilteredInvalidWhere = this.filterInvalidJoinWhere(
        JSON.parse(stringifiedRefQueryWithSetValues)
      );

      return await fetchJoinedDirectory(refQueryWithFilteredInvalidWhere);
    } catch (e) {
      console.error(e);
      throw e;
    }
  };

  @flow.bound
  async *fetchJoinedDirectory(refQuery: TRefQuery) {
    try {
      if (isDynamicJoin(refQuery)) {
        // Remove 'where' field to load all items.
        const simpleRefQuery: TRefQuery = {
          join: refQuery.join.map(({ where, ...joinItem }) => joinItem),
          objectType: refQuery.objectType,
          joinedAlias: refQuery.joinedAlias,
        };
        const res = await fetchJoinedDirectory(simpleRefQuery);
        yield;

        this.joinedObjects[JSON.stringify(refQuery)] = res;
        return res;
      } else {
        const res = await fetchJoinedDirectory(refQuery);
        yield;

        this.joinedObjects[JSON.stringify(refQuery)] = res;
        return res;
      }
    } catch (error) {
      console.error(error);
      return;
    }
  }
}
