export abstract class BaseOperationError extends Error {}

/**
 * Операция отменена вызывающей стороной
 */
export class OperationAbortedError extends BaseOperationError {}

/**
 * Операция прекратилась по внутренней причине
 */
export class OperationCancelledError extends BaseOperationError {}

export class OperationFailedError extends BaseOperationError {}

export class PlatformRejectsOperationError extends BaseOperationError {}
