import type { DevSettingsScreenEntity } from '../../dev-settings/DevSettingsScreen.entity';
import type { TRenderScreen, TSettingsScreenOptions } from '../SettingsScreenRendererService';

import { DevSettingsScreen } from '../../dev-settings/DevSettingsScreen';

export function renderDevSettingsScreen(
  screen: DevSettingsScreenEntity,
  options: TSettingsScreenOptions
): TRenderScreen {
  return () => <DevSettingsScreen stack={options.stack} screen={screen} />;
}
