import {
  FullArrowLight as FullIcon,
  SubttasksLight as SubstacksIcon,
  FilterAltLight as FilterIcon,
  WidgetAddLight as WidgetAddIcon,
} from '@profgeosoft-ui/icons';
import { Button, Loader } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useService } from 'src/packages/di';
import { NewContentWidgetModal } from 'src/pages/dashboard/features/new-content-widget-modal/NewContentWidgetModal';
import { Workbench } from 'src/pages/dashboard/features/workbench/Workbench';

import type { WellIndexType } from '@go-widgets/well-logs-widget';

import { MasterDisconnectedModal } from '../features/master-disconnected-modal/MasterDisconnectedModal';

import { TabName } from './components/tab-button/TabName';
import { SingleTabStore } from './SingleTab.store';

import styles from './SingleTab.module.scss';

export const SingleTab = observer(function SingleTab() {
  const { id: tabId } = useParams();

  const [store, setStore] = useState<SingleTabStore | null>(null);

  const { t } = useTranslation();

  const destroyTab = store?.windowsManager?.destroyTab;

  const workspaceRef = useRef<HTMLDivElement>(null);
  const { isFullscreenMode, enterFullscreen, exitFullscreen } = useService('fullscreenService');

  const handleEnterFullscreen = () => {
    if (!workspaceRef.current) {
      return;
    }
    enterFullscreen(workspaceRef.current);
  };

  const onCreateWidget = (type: string, wellIndexType?: WellIndexType) => {
    store?.newContentWidgetController?.resolve({ type, wellIndexType });
  };

  const onCloseNewContentWidgetModal = () => {
    store?.newContentWidgetController?.resolve(null);
  };

  useEffect(() => {
    if (tabId && !store) {
      setStore(new SingleTabStore(tabId));
    }
  }, [tabId, store]);

  const effect = store?.effect ?? (() => {});

  useEffect(effect, [effect]);

  if (store?.hasError) {
    return (
      <main className={styles.wrapper}>
        <p>Error. Please reload the page</p>
      </main>
    );
  }

  if (!store || store.isLoading || !store.actualTab) {
    return <Loader size="l" className={styles.loader} />;
  }

  const {
    actualTab,
    isUpdating,
    onCreateNewContentWidget,
    newContentWidgetController,
    masterConnectionController,
    recoverMaster,
    ingoreMasterRecovering,
  } = store;

  return (
    <main className={clsx(styles.wrapper, actualTab?.hasFullscreenWidget && styles.wrapperFullscreenMode)}>
      {isUpdating && <Loader className={styles.loader} />}

      <section className={styles.header}>
        <TabName onRemove={() => (tabId ? destroyTab?.(tabId) : void 0)}>{store.actualTab?.name}</TabName>
        <div className={styles.widgetsToolbar}>
          <Button
            variant="flat"
            icon={<FullIcon className={styles.icon} />}
            title={t('dashboard:Toolbar.enableFullScreen')}
            onClick={handleEnterFullscreen}
            className={styles.toolbarIcon}
          />
          <Button
            variant="flat"
            icon={<SubstacksIcon className={styles.icon} />}
            onClick={() => actualTab?.createWellListWidget()}
            className={styles.toolbarIcon}
          />
          <Button
            variant="flat"
            icon={<FilterIcon className={styles.icon} />}
            onClick={() => actualTab?.createWellsFilterWidget?.()}
            className={styles.toolbarIcon}
          />
          <Button
            variant="flat"
            icon={<WidgetAddIcon className={styles.icon} />}
            onClick={onCreateNewContentWidget}
            className={styles.toolbarIcon}
          />
        </div>
      </section>

      {actualTab && (
        <Workbench
          key={actualTab.id}
          isFullscreenMode={isFullscreenMode}
          tab={actualTab}
          groupManager={actualTab.groupManager}
          workspaceRef={workspaceRef}
          onExitFullscreen={exitFullscreen}
        />
      )}

      {actualTab && (
        <NewContentWidgetModal
          isOpened={!!newContentWidgetController}
          onCreateWidget={onCreateWidget}
          actualTab={actualTab}
          onCloseModal={onCloseNewContentWidgetModal}
        />
      )}

      <MasterDisconnectedModal
        isOpened={!!masterConnectionController}
        onConfirm={recoverMaster}
        onCancel={ingoreMasterRecovering}
      />
    </main>
  );
});
