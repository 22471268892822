import { parseWellAnalyseWidgetInternalState } from 'src/pages/dashboard/features/well-analyse-widget/parseWellAnalyseWidget';
import { WellAnalyseWidgetEntity } from 'src/pages/dashboard/features/well-analyse-widget/WellAnalyseWidget.entity';

import type { TSerializedWidget } from 'src/entities/workspace/types';

export function mapWellAnalyseWidget(widget: TSerializedWidget, index: number): WellAnalyseWidgetEntity {
  const internalState = parseWellAnalyseWidgetInternalState(widget.internalState);

  const newFilterWidget = new WellAnalyseWidgetEntity(
    {
      wellId: internalState.wellId,
      stateParams: internalState.stateParams,
      type: 'well-analyse-widget',
    },
    {
      uuid: widget.uuid,
      isPinned: !!widget.isPinned,
      zIndex: index + 1,
      isFullscreen: widget.isFullscreen ?? false,
      position: {
        x: widget?.position?.x ?? 0,
        y: widget?.position?.y ?? 0,
      },
      size: {
        w: widget.size?.w ?? 59,
        h: widget.size?.h ?? 316,
      },
      groupId: widget.groupId,
      isGroupsDefaultOpened: false,
    }
  );

  return newFilterWidget;
}
