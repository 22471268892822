import type { TabTemplatesListScreenEntity } from '../../tab-templates-list-screen/TabTemplatesListScreen.entity';
import type { TRenderScreen, TSettingsScreenOptions } from '../SettingsScreenRendererService';

import { TabTemplatesListScreen } from '../../tab-templates-list-screen/TabTemplatesListScreen';

export function renderTabTemplatesListScreen(
  screen: TabTemplatesListScreenEntity,
  options: TSettingsScreenOptions
): TRenderScreen {
  return () => <TabTemplatesListScreen stack={options.stack} screen={screen} />;
}
