import { ExpandDownLight as ArrowIcon } from '@profgeosoft-ui/icons';
import { Collapse, Scrollbar } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import type { Key, ReactNode } from 'react';

import styles from './ScreenCollapse.module.scss';

type Props<T> = {
  activeKeys: (string | number)[];
  collapses: T[];
  onOpenedCollapsesChange(collapses: Key[]): void;
  getCollapseName(collapse: T): string;
  getCollapseId(collapse: T): string;
  renderCollapseContent(collapse: T): ReactNode;
  renderCollapseButtons?(collapse: T): ReactNode;
};

export const ScreenCollapse = observer(function ScreenCollapse<T>({
  activeKeys,
  onOpenedCollapsesChange,
  getCollapseName,
  getCollapseId,
  renderCollapseButtons,
  renderCollapseContent,
  collapses,
}: Props<T>) {
  return (
    <Scrollbar>
      <Collapse collapseClassname={styles.collapse} activeKeys={activeKeys} onChange={onOpenedCollapsesChange}>
        {collapses.map((collapse) => {
          const id = getCollapseId(collapse);
          const isActive = activeKeys.includes(id);

          return (
            <Collapse.Panel
              panelKey={id}
              key={id}
              header={
                <div className={styles.collapseHeader}>
                  <ArrowIcon className={clsx(styles.arrowIcon, isActive && styles.arrowIconUp)} />
                  <div className={styles.collapseTitle}>
                    {getCollapseName(collapse)} {renderCollapseButtons?.(collapse)}
                  </div>
                </div>
              }
              stickyHeader
            >
              {renderCollapseContent(collapse)}
            </Collapse.Panel>
          );
        })}
      </Collapse>
    </Scrollbar>
  );
});
