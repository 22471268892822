import { action, computed, makeObservable, observable } from 'mobx';

import { requireService } from 'src/packages/di';

import type { WidgetTemplatesListScreenEntity } from './WidgetTemplatesListScreen.entity';
import type { BaseSettingsScreen } from '../screen/BaseSettingsScreen';
import type { Template, TemplatesManager, UserSettingsManager, DashboardService } from '@go-widgets/well-logs-widget';
import type { Stack } from 'src/packages/data/structures/Stack';
import type { WellLogsTemplatesManager } from 'src/packages/well-logs-template-service/WellLogsTemplatesManager';

export type TTemplateCollapse = {
  name: string;
  type: string;
  templates: Template[];
};

export class WidgetTemplatesListScreenStore {
  readonly stack: Stack<BaseSettingsScreen>;
  readonly screenEntity: WidgetTemplatesListScreenEntity;
  readonly templatesManager: TemplatesManager;

  private readonly templatesService: WellLogsTemplatesManager;
  private readonly userSettings: UserSettingsManager;
  private readonly settingsApi: DashboardService;

  @observable activeTemplateCollapses: string[] = ['public', 'private'];
  @observable selectedTemplateId?: number;

  constructor(
    stack: Stack<BaseSettingsScreen>,
    screen: WidgetTemplatesListScreenEntity,
    private readonly authService = requireService('authService')
  ) {
    this.stack = stack;
    this.screenEntity = screen;

    const wellLogsTemplateService = requireService('wellLogsTemplateService');

    this.templatesService = wellLogsTemplateService.getTemplatesManager(this.screenEntity.widgetSubType);

    this.settingsApi = this.templatesService.api;
    this.userSettings = this.templatesService.userSettings;
    this.templatesManager = this.templatesService.templatesManager;

    makeObservable(this);
  }

  @computed
  get collapses(): TTemplateCollapse[] {
    let collapses: TTemplateCollapse[] = [];

    if (this.templatesManager.publicTemplates) {
      collapses.push({
        name: 'settings:Templates.public',
        type: 'public',
        templates: this.templatesManager.publicTemplates,
      });
    }

    if (this.templatesManager.personalTemplates) {
      collapses.push({
        name: 'settings:Templates.private',
        type: 'private',
        templates: this.templatesManager.personalTemplates,
      });
    }

    return collapses;
  }

  @action.bound
  onTemplateCollapse(value: string[]): void {
    this.activeTemplateCollapses = value;
  }

  @action.bound
  setTemplateId(id: number): void {
    this.selectedTemplateId = id;
  }

  @action.bound
  selectWidgetTemplate(template: Template): void {
    if (template.id === this.selectedTemplateId || template.id === null) {
      return;
    }

    this.stack.setLength(2);

    this.screenEntity.createWidgetTemplateDetails(
      template,
      this.userSettings,
      this.templatesManager,
      this.screenEntity.widgetSubType
    );
    this.setTemplateId(template.id);
  }

  init = () => {
    const firstTemplate = this.templatesManager.publicTemplates?.[0] ?? this.templatesManager.personalTemplates?.[0];

    if (firstTemplate) {
      this.selectWidgetTemplate(firstTemplate);
    }
  };
}
