import { requireService } from 'src/packages/di';

import type { WorkspaceStore } from '../Workspace.store';
import type { TDashboardReceivingMessage } from 'src/packages/session-service/SessionService';

export class WorkspaceRefreshingService {
  private readonly workspace: WorkspaceStore;

  constructor(workspace: WorkspaceStore, private readonly sessionService = requireService('sessionService')) {
    this.workspace = workspace;
  }

  sendWorkspaceChanged(): void {
    this.sessionService.sendMessage('WORKSPACE-CHANGED');
  }

  private onWorkspaceChange = (message: TDashboardReceivingMessage): void => {
    if (message !== 'WORKSPACE-CHANGED') return;

    this.workspace.updateWorkspace();
  };

  init = (): void => {
    this.sessionService.subscribe(this.onWorkspaceChange);
  };

  dispose = (): void => {
    this.sessionService.unsubscribe(this.onWorkspaceChange);
  };
}
