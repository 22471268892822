import type { Deep, DeepPartial } from '../shared/types/DeepPartial';

import { BroadcastReadTunnel } from './BroadcastReadTunnel';
import { BroadcastWriteTunnel } from './BroadcastWriteTunnel';

export class BroadcastTunnel<TSentMessage, TReceivedMessage> {
  private readonly readTunnel: BroadcastReadTunnel<TReceivedMessage>;
  private readonly writeTunnel: BroadcastWriteTunnel<TSentMessage>;

  constructor(
    filterMessage: (data: DeepPartial<TReceivedMessage>) => data is Deep<TReceivedMessage>,
    handler: (event: TReceivedMessage) => void
  ) {
    this.readTunnel = new BroadcastReadTunnel(filterMessage, handler);
    this.writeTunnel = new BroadcastWriteTunnel();
  }

  postMessage(message: TSentMessage): void {
    this.writeTunnel.postMessage(message);
  }

  disconnect(): void {
    this.readTunnel.disconnect();
  }
}
