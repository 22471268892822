import { flow, makeObservable, observable, runInAction } from 'mobx';

import { WITH_WELLBORE_SORTING } from 'src/api/consts';
import { NotFoundApiError, OperationAbortedError } from 'src/app/errors';
import { requireService } from 'src/packages/di';

import type { WellboreApi, Wellbore } from './api/Wellbore.api';

export class Wellbores {
  @observable
  inProgress = false;

  @observable
  list: Wellbore[] = [];

  constructor(private wellDirectoryService: WellboreApi, private notifications = requireService('notifications')) {
    makeObservable(this);
  }

  @flow.bound
  async *fetchWellbores(wellId: number, abortController: AbortController) {
    this.inProgress = true;

    try {
      const data = await this.wellDirectoryService.getWellbores(wellId, abortController, WITH_WELLBORE_SORTING);
      yield;
      this.list = data;
      return this.list;
    } catch (e) {
      yield;
      if (!(e instanceof NotFoundApiError) && !(e instanceof OperationAbortedError)) {
        this.notifications.showErrorMessageT('imageBroadcast:error.wellboreList');
      }
      return [];
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }
}
