import { action, makeObservable } from 'mobx';

import { BroadcastTunnel } from 'src/packages/broadcast-tunnel/BroadcastTunnel';
import { storage } from 'src/packages/shared/consts/storage';

import type { MasterWindowsManager } from './MasterWindowsManager';
import type { TTabDeletedMessage } from '../types';
import type { WorkspaceStore } from '../Workspace.store';
import type { TDeleteTabMessage } from 'src/pages/tab/single-tab/SingleTab.types';

const EXTERNAL_TABS_KEY = 'external-tabs';

export class ExternalTabClosingService {
  private readonly store: WorkspaceStore;
  private readonly windowsManager: MasterWindowsManager;
  private readonly tabClosingTunnel: BroadcastTunnel<TDeleteTabMessage, TTabDeletedMessage>;

  constructor(store: WorkspaceStore, windowsManager: MasterWindowsManager) {
    this.store = store;
    this.windowsManager = windowsManager;

    this.tabClosingTunnel = new BroadcastTunnel((message): message is TTabDeletedMessage => {
      if (message?.type !== 'tab-deleted' || message?.to?.id !== this.store.id) return false;

      return true;
    }, this.onTabClosed);

    makeObservable(this);
  }

  @action.bound
  private onTabClosed = (message: TTabDeletedMessage): void => {
    this.removeClosedTab(message.from.id);
  };

  @action.bound
  removeClosedTab(id: string): void {
    this.store.removeExternalTab(id);
    this.windowsManager.removeWindowRef(id);

    storage.SET<string[]>(
      EXTERNAL_TABS_KEY,
      this.store.externalTabs.map((tab) => tab.id)
    );
  }

  closeTab = (id: string): void => {
    this.tabClosingTunnel.postMessage({
      type: 'delete-tab',
      from: {
        type: 'master',
        id: this.store.id,
      },
      to: {
        id,
      },
    });
  };

  dispose = (): void => {
    this.tabClosingTunnel.disconnect();
  };
}
