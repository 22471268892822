import { WellListWidgetEntity } from 'src/pages/dashboard/features/well-list-widget/WellListWidget.entity';
import { parseWellListInternalState } from 'src/pages/dashboard/features/well-list-widget/WellListWidget.utils';

import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

export function mapWellListTemplate(template: TSerializedWidgetTemplate, index: number): WellListWidgetEntity {
  const internalState = parseWellListInternalState(template?.internalState ?? {});

  const { size, position } = template;

  const newWidget = new WellListWidgetEntity(
    {
      groupBy: internalState.groupBy ?? null,
      filterBy: internalState.filterBy ?? null,
      filterState: internalState.filterState ?? null,
      searchValue: null,
      selectedWellID: internalState.selectedWellID ?? null,
      collapsedKeys: null,
      type: 'well-list-control',
    },
    {
      uuid: template.uuid,
      isPinned: false,
      zIndex: index + 1,
      isFullscreen: false,
      position: {
        x: position.x,
        y: position.y,
      },
      size: {
        w: size.w,
        h: size.h,
      },
      groupId: template.groupId ?? null,
      isGroupsDefaultOpened: false,
    }
  );

  return newWidget;
}
