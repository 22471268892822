import { CloseRoundLight as CloseIcon } from '@profgeosoft-ui/icons';
import { Button, Tooltip } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { GroupSelectStore } from '../group-select/GroupSelect.store';
import type { ReactNode } from 'react';
import type { TGroup } from 'src/entities/tab/TabEntity';
import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';

import { GroupSelect } from '../group-select/GroupSelect';

import styles from './WidgetHeader.module.scss';

type Props = {
  title: string;
  groupSelectStore: GroupSelectStore;
  onWidgetDelete(): void;
  onWidgetChangeGroup(group: TGroup | null): void;
  showCloseButton?: boolean;
  draggable?: boolean;
  entity: WidgetEntity;
  renderAdditionalButtons?: () => ReactNode;
  progress?: number | null;
  sectionTitle?: string;
  widgetTitleClassName?: string;
  widgetNameClassName?: string;
};

export const WidgetHeader = observer(function WidgetHeader({
  title,
  groupSelectStore,
  showCloseButton = true,
  draggable = true,
  entity,
  onWidgetDelete,
  onWidgetChangeGroup,
  renderAdditionalButtons,
  sectionTitle,
  progress,
  widgetTitleClassName,
  widgetNameClassName,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className={styles.header}>
      <div className={clsx(styles.widgetTitle, widgetTitleClassName && widgetTitleClassName)}>
        <GroupSelect
          groupSelectStore={groupSelectStore}
          isDefaultOpened={entity.isGroupsDefaultOpened}
          selectedGroup={groupSelectStore.groupManager.groups.find((group) => group.id === entity.groupId)}
          onWidgetChangeGroup={onWidgetChangeGroup}
        />
        <div
          className={clsx(
            styles.draggableHandle,
            draggable && styles.draggableHandleDraggable,
            draggable && 'drag-handle'
          )}
        >
          <span className={clsx(styles.widgetName, widgetNameClassName && widgetNameClassName)}>{title}</span>
        </div>
      </div>

      <div
        className={clsx(
          styles.widgetSection,
          styles.draggableHandle,
          draggable && styles.draggableHandleDraggable,
          draggable && 'drag-handle'
        )}
      >
        <span className={styles.sectionName}>{sectionTitle}</span>
      </div>

      <div className={styles.controls}>
        <div
          className={clsx(
            styles.draggableHandle,
            draggable && styles.draggableHandleDraggable,
            draggable && 'drag-handle'
          )}
        ></div>
        {progress != null && <div className={styles.progressText}>{progress}%</div>}
        {renderAdditionalButtons?.()}
        {showCloseButton && (
          <Tooltip title={t('common:Widget.close')} delay={400}>
            <Button
              variant="flat"
              onClick={onWidgetDelete}
              icon={<CloseIcon className={styles.controlsButtonIcon} />}
              className={styles.controlsButton}
            />
          </Tooltip>
        )}
      </div>
      <div className={clsx(styles.progressBarContainer, !progress && styles.progressBarContainer__hidden)}>
        <div className={styles.progressBar} style={{ width: `${progress}%` }} />
      </div>
    </div>
  );
});
