import { DatePicker } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import type { ReactNode } from 'react';

import { InputError } from '../input-error/InputError';
import { InputLabel } from '../input-label/InputLabel';

import { DATETIME_FORMAT, DATE_FORMAT } from './consts';

import styles from './FormDatePicker.module.scss';

type Props = {
  allowClear?: boolean;
  value?: Date | null;
  label?: ReactNode;
  errorText?: string;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  format?: string;
  defaultTime?: Date;
  defaultDate?: Date;
  withTime?: boolean;
  ghostLabel?: boolean;
  showCalendarIcon?: boolean;
  disabledDate?: (date: Date) => boolean;
  onChange(value: Date | null): void;
  onBlur?(): void;
  onFocus?(): void;
};

export const FormDatePicker = observer(function FormDatePicker({
  allowClear,
  value = null,
  label,
  className,
  disabled,
  withTime,
  errorText,
  defaultDate,
  placeholder,
  ghostLabel = false,
  format = withTime ? DATETIME_FORMAT : DATE_FORMAT,
  disabledDate,
  onChange,
  onBlur,
  onFocus,
}: Props) {
  const labelRender = () => {
    if (typeof label === 'string') {
      return <InputLabel text={label} className={clsx(!ghostLabel && styles.label)} />;
    }

    return label;
  };

  return (
    <div className={clsx(styles.inputWrapper, className)}>
      {labelRender()}
      <DatePicker
        value={value}
        disabled={disabled}
        dateFormat={format}
        defaultValue={defaultDate}
        showTime={withTime}
        isDateDisabled={disabledDate}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        allowClear={allowClear}
      />
      {!!errorText && <InputError errorTextRaw={errorText} />}
    </div>
  );
});
