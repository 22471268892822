import { BookmarkFill as FlagIcon } from '@profgeosoft-ui/icons';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import styles from './TemplatesList.module.scss';

type TTemplateItem = {
  name: string;
  description: string | null;
  id: string | number | null;
};

type Props<T> = {
  templates: T[];
  activeKey?: string | number;
  defaultTemplateId?: number | null;
  onClick(template: T): void;
  adaptTemplateToItem(template: T): TTemplateItem;
};

export const TemplatesList = observer(function TemplatesList<T>({
  templates,
  onClick,
  adaptTemplateToItem,
  activeKey,
  defaultTemplateId,
}: Props<T>) {
  return (
    <ul className={styles.list}>
      {templates.map((template) => {
        const adaptedTemplate = adaptTemplateToItem(template);

        return (
          <li
            key={adaptedTemplate.id}
            className={clsx(styles.item, activeKey === adaptedTemplate.id && styles.item__active)}
          >
            <button type="button" onClick={() => onClick(template)} className={styles.itemButton}>
              <div className={styles.itemTitleWrapper}>
                <p className={styles.itemName}>{adaptedTemplate.name}</p>
                <p className={styles.itemDesc}>{adaptedTemplate.description}</p>
              </div>
              {adaptedTemplate.id === defaultTemplateId && <FlagIcon className={styles.icon} />}
            </button>
          </li>
        );
      })}
    </ul>
  );
});
