import { FilterWidgetEntity } from 'src/pages/dashboard/features/filter-widget/FilterWidget.entity';
import { parseWellsFilterInternalState } from 'src/pages/dashboard/features/filter-widget/FilterWidget.utils';

import type { TSerializedWidget } from 'src/entities/workspace/types';

export function mapWellsFilter(widget: TSerializedWidget, index: number): FilterWidgetEntity {
  const internalState = parseWellsFilterInternalState(widget.internalState);

  const newFilterWidget = new FilterWidgetEntity(
    {
      isEditMode: internalState.isEditMode,
      state: internalState.state,
      initialState: internalState.initialState,
      type: 'filter-control',
    },
    {
      uuid: widget.uuid,
      isPinned: !!widget.isPinned,
      zIndex: index + 1,
      isFullscreen: widget.isFullscreen ?? false,
      position: {
        x: widget?.position?.x ?? 0,
        y: widget?.position?.y ?? 0,
      },
      size: {
        w: widget.size?.w ?? 35,
        h: widget.size?.h ?? 384,
      },
      groupId: widget.groupId,
      isGroupsDefaultOpened: false,
    }
  );

  return newFilterWidget;
}
