import { ImportLight as ImportIcon } from '@profgeosoft-ui/icons';
import { Loader, Tooltip } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import Upload from 'rc-upload';
import { useTranslation } from 'react-i18next';

import { useService } from 'src/packages/di';
import { useViewStore } from 'src/packages/shared/hooks/useViewStore';

import type { WidgetTemplatesListScreenEntity } from './WidgetTemplatesListScreen.entity';
import type { TTemplateCollapse } from './WidgetTemplatesListScreen.store';
import type { BaseSettingsScreen } from '../screen/BaseSettingsScreen';
import type { Template } from '@go-widgets/well-logs-widget';
import type { RcFile } from 'rc-upload/lib/interface';
import type { Stack } from 'src/packages/data/structures/Stack';

import { ScreenCollapse } from '../screen-collapse/ScreenCollapse';
import { TemplatesList } from '../templates-list/TemplatesList';

import { WidgetTemplatesListScreenStore } from './WidgetTemplatesListScreen.store';

import styles from './WidgetTemplatesListScreen.module.scss';

type Props = {
  stack: Stack<BaseSettingsScreen>;
  screen: WidgetTemplatesListScreenEntity;
};

// TODO: pass user role
const dummyUserRole = 'admin';

export const WidgetTemplatesListScreen = observer(function WidgetTemplatesListScreen({ stack, screen }: Props) {
  const store = useViewStore(() => new WidgetTemplatesListScreenStore(stack, screen));

  const { t } = useTranslation();

  const notifications = useService('notifications');

  const {
    templatesManager,
    activeTemplateCollapses,
    onTemplateCollapse,
    selectWidgetTemplate,
    selectedTemplateId,
    collapses,
  } = store;

  const onUploadFailure = (error: Error) => {
    console.error(error);
    notifications.showErrorMessageT('FailedToImportTemplate');
  };

  const onFileUpload = async (file: RcFile, isPublic: boolean) => {
    try {
      if (!file.size) return;

      const text = await file.text();

      if (isPublic) {
        templatesManager?.importTemplateToPublic(text);
      } else {
        templatesManager?.importTemplateToPersonal(text);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderCollapseContent = (collapse: TTemplateCollapse) => (
    <TemplatesList<Template>
      templates={collapse.templates}
      adaptTemplateToItem={(template: Template) => template}
      activeKey={selectedTemplateId}
      onClick={selectWidgetTemplate}
      defaultTemplateId={templatesManager?.defaultTemplateId}
    />
  );

  const renderCollapseButtons = (collapse: TTemplateCollapse) => {
    const isPublic = collapse.type === 'public';
    const isPrivate = collapse.type === 'private';
    const showCopyButton = (isPrivate && dummyUserRole === 'admin') || isPublic;

    if (showCopyButton) {
      return (
        <Tooltip title={t('settings:importTemplate')} useSafePolygon={false} placement="top" delay={400}>
          <button onClick={(e) => e.stopPropagation()} className={styles.importButton}>
            <Upload
              onSuccess={(_, file) => onFileUpload(file, isPublic)}
              onError={onUploadFailure}
              accept=".json"
              customRequest={({ file, onSuccess }) => {
                onSuccess?.(file);
              }}
            >
              <ImportIcon className={styles.icon} />
            </Upload>
          </button>
        </Tooltip>
      );
    }

    return null;
  };

  return (
    <article className={styles.column}>
      {templatesManager.isFetching && <Loader className={styles.loader} />}

      <ScreenCollapse<TTemplateCollapse>
        activeKeys={activeTemplateCollapses}
        onOpenedCollapsesChange={onTemplateCollapse}
        getCollapseName={(collapse) => t(collapse.name)}
        getCollapseId={(collapse) => collapse.type}
        renderCollapseContent={renderCollapseContent}
        renderCollapseButtons={renderCollapseButtons}
        collapses={collapses}
      />
    </article>
  );
});
