import { action, makeObservable } from 'mobx';

import { BroadcastTunnel } from 'src/packages/broadcast-tunnel/BroadcastTunnel';
import { storage } from 'src/packages/shared/consts/storage';
import { isStringArray } from 'src/packages/shared/utils/is-string-array';
import { serializeTabs } from 'src/serializers/tab/serializeTab';

import type { TTabCreatedMessage } from '../types';
import type { WorkspaceStore } from '../Workspace.store';
import type { TabEntity } from 'src/entities/tab/TabEntity';
import type { TTabDataMessage } from 'src/pages/tab/single-tab/SingleTab.types';

const EXTERNAL_TABS_KEY = 'external-tabs';

export class ExternalTabOpeningService {
  private readonly store: WorkspaceStore;
  private readonly tabOpeningTunnel: BroadcastTunnel<TTabDataMessage, TTabCreatedMessage>;

  constructor(store: WorkspaceStore) {
    this.store = store;

    this.tabOpeningTunnel = new BroadcastTunnel((message): message is TTabCreatedMessage => {
      if (message?.type !== 'tab-created' || message?.to?.type !== 'master') return false;

      return true;
    }, this.onTabOpen);

    makeObservable(this);
  }

  private sendTabData = (tab: TabEntity): void => {
    this.tabOpeningTunnel.postMessage({
      type: 'tab-data',
      from: {
        type: 'master',
        id: this.store.id,
      },
      to: {
        id: tab.id,
      },
      data: serializeTabs([tab])[0],
    });
  };

  @action.bound
  private onTabOpen = (message: TTabCreatedMessage): void => {
    const tabId = message.from.id;

    const targetTab = this.store.tabsEntities.find((tab) => tab.id === tabId);

    if (!targetTab) return;

    this.sendTabData(targetTab);

    const externalTabs = storage.GET<string[] | null>(EXTERNAL_TABS_KEY) ?? [];

    if (!isStringArray(externalTabs)) return;

    if (!externalTabs.includes(tabId)) {
      externalTabs.push(tabId);
      storage.SET<string[]>(EXTERNAL_TABS_KEY, externalTabs);
    }

    this.store.initExternalTab(targetTab.id);
    this.store.adjustActualTab();
  };

  dispose = (): void => {
    this.tabOpeningTunnel.disconnect();
  };
}
