import { AddRingFill as PlusIcon } from '@profgeosoft-ui/icons';
import { Button, Tooltip } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useService } from 'src/packages/di';
import { useOutsideClick } from 'src/packages/shared/hooks/use-outside-click';

import type { GroupSelectStore } from './GroupSelect.store';
import type { TGroup } from 'src/entities/tab/TabEntity';

import styles from './GroupSelect.module.scss';

type Props = {
  groupSelectStore: GroupSelectStore;
  onWidgetChangeGroup(group: TGroup | null): void;
  selectedGroup?: null | TGroup;
  isDefaultOpened?: boolean;
};

export const GroupSelect = observer(function GroupSelect({
  groupSelectStore,
  selectedGroup,
  isDefaultOpened,
  onWidgetChangeGroup,
}: Props) {
  const { t } = useTranslation();
  const [isGroupsOpen, setGroupsOpen] = useState(isDefaultOpened ?? false);

  const containerRef = useRef<HTMLUListElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  useOutsideClick(containerRef, () => setGroupsOpen(false), [buttonRef]);

  const directories = useService('directories');

  const wells = directories.getObject('GOdashboard_Well');

  const renderPopoverContent = () => (
    <ul className={styles.groupsList} ref={containerRef}>
      {groupSelectStore.groupManager.groups.map((group) => {
        const selectedWell = groupSelectStore.getWellIdInGroup(group.id);
        const usesByAnotherWidgets = groupSelectStore.getUsesByAnotherWidgets(group.id);

        const wellName = wells?.find((item) => item.id === selectedWell)?.data.name ?? selectedWell;

        return (
          <li key={group.id} className={styles.groupItem}>
            <button type="button" className={styles.groupButton} onClick={() => onWidgetChangeGroup(group)}>
              <figure className={styles.groupCircle} style={{ backgroundColor: group.color }} />
              <p className={styles.groupName}>
                {t('widgets:group', { groupId: group.id })}{' '}
                {!selectedWell && usesByAnotherWidgets ? `(${t('widgets:used')})` : ''}
                {selectedWell && `(${wellName})`}
              </p>
            </button>
          </li>
        );
      })}
    </ul>
  );

  return (
    <div className={styles.wrapper} ref={buttonRef}>
      {selectedGroup ? (
        <button
          type="button"
          style={{ color: selectedGroup.color }}
          className={styles.clearGroupButton}
          onClick={() => {
            onWidgetChangeGroup(null);
            setGroupsOpen(false);
          }}
        >
          <PlusIcon className={styles.clearGroupIcon} />
          <figure className={styles.clearGroupCircle} style={{ backgroundColor: selectedGroup.color }} />
        </button>
      ) : (
        <Tooltip
          title={renderPopoverContent()}
          open={isGroupsOpen}
          trigger={['click']}
          className={styles.popover}
          onOpenChange={setGroupsOpen}
          withArrow={true}
        >
          <Button
            variant="flat"
            onClick={() => setGroupsOpen(true)}
            icon={<PlusIcon className={styles.groupIcon} />}
            className={styles.addGroupButton}
          />
        </Tooltip>
      )}
    </div>
  );
});
