import { action, makeObservable } from 'mobx';

import { BroadcastTunnel } from 'src/packages/broadcast-tunnel/BroadcastTunnel';

import type { TMasterSearchMessage } from '../types';
import type { WorkspaceStore } from '../Workspace.store';
import type { TMasterDetectingMessage } from 'src/pages/tab/single-tab/SingleTab.types';

export class MasterDetectingService {
  private readonly store: WorkspaceStore;
  private readonly masterDetectingTunnel: BroadcastTunnel<TMasterDetectingMessage, TMasterSearchMessage>;

  constructor(store: WorkspaceStore) {
    this.store = store;

    this.masterDetectingTunnel = new BroadcastTunnel((message): message is TMasterSearchMessage => {
      if (message?.type !== 'find-master' || message?.from?.type !== 'slave') return false;

      return true;
    }, this.onSearchMaster);

    makeObservable(this);
  }

  @action.bound
  private onSearchMaster = (message: TMasterSearchMessage): void => {
    const targetTab = this.store.tabsEntities.find((tab) => tab.id === message.from.id);

    if (!targetTab) return;

    this.masterDetectingTunnel.postMessage({
      type: 'master-detected',
      from: {
        type: 'master',
        id: this.store.id,
      },
      to: {
        id: message.from.id,
      },
    });
  };

  dispose = (): void => {
    this.masterDetectingTunnel.disconnect();
  };
}
