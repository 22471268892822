import { WellLogsWidgetState } from '@go-widgets/well-logs-widget';
import { action, comparer, flow, makeObservable, observable, reaction } from 'mobx';

import { SPLIT_RANGE_DEPTH, SPLIT_RANGE_TIME, WITH_WELLBORE_SORTING } from 'src/api/consts';
import { lagacyAgent } from 'src/api/legacyAgent';
import { wellLogsWidgetAgent } from 'src/api/wellLogsWidgetAgent';
import { requireService, requireServiceAccessor } from 'src/packages/di';
import { IS_PORTABLE_DEVICE } from 'src/packages/shared/consts/isPortableDevice';
import { getNewAndDeletedFields } from 'src/packages/shared/utils/getNewAndDeletedElements';

import type { WellLogsWidgetEntity } from './WellLogsWidget.entity';
import type { GroupSelectStore } from '../group-select/GroupSelect.store';
import type { InitParams, WellIndexType, WidgetStateParams } from '@go-widgets/well-logs-widget';
import type { GlobalConfig } from '@profgeosoft-ui/react';
import type { IReactionDisposer, ObservableMap } from 'mobx';
import type { TCreateWidgetOptions, TGroup } from 'src/entities/tab/TabEntity';
import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';

import { setWellIdInGroup } from '../../utils/setWellIdInGroup';
import { WellListWidgetEntity } from '../well-list-widget/WellListWidget.entity';

import { DEFAULT_SPLIT_RANGE_DEPTH, DEFAULT_SPLIT_RANGE_TIME } from './consts';
import { getCorrectLocale } from './WellLogsWidget.utils';

export class WellLogsWidgetStore {
  private readonly wellLogsEntity: WellLogsWidgetEntity;
  private readonly groupSelectStore: GroupSelectStore;
  private readonly initParams: InitParams;

  @observable isLoading: boolean = false;
  @observable isExitFullscreenFetching: boolean = false;
  @observable isReady: boolean = false;
  @observable statusBarValue?: number | null;
  @observable hasError: boolean = false;
  @observable disposers: ObservableMap<WidgetEntity, IReactionDisposer> = observable.map();
  @observable progress: number | null = null;

  onCreateWellLogsWidget: (
    wellIndexType: WellIndexType,
    wellId: number | null,
    stateParams: Partial<WidgetStateParams> | null,
    options?: TCreateWidgetOptions
  ) => void;

  constructor(
    wellLogsEntity: WellLogsWidgetEntity,
    groupSelectStore: GroupSelectStore,
    onCreateWellLogsWidget: (
      wellIndexType: WellIndexType,
      wellId: number | null,
      stateParams: Partial<WidgetStateParams> | null,
      options?: TCreateWidgetOptions
    ) => void,
    private readonly i18 = requireService('i18'),
    private readonly theme = requireService('theme'),
    private readonly stompPublisherService = requireService('stompPublisherService'),
    private readonly notifications = requireService('notifications'),
    private readonly language = requireService('language'),
    private readonly wellLogsTemplateService = requireService('wellLogsTemplateService'),
    private readonly getAuthService = requireServiceAccessor('authService'),
    private readonly classOfUnitsService = requireService('classOfUnitsService')
  ) {
    this.onCreateWellLogsWidget = onCreateWellLogsWidget;

    this.wellLogsEntity = wellLogsEntity;
    const { wellIndexType } = wellLogsEntity;
    this.groupSelectStore = groupSelectStore;
    const wellLogsTemplatesManager = this.wellLogsTemplateService.getTemplatesManager(wellIndexType);

    this.initParams = {
      widgetType: 'desktop',
      legacyAxiosInstance: lagacyAgent,
      index: wellIndexType,
      stompPublisher: this.stompPublisherService.stompPublisher,
      axiosInstance: wellLogsWidgetAgent,
      userData: {
        sub: this.getAuthService().userInfo.sub,
      },
      dashboardService: wellLogsTemplatesManager.api,
      templatesManager: wellLogsTemplatesManager.templatesManager,
      userSettingsManager: wellLogsTemplatesManager.userSettings,
      withWellboreSorting: WITH_WELLBORE_SORTING,
      splitRange: {
        time: SPLIT_RANGE_TIME != null ? Number(SPLIT_RANGE_TIME) : DEFAULT_SPLIT_RANGE_TIME,
        depth: SPLIT_RANGE_DEPTH != null ? Number(SPLIT_RANGE_DEPTH) : DEFAULT_SPLIT_RANGE_DEPTH,
      },
      classOfUnitsService,
    };

    makeObservable(this);
  }

  @action.bound
  enableEditMode(): void {
    if (IS_PORTABLE_DEVICE) return;

    this.wellLogsEntity.setFullscreen(true);
    this.wellLogsEntity.state?.enterEditMode();
  }

  @flow.bound
  async *exitFullScreen(themeConfig: GlobalConfig) {
    if (this.isExitFullscreenFetching) return;

    this.isExitFullscreenFetching = true;

    const enableToClose = await this.wellLogsEntity.state?.exitEditModeAsync(themeConfig);

    yield;

    if (enableToClose) {
      this.wellLogsEntity.setFullscreen(false);
    }

    this.isExitFullscreenFetching = false;
  }

  handleExport = (): void => {
    this.wellLogsEntity.state?.setExportsOpen(true);
  };

  @action.bound
  setGroupForWellLogs(group: TGroup | null): void {
    this.wellLogsEntity.setGroup(group ? group.id : null);
  }

  @action.bound
  setWellId(well: number | null): void {
    this.wellLogsEntity.setWellId(well);
  }

  private mapNewWidgets(widgets: WidgetEntity[]): void {
    for (const widget of widgets) {
      if (!(widget instanceof WellListWidgetEntity) || this.disposers.has(widget)) {
        continue;
      }

      const disposeWellListWidget = reaction(
        () => widget.selectedWellID,
        (wellId) => {
          this.wellLogsEntity.setWellId(wellId);
        }
      );

      this.disposers.set(widget, disposeWellListWidget);
    }
  }

  @action.bound
  private setReady(value: boolean): void {
    this.isReady = value;
  }

  @action.bound
  private setProgress(value: number | null) {
    this.progress = value;
  }

  @action.bound
  private setInActiveTab(value: boolean) {
    this.wellLogsEntity.setInActiveTab(value);
  }

  private mapDeletedWidgets(widgets: WidgetEntity[]): void {
    for (const widget of widgets) {
      if (this.disposers.has(widget)) {
        const disposer = this.disposers.get(widget);

        disposer?.();

        this.disposers.delete(widget);
      }
    }
  }

  private getNewState(wellId: number): WellLogsWidgetState {
    return new WellLogsWidgetState(
      this.initParams,
      {
        // TODO: remove hardcode
        wellId,
        width: 500,
        height: 500,
        locale: getCorrectLocale(this.language.language),
        theme: this.theme.theme,
        fullScreen: this.wellLogsEntity.isFullscreen,
        active: this.wellLogsEntity.inActiveTab,
      },
      this.wellLogsEntity.stateParams,
      {
        onEditModeChanged: (state) => {
          this.wellLogsEntity.setEditMode(state);
        },
        onInitializedSuccess: () => {
          this.setReady(true);
        },
        onInitializationFailed: (_, error) => {
          this.setReady(false);

          console.error(error);
          this.notifications.showErrorMessageT('errors:failedToInitWidget');
        },
        onInternalStateChanged: (state) => {
          this.wellLogsEntity.setStateParams(state);
        },
        onCloneWidgetRequested: (state) => {
          const { wellIndexType, wellId } = this.wellLogsEntity;
          this.onCreateWellLogsWidget(wellIndexType, wellId, state);
        },
        onLoaderStatusChanged: (state) => {
          this.setProgress(state ? Math.round(state) : state);
        },
      }
    );
  }

  effect = () => {
    this.setInActiveTab(true);

    const disposeGroupId = reaction(
      () => this.wellLogsEntity.groupId,
      (groupId) => {
        if (groupId === null) return;

        const widgets = this.groupSelectStore.groupManager.getWidgetsByGroupId(groupId);
        setWellIdInGroup(widgets, this.wellLogsEntity);
      }
    );

    const dispose = reaction(
      () => this.groupSelectStore.groupManager.getWidgetsByGroupId(this.wellLogsEntity.groupId).slice(),
      (widgets, prevState) => {
        const prevWidgets = prevState ?? [];

        const { newElements: newWidgets, deletedElements: deletedWidgets } = getNewAndDeletedFields<WidgetEntity>(
          widgets,
          prevWidgets
        );

        this.mapNewWidgets(newWidgets);
        this.mapDeletedWidgets(deletedWidgets);
      },
      { fireImmediately: true, equals: comparer.shallow }
    );

    const disposeWellId = reaction(
      () => this.wellLogsEntity.wellId,
      (wellId, prevWellId) => {
        if (wellId && !this.wellLogsEntity.state) {
          const newState = this.getNewState(wellId);

          this.wellLogsEntity.setState(newState);
        } else if (wellId && this.wellLogsEntity.state && typeof prevWellId !== 'undefined') {
          this.wellLogsEntity.state.setAppParams({ wellId });
        } else if (!wellId && this.wellLogsEntity.state) {
          this.wellLogsEntity.state.destroy();
          this.wellLogsEntity.setState(null);
        }
      },
      { fireImmediately: true }
    );

    const disposeStateParams = reaction(
      () => ({ theme: this.theme.theme, language: this.language.language }),
      ({ theme, language }) => {
        this.wellLogsEntity.state?.setAppParams({
          theme,
          locale: getCorrectLocale(language),
        });
      },
      {
        fireImmediately: true,
      }
    );

    return () => {
      this.setInActiveTab(false);
      dispose();
      disposeGroupId();
      disposeWellId();
      disposeStateParams();
    };
  };
}
