import { flow, makeObservable, observable, runInAction } from 'mobx';

import { NotFoundApiError, OperationAbortedError } from 'src/app/errors';
import { requireService } from 'src/packages/di';

import type { BroadcastObject, WellboreApi } from './api/Wellbore.api';

export class ImageBroadcasts {
  @observable
  inProgress = false;

  @observable
  list: BroadcastObject[] = [];

  constructor(private wellDirectoryService: WellboreApi, private notifications = requireService('notifications')) {
    makeObservable(this);
  }

  @flow.bound
  async *fetchBroadcasts(wellboreId: number, abortController: AbortController) {
    this.inProgress = true;

    try {
      const data = await this.wellDirectoryService.getBroadcasts(wellboreId, abortController);
      yield;
      this.list = data;

      return data;
    } catch (e) {
      yield;
      if (!(e instanceof NotFoundApiError) && !(e instanceof OperationAbortedError)) {
        this.notifications.showErrorMessageT('imageBroadcast:error.broadcastList');
      }
      return [];
    } finally {
      runInAction(() => {
        this.inProgress = false;
      });
    }
  }

  clear() {
    this.list.length = 0;
  }
}
