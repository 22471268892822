import { action, makeObservable } from 'mobx';

import { BroadcastTunnel } from 'src/packages/broadcast-tunnel/BroadcastTunnel';
import { hasValue } from 'src/packages/shared/utils/common';

import type { SlaveWindowsManager } from './SlaveWindowsManager';
import type { SingleTabStore } from '../SingleTab.store';
import type { TMasterDetectingMessage } from '../SingleTab.types';
import type { TMasterSearchMessage } from 'src/pages/dashboard/features/workspace/types';

const SEARCH_MASTER_INTERVAL_DELAY = 1000;

export class MasterSearchService {
  private readonly store: SingleTabStore;
  private readonly windowsManager: SlaveWindowsManager;
  private readonly masterSearchTunnel: BroadcastTunnel<TMasterSearchMessage, TMasterDetectingMessage>;

  private intervalId: number | null = null;

  constructor(store: SingleTabStore, windowsManager: SlaveWindowsManager) {
    this.store = store;
    this.windowsManager = windowsManager;

    this.masterSearchTunnel = new BroadcastTunnel((message): message is TMasterDetectingMessage => {
      if (message.type !== 'master-detected' || message?.to?.id !== this.tabId || message?.from?.type !== 'master') {
        return false;
      }

      return true;
    }, this.onMasterDetecting);

    makeObservable(this);
  }

  private get tabId(): string {
    return this.store.tabId;
  }

  @action.bound
  private onMasterDetecting = (message: TMasterDetectingMessage): void => {
    this.windowsManager.setMasterStatus(true);
    this.windowsManager.setMasterId(message.from.id);
    this.windowsManager.sendTabStatus();
  };

  private findMaster(): void {
    this.masterSearchTunnel.postMessage({
      type: 'find-master',
      from: {
        type: 'slave',
        id: this.tabId,
      },
      to: {
        type: 'master',
      },
    });
  }

  init = (): void => {
    this.findMaster();

    this.intervalId = window.setInterval(() => {
      if (this.windowsManager.master.isConnected) return;

      this.findMaster();
    }, SEARCH_MASTER_INTERVAL_DELAY);
  };

  dispose = (): void => {
    if (hasValue(this.intervalId)) {
      clearInterval(this.intervalId);
    }

    this.masterSearchTunnel.disconnect();
  };
}
