import { parseWellDetailsWidgetInternalState } from 'src/pages/dashboard/features/well-details-widget/parseWellDetailsWidget';

import type { TSerializedWidget } from 'src/entities/workspace/types';
import type { WellDetailsWidgetEntity } from 'src/pages/dashboard/features/well-details-widget/WellDetailsWidget.entity';

import { baseWidgetApplyChanges } from './baseWidgetApplyChanges';

export function wellDetailsApplyChanges(widget: WellDetailsWidgetEntity, changes: TSerializedWidget, index: number) {
  baseWidgetApplyChanges(widget, changes, index);

  const internalState = parseWellDetailsWidgetInternalState(changes.internalState);

  widget.setSection(internalState.section);
  widget.setWellId(internalState.wellId);
}
