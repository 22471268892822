import { requireService } from 'src/packages/di';

import type { Deep, DeepPartial } from '../shared/types/DeepPartial';

export class BroadcastReadTunnel<T> {
  private handler: (event: Deep<T>) => void;

  constructor(
    filterMessage: (data: DeepPartial<T>) => data is Deep<T>,
    handler: (event: T) => void,
    private readonly appBroadcastService = requireService('appBroadcastService')
  ) {
    this.handler = (data: Deep<T>) => {
      if (filterMessage(data)) {
        handler(data);
      }
    };

    this.appBroadcastService.addEventListener('message', this.handler);
  }

  disconnect(): void {
    this.appBroadcastService.removeEventListener(this.handler);
  }
}
