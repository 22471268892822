import { goStorageAgent } from 'src/api/goStorageAgent';
import { throwApiError } from 'src/api/utils';

import type { TDictObject, TJoinResponse, TRefQuery, TValuesInterpretations } from '../types';
import type { AxiosResponse } from 'axios';

export const fetchDirectory = async <T = TDictObject[]>(dirName: string): Promise<T> => {
  try {
    const res = await goStorageAgent.get<T>(`object/list/${dirName}`);
    return res.data;
  } catch (error) {
    throwApiError(error);
  }
};

export async function fetchJoinedDirectory(refQuery: TRefQuery, flatResponse = false): Promise<TJoinResponse[]> {
  try {
    const res = await goStorageAgent.post<TRefQuery, AxiosResponse<TJoinResponse[]>>('object/select', refQuery, {
      params: {
        flatResponse,
      },
    });
    return res.data;
  } catch (error) {
    throwApiError(error);
  }
}

export const fetchSettingsLabels = async (locale?: string): Promise<Record<string, string>> => {
  const headers = locale
    ? {
        'accept-language': locale,
      }
    : void 0;

  try {
    const { data: settingsLabels } = await goStorageAgent.get('settings/labels', {
      headers,
    });

    return settingsLabels;
  } catch (e) {
    throwApiError(e);
  }
};

export const fetchValuesInterpretations = async (locale?: string): Promise<TValuesInterpretations> => {
  const headers = locale
    ? {
        'accept-language': locale,
      }
    : void 0;

  try {
    const { data: valuesInterpretations } = await goStorageAgent.get('settings/value_interpretations', {
      headers,
    });

    return valuesInterpretations;
  } catch (e) {
    throwApiError(e);
  }
};
