import { CopyAltLight as CopyIcon, TrashLight as TrashIcon, ExportLight as ExportIcon } from '@profgeosoft-ui/icons';
import { Button, Checkbox, Input, Textarea, Tooltip } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RemoveObjectModal } from 'src/pages/dashboard/features/remove-object-modal/RemoveObjectModal';

import type { WidgetTemplateDetailsEntity } from './WidgetTemplateDetails.entity';

import { WidgetTemplateDetailsStore } from './WidgetTemplateDetails.store';

import styles from './WidgetTemplateDetails.module.scss';

type Props = {
  screen: WidgetTemplateDetailsEntity;
};

export const WidgetTemplateDetails = observer(function WidgetTemplateDetails({ screen }: Props) {
  const [name, setName] = useState(screen.template.name);
  const [desc, setDesc] = useState(screen.template.description ?? '');

  const [store] = useState(() => new WidgetTemplateDetailsStore(screen));

  const { t } = useTranslation();

  const {
    onTemplateDelete,
    onTemplateExport,
    onTemplateNameChange,
    onTemplateDescriptionChange,
    onOpenByDefaultChange,
    onDuplicateToPersonal,
    onDuplicateToPublic,
    onDeleteTemplateAccept,
    onDeleteTemplateCancel,
    deleteTemplateController,
  } = store;

  const handleNameChange = (value: string) => {
    if (value.length <= 20) {
      setName(value);
    }
  };

  const handleDescChange = (value: string) => {
    if (value.length <= 80) {
      setDesc(value);
    }
  };

  const [isDeleting, setIsDeleting] = useState(false);

  const handleTemplateDelete = async () => {
    setIsDeleting(true);
    await onTemplateDelete();
    setIsDeleting(false);
  };

  const [isDuplicating, setIsDuplicating] = useState(false);

  const handleDuplicateToPersonal = async () => {
    setIsDuplicating(true);
    await onDuplicateToPersonal();
    setIsDuplicating(false);
  };
  const handleDuplicateToPublic = async () => {
    setIsDuplicating(true);
    await onDuplicateToPublic();
    setIsDuplicating(false);
  };

  useEffect(() => {
    setName(screen.template.name);
  }, [screen.template.name]);

  useEffect(() => {
    setDesc(screen.template.description ?? '');
  }, [screen.template.description]);

  const isDefaultTemplate = store.id === store.templates.defaultTemplateId;

  return (
    <article className={styles.column}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <p className={styles.headerTitle}>{t('settings:templateDetails')}</p>
          <div className={styles.headerButton}>
            <Tooltip title={t('common:Buttons.delete')} useSafePolygon={false} delay={400}>
              <Button
                variant="flat"
                icon={<TrashIcon className={styles.icon} />}
                onClick={handleTemplateDelete}
                className={styles.iconButton}
                loading={isDeleting}
                disabled={isDefaultTemplate}
              />
            </Tooltip>
            <Tooltip title={t('common:Buttons.export')} useSafePolygon={false} delay={400}>
              <Button
                variant="flat"
                icon={<ExportIcon className={styles.icon} />}
                onClick={onTemplateExport}
                className={styles.iconButton}
              />
            </Tooltip>
          </div>
        </div>

        <div className={styles.content}>
          <div className={styles.templateDetails}>
            <Input
              value={name}
              onChange={(e) => handleNameChange(e.target.value)}
              onBlur={(e) => onTemplateNameChange(e.target.value.trim())}
              fieldClassName={clsx(styles.field, styles.fieldName)}
              inputClassName={styles.input}
              placeholder={t('common:Tab.inputTemplateName')}
              allowClear={false}
            />
            <Textarea
              rows={2}
              value={desc}
              maxLength={80}
              fieldClassName={clsx(styles.field, styles.fieldDesc)}
              className={styles.textAreaWrapper}
              placeholder={t('common:Tab.inputTemplateDescription')}
              onChange={handleDescChange}
              onBlur={(e) => onTemplateDescriptionChange(e.target.value.trim())}
            />
            <Checkbox
              onChange={onOpenByDefaultChange}
              className={styles.openByDefaultCheckbox}
              checked={isDefaultTemplate}
              disabled={isDefaultTemplate}
              labelClassName={styles.openByDefaultCheckboxLabel}
            >
              {t('settings:openByDefault')}
            </Checkbox>
            <Button
              variant="flat"
              icon={<CopyIcon />}
              onClick={screen.template.isSystem ? handleDuplicateToPersonal : handleDuplicateToPublic}
              className={styles.duplicateButton}
              loading={isDuplicating}
            >
              {screen.template.isSystem ? t('settings:duplicateToPersonal') : t('settings:duplicateToPublic')}
            </Button>
          </div>

          <div className={styles.params}>
            <p className={styles.paramsHeader}>{t('settings:parametersGroups')}:</p>
            <ul className={styles.paramsItems}>
              {store.logs.map((log, ind) => (
                <li key={ind} className={styles.paramsItem}>
                  {log}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <RemoveObjectModal
        title={t('modals:RemoveWidgetTemplateModal.title')}
        desc={t('modals:RemoveWidgetTemplateModal.desc')}
        removeButtonText={t('common:Buttons.delete')}
        isOpened={!!deleteTemplateController}
        onConfirm={onDeleteTemplateAccept}
        onCancel={onDeleteTemplateCancel}
      />
    </article>
  );
});
