export enum LANGUAGES {
  ru = 'ru',
  en = 'en',
  es = 'es',
}

export const languageSwitcherItems: Record<LANGUAGES, { text: string }> = {
  ru: { text: 'Русский' },
  en: { text: 'English' },
  es: { text: 'Español' },
};

export const PRIMARY_LANG: LANGUAGES =
  ((window._env_.REACT_APP_PRIMARY_LANGUAGE || process.env.REACT_APP_PRIMARY_LANGUAGE) as LANGUAGES) || LANGUAGES.ru;
export const SECONDARY_LANG: LANGUAGES | undefined = (window._env_.REACT_APP_SECONDARY_LANGUAGE ||
  process.env.REACT_APP_SECONDARY_LANGUAGE) as LANGUAGES;
