import { DashboardService, type WellIndexType } from '@go-widgets/well-logs-widget';

import { wellLogsWidgetAgent } from 'src/api/wellLogsWidgetAgent';
import { requireService } from 'src/packages/di';
import { WellLogsWidgetEntity } from 'src/pages/dashboard/features/well-logs-widget/WellLogsWidget.entity';
import { WorkspaceApi } from 'src/pages/dashboard/features/workspace/api/TabsApi';
import { mapTabs } from 'src/serializers/tab/mapTab';

import type { TWellLogsUpdateTemplatesNotification } from './WellLogsTemplatesManager';
import type { TDashboardReceivingMessage } from '../session-service/SessionService';
import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';

import { BroadcastWriteTunnel } from '../broadcast-tunnel/BroadcastWriteTunnel';

import { WellLogsTemplatesManager } from './WellLogsTemplatesManager';

export interface IWellLogsTemplateService {
  readonly api: DashboardService;
  registerTemplateManager(type: WellIndexType): void;
  getTemplatesManager(type: WellIndexType): WellLogsTemplatesManager;
  getIsTemplateDeletable(id: number): Promise<boolean>;
  notifyOtherTabs(type: WellIndexType): void;
  destroy(): void;
  init(): Promise<void>;
}

const WELL_LOGS_TEMPLATES_BY_DEPTH_CHANGED = 'WELL-LOGS-TEMPLATES-BY-DEPTH-CHANGED';
const WELL_LOGS_TEMPLATES_BY_TIME_CHANGED = 'WELL-LOGS-TEMPLATES-BY-TIME-CHANGED';

export class WellLogsTemplateService implements IWellLogsTemplateService {
  private readonly tabsApi: WorkspaceApi;
  private readonly templatesUpdatingChannel: BroadcastWriteTunnel<TWellLogsUpdateTemplatesNotification>;

  readonly api: DashboardService;

  private templatesManagerMap: Map<WellIndexType, WellLogsTemplatesManager> = new Map();

  constructor(
    private readonly notifications = requireService('notifications'),
    private readonly sessionService = requireService('sessionService')
  ) {
    this.tabsApi = new WorkspaceApi();
    this.api = new DashboardService(wellLogsWidgetAgent);
    this.templatesUpdatingChannel = new BroadcastWriteTunnel();
  }

  getTemplatesManager(type: WellIndexType): WellLogsTemplatesManager {
    const templatesManager = this.templatesManagerMap.get(type);

    if (!templatesManager) {
      throw new Error(`${type} - templates manager not found`);
    }

    return templatesManager;
  }

  sendUpdateNotification = (type: WellIndexType): void => {
    const message = type === 'LOG_DEPTH' ? WELL_LOGS_TEMPLATES_BY_DEPTH_CHANGED : WELL_LOGS_TEMPLATES_BY_TIME_CHANGED;

    this.sessionService.sendMessage(message);
    this.notifyOtherTabs(type);
  };

  private onTemplateUpdate = async (message: TDashboardReceivingMessage): Promise<void> => {
    if (message !== WELL_LOGS_TEMPLATES_BY_DEPTH_CHANGED && message !== WELL_LOGS_TEMPLATES_BY_TIME_CHANGED) return;

    const type: WellIndexType = message === WELL_LOGS_TEMPLATES_BY_DEPTH_CHANGED ? 'LOG_DEPTH' : 'LOG_TIME';

    const manager = this.getTemplatesManager(type);

    try {
      await manager.templatesManager.reloadList();
    } catch (error) {
      console.error(error);
    }
  };

  async registerTemplateManager(type: WellIndexType): Promise<void> {
    if (this.templatesManagerMap.has(type)) {
      throw new Error('template manager already registered');
    }

    const manager = new WellLogsTemplatesManager(type, this.api, {
      canTemplateBeDeleted: async (id: number) => {
        return await this.getIsTemplateDeletable(id);
      },
      onTemplatesUpdate: () => {
        this.sendUpdateNotification(type);
      },
    });

    await manager.init();

    this.templatesManagerMap.set(type, manager);
  }

  notifyOtherTabs = (type: WellIndexType): void => {
    this.templatesUpdatingChannel.postMessage({
      type: 'well-logs-update-templates',
      data: {
        indexType: type,
      },
    });
  };

  async getIsTemplateDeletable(id: number): Promise<boolean> {
    try {
      const tabsResponse = await this.tabsApi.fetchTabs();

      const {
        data: { tabs: rawTabs },
      } = tabsResponse[0];

      const { tabs } = mapTabs(rawTabs);

      const allWidgets: WidgetEntity[] = [];

      for (const tab of tabs) {
        for (const widget of tab.widgets) {
          allWidgets.push(widget);
        }
      }

      for (const widget of allWidgets) {
        if (widget instanceof WellLogsWidgetEntity && widget.stateParams.templateId === id) {
          this.notifications.showErrorMessageT('errors:templateIsUsedInAnotherWidget');

          return false;
        }
      }

      return true;
    } catch (error) {
      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadTabs');
      return false;
    }
  }

  init = async () => {
    await this.sessionService.init();

    this.sessionService.subscribe(this.onTemplateUpdate);
  };

  destroy = (): void => {
    this.templatesManagerMap.forEach((manager) => {
      manager.dispose();
    });
    this.sessionService.unsubscribe(this.onTemplateUpdate);
  };
}
