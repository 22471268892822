import { flow, makeObservable, observable } from 'mobx';

import { requireService } from 'src/packages/di';

import type { ITabTemplatesService } from './ITabTemplatesService';
import type { TTabTemplateData, TTabTemplateWithGSProps } from 'src/pages/dashboard/features/workspace/types';

import { TabTemplatesApi } from './api/TabTemplates.api';

export class TabTemplatesService implements ITabTemplatesService {
  private readonly api: TabTemplatesApi;

  @observable personal: TTabTemplateWithGSProps[] = [];
  @observable publicTemplates: TTabTemplateWithGSProps[] = [];
  @observable system: TTabTemplateWithGSProps[] = [];

  @observable isLoading = false;

  constructor(
    private readonly notifications = requireService('notifications'),
    private readonly auth = requireService('authService')
  ) {
    this.api = new TabTemplatesApi();

    makeObservable(this);
  }

  isPersonal(templateId: number): boolean {
    return this.personal.some((template) => template.id === templateId);
  }

  isPublic(templateId: number): boolean {
    return this.publicTemplates.some((template) => template.id === templateId);
  }

  getTemplateDataById(id: number): TTabTemplateData | null {
    for (const personalTemplate of this.personal) {
      if (personalTemplate.id === id) {
        return personalTemplate.data;
      }
    }

    for (const personalTemplate of this.publicTemplates) {
      if (personalTemplate.id === id) {
        return personalTemplate.data;
      }
    }

    for (const personalTemplate of this.system) {
      if (personalTemplate.id === id) {
        return personalTemplate.data;
      }
    }

    return null;
  }

  @flow.bound
  async *updatePersonalTemplate(id: number, templateData: TTabTemplateData): Promise<void> {
    this.isLoading = true;

    try {
      if (this.isPersonal(id)) {
        await this.api.updateTemplate(id, templateData);
      } else {
        await this.api.createTemplate(templateData);
      }

      yield;

      await this.syncPersonalTemplates();

      yield;
    } catch (error) {
      yield;
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *updateTemplate(id: number, templateData: TTabTemplateData): Promise<void> {
    this.isLoading = true;

    try {
      await this.api.updateTemplate(id, templateData);

      yield;

      if (this.isPersonal(id)) {
        await this.syncPersonalTemplates();
      } else {
        await this.syncPublicTemplates();
      }

      yield;
    } catch (error) {
      yield;
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *updatePublicTemplate(id: number, templateData: TTabTemplateData): Promise<void> {
    this.isLoading = true;

    try {
      if (this.isPublic(id)) {
        await this.api.updateTemplate(id, templateData);
      } else {
        await this.api.createTemplate(templateData);
      }

      yield;

      await this.syncPublicTemplates();

      yield;
    } catch (error) {
      yield;
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *saveAsPersonal(templateData: TTabTemplateData): Promise<void> {
    this.isLoading = true;

    try {
      await this.api.createTemplate(templateData);
      await this.syncPersonalTemplates();
      yield;
    } catch (error) {
      yield;

      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *syncPersonalTemplates() {
    try {
      const personalTemplates = await this.api.loadPersonalTemplates();

      yield;

      this.personal = personalTemplates;
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadTabTemplates');
    }
  }

  @flow.bound
  async *syncPublicTemplates() {
    try {
      const publicTemplates = await this.api.loadPublicTemplates();

      yield;

      this.publicTemplates = publicTemplates;
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadTabTemplates');
    }
  }

  @flow.bound
  async *loadAllTemplates() {
    this.isLoading = true;

    try {
      const [systemTemplates, publicTemplates, personalTemplates] = await Promise.all([
        this.api.loadSystemTemplates(),
        this.api.loadPublicTemplates(),
        this.api.loadPersonalTemplates(),
      ]);

      yield;

      this.system = systemTemplates;
      this.publicTemplates = publicTemplates;
      this.personal = personalTemplates;
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToLoadTabTemplates');
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  @flow.bound
  async *deleteTemplate(id: number) {
    try {
      await this.api.deleteTemplate(id);

      yield;
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedToDeleteTemplate');
    }
  }

  @flow.bound
  async *cloneToPersonal(templateData: TTabTemplateData) {
    if (templateData.type === 'personal') return;

    const newTemplateData: TTabTemplateData = {
      type: 'personal',
      ownerUserId: this.auth.userInfo.sub,
      template: templateData.template,
    };

    try {
      await this.api.createTemplate(newTemplateData);

      yield;

      const personal = await this.api.loadPersonalTemplates();

      yield;

      this.personal = personal;
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedCopyTemplate');
    }
  }

  @flow.bound
  async *cloneToPublic(templateData: TTabTemplateData) {
    if (templateData.type === 'public') return;

    const newTemplateData: TTabTemplateData = {
      type: 'public',
      ownerUserId: null,
      template: templateData.template,
    };

    try {
      await this.api.createTemplate(newTemplateData);

      yield;

      const publicTemplates = await this.api.loadPublicTemplates();

      yield;

      this.publicTemplates = publicTemplates;
    } catch (error) {
      yield;

      console.error(error);
      this.notifications.showErrorMessageT('errors:failedCopyTemplate');
    }
  }

  init = () => {
    return this.loadAllTemplates();
  };
}
