import { FilterAltLight as FilterIcon } from '@profgeosoft-ui/icons';
import { Button, Tooltip } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import styles from './FilterButton.module.scss';

type Props = {
  onClick: VoidFunction;
};

export const FilterButton = observer(function FilterButton({ onClick }: Props) {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('common:Widget.filter')} delay={400}>
      <Button
        onClick={onClick}
        variant="flat"
        icon={<FilterIcon className={styles.icon} />}
        className={styles.button}
      />
    </Tooltip>
  );
});
