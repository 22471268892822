import { action, flow, makeObservable, observable } from 'mobx';

import { requireService } from 'src/packages/di';

import type { Broadcast, WellboreApi } from './api/Wellbore.api';

export class ImageBroadcastChannel {
  @observable inProgress = false;

  @observable info: Broadcast | null = null;

  @observable subscribedBroadcast = false;

  id: number;

  @observable image: string | null = null;

  constructor(
    id: number,
    private wellDirectoryService: WellboreApi,
    private wellStatusService = requireService('wellStatusService'),
    private notifications = requireService('notifications')
  ) {
    this.id = id;
    makeObservable(this);
  }

  @action.bound
  subscribe() {
    if (this.subscribedBroadcast) {
      return;
    }
    this.wellStatusService.subscribe(`/broadcast/state/broadcast/${this.id}`, this.update);
    this.subscribedBroadcast = true;
  }

  @action.bound
  unsubscribe() {
    this.wellStatusService.unsubscribe(`/broadcast/state/broadcast/${this.id}`, this.update);
    this.subscribedBroadcast = false;
  }

  @flow.bound
  async *update() {
    this.inProgress = true;
    try {
      const data = await this.wellDirectoryService.getBroadcastInfo(this.id);
      await this.onImageUpdate();
      yield;
      this.info = data;
    } catch (e) {
      yield;
      console.error(e);
      this.notifications.showErrorMessageT('imageBroadcast:error.broadcastInfo');
    } finally {
      this.inProgress = false;
    }
  }

  @flow.bound
  async *onImageUpdate() {
    try {
      const data = await this.wellDirectoryService.getBroadcastImage(this.id);
      yield;
      this.image = data;
    } catch (e) {
      yield;
      console.error(e);
      this.notifications.showErrorMessageT('imageBroadcast:error.broadcastInfo');
    }
  }
}
