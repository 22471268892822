import { WellAnalysisWidgetState } from '@go-widgets/well-logs-widget';
import { action, comparer, makeObservable, observable, reaction } from 'mobx';

import { WITH_WELLBORE_SORTING } from 'src/api/consts';
import { lagacyAgent } from 'src/api/legacyAgent';
import { wellLogsWidgetAgent } from 'src/api/wellLogsWidgetAgent';
import { requireService } from 'src/packages/di';
import { getNewAndDeletedFields } from 'src/packages/shared/utils/getNewAndDeletedElements';

import type { WellAnalyseWidgetEntity } from './WellAnalyseWidget.entity';
import type { GroupSelectStore } from '../group-select/GroupSelect.store';
import type { AnalysisInitParams } from '@go-widgets/well-logs-widget';
import type { WidgetStateParams } from '@go-widgets/well-logs-widget/dist/AnalysisWidget/types';
import type { IReactionDisposer, ObservableMap } from 'mobx';
import type { TCreateWidgetOptions, TGroup } from 'src/entities/tab/TabEntity';
import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';

import { setWellIdInGroup } from '../../utils/setWellIdInGroup';
import { WellListWidgetEntity } from '../well-list-widget/WellListWidget.entity';

import { getCorrectLocale } from './WellAnalyseWidget.utils';

export class WellAnalyseWidgetStore {
  private readonly wellAnalyseEntity: WellAnalyseWidgetEntity;
  private readonly groupSelectStore: GroupSelectStore;
  private readonly initParams: AnalysisInitParams;

  @observable isLoading: boolean = false;
  @observable isReady: boolean = false;
  @observable hasError: boolean = false;
  @observable disposers: ObservableMap<WidgetEntity, IReactionDisposer> = observable.map();

  onCreateWellAnalyseWidget: (
    wellId: number | null,
    stateParams: Partial<WidgetStateParams> | null,
    options?: TCreateWidgetOptions
  ) => void;

  constructor(
    wellAnalyseEntity: WellAnalyseWidgetEntity,
    groupSelectStore: GroupSelectStore,
    onCreateWellAnalyseWidget: (
      wellId: number | null,
      stateParams: Partial<WidgetStateParams> | null,
      options?: TCreateWidgetOptions
    ) => void,
    private readonly theme = requireService('theme'),
    private readonly stompPublisherService = requireService('stompPublisherService'),
    private readonly notifications = requireService('notifications'),
    private readonly language = requireService('language')
  ) {
    this.onCreateWellAnalyseWidget = onCreateWellAnalyseWidget;

    this.wellAnalyseEntity = wellAnalyseEntity;
    this.groupSelectStore = groupSelectStore;

    this.initParams = {
      widgetType: 'desktop',
      legacyAxiosInstance: lagacyAgent,
      stompPublisher: this.stompPublisherService.stompPublisher,
      axiosInstance: wellLogsWidgetAgent,
      withWellboreSorting: WITH_WELLBORE_SORTING,
    };

    makeObservable(this);
  }

  @action.bound
  exitFullScreen() {
    this.wellAnalyseEntity.setFullscreen(false);
  }

  @action.bound
  setGroupForWellLogs(group: TGroup | null): void {
    this.wellAnalyseEntity.setGroup(group ? group.id : null);
  }

  @action.bound
  setWellId(well: number | null): void {
    this.wellAnalyseEntity.setWellId(well);
  }

  private mapNewWidgets(widgets: WidgetEntity[]): void {
    for (const widget of widgets) {
      if (!(widget instanceof WellListWidgetEntity) || this.disposers.has(widget)) {
        continue;
      }

      const disposeWellListWidget = reaction(
        () => widget.selectedWellID,
        (wellId) => {
          this.wellAnalyseEntity.setWellId(wellId);
        }
      );

      this.disposers.set(widget, disposeWellListWidget);
    }
  }

  @action.bound
  private setReady(value: boolean): void {
    this.isReady = value;
  }

  private mapDeletedWidgets(widgets: WidgetEntity[]): void {
    for (const widget of widgets) {
      if (this.disposers.has(widget)) {
        const disposer = this.disposers.get(widget);

        disposer?.();

        this.disposers.delete(widget);
      }
    }
  }

  private getNewState(wellId: number): WellAnalysisWidgetState {
    return new WellAnalysisWidgetState(
      this.initParams,
      {
        // TODO: remove hardcode
        wellId,
        width: 500,
        height: 500,
        locale: getCorrectLocale(this.language.language),
        theme: this.theme.theme,
        fullScreen: this.wellAnalyseEntity.isFullscreen,
      },
      this.wellAnalyseEntity.stateParams,
      {
        onInitializedSuccess: () => {
          this.setReady(true);
        },
        onInitializationFailed: (_, error) => {
          this.setReady(false);

          console.error(error);
          this.notifications.showErrorMessageT('errors:failedToInitWidget');
        },
        onInternalStateChanged: (state) => {
          this.wellAnalyseEntity.setStateParams(state);
        },
        onTitleChanged: (value) => {
          this.wellAnalyseEntity.setCenterTitle(value);
        },
      }
    );
  }

  effect = () => {
    const disposeGroupId = reaction(
      () => this.wellAnalyseEntity.groupId,
      (groupId) => {
        if (groupId === null) return;

        const widgets = this.groupSelectStore.groupManager.getWidgetsByGroupId(groupId);
        setWellIdInGroup(widgets, this.wellAnalyseEntity);
      }
    );

    const dispose = reaction(
      () => this.groupSelectStore.groupManager.getWidgetsByGroupId(this.wellAnalyseEntity.groupId).slice(),
      (widgets, prevState) => {
        const prevWidgets = prevState ?? [];

        const { newElements: newWidgets, deletedElements: deletedWidgets } = getNewAndDeletedFields<WidgetEntity>(
          widgets,
          prevWidgets
        );

        this.mapNewWidgets(newWidgets);
        this.mapDeletedWidgets(deletedWidgets);
      },
      { fireImmediately: true, equals: comparer.shallow }
    );

    const disposeWellId = reaction(
      () => this.wellAnalyseEntity.wellId,
      (wellId, prevWellId) => {
        if (wellId && !this.wellAnalyseEntity.state) {
          const newState = this.getNewState(wellId);

          this.wellAnalyseEntity.setState(newState);
        } else if (wellId && this.wellAnalyseEntity.state && typeof prevWellId !== 'undefined') {
          this.wellAnalyseEntity.state.setAppParams({ wellId });
        } else if (!wellId && this.wellAnalyseEntity.state) {
          this.wellAnalyseEntity.state.destroy();
          this.wellAnalyseEntity.setState(null);
        }
      },
      { fireImmediately: true }
    );

    const disposeStateParams = reaction(
      () => ({ theme: this.theme.theme, language: this.language.language }),
      ({ theme, language }) => {
        this.wellAnalyseEntity.state?.setAppParams({
          theme,
          locale: getCorrectLocale(language),
        });
      },
      {
        fireImmediately: true,
      }
    );

    return () => {
      dispose();
      disposeGroupId();
      disposeWellId();
      disposeStateParams();
    };
  };
}
