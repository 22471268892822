import { Responsive as ReactGridLayout } from '@profgeosoft/go-react-grid-layout';
import { ReduceLight as CloseFullscreenIcon } from '@profgeosoft-ui/icons';
import { Button, Scrollbar } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useService } from 'src/packages/di';

import type { GroupManager } from '../group-select/GroupManager';
import type { ItemCallback, Layout, Layouts } from '@profgeosoft/go-react-grid-layout';
import type { RefObject, UIEvent } from 'react';
import type { ScrollState } from 'react-scrollbars-custom/dist/types/types';
import type { TWellListView } from 'src/api-types/wells.types';
import type { TabEntity } from 'src/entities/tab/TabEntity';

import { getCurrentBreakpoint } from '../../utils/getCurrentBreakpoint';
import { GroupSelectStore } from '../group-select/GroupSelect.store';
import { ImageBroadcastWidgetEntity } from '../image-broadcast-widget/ImageBroadcastWidget.entity';
import { RemoveObjectModal } from '../remove-object-modal/RemoveObjectModal';
import { WellAnalyseWidgetEntity } from '../well-analyse-widget/WellAnalyseWidget.entity';
import { WellDetailsWidgetEntity } from '../well-details-widget/WellDetailsWidget.entity';
import { WellLogsWidgetEntity } from '../well-logs-widget/WellLogsWidget.entity';
import { WidgetCreationHint } from '../wiget-creation-hint';

import './widget-resize-handle/WidgetResizeHandle.scss';

import {
  breakpoints,
  cols,
  X_CONTAINER_PADDING,
  X_WIDGETS_MARGIN,
  Y_CONTAINER_PADDING,
  Y_WIDGETS_MARGIN,
} from './consts';
import { WorkbenchStore } from './Workbench.store';

import styles from './Workbench.module.scss';

const PADDINGS: [number, number] = [X_CONTAINER_PADDING, Y_CONTAINER_PADDING];
const MARGINS: [number, number] = [X_WIDGETS_MARGIN, Y_WIDGETS_MARGIN];

type Props = {
  tab: TabEntity;
  isFullscreenMode: boolean;
  workspaceRef: RefObject<HTMLDivElement>;
  groupManager: GroupManager;
  onExitFullscreen: VoidFunction;
  wellListView?: TWellListView;
};

export const Workbench = observer(function Workbench({
  tab,
  isFullscreenMode,
  workspaceRef,
  groupManager,
  onExitFullscreen,
}: Props) {
  const { t } = useTranslation();

  const ref = useRef<HTMLDivElement>(null);

  const [draggingWidgetId, setDraggingWidgetId] = useState<string | null>(null);

  const {
    initialLayouts,
    widgets,
    width,
    offsetTop,
    workbenchViewportHeight,
    currentBreakpoint,
    onLayoutChange,
    getExistingWidgetLayout,
    createWellsFilterWidget,
    createWellLogsWidget,
    createWellAnalyseWidget,
    createImageBroadcastWidget,
    createOperationalParametersWidget,
    setOffsetTop,
    setHeight,
    setRealHeight,
    setWidth,
    hasFullscreenWidget,
  } = tab;

  const [groupSelectStore] = useState(() => new GroupSelectStore(groupManager, widgets));
  const [store] = useState(() => new WorkbenchStore(tab));

  const {
    widgetsDisplayingPriorityManager,
    effect,
    deleteWidget,
    onDeleteWidgetAccept,
    onDeleteWidgetCancel,
    deleteWidgetController,
    rowHeight,
    minWorkbenchWidth,
  } = store;

  const widgetRendererService = useService('widgetRenderer');

  const _onLayoutChange = (_: Layout[], allLayouts: Layouts) => {
    onLayoutChange(allLayouts);
  };

  const handleWidgetChangingPositionStart: ItemCallback = (_, item) => {
    setDraggingWidgetId(item.i);
  };

  const handleWidgetChangingPositionEnd: ItemCallback = (layout, old, newL) => {
    setDraggingWidgetId(null);
  };

  const onScrollUpdate = (scrollState: UIEvent<HTMLDivElement, globalThis.UIEvent> | ScrollState) => {
    if ('contentScrollHeight' in scrollState) {
      setRealHeight(scrollState.contentScrollHeight);
    }

    if ('scrollTop' in scrollState) {
      setOffsetTop(scrollState.scrollTop);
    }
  };

  const shouldRenderGridLayout = !!width && !!tab.workbenchViewportHeight && !!rowHeight;

  useEffect(effect, [effect]);

  useEffect(() => {
    const container = ref.current;

    if (container) {
      const { width } = container.getBoundingClientRect();
      setWidth(width);

      const resizeObserver = new ResizeObserver((entries) => {
        window.requestAnimationFrame((): void | undefined => {
          if (!Array.isArray(entries) || !entries.length) {
            return;
          }

          const { height, width } = container.getBoundingClientRect();
          setHeight(height - Y_CONTAINER_PADDING * 2);
          setWidth(width);
        });
      });

      resizeObserver.observe(container);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [setHeight, setWidth, ref]);

  return (
    <div ref={workspaceRef} className={clsx(styles.contentWrapper, isFullscreenMode && styles.contentWrapperFSMode)}>
      <Scrollbar
        className={styles.workbenchScrollers}
        onUpdate={onScrollUpdate}
        noScrollX={!!draggingWidgetId}
        noScrollY={widgets.some((w) => w.isFullscreen)}
      >
        <div
          ref={ref}
          className={styles.gridOverflow}
          style={{
            minWidth: minWorkbenchWidth || undefined,
          }}
        >
          {shouldRenderGridLayout && (
            <ReactGridLayout
              width={Math.trunc(width)}
              // возможно, стоит перенести скролл в библиотеку, чтобы не прокидывать сомнительные пропы
              containerViewportHeight={Math.trunc(workbenchViewportHeight)}
              offsetTop={offsetTop}
              //
              cols={cols}
              saveLayoutItemsPositionBetweenBreakpoints={true}
              autoSize={false}
              maxRows={500}
              breakpoints={breakpoints}
              breakpoint={currentBreakpoint ?? 'md'}
              preventCollision={false}
              className={styles.content}
              rowHeight={rowHeight}
              compressLayout={true}
              magnetizingDistance={15}
              compactType="horizontal"
              draggableHandle=".drag-handle"
              layouts={initialLayouts}
              onResizeStart={handleWidgetChangingPositionStart}
              onResizeStop={handleWidgetChangingPositionEnd}
              onLayoutChange={_onLayoutChange}
              onDrag={handleWidgetChangingPositionStart}
              onDragStop={handleWidgetChangingPositionEnd}
              containerPadding={PADDINGS}
              margin={MARGINS}
              allowOverlap={true}
            >
              {widgets.map((widget) => {
                let isFullscreen = false;
                const existingLayout = getExistingWidgetLayout(widget.uuid, getCurrentBreakpoint(width));

                if (
                  widget instanceof WellLogsWidgetEntity ||
                  widget instanceof WellDetailsWidgetEntity ||
                  widget instanceof WellAnalyseWidgetEntity ||
                  widget instanceof ImageBroadcastWidgetEntity
                ) {
                  isFullscreen = widget.isFullscreen;
                }

                const handleClickOnWidget = () => widgetsDisplayingPriorityManager.placeWidgetOnTop(widget);
                const handleMouseOver = () => {
                  if (widget.uuid !== store.hoveredWidgetId) {
                    store.setHoveredWidgetId(widget.uuid);
                  }
                };

                const renderWidget = widgetRendererService.renderWidget(widget, {
                  groupSelectStore,
                  onWidgetDelete: deleteWidget,
                  widgetsDisplayingPriorityManager,
                });

                return (
                  <article
                    key={widget.uuid}
                    data-grid={
                      existingLayout || {
                        i: widget.uuid,
                        x: widget.position.x,
                        y: widget.position.y,
                        w: widget.size.w,
                        h: widget.size.h,
                        minW: widget.sizeBoundaries.min.w,
                        minH: widget.sizeBoundaries.min.h,
                        maxW: widget.sizeBoundaries.max.w,
                        maxH: widget.sizeBoundaries.max.h,
                        minPxW: widget.sizeBoundaries.minInPixels.w,
                        minPxH: widget.sizeBoundaries.minInPixels.h,
                        maxPxW: widget.sizeBoundaries.maxInPixels?.w,
                        maxPxH: widget.sizeBoundaries.maxInPixels?.h,
                        isQuickResizeAllowed: !widget.isControlWidget,
                        resizeHandles: ['e', 's', 'w', 'n', 'se', 'sw', 'nw', 'ne'],
                      }
                    }
                    className={clsx(styles.itemWrapper, isFullscreen && styles.itemWrapperFullscreen)}
                    style={{
                      zIndex: draggingWidgetId === widget.uuid || isFullscreen ? 999 : widget.zIndex,
                    }}
                    onClick={handleClickOnWidget}
                    onMouseMove={handleMouseOver}
                    onMouseLeave={() => store.setHoveredWidgetId(null)}
                  >
                    <div
                      className={clsx(
                        styles.item,
                        isFullscreen && styles.itemFullscreen,
                        isFullscreen && isFullscreenMode && styles.itemFullscreenWithoutHeader,
                        !isFullscreen && hasFullscreenWidget && styles.itemHidden
                      )}
                    >
                      {renderWidget({
                        onCreateWellsFilterWidget: createWellsFilterWidget,
                        onCreateWellLogsWidget: createWellLogsWidget,
                        onCreateWellAnalyseWidget: createWellAnalyseWidget,
                        onCreateImageBroadcastWidget: createImageBroadcastWidget,
                        onCreateOperationalParametersWidget: createOperationalParametersWidget,
                      })}
                    </div>
                  </article>
                );
              })}
            </ReactGridLayout>
          )}
          {!widgets.length && <WidgetCreationHint />}
        </div>
      </Scrollbar>

      {isFullscreenMode && (
        <Button
          title={t('dashboard:Toolbar.exitFullScreen')}
          variant="flat"
          className={styles.exitFSButton}
          icon={<CloseFullscreenIcon width={32} height={32} />}
          onClick={onExitFullscreen}
        />
      )}

      <RemoveObjectModal
        title={t('modals:RemoveWidgetModal.title')}
        desc={t('modals:RemoveWidgetModal.desc')}
        isOpened={!!deleteWidgetController}
        onConfirm={onDeleteWidgetAccept}
        onCancel={onDeleteWidgetCancel}
      />
    </div>
  );
});
