import { NotificationContainer, Scrollbar } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { Sidebar } from 'src/components/sidebar/Sidebar';
import { checkIsPortableDevice } from 'src/packages/shared/utils/checkIsPortableDevice';

import type { PropsWithChildren } from 'react';

import styles from './MainLayout.module.scss';

type Props = PropsWithChildren<{
  title: string;
  showSidebar?: boolean;
}>;

export const MainLayout = observer(function MainLayout({ children, showSidebar = true }: Props) {
  return (
    <Scrollbar>
      <div className={clsx(styles.wrapper, checkIsPortableDevice() && styles.wrapper_portable)}>
        <div className={styles.contentWrapper}>
          <div className={styles.childrenWrapper}>{children}</div>
        </div>

        {showSidebar && <Sidebar />}

        <NotificationContainer className={styles.notificationsContainer} />
      </div>
    </Scrollbar>
  );
});
