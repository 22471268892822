import * as Sentry from '@sentry/react';
import axios from 'axios';

import { API_LEGACY_CACHE_URL, AUTH_TOKEN } from './consts';

const lagacyAgent = axios.create({
  baseURL: API_LEGACY_CACHE_URL,
  headers: {
    'content-type': 'application/json',
  },
});

lagacyAgent.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error?.response?.status === 401) {
      if (error?.config) {
        return axios.request({
          ...error.config,
          headers: {
            ...error.config.headers,
            Authorization: `Bearer ${AUTH_TOKEN}`,
          },
        });
      }
    }

    Sentry.captureException(error);

    return Promise.reject(error);
  }
);

lagacyAgent.interceptors.request.use((config) => {
  config.headers.set('Authorization', `Bearer ${AUTH_TOKEN}`);

  return config;
});

export { lagacyAgent };
