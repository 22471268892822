import { assert } from 'src/packages/shared/utils/assert';
import { TemplateValueBuilder, isTemplateValue } from 'src/packages/template-builder/TemplateBuilder';

import type { RequestBuilderSettings } from 'src/packages/template-builder/TemplateBuilder';

export type TRequestBuilderApplyInstruction =
  | TRequestBuilderApplyInstruction_AddGetParam
  | TRequestBuilderApplyInstruction_AddPostObjectValue;

export type TTemplateValue = string | { type: string; value: string };
type TInstructionValue = string[] | number[] | TTemplateValue;

type TRequestBuilderApplyInstruction_AddGetParam = {
  type: 'addGetParam';
  key: string;
  value: TInstructionValue;
};
type TRequestBuilderApplyInstruction_AddPostObjectValue = {
  type: 'addPostData';
  path: string[];
  value: TInstructionValue;
};

type TParams = { [key: string]: TParams } | string[] | string | number | number[];

export class RequestBuilder {
  private readonly templateValueBuilder = new TemplateValueBuilder();
  private readonly getParams: Record<string, string | number> = {};
  private postData: unknown;

  configure(callback: (settings: RequestBuilderSettings) => void): this {
    this.templateValueBuilder.configure(callback);
    return this;
  }

  addGetParams(data: Record<string, string | number>): this {
    for (const [key, value] of Object.entries(data)) {
      this.getParams[key] = value;
    }

    return this;
  }

  addPostData(data: unknown): this {
    if (typeof data === 'string' || typeof data === 'number') {
      this.postData = data;
    } else if (!!data && typeof data === 'object') {
      if (this.postData && typeof this.postData === 'object') {
        for (const [key, value] of Object.entries(data)) {
          this.postData[key] = value;
        }
      } else {
        const _postData: object = {};

        for (const [key, value] of Object.entries(data)) {
          _postData[key] = value;
        }

        this.postData = _postData;
      }
    }

    return this;
  }

  apply(instruction: TRequestBuilderApplyInstruction): this {
    const value =
      isTemplateValue(instruction.value) && TemplateValueBuilder.isTemplateValue(instruction.value)
        ? this.templateValueBuilder.build(instruction.value)
        : instruction.value;

    if (instruction.type === 'addGetParam') {
      assert(typeof value === 'string');

      this.getParams[instruction.key] = value;
    }

    if (instruction.type === 'addPostData') {
      if (
        typeof value === 'undefined' ||
        value === null ||
        (typeof value !== 'number' && typeof value !== 'string' && !Array.isArray(value))
      )
        return this;

      if (!this.postData || typeof this.postData !== 'object') {
        this.postData = {};
      }

      if (typeof this.postData !== 'object' || !this.postData || Array.isArray(this.postData)) return this;

      let _params = this.postData as Record<string, TParams>;

      for (let [i, field] of Object.entries(instruction.path)) {
        if (Number(i) === instruction.path.length - 1) {
          if (!Array.isArray(_params) && typeof _params === 'object' && !!_params) {
            _params[field] = value;
          }
        } else {
          if (!(field in _params)) {
            _params[field] = {};
          }

          const objField = _params[field];

          if (!Array.isArray(objField) && typeof objField !== 'string' && typeof objField !== 'number') {
            _params = objField;
          }
        }
      }
    }

    return this;
  }

  build() {
    const { getParams, postData } = this;
    return { getParams, postData };
  }
}
