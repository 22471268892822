import { throwApiError } from 'src/api/utils';
import { wellDirectoryServiceAgent } from 'src/api/well-directory-service-agent';

export interface Broadcast {
  id: number;
  uid: string;
  parentObjectId: number;
  path: string;
  name: string;
  createTime: number;
  image: string;
  updateTime: string;
}

export interface BroadcastObject {
  id: string;
  text: string;
}

export type Wellbore = {
  id: number;
  name: string;
  maxTimeLogDepth?: number;
  maxTimeLogTime?: number;
  numLogsDepth: number;
  numLogsTime: number;
};

export type WellDto = {
  id: number;
  name: string;
  timeZone: string;
  status: 'ACTIVE' | 'COMPLETED' | 'BAD';
};

export class WellboreApi {
  getWell(wellId: number, abortController: AbortController) {
    return wellDirectoryServiceAgent
      .get<WellDto>(`/wells/${wellId}`, {
        signal: abortController.signal,
      })
      .then(({ data }) => data)
      .catch((e) => {
        throwApiError(e);
      });
  }

  getWellbores(wellId: number, abortController: AbortController, includeMaxTime?: boolean) {
    return wellDirectoryServiceAgent
      .get<Wellbore[]>(`/wells/${wellId}/wellbores`, {
        params: {
          includeMaxTime,
        },
        signal: abortController.signal,
      })
      .then(({ data }) => data)
      .catch((e) => {
        throwApiError(e);
      });
  }

  getBroadcasts(wellboreId: number, abortController: AbortController) {
    return wellDirectoryServiceAgent
      .get<Array<BroadcastObject>>(`/broadcast/wellbore/${wellboreId}`, {
        signal: abortController.signal,
      })
      .then((response) => response.data)
      .catch((e) => {
        throwApiError(e);
      });
  }

  getBroadcastInfo(broadcastId: number) {
    return wellDirectoryServiceAgent
      .get<Broadcast>(`/broadcast/${broadcastId}`)
      .then((response) => response.data)
      .catch((e) => {
        throwApiError(e);
      });
  }

  getBroadcastImage(broadcastId: number) {
    return wellDirectoryServiceAgent
      .get(`/broadcast/${broadcastId}/image`, { responseType: 'text' })
      .then((response) => {
        return response.data;
      })
      .catch((e) => {
        throwApiError(e);
      });
  }
}
