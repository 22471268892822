import {
  BellPinLight as BellIcon,
  SettingLineLight as SettingsIcon,
  QuestionLight as QuestionIcon,
} from '@profgeosoft-ui/icons';
import clsx from 'clsx';

import { ReactComponent as EarphonesIcon } from 'src/packages/assets/icons/earphones.svg';

import styles from './Support.module.scss';

export const support = [
  ...(process.env.NODE_ENV !== 'production'
    ? [
        {
          key: 'support',
          name: 'Nav.support',
          renderIcon: (className: string) => <EarphonesIcon className={className} />,
        },
        {
          key: 'help',
          name: 'Nav.help',
          renderIcon: (className: string) => <QuestionIcon className={clsx(className, styles.questionIcon)} />,
        },
        {
          key: 'notifications',
          name: 'Nav.notifications',
          renderIcon: (className: string) => <BellIcon className={clsx(className, styles.bellIcon)} />,
          notifications: {
            count: 49,
          },
        },
      ]
    : []),
  {
    key: 'settings',
    name: 'Nav.settings',
    renderIcon: (className: string) => <SettingsIcon className={clsx(className, styles.settingsIcon)} />,
  },
];
