import { serializeDataSource } from 'src/serializers/data-source/serializeDataSource';

import type { TFilterDataSource } from 'src/pages/dashboard/features/filter-widget/WellsFilterDataSource';
import type { WellListWidgetEntity } from 'src/pages/dashboard/features/well-list-widget/WellListWidget.entity';
import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

export function serializeWellListTemplate(widget: WellListWidgetEntity): TSerializedWidgetTemplate {
  const listWidgetInternalState: {
    name: string;
    value: string | number | (number | string)[] | TFilterDataSource;
  }[] = [];

  if (widget.filterBy) {
    listWidgetInternalState.push({
      name: 'filterBy',
      value: widget.filterBy,
    });
  }

  if (widget.groupBy) {
    listWidgetInternalState.push({
      name: 'groupBy',
      value: widget.groupBy,
    });
  }

  if (widget.selectedWellID) {
    listWidgetInternalState.push({
      name: 'selectedWellID',
      value: widget.selectedWellID,
    });
  }

  if (widget.filterState) {
    listWidgetInternalState.push({
      name: 'filterState',
      value: serializeDataSource(widget.filterState),
    });
  }

  return {
    uuid: widget.uuid,
    type: widget.type,
    groupId: widget.groupId,
    size: {
      w: widget.size.w,
      h: widget.size.h,
    },
    position: {
      x: widget.position.x,
      y: widget.position.y,
    },
    internalState: {
      values: listWidgetInternalState,
    },
  };
}
