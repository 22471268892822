import { parseWellDetailsWidgetInternalState } from 'src/pages/dashboard/features/well-details-widget/parseWellDetailsWidget';
import { WellDetailsWidgetEntity } from 'src/pages/dashboard/features/well-details-widget/WellDetailsWidget.entity';

import type { TSerializedWidget } from 'src/entities/workspace/types';

export function mapWellDetailsWidget(widget: TSerializedWidget, index: number): WellDetailsWidgetEntity {
  const internalState = parseWellDetailsWidgetInternalState(widget.internalState);

  const newFilterWidget = new WellDetailsWidgetEntity(
    {
      uuid: widget.uuid,
      isPinned: !!widget.isPinned,
      zIndex: index + 1,
      isFullscreen: widget.isFullscreen ?? false,
      position: {
        x: widget?.position?.x ?? 0,
        y: widget?.position?.y ?? 0,
      },
      size: {
        w: widget.size?.w ?? 59,
        h: widget.size?.h ?? 316,
      },
      groupId: widget.groupId,
      isGroupsDefaultOpened: false,
    },
    {
      wellId: internalState.wellId,
      section: internalState.section,
      isEditMode: internalState.isEditMode,
    }
  );

  return newFilterWidget;
}
