import type { WidgetTemplatesListScreenEntity } from '../../widget-templates-list-screen/WidgetTemplatesListScreen.entity';
import type { TRenderScreen, TSettingsScreenOptions } from '../SettingsScreenRendererService';

import { WidgetTemplatesListScreen } from '../../widget-templates-list-screen/WidgetTemplatesListScreen';

export function renderWidgetTemplatesListScreen(
  screen: WidgetTemplatesListScreenEntity,
  options: TSettingsScreenOptions
): TRenderScreen {
  return () => <WidgetTemplatesListScreen stack={options.stack} screen={screen} />;
}
