import {
  CloseRoundLight as CloseIcon,
  OutLight as OutIcon,
  ArrowRightLight as ArrowRight,
} from '@profgeosoft-ui/icons';
import { Button } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import type { TabEntity } from 'src/entities/tab/TabEntity';

import styles from './TabTooltipContent.module.scss';

type Props = {
  tabEntity: TabEntity;
  onWindowOpen?(): void;
  onClose(): void;
  onTabFocus(): void;
  onRemove(): void;
  isExternalTab: boolean;
  isActive: boolean;
};

export const TabTooltipContent = observer(function TabTooltipContent({
  tabEntity,
  onWindowOpen,
  onClose,
  onTabFocus,
  onRemove,
  isExternalTab,
  isActive,
}: Props) {
  const { t } = useTranslation();
  return (
    <div className={styles.contentWrapper}>
      <p className={clsx(styles.tabName, isExternalTab && styles.tabName__external)}>
        {isExternalTab && <OutIcon className={styles.tabNameIcon} />}
        {tabEntity.name}
      </p>

      <div className={styles.divider} />

      <div className={styles.buttonsBox}>
        <Button
          className={styles.tabActionButton}
          variant="secondary"
          icon={<CloseIcon className={styles.tabActionButtonIcon} />}
          onClick={isExternalTab ? onClose : onRemove}
        >
          {t('common:Buttons.close')}
        </Button>

        {!isExternalTab && onWindowOpen && (
          <Button
            className={styles.tabActionButton}
            variant="secondary"
            icon={<OutIcon className={styles.tabActionButtonIcon} />}
            onClick={onWindowOpen}
          >
            {t('common:Buttons.openInNewWindow')}
          </Button>
        )}

        {isExternalTab && (
          <Button
            className={styles.tabActionButton}
            variant="secondary"
            icon={<ArrowRight className={styles.tabActionButtonIcon} />}
            onClick={onTabFocus}
          >
            {t('common:Buttons.goToWindow')}
          </Button>
        )}
      </div>

      {!!tabEntity.widgets.length && !isActive && (
        <>
          <div className={styles.divider} />
          <ul className={styles.widgetsList}>
            {tabEntity.widgets.map((widget) => (
              <li key={widget.uuid} className={styles.widgetName}>
                {widget.title}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
});
