import type {
  TWellAnalyseSerializedInternalState,
  WellAnalyseWidgetEntity,
} from 'src/pages/dashboard/features/well-analyse-widget/WellAnalyseWidget.entity';
import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

export function serializeWellAnalyseTemplate(widget: WellAnalyseWidgetEntity): TSerializedWidgetTemplate {
  const internalState: TWellAnalyseSerializedInternalState = {
    wellId: widget.wellId,
    stateParams: {
      timeZone: widget.stateParams.timeZone,
      xAxisIndex: widget.stateParams.xAxisIndex,
      operationCode: widget.stateParams.operationCode,
      legendVisible: widget.stateParams.legendVisible,
    },
  };

  return {
    uuid: widget.uuid,
    type: widget.type,
    groupId: widget.groupId,
    size: {
      w: widget.size.w,
      h: widget.size.h,
    },
    position: {
      x: widget.position.x,
      y: widget.position.y,
    },
    internalState,
  };
}
