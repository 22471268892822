import { DndContext, PointerSensor, useSensor } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToHorizontalAxis } from '@dnd-kit/modifiers';
import { SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { ArrowLeftLight as ArrowLeft, ArrowRightLight as ArrowRight } from '@profgeosoft-ui/icons';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import type { DragEndEvent } from '@dnd-kit/core';
import type { MouseEvent, TouchEvent } from 'react';
import type { TabEntity } from 'src/entities/tab/TabEntity';

import { DraggableTab } from './components/draggable-tab/DraggableTab';

import styles from './TabsList.module.scss';

type Props = {
  tabsEntities: TabEntity[];
  onSetActiveKey(key?: string): void;
  onDragEnd({ active, over }: DragEndEvent): void;
  onCreateTab(): void;
  onRemoveTab(id: string): void;
  onCloseTab?(id: string): void;
  onTabFocus?(id: string): void;
  onChangeTabName(id: string, name: string): void;
  onWindowOpen?(tabId: string): void;
  getIsTabDisabled(tab: TabEntity): boolean;
  activeKey?: string;
};

export const TabsList = observer(function TabsList({
  activeKey,
  tabsEntities,
  getIsTabDisabled,
  onSetActiveKey,
  onDragEnd,
  onRemoveTab,
  onCloseTab,
  onTabFocus,
  onChangeTabName,
  onWindowOpen,
}: Props) {
  const [showArrows, setShowArrows] = useState(false);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);
  const [isDefaultScrollInited, setDefaultScrollInited] = useState(false);
  const [isDragging, setDragging] = useState(false);

  const sensor = useSensor(PointerSensor, { activationConstraint: { distance: 10 } });

  const leftArrow = useRef<HTMLButtonElement>(null);
  const rightArrow = useRef<HTMLButtonElement>(null);
  const carouselRef = useRef<HTMLDivElement>(null);

  const frameId = useRef<number>(0);

  const tabs = tabsEntities.map((tab) => ({
    label: tab.name,
    key: tab.id,
    disabled: getIsTabDisabled(tab),
    isFocused: tab.isFocused,
    shouldScrollToViewport: tab.shouldScrollToViewport,
    entity: tab,
    onFocusChange: tab.changeFocus,
    onScrollToViewport: tab.setShouldScrollToViewport,
  }));

  useEffect(() => {
    return () => {
      cancelAnimationFrame(frameId.current);
    };
  }, []);

  const startScrolling = (direction: 'left' | 'right') => {
    if (!carouselRef.current) return;

    const currentOffset = carouselRef.current.scrollLeft;
    const currentScroll = carouselRef.current.scrollLeft + carouselRef.current.offsetWidth;
    const scrollWidth = carouselRef.current.scrollWidth;

    carouselRef.current.scrollTo({
      left: currentOffset + (direction === 'right' ? 3 : -3),
    });

    const shouldSrollToRight = currentScroll + 1 < scrollWidth && direction === 'right';
    const shouldSrollToLeft = currentOffset > 0 && direction === 'left';

    if (shouldSrollToRight || shouldSrollToLeft) {
      frameId.current = requestAnimationFrame(() => startScrolling(direction));
    }
  };

  const stopScrolling = (e: TouchEvent | MouseEvent) => {
    e.preventDefault();

    cancelAnimationFrame(frameId.current);
  };

  const resizeHandler = () => {
    const carousel = carouselRef.current;

    if (!carousel) return;

    const shouldShowArrows = carousel.scrollWidth > carousel.clientWidth;

    setShowLeftArrow(carousel.scrollLeft > 0);
    setShowRightArrow(carousel.scrollLeft + carousel.offsetWidth < carousel.scrollWidth);
    setShowArrows(shouldShowArrows);
  };

  useEffect(() => {
    resizeHandler();
  }, [tabs]);

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);

    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  useEffect(() => {
    const carousel = carouselRef.current;
    if (!carousel) return;

    const wheelHandler = (e: WheelEvent) => {
      e.preventDefault();

      carousel.scrollTo({
        left: carousel.scrollLeft + e.deltaY,
      });
    };

    carousel.addEventListener('wheel', wheelHandler);

    return () => {
      carousel.removeEventListener('wheel', wheelHandler);
    };
  }, []);

  useEffect(() => {
    const carousel = carouselRef.current;

    if (!carousel) return;

    const scrollHandler = () => {
      setShowLeftArrow(carousel.scrollLeft > 0);
      setShowRightArrow(carousel.scrollLeft + carousel.offsetWidth < carousel.scrollWidth);
    };

    carousel.addEventListener('scroll', scrollHandler);

    return () => {
      carousel.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  if (!tabs) return null;

  return (
    <nav className={styles.tabs}>
      <div className={styles.carousel} ref={carouselRef}>
        <DndContext
          sensors={[sensor]}
          onDragMove={() => setDragging(true)}
          onDragEnd={(opt) => {
            setDragging(false);
            onDragEnd(opt);
          }}
          modifiers={[restrictToHorizontalAxis, restrictToFirstScrollableAncestor]}
        >
          <SortableContext items={tabs.map((i) => i.key)} strategy={horizontalListSortingStrategy}>
            {tabs.map((tab) => (
              <DraggableTab
                isDragging={isDragging}
                onWindowOpen={onWindowOpen ? () => onWindowOpen(tab.entity.id) : void 0}
                carouselRef={carouselRef}
                key={tab.key}
                text={tab.label}
                isActive={tab.key === activeKey}
                onRemove={() => (tab.disabled ? onCloseTab?.(tab.key) : onRemoveTab(tab.key))}
                onClose={() => onCloseTab?.(tab.key)}
                onTabFocus={() => onTabFocus?.(tab.key)}
                onChangeActive={() => (tab.disabled ? null : onSetActiveKey(tab.key))}
                onChangeTabName={onChangeTabName}
                isFocused={tab.isFocused}
                onFocusChange={tab.onFocusChange}
                isDefaultScrollInited={isDefaultScrollInited}
                onDefaultScrollInited={setDefaultScrollInited}
                shouldScrollToViewport={tab.shouldScrollToViewport}
                onScrollToViewport={tab.onScrollToViewport}
                tabEntity={tab.entity}
                id={tab.key}
                isExternalTab={tab.disabled}
              >
                {String(tab.label)}
              </DraggableTab>
            ))}
          </SortableContext>
        </DndContext>
      </div>

      {showArrows && showLeftArrow && (
        <button
          type="button"
          className={clsx(styles.arrow, styles.arrowLeft)}
          ref={leftArrow}
          onMouseEnter={() => startScrolling('left')}
          onMouseLeave={stopScrolling}
          onTouchStart={() => startScrolling('left')}
          onTouchEndCapture={stopScrolling}
        >
          <ArrowLeft />
        </button>
      )}

      {showArrows && showRightArrow && (
        <button
          type="button"
          className={clsx(styles.arrow, styles.arrowRight)}
          ref={rightArrow}
          onMouseEnter={() => startScrolling('right')}
          onMouseLeave={stopScrolling}
          onTouchStart={() => startScrolling('right')}
          onTouchEndCapture={stopScrolling}
        >
          <ArrowRight />
        </button>
      )}
    </nav>
  );
});
