import { useEffect, useState } from 'react';

export const useViewStore = <T extends IViewStore>(createStore: () => T) => {
  const [service] = useState<T>(createStore);

  const { init } = service;

  useEffect(() => init?.(), [init]);

  return service;
};

export interface IViewStore {
  init?(): void | VoidFunction;
}
