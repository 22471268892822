import { makeObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

import { BaseWidgetEntityWithWellId } from 'src/entities/widget/BaseWidgetEntityWithWellId';

import type { GroupManager } from './GroupManager';
import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';

import { FilterWidgetEntity } from '../filter-widget/FilterWidget.entity';

export class GroupSelectStore {
  readonly groupManager: GroupManager;

  @observable widgets: WidgetEntity[];

  constructor(groupManager: GroupManager, widgets: WidgetEntity[]) {
    this.groupManager = groupManager;
    this.widgets = widgets;

    makeObservable(this);
  }

  getWellIdInGroup = computedFn((groupId: string | null): number | null => {
    const currentGroup = this.groupManager.getWidgetsByGroupId(groupId);

    for (const widget of currentGroup) {
      if (widget instanceof BaseWidgetEntityWithWellId && widget.wellId) {
        return widget.wellId;
      }
    }

    return null;
  });

  getUsesFilter = computedFn((groupId: string | null): boolean => {
    const currentGroup = this.groupManager.getWidgetsByGroupId(groupId);

    return !!currentGroup?.some((widget) => widget instanceof FilterWidgetEntity);
  });

  getUsesByAnotherWidgets = computedFn((groupId: string): boolean => {
    const currentGroup = this.groupManager.getWidgetsByGroupId(groupId);

    return currentGroup.length > 0;
  });
}
