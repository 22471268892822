import { action, makeObservable, observable } from 'mobx';

import { requireService } from 'src/packages/di';
import { createPromiseController, type TPromiseController } from 'src/packages/shared/utils/promise-controller';

import type { WorkspaceStore } from '../Workspace.store';
import type { TDashboardReceivingMessage } from 'src/packages/session-service/SessionService';

export class SessionRegistrationService {
  private readonly workspace: WorkspaceStore;

  @observable remoteSessionController: TPromiseController<boolean> | null = null;

  constructor(workspace: WorkspaceStore, private readonly sessionService = requireService('sessionService')) {
    this.workspace = workspace;

    makeObservable(this);
  }

  sendWorkspaceChanged(): void {
    this.sessionService.sendMessage('WORKSPACE-CHANGED');
  }

  @action.bound
  onKeepCurrentSession(): void {
    this.remoteSessionController?.resolve(true);
  }

  @action.bound
  onCloseCurrentSession(): void {
    this.remoteSessionController?.resolve(false);
  }

  @action.bound
  private async handleRemoteSession(): Promise<void> {
    this.remoteSessionController = createPromiseController<boolean>();

    const shouldKeepSession = await this.remoteSessionController;

    if (shouldKeepSession) {
      this.sessionService.forceRegisterSession();
      return;
    }

    this.sessionService.setStatus('closed');
  }

  private onRegisterSession = (message: TDashboardReceivingMessage): void => {
    if (message !== 'OK') return;

    this.sessionService.setStatus('allowed');
  };

  private onRegistrationError = (message: TDashboardReceivingMessage): void => {
    if (message !== 'ERROR-CONCURRENT-SESSION') return;

    this.sessionService.setStatus('error');
    this.handleRemoteSession();
  };

  private onCloseSession = (message: TDashboardReceivingMessage): void => {
    if (message !== 'CLOSE-SESSION') return;

    this.sessionService.setStatus('closed');
    this.workspace.closeInitedTabs();
    this.workspace.destroy();
  };

  init = (): void => {
    this.sessionService.subscribe(this.onRegisterSession);
    this.sessionService.subscribe(this.onRegistrationError);
    this.sessionService.subscribe(this.onCloseSession);

    this.sessionService.registerSession();
  };

  dispose = (): void => {
    this.sessionService.unsubscribe(this.onRegisterSession);
    this.sessionService.unsubscribe(this.onRegistrationError);
    this.sessionService.unsubscribe(this.onCloseSession);
  };
}
