import { useDndContext } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { BellPinFill, CloseRound as CloseIcon } from '@profgeosoft-ui/icons';
import { Tooltip } from '@profgeosoft-ui/react';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';

import { useOutsideClick } from 'src/packages/shared/hooks/use-outside-click';

import type { ChangeEvent, KeyboardEvent, MouseEvent, RefObject } from 'react';
import type { TabEntity } from 'src/entities/tab/TabEntity';

import { MAX_TAB_NAME_LENGTH } from '../../TabsList.consts';
import { TabTooltipContent } from '../tab-tooltip-content/TabTooltipContent';

import styles from './DraggableTab.module.scss';

interface DraggableTabPaneProps extends React.HTMLAttributes<HTMLDivElement> {
  carouselRef: RefObject<HTMLDivElement>;
  isActive: boolean;
  id: string;
  text: string;
  isDefaultScrollInited: boolean;
  isFocused: boolean;
  shouldScrollToViewport: boolean;
  tabEntity: TabEntity;
  isExternalTab: boolean;
  isDragging: boolean;
  onDefaultScrollInited(value: boolean): void;
  onRemove(): void;
  onClose(): void;
  onTabFocus(): void;
  onChangeActive(): void;
  onChangeTabName(id: string, name: string): void;
  onFocusChange(value: boolean): void;
  onScrollToViewport(value: boolean): void;
  onWindowOpen?(): void;
}

export const DraggableTab = observer(function DraggableTab({
  className,
  isActive,
  text,
  id,
  isFocused,
  carouselRef,
  isDefaultScrollInited,
  shouldScrollToViewport,
  tabEntity,
  isExternalTab,
  isDragging,
  onFocusChange,
  onTabFocus,
  onChangeActive,
  onClose,
  onRemove,
  onChangeTabName,
  onDefaultScrollInited,
  onScrollToViewport,
  onWindowOpen,
  ...props
}: DraggableTabPaneProps) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });
  const { active } = useDndContext();
  const isCurrentTabDragging = active?.id === id;

  const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(text);
  const [inputWidth, setInputWidth] = useState(0);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const span = useRef<HTMLSpanElement>(null);

  const style: React.CSSProperties = {
    ...props.style,
    transform: transform ? `translate(${transform.x}px, 0) scaleX(1) scaleY(1)` : undefined,
    transition,
  };

  const showCloseButton = !isExternalTab && !isDragging;

  const onInputBlur = async () => {
    if (inputValue.length === 0 || text === inputValue) {
      onFocusChange(false);
      setInputValue(text);

      return;
    }

    onChangeTabName(id, inputValue);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    if (value.length > MAX_TAB_NAME_LENGTH) return;

    setInputValue(value);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onInputBlur();
    }
  };

  const removeTab = (e: MouseEvent) => {
    e.stopPropagation();
    onRemove();
  };

  useOutsideClick(inputRef, onInputBlur, [inputRef]);

  useEffect(() => {
    if (isActive) {
      setOpen(false);
    }
  }, [isActive]);

  useEffect(() => {
    if (span.current) {
      setInputWidth(span.current.offsetWidth);
    }
  }, [inputValue, isFocused]);

  useEffect(() => {
    if (!wrapperRef.current || !carouselRef.current) return;

    if (isActive && !isDefaultScrollInited) {
      carouselRef.current.scrollTo({
        left: wrapperRef.current.offsetLeft,
      });

      onDefaultScrollInited(true);
    }
  }, [isActive, carouselRef, isDefaultScrollInited, onDefaultScrollInited]);

  useEffect(() => {
    if (!wrapperRef.current || !carouselRef.current) return;

    if (shouldScrollToViewport) {
      carouselRef.current.scrollTo({
        left: wrapperRef.current.offsetLeft,
        behavior: 'smooth',
      });

      onScrollToViewport(false);
    }
  }, [shouldScrollToViewport, carouselRef, onScrollToViewport]);

  const { alarm } = tabEntity;

  return (
    <Tooltip
      trigger={['hover']}
      delay={500}
      className={styles.tooltipOverlay}
      onOpenChange={setOpen}
      title={
        <TabTooltipContent
          isActive={isActive}
          tabEntity={tabEntity}
          onWindowOpen={onWindowOpen}
          onTabFocus={onTabFocus}
          onClose={onClose}
          onRemove={onRemove}
          isExternalTab={isExternalTab}
        />
      }
      placement="bottom"
      open={isOpen}
    >
      <div
        ref={wrapperRef}
        className={clsx(
          styles.wrapper,
          isFocused && styles.wrapperFocused,
          isCurrentTabDragging && styles.wrapperDragging,
          alarm && styles.wrapperAlarm
        )}
      >
        <button
          style={style}
          {...attributes}
          {...(!isExternalTab ? listeners : {})}
          ref={setNodeRef}
          className={clsx(
            styles.tab,
            isActive && styles.tabActive,
            isExternalTab && styles.tabDisabled,
            isCurrentTabDragging && styles.tabDragging
          )}
          type="button"
          onClick={onChangeActive}
          onDoubleClick={() => onFocusChange(true)}
        >
          <span ref={span} className={styles.hiddenSpan}>
            {inputValue}
          </span>
          {isFocused ? (
            <input
              ref={inputRef}
              value={inputValue}
              type="text"
              onChange={onInputChange}
              onKeyDown={handleKeyDown}
              onBlur={onInputBlur}
              onPointerDown={(e) => e.stopPropagation()}
              className={styles.input}
              style={{ width: inputWidth ?? 'auto' }}
              autoFocus
            />
          ) : (
            <span className={styles.tabName}>
              {props.children} {alarm && !isFocused && <BellPinFill className={styles.alarmIcon} />}
            </span>
          )}
        </button>
        {showCloseButton && (
          <button onClick={removeTab} type="button" className={styles.closeButton}>
            <CloseIcon />
          </button>
        )}
      </div>
    </Tooltip>
  );
});
