import { observer } from 'mobx-react-lite';
import { useState, useEffect } from 'react';

import type { FilterMultiCombobox } from '../../entities/FilterMultiCombobox.entity';

import { FormMultiSelect } from '../form-multiselect/FormMultiselect';

type Props = {
  className?: string;
  label?: string;
  item: FilterMultiCombobox;
  isDisabled: boolean;
  isLoading: boolean;
  onBlur?: VoidFunction;
};

export const FilterMulticombobox = observer(function FilterMulticombobox({
  className,
  label,
  item,
  isDisabled,
  isLoading,
  onBlur,
}: Props) {
  const [isOpened, setIsOpened] = useState(false);

  const { effect } = item;

  const onChange = (value: (string | number)[]): void => {
    item.tryToSetRawValue(value);
    item.clearError();
    item.validate();
  };

  const onDropdownVisibleChange = (open: boolean): void => {
    setIsOpened(open);
  };

  useEffect(effect, [effect]);

  return (
    <FormMultiSelect
      placeholder={item.placeholder}
      open={isOpened}
      loading={isLoading}
      value={item.value}
      label={label}
      className={className}
      errorText={item.errorText}
      options={item.options}
      isDisabled={isDisabled}
      dropdownStyle={{
        zIndex: 1000,
      }}
      onDropdownVisibleChange={onDropdownVisibleChange}
      onBlur={onBlur}
      onClear={item.clearItem}
      onChange={onChange}
    />
  );
});
