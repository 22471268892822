import { parseWellAnalyseWidgetInternalState } from 'src/pages/dashboard/features/well-analyse-widget/parseWellAnalyseWidget';

import type { TSerializedWidget } from 'src/entities/workspace/types';
import type { WellAnalyseWidgetEntity } from 'src/pages/dashboard/features/well-analyse-widget/WellAnalyseWidget.entity';

import { baseWidgetApplyChanges } from './baseWidgetApplyChanges';

export function wellAnalyseApplyChanges(widget: WellAnalyseWidgetEntity, changes: TSerializedWidget, index: number) {
  baseWidgetApplyChanges(widget, changes, index);

  const internalState = parseWellAnalyseWidgetInternalState(changes.internalState);

  widget.setWellId(internalState.wellId);
  widget.setStateParams(internalState.stateParams);
}
