import { makeObservable, observable } from 'mobx';

export type CollapseItemOptions = {
  name: string;
  id: number;
};

export type WellItemOptions = CollapseItemOptions & {
  status: string;
};

export interface IWellCollapseItemEntity {
  name: string;
  id: number;
}

export interface IWellItemEntity extends IWellCollapseItemEntity {
  status: string;
}

export class WellCollapseItemEntity implements IWellCollapseItemEntity {
  @observable name: string;
  @observable id: number;

  constructor({ name, id }: CollapseItemOptions) {
    this.name = name;
    this.id = id;

    makeObservable(this);
  }
}

export class WellItemEntity extends WellCollapseItemEntity implements IWellItemEntity {
  @observable status: string;

  constructor({ name, id, status }: WellItemOptions) {
    super({ name, id });

    this.status = status;

    makeObservable(this);
  }
}
