import type { TRenderWidget, TWidgetRendererOptions } from './WidgetRendererService';
import type { WellDetailsWidgetEntity } from '../../well-details-widget/WellDetailsWidget.entity';

import { WellDetailsWidgetWrapper } from '../../well-details-widget/WellDetailsWidget';

export function renderWellDetailsWidget(
  widget: WellDetailsWidgetEntity,
  options: TWidgetRendererOptions
): TRenderWidget {
  return () => <WellDetailsWidgetWrapper entity={widget} options={options} />;
}
