import { requireService } from 'src/packages/di';

import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';
import type { TSerializedWidgetTemplate } from 'src/pages/dashboard/features/workspace/Workspace.store';

export function serializeWidgetsTemplates(widgets: WidgetEntity[]): TSerializedWidgetTemplate[] {
  return widgets
    .slice()
    .sort((a, b) => a.zIndex - b.zIndex)
    .map((widget) => {
      return serializeWidgetTemplate(widget);
    });
}

function serializeWidgetTemplate(widget: WidgetEntity): TSerializedWidgetTemplate {
  const serializeWdigetService = requireService('widgetTemplateSerializer');

  return serializeWdigetService.serialize(widget);
}
