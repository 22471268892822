import type { Item } from 'src/pages/dashboard/features/controls/abstract-control-entities';
import type { TItemRaw, TSavedFormItems, TSavedFormValue } from 'src/pages/dashboard/features/controls/types';

import { mapComboBoxItem } from './mapComboBoxItem';
import { mapDateIntervalFieldItem } from './mapDateIntervalField.serializer';
import { mapFilterMulticombobox } from './mapFilterMulticombobox';

export const mapItems = (items: TItemRaw[], savedItems?: TSavedFormItems): Item[] => {
  return items.map((item) => {
    const savedValue = Array.isArray(savedItems)
      ? undefined
      : (!!item.attrName && savedItems?.[item.attrName]) || savedItems?.[item.fieldId];
    return mapItem(item, savedValue);
  });
};

export const mapItem = (item: TItemRaw, savedValue?: TSavedFormValue): Item => {
  switch (item.control) {
    case 'ComboBox':
      return mapComboBoxItem({ item, savedValue });
    case 'MultiComboBox':
      return mapFilterMulticombobox({ item });
    case 'DateRange':
      return mapDateIntervalFieldItem({ item, savedValue });
    default: {
      console.error('Unable to recognize the item', item);
      throw new Error('Unable to recognize the item');
    }
  }
};
