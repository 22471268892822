import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';
import type { TSerializedWidget } from 'src/entities/workspace/types';

type TApplyerFunction = (widget: WidgetEntity, changes: TSerializedWidget, index: number) => void;

export interface IWidgetChangesApplyerService {
  applyChanges<T extends WidgetEntity>(widget: T, changes: TSerializedWidget, index: number): void;
  register<T extends WidgetEntity>(
    type: string,
    applyer: (widget: T, changes: TSerializedWidget, index: number) => void
  ): void;
}

export class WidgetChangesApplyerService implements IWidgetChangesApplyerService {
  private collection: Map<string, TApplyerFunction> & {
    get<T extends WidgetEntity>(type: string): (widget: T, changes: TSerializedWidget, index: number) => void;
    set<T extends WidgetEntity>(
      type: string,
      applyer: (widget: T, changes: TSerializedWidget, index: number) => void
    ): void;
  } = new Map();

  applyChanges(widget: WidgetEntity, changes: TSerializedWidget, index: number): void {
    const applyer = this.collection.get(widget.type);

    if (!applyer) {
      const errorMessage = `${widget.type} applyer not found`;

      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    applyer(widget, changes, index);
  }

  register<T extends WidgetEntity>(
    type: string,
    applyer: (widget: T, changes: TSerializedWidget, index: number) => void
  ): void {
    if (this.collection.has(type)) {
      const errorMessage = `${type} applyer already registered`;

      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    this.collection.set(type, applyer);
  }
}
