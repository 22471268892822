import { useEffect } from 'react';

import type { RefObject } from 'react';

export const useOutsideClick = <T extends HTMLElement>(
  ref: RefObject<T>,
  outsideClickCallback: () => void,
  excludeElements: RefObject<HTMLElement>[] | undefined = []
) => {
  const handleClickOutside = (event: MouseEvent) => {
    if (!(event.target instanceof Node)) return;

    const node = event.target;

    if (
      ref.current &&
      !ref.current.contains(node) &&
      !excludeElements.some((el) => el.current && el.current?.contains(node))
    ) {
      outsideClickCallback();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};
