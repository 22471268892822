import { makeObservable, observable } from 'mobx';
import { computedFn } from 'mobx-utils';

import type { TGroup, TabEntity } from 'src/entities/tab/TabEntity';
import type { WidgetEntity } from 'src/entities/widget/WidgetEntity';

export class GroupManager {
  @observable.shallow groups: TGroup[];
  @observable tab: TabEntity;

  constructor(tab: TabEntity, initialGroups: TGroup[]) {
    this.groups = initialGroups;
    this.tab = tab;

    makeObservable(this);
  }

  getWidgetsByGroupId = computedFn((groupId: string | null): WidgetEntity[] => {
    if (groupId === null) return [];

    return this.tab.widgets.filter((widget) => groupId && widget.groupId === groupId);
  });

  findEmptyGroup(): string | null {
    for (const group of this.groups) {
      const widgetsInGroup = this.getWidgetsByGroupId(group.id);

      if (widgetsInGroup.length === 0) {
        return group.id;
      }
    }

    return null;
  }
}
