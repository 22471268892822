import { RadioButtonGroup } from '@profgeosoft-ui/react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { THEMES } from 'src/app/theme/consts';
import { useService } from 'src/packages/di';

export const ThemeSwitcher = observer(function ThemeSwitcher() {
  const theme = useService('theme');
  const { t } = useTranslation();

  const themeItems = [
    {
      value: THEMES.dark,
      label: t('common:Theme.dark'),
    },
    {
      value: THEMES.light,
      label: t('common:Theme.light'),
    },
  ];

  return (
    <div>
      <RadioButtonGroup items={themeItems} value={theme.theme} size="m" onChange={theme.select} />
    </div>
  );
});
