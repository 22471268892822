import type { IDirectoriesService } from './IDirectoriesService';
import type { IServicesCollection } from 'src/packages/di';

import { DirectoriesService } from './DirectoriesService';

declare module 'src/packages/di' {
  export interface ServicesCollectionMap {
    directories: IDirectoriesService;
  }
}

export async function addDirectoriesService(di: IServicesCollection): Promise<void> {
  const directories = new DirectoriesService();

  di.addSingleton('directories', directories);
}
