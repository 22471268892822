import { action, autorun, computed, makeObservable, observable } from 'mobx';

import { requireService } from 'src/packages/di';
import { hasValue } from 'src/packages/shared/utils/common';

import type {
  IRestrictions,
  TAttrConcatRefs,
  TEnableIf,
  TFormula,
  TOnChangeComboboxInstructions,
  TOption,
  TRequiredIf,
  TVisuallyDisabled,
} from './types';
import type { TDictObject, TJoinResponse, TPlainDictObject, TRefQuery, TRefRest } from 'src/packages/directories/types';

export const checkIsRegularDirectory = (directory: unknown): directory is TPlainDictObject => {
  return !!directory && typeof directory === 'object' && 'data' in directory && 'id' in directory;
};

export interface IFormElement {
  formElementRefId?: string;
}

type TControlDisablingObject = {
  flagId: number;
  value: boolean;
};

export abstract class Item<T = unknown> implements IFormElement {
  private readonly _isVisuallyDisabledTrueVotes: Set<number> = observable.set();
  private readonly _isDisabledTrueVotes: Set<number> = observable.set();

  formElementRefId;
  fieldId: string;
  label?: string;
  valueFromDirectory: T | null;
  comment?: string;
  calculatedValue?: TFormula;
  visuallyDisabled?: TVisuallyDisabled[];
  validateByServer?: boolean;
  validationTags?: string[];

  constructor(item: IItemProps) {
    this.visuallyDisabled = item.visuallyDisabled;
    this.calculatedValue = item.calculatedValue;
    this.formElementRefId = item.formElementRefId;
    this.fieldId = item.fieldId;
    this.comment = item.comment;
    this.label = item.label;
    this.validateByServer = item.validate;
    this.validationTags = item.validationTags;
    this.valueFromDirectory = null;

    makeObservable(this);
  }

  abstract get value(): T;
  abstract get initialValue(): T;

  abstract setValue(value: T, setValueAsInitial?: boolean): void;
  abstract setInitialValue(value: T): void;
  abstract returnInitialValue(): void;
  abstract tryToSetRawValue(value: unknown, setValueAsInitial?: boolean): boolean;

  abstract clearItem(): void;
  abstract clearInitialValue(): void;

  setValueFromDirectory(value: T) {
    this.valueFromDirectory = value;
  }

  @computed
  get isVisuallyDisabled(): boolean {
    return !!this._isVisuallyDisabledTrueVotes.size;
  }

  @computed
  get isDisabled(): boolean {
    return !!this._isDisabledTrueVotes.size;
  }

  @action.bound
  setIsVisuallyDisabled(object: TControlDisablingObject) {
    if (object.value) {
      this._isVisuallyDisabledTrueVotes.add(object.flagId);
    } else {
      if (this._isVisuallyDisabledTrueVotes.has(object.flagId)) {
        this._isVisuallyDisabledTrueVotes.delete(object.flagId);
      }
    }
  }

  @action.bound
  setIsDisabled(object: TControlDisablingObject) {
    if (object.value) {
      this._isDisabledTrueVotes.add(object.flagId);
    } else {
      if (this._isDisabledTrueVotes.has(object.flagId)) {
        this._isDisabledTrueVotes.delete(object.flagId);
      }
    }
  }
}

export abstract class ValidatableItem<T> extends Item<T> {
  @observable errorText?: string | [string, { [locKey: string]: number | string }];

  useInMainProgressBar: boolean;
  @observable readonly restrictions: IRestrictions;
  readonly requiredIf?: TRequiredIf[];

  constructor(item: TValidatableItemProps) {
    super(item);
    this.errorText = undefined;
    this.useInMainProgressBar = !!item.useInMainProgressBar;
    this.restrictions = item.restrictions;
    this.requiredIf = item.requiredIf;

    makeObservable(this);
  }

  abstract clearError(): void;

  @action.bound
  setError(error: TControlError): void {
    if (!this.isVisuallyDisabled) {
      this.errorText = error;
    }
  }

  abstract hasErrors(): boolean;

  abstract checkIsReady(): boolean;

  @action.bound
  setIsRequired(is: boolean) {
    this.restrictions.required = is;
  }

  @computed
  get isReady(): boolean {
    return this.checkIsReady();
  }
}

export abstract class Field<T> extends ValidatableItem<T> {
  placeholder?: string;
  unit?: string;

  constructor(props: TFieldProps) {
    super(props);
    this.placeholder = props.placeholder;
    this.unit = props.unit;
  }
}

type TComboboxData = TValidatableItemProps & {
  refQuery?: TRefQuery;
  refRest?: TRefRest;
  placeholder?: string;
  onChangeInstructions?: TOnChangeComboboxInstructions;
  refObjectFilterByFields?: Record<string, string>;
  refObjectType?: string;
  refObjectAttr?: string;
  attrConcat?: string[];
  attrConcatRefs?: TAttrConcatRefs;
  delimiter?: string;
  directory: TDictObject[] | TJoinResponse[] | null;
};

export abstract class Combobox<T = unknown, O = TOption> extends ValidatableItem<T | null> {
  readonly refQuery?: TRefQuery;
  readonly placeholder?: string;
  readonly refRest?: TRefRest;
  readonly onChangeInstructions?: TOnChangeComboboxInstructions;
  readonly refObjectFilterByFields?: Record<string, string>;
  readonly refObjectType?: string;
  readonly refObjectAttr?: string;
  readonly attrConcat?: string[];
  readonly attrConcatRefs?: TAttrConcatRefs;
  readonly delimiter?: string;

  @observable directory: TDictObject[] | TJoinResponse[] | null = null;
  @observable filterValues: Record<string, unknown> | null = null;
  @observable invalidValue: string | null = null;

  constructor(
    data: TComboboxData,
    protected readonly directories = requireService('directories'),
    protected readonly i18 = requireService('i18')
  ) {
    super(data);

    this.directory = data.directory;
    this.refQuery = data.refQuery;
    this.refRest = data.refRest;
    this.placeholder = data.placeholder;
    this.onChangeInstructions = data.onChangeInstructions;
    this.refObjectFilterByFields = data.refObjectFilterByFields;
    this.refObjectType = data.refObjectType;
    this.refObjectAttr = data.refObjectAttr;
    this.attrConcat = data.attrConcat;
    this.attrConcatRefs = data.attrConcatRefs;
    this.delimiter = data.delimiter;

    makeObservable(this);
  }

  abstract get options(): O[];

  protected serializeDataToOptions(): TOption[] {
    const directory = this.directory;

    if (!directory) {
      return [];
    }

    return Combobox.checkIsRegularDirectories(directory) ? this.serializeDirectoryToOptions(directory) : [];
  }

  private serializeDirectoryToOptions(directory: TDictObject[]): TOption[] {
    if (!this.refObjectAttr) return [];
    const options = [];
    optionCycle: for (const dirValue of directory) {
      if (!dirValue.data[this.refObjectAttr] || !hasValue(dirValue.id)) {
        continue;
      }
      if (this.filterValues) {
        for (const key in this.filterValues) {
          if (dirValue.data[key] !== this.filterValues[key]) {
            continue optionCycle;
          }
        }
      }
      const dirValueAttr = dirValue.data[this.refObjectAttr];

      if (!dirValueAttr) continue;
      options.push({
        label: dirValueAttr.toString(),
        value: dirValue.id,
      });
    }

    return options;
  }

  private getDirectoryForFiltration(): TDictObject | null {
    const directory = this.directory;

    if (!directory) {
      return null;
    }

    if (Combobox.checkIsRegularDirectories(directory)) {
      return directory.find((directoryValue) => directoryValue.id === this.value) || null;
    } else {
      const refObjectType = this.refObjectType;

      if (!refObjectType) {
        return null;
      }

      for (const joinDirectoryValue of directory) {
        if (joinDirectoryValue[refObjectType]) {
          return joinDirectoryValue[refObjectType];
        }
      }

      return null;
    }
  }

  private trackOptionsAndResetWrongValue(): VoidFunction {
    const disposer = autorun(() => {
      if (Array.isArray(this.value)) {
        let filteredValues = [...this.value];

        for (const item of this.value) {
          const isExists = !!this.options.find((option) => {
            if (!!option && typeof option === 'object' && 'value' in option) {
              return option['value'] === item;
            }
            return false;
          });

          if (!isExists) {
            filteredValues = filteredValues.filter((valueItem) => valueItem !== item);
          }
        }

        // проверяем длину текущих выбранных значений и отфильтрованных текущих значений для понимания, нужно ли обновлять текущее значение контрола или нет.
        if (filteredValues.length !== this.value.length) {
          this.tryToSetRawValue([...filteredValues]);
        }
      } else {
        const isCurrentValuePresentedInOptions = !!this.options.find((option) => {
          if (!!option && typeof option === 'object' && 'value' in option) {
            return option['value'] === this.value;
          }

          return false;
        });

        if (!this.invalidValue && !isCurrentValuePresentedInOptions) {
          this.setValue(null);
        }
      }
    });

    return disposer;
  }

  effect = (): VoidFunction => {
    const trackOptionsAndResetWrongValueDisposer = this.trackOptionsAndResetWrongValue();

    return () => {
      trackOptionsAndResetWrongValueDisposer();
    };
  };

  @action.bound
  setDirectory(directory: TDictObject[] | TJoinResponse[]) {
    this.directory = directory;
  }

  @action.bound
  setFilteringValues(filter: Record<string, unknown>): void {
    this.filterValues = filter;

    const directory = this.getDirectoryForFiltration();

    if (directory) {
      for (const key in filter) {
        if (directory.data[key] !== this.filterValues[key]) {
          this.clearItem();
          return;
        }
      }
    }
  }

  static checkIsRegularDirectories(directory: unknown): directory is TDictObject[] {
    return Array.isArray(directory) && directory.every((dirValue) => checkIsRegularDirectory(dirValue));
  }
}

export interface IItemProps {
  formElementRefId?: string;
  fieldId: string;
  validate?: boolean;
  validationTags?: string[];
  calculatedValue?: TFormula;
  enableIf?: TEnableIf[];
  visuallyDisabled?: TVisuallyDisabled[];
  comment?: string;
  label?: string;
}

export interface TValidatableItemProps extends IItemProps {
  useInMainProgressBar?: boolean;
  restrictions: IRestrictions;
  requiredIf?: TRequiredIf[];
}

export interface TFieldProps extends TValidatableItemProps {
  placeholder?: string;
  unit?: string;
}

export type TNumberIntervalValue = {
  start: number | null;
  end: number | null;
};

export type TControlError =
  | string
  | [
      string,
      {
        [locKey: string]: string | number;
      }
    ];

export type TLabelValue = {
  label: string;
  value: number[] | number | null;
};
